import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { RewardState } from './reward.type';


function createInitialState(): RewardState {
  return {
    seenCodes: [],
    rewards: [],
    pcaCodes: [],
    ttp: null,
    tpp: null,
    tppc: null,
    oldUser: null,
    ppRatio: null,
    success: null,
    totalEarnedPoints: null,
    totalRedeemedPoints: null,
    totalPointsBalance: null,
    profileRewards: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'rewards', resettable: true })
export class RewardStore extends Store<RewardState> {
  constructor() {
    super(createInitialState());
  }
}
