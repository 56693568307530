import {
  MOBILE_VIEW_THRESHOLD_PX,
  ROUTE_CREATE_ACCOUNT,
  ROUTE_LANDING,
  ROUTE_LOGIN,
} from '@1bill-app/constants';
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { BehaviorSubject, combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { ApiService } from './api/api.service';
import { AuthQuery } from './auth/state/auth.query';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { ActionDeniedModalComponent } from '@1bill-app/components/action-denied-modal/action-denied-modal.component';

const semverLt = require('semver/functions/lt');
const semverCoerce = require('semver/functions/coerce');
@Injectable({
  providedIn: 'root',
})
export class UIService {
  constructor(private router: Router, private authQuery: AuthQuery, private api: ApiService, private modalController: ModalController) {
    Network.addListener('networkStatusChange', (status) => {
      this.networkStatusSubject.next(status.connected);
    });
    // Set initial network status
    Network.getStatus().then((status) => {
      this.networkStatusSubject.next(status.connected);
    });
  }

  private isDesktop = new BehaviorSubject<boolean>(false);
  private _isSplitPaneVisible = new BehaviorSubject<boolean>(false);
  private networkStatusSubject = new BehaviorSubject<boolean>(null);
  isSplitPaneVisible$ = this._isSplitPaneVisible.asObservable().pipe(distinctUntilChanged());
  networkStatus$ = this.networkStatusSubject.asObservable().pipe(distinctUntilChanged());

  public isLoggedIn$ = this.authQuery.isLoggedIn$;

  /**
   * A quick function to get network status. This is necessary as the observable networkStatus$ is not accessible from constructors.
   * Some constructors (e.g. GoogleAddressService) may require an internet connection else they will throw an error which will run
   * through each component that initialises it.
   * @returns A promise of connection status
   */
  async getNetworkStatus() {
    return Network.getStatus();
  }
  onResize(size: number) {
    this.isDesktop.next(size >= MOBILE_VIEW_THRESHOLD_PX);
  }
  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
  isDisableMenu() {
    return combineLatest([
      this.router.events.pipe(
        filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
      ),
      this.isLoggedIn$,
    ]).pipe(
      map(([event, loggedIn]) => {
        const url = (event as NavigationEnd | NavigationEnd).url;
        // this.logger.debug('isDisableMenu objects', { event, loggedIn });
        const disable = url.includes('/auth/') || url.includes('/first-bill/') || !loggedIn;
        // this.logger.debug('isDisableMenu', disable);
        return disable;
      }),
    );
  }
  isHideDesktopHeader() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd),
      map((event: NavigationEnd | NavigationEnd) => {
        const url = event.urlAfterRedirects ? event.urlAfterRedirects : event.url;
        return (
          url === ROUTE_LANDING ||
          url === ROUTE_LOGIN ||
          url === '/' ||
          url === ROUTE_CREATE_ACCOUNT
        );
      }),
      startWith(false),
    );
  }
  updateHeaderCss() {
    if (this.isDesktop.value) {
    }
  }
  updateSplitPaneVisiblity(value: boolean) {
    this._isSplitPaneVisible.next(value);
  }

  /**
   * Checks wthether native app's new version is available.
   * @returns
   */
  newAppVersionAvailable(): Observable<{
    newVersionAvailable: boolean;
    forceUpdateApp: boolean;
  }> {
    const getAppInfo = () =>
      Capacitor.isNativePlatform() ? App.getInfo() : Promise.resolve(null as AppInfo);
    return forkJoin([this.api.appVersion(), from(Device.getInfo()), from(getAppInfo())]).pipe(
      distinctUntilChanged(),
      map((res) => {
        const appVersionApi = res[0];
        const deviceInfo = res[1];
        const appInfo = res[2];

        if (appVersionApi.success) {
          const { android, ios } = appVersionApi.version;
          if (Capacitor.isNativePlatform()) {
            const currentVersion = appInfo.version;
            const platform = deviceInfo.platform;
            if (platform === 'ios') {
              return {
                newVersionAvailable: semverLt(semverCoerce(currentVersion), semverCoerce(ios)),
                forceUpdateApp: appVersionApi.version?.forceUpdateApp,
              };
            } else if (platform === 'android') {
              return {
                newVersionAvailable: semverLt(
                  semverCoerce(currentVersion),
                  semverCoerce(android),
                ),
                forceUpdateApp: appVersionApi.version?.forceUpdateApp,
              };
            }
          } else {
            return {
              newVersionAvailable: false,
              forceUpdateApp: false,
            };
          }
        } else {
          return { newVersionAvailable: false, forceUpdateApp: false };
        }
      }),
      catchError((err) => {
        return of({ newVersionAvailable: false, forceUpdateApp: false });
      }),
    );
  }

  /**
   * Presents a modal that informs the user why they cannot perform a certain action.
   * @param topText The text at the top of the modal.
   * @param bottomText The text near the bottom of the modal.
   * @param buttonText The text in the button at the bottom of the modal.
   * @param route The route that the user will be taken to when they click the 
   * @param routeSource The route that the modal was called from.
   * @param modalElementId The ID of the element. This is used to check for
   *   duplicates. There is a default ID that will check for duplicates against all
   *   modals where no ID was provided.
   * @param classes Any CSS classes to add onto the modal. `action-denied-modal` is
   *   always applied.
   * @param onDismiss A function to run when the modal is dismissed.
   * @returns The Ionic modal object
   */
   async presentActionDeniedModal(
    topText: string,
    bottomText?: string,
    buttonText?: string,
    route?: string,
    routeSource?: string,
    modalElementId = 'action-denied-modal',
    classes?: string[],
    onDismiss?: () => void,
  ) {
    const noDuplicateModal = !document.getElementById(modalElementId);
    if (noDuplicateModal) {
      return await this.modalController.create({
        id: modalElementId,
        component: ActionDeniedModalComponent,
        cssClass: `action-denied-modal ${(classes ?? []).join(' ')}`,
        componentProps: {
          text1: topText,
          text2: bottomText,
          buttonText,
          route,
          routeSource,
        },
        backdropDismiss: false,
      }).then((modal) => {
        modal.present();
        modal.onWillDismiss().then(onDismiss);
        return modal;
      });
    }
  }
}
