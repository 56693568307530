import { Component, Input, Output, EventEmitter } from '@angular/core';
import { sum, first, last, groupBy } from 'lodash';
import dayjs from 'dayjs';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { NGXLogger } from 'ngx-logger';
import { CSS_COLOR_LIGHTERPRIMARY } from '@1bill-app/constants';
import { UserBillPayment } from '@1bill-app/services/bill/types';

interface BillsChartData {
  /**
   * Summed due amount of bills matching a category in one month
   */
  amount: number;
  /**
   * Height (in %) of the bar segment
   */
  segmentHeight?: number;
  /**
   * Indicates whether or not the top corners should be curved as first segment
   */
  firstSegment?: boolean;
  /**
   * Indicates whether or not the bottom corners should be curved as last segment
   */
  lastSegment?: boolean;
}

interface BillsChartSeriesItem {
  /**
   * Data of bills which fall under the 'Paid' category
   */
  paid: BillsChartData;
  /**
   * Data of bills which fall under the 'Unpaid' category
   */
  unpaid: BillsChartData;
  /**
   * Data of bills which fall under the 'Unpaid & Overdue' category
   */
  overdue: BillsChartData;
  /**
   * Summed due amount of bills due in a particular month
   */
  totalAmount: number;
  /**
   * Three-letter month identifier, e.g. 'Jan'
   */
  label: string;
  /**
   * Format: YYYY/MM
   */
  monthId: string;
  /**
   * Height (in px) of the bar
   */
  barHeight?: number;
  /**
   * Whether selected by the user by clicking or default; data will be displayed in .chart-overview-wrapper
   */
  active?: boolean;
}

enum BillPaymentStatusCategory {
  PAID = 'Paid',
  UNPAID = 'Unpaid',
  OVERDUE = 'Unpaid & Overdue',
}

@Component({
  selector: 'app-bills-chart',
  templateUrl: './bills-chart.component.html',
  styleUrls: ['./bills-chart.component.scss'],
})
export class BillsChart {
  @Input() loggedIn: boolean;
  @Input() dummy: boolean;
  @Output() chartLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  barChartData: {
    seriesData: BillsChartSeriesItem[];
    barChartMax: number;
    barChartAvg: number;
  };

  selectedBarAmount: number;
  selectedBarMonth: string;
  avgLineHeightOffset: number;

  loadingChart = true;
  error = false;

  dueDate: string;
  amount: number;

  dummyBillData = [
    { dueDate: '2022-01-01', amount: 100000.0 },
    { dueDate: '2021-02-02', amount: 110000.0 },
    { dueDate: '2021-03-03', amount: 120000.0 },
    { dueDate: '2021-04-04', amount: 130000.0 },
    { dueDate: '2021-05-05', amount: 140000.0 },
    { dueDate: '2021-06-06', amount: 150000.0 },
    { dueDate: '2021-07-07', amount: 160000.0 },
    { dueDate: '2021-08-08', amount: 150000.0 },
    { dueDate: '2021-09-09', amount: 140000.0 },
    { dueDate: '2021-10-10', amount: 130000.0 },
    { dueDate: '2021-11-11', amount: 120000.0 },
    { dueDate: '2021-12-12', amount: 100000.0 },
  ];

  /**
   * Height of the largest bar on the graph in pixels
   */
  readonly MAX_BAR_HEIGHT = 200;

  readonly GRID_LINES_COUNT = 4;

  readonly PAYMENT_STATUS_LEGEND_CONFIG = [
    {
      name: BillPaymentStatusCategory.PAID,
      key: 'paid',
      fill: true,
      colour: CSS_COLOR_LIGHTERPRIMARY,
      filterLogic: (bill: UserBillPayment) => {
        // Previous filter included negative amounts (bills in credit) but this caused confusing
        // display on the bills chart as the bar segment would exist with 0 height.
        return bill.amount >= 0 && !!bill.datePaid;
      },
    },
    {
      name: BillPaymentStatusCategory.UNPAID,
      key: 'unpaid',
      fill: true,
      colour: '#acffe1',
      filterLogic: (bill: UserBillPayment) => {
        return !bill.datePaid && dayjs(bill.dueDate).isAfter(dayjs(), 'day');
      },
    },
    {
      name: BillPaymentStatusCategory.OVERDUE,
      key: 'overdue',
      fill: true,
      colour: '#d8545c',
      filterLogic: (bill: UserBillPayment) => {
        return bill.amount > 0 && !bill.datePaid && dayjs().isAfter(bill.dueDate);
      },
    },
  ];

  /**
   * The rightmost bar of the chart will be either the latest month on which the user has
   * a bill due, or the current month, whichever is later. This number specifies how many
   * months back the chart will go, and the maximum number of bars that will be displayed
   * on the chart.
   */
  private readonly BILLS_CHART_BACKLOG = 12;

  /** CSS `padding` property of the .chart-container element. This needs to be defined here as
   * it is used in correctly positioning the average plot.
   */
  readonly CHART_CONTAINER_PADDING_PX = 8;

  constructor(private billQuery: BillQuery, private logger: NGXLogger) {}

  ngOnInit() {
    this.billQuery.selectLoading().subscribe((isLoading) => {
      // getBillsChartData() relies on billPayments data existing to fill chart with data,
      // so only perform this function once data is loaded.
      if (isLoading === false || this.dummy) {
        this.getBillsChartData()
          .then(() => {
            this.error = false;
          })
          .catch((error) => {
            this.logger.error('Error loading bills chart:', error);
            this.error = true;
          })
          .finally(() => {
            this.loadingChart = false;

            // This output is to allow tab-home.page.ts to know when the chart has loaded, and to display 'Add your first bill' buttons if there are no
            // bills in the user's account. This should require a more permanent solution, as the buttons will never load if this chart fails to complete.
            // BillQuery::selectLoading() functionality does not seem to work optimally, as the data only becomes available after several seconds, where
            // the loading status is set true and false at least once before the data is really available, requiring this workaround.
            this.chartLoaded.emit(true);
          });
      }
    });
  }

  generateArray(count: number) {
    return new Array(count);
  }

  async getBillsChartData() {
    /**
     * Data to be fed into the chart via template
     */
    let seriesData: BillsChartSeriesItem[] = [];

    if (!Array.isArray(this.billQuery.getValue().billPayments) && !this.dummy) return;
    // const userBills = (this.dummy ? this.dummyBillData : [...this.billQuery.getValue().billPayments]) as Partial<UserBillPayment>[];
    const userBills = [...this.billQuery.getValue().billPayments];

    if (userBills.length === 0) {
      // No bills; emit loading complete; tab-home.page.ts will declare that user has no bills
      return;
    }

    const _paidBills = userBills.filter(
      this.PAYMENT_STATUS_LEGEND_CONFIG.find(
        (cfg) => cfg.name === BillPaymentStatusCategory.PAID,
      ).filterLogic,
    );
    const _unpaidBills = userBills.filter(
      this.PAYMENT_STATUS_LEGEND_CONFIG.find(
        (cfg) => cfg.name === BillPaymentStatusCategory.UNPAID,
      ).filterLogic,
    );
    const _unpaidOverdueBills = userBills.filter(
      this.PAYMENT_STATUS_LEGEND_CONFIG.find(
        (cfg) => cfg.name === BillPaymentStatusCategory.OVERDUE,
      ).filterLogic,
    );

    const billFormatConverter = (bill: { amount: number; dueDate: string }) => ({
      amount: bill.amount,
      dueDate: bill.dueDate,
    });
    const paidBills = _paidBills.map(billFormatConverter);
    const unpaidBills = _unpaidBills.map(billFormatConverter);
    const overdueBills = _unpaidOverdueBills.map(billFormatConverter);

    const latestBillDueDate = dayjs(
      first(
        userBills.sort((bill1, bill2) =>
          dayjs(bill1.dueDate).isAfter(bill2.dueDate) ? -1 : 1,
        ),
      ).dueDate,
    );

    const currentMonth = dayjs();
    const currentMonthId = currentMonth.format('YYYY/MM');

    const latestChartMonth = latestBillDueDate.isAfter(currentMonth, 'month')
      ? latestBillDueDate
      : currentMonth;
    const latestChartMonthId = latestChartMonth.format('YYYY/MM');

    // Generate bars for the chart
    for (let i = 0; i < this.BILLS_CHART_BACKLOG; i++) {
      const month = latestChartMonth.subtract(i, 'months');
      const byMonthFilter = (paidBill: typeof paidBills[0]) =>
        dayjs(paidBill.dueDate).isSame(month, 'month');

      const filteredPaidBills = paidBills.filter(byMonthFilter);
      const filteredUnpaidBills = unpaidBills.filter(byMonthFilter);
      const filteredOverdueBills = overdueBills.filter(byMonthFilter);

      seriesData.push({
        paid: {
          amount: sum(filteredPaidBills.map((bill) => bill.amount)),
          firstSegment: !filteredUnpaidBills.length && !filteredOverdueBills.length,
          lastSegment: true,
        },
        unpaid: {
          amount: sum(filteredUnpaidBills.map((bill) => bill.amount)),
          firstSegment: !filteredOverdueBills.length,
          lastSegment: !filteredPaidBills.length,
        },
        overdue: {
          amount: sum(filteredOverdueBills.map((bill) => bill.amount)),
          firstSegment: true,
          lastSegment: !filteredPaidBills.length && !filteredUnpaidBills.length,
        },
        totalAmount: filteredPaidBills
          .concat(filteredUnpaidBills)
          .concat(filteredOverdueBills)
          .reduce((currentValue, bill) => currentValue + bill.amount, 0),
        label: month.format('MMM'),
        monthId: month.format('YYYY/MM'),
      });
    }

    const earliestChartDate = last(seriesData).monthId;

    let earliestBillDueDate: string;

    const sortedUserBillDates = userBills
      .filter((bill) => !dayjs(bill.dueDate).isBefore(earliestChartDate))
      .sort((bill1, bill2) => (dayjs(bill1.dueDate).isBefore(bill2.dueDate) ? -1 : 1));

    earliestBillDueDate = first(sortedUserBillDates)?.dueDate ?? earliestChartDate;

    /**
     * Defines all items with chart data filled
     */
    const nonNullSeriesItems = seriesData.filter(
      (bar) =>
        dayjs(bar.monthId).isAfter(earliestBillDueDate) ||
        bar.monthId === dayjs(earliestBillDueDate).format('YYYY/MM'),
    );

    /**
     * Defines the amounts of all filled chart data
     */
    const amountMap = nonNullSeriesItems.map((bar) => bar.totalAmount);

    const barChartMax = Math.max(...amountMap);
    const barChartAvg = sum(amountMap) / amountMap.length;

    seriesData = seriesData
      .map((seriesItem) => ({
        ...seriesItem,
        barHeight: dayjs(seriesItem.monthId).add(1, 'month').isBefore(earliestBillDueDate)
          ? 0
          : (seriesItem.totalAmount / Math.max(barChartMax, 1)) * this.MAX_BAR_HEIGHT,
      }))
      .sort((element1, element2) =>
        dayjs(element1.monthId).isSame(element2.monthId) ? 1 : -1,
      );

    // Newer bills chart does not include average plot line for now
    // const barHeightAvg =
    //   sum(
    //     nonNullSeriesItems.map(
    //       (item) => seriesData.find((sd) => sd.monthId === item.monthId).barHeight,
    //     ),
    //   ) / (nonNullSeriesItems.length || 1);

    // this.avgLineHeightOffset =
    //   this.maxBarHeight -
    //   Math.max(barHeightAvg, 1) -
    //   // padding-top property of .chart-container
    //   this.CHART_CONTAINER_PADDING_PX +
    //   // offset in px of the top border of .average-dashed-line from bottom of .average-line element
    //   // note that .average-dashed-line is 2px height
    //   10;

    // this.logger.debug(
    //   `avgLineHeightOffset Calculation: (${this.maxBarHeight} - ${barHeightAvg}) - ${this.CHART_CONTAINER_PADDING_PX} + 10 = ${this.avgLineHeightOffset}`,
    // );

    // Find bar representing the current month and display its data
    this.billChartClickEvent(seriesData.find((item) => item.monthId === currentMonthId));

    seriesData.forEach((seriesItem) => {
      seriesItem.paid.segmentHeight =
        (seriesItem.paid.amount / seriesItem.totalAmount) * 100 || 0;
      seriesItem.unpaid.segmentHeight =
        (seriesItem.unpaid.amount / seriesItem.totalAmount) * 100 || 0;
      seriesItem.overdue.segmentHeight =
        (seriesItem.overdue.amount / seriesItem.totalAmount) * 100 || 0;
    });

    this.barChartData = { seriesData, barChartMax, barChartAvg };
  }

  async billChartClickEvent(chartBar: BillsChartSeriesItem) {
    // Set all bars as inactive, and only the clicked one as active
    this.barChartData?.seriesData.forEach((bar) => (bar.active = false));
    chartBar ? (chartBar.active = true) : '';

    const month = dayjs(chartBar?.monthId);
    this.selectedBarMonth = month.format('MMMM YYYY');
    this.selectedBarAmount = chartBar?.totalAmount;
  }
}
