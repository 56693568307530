import { BillsFilterState } from '@1bill-app/services/bill/bill.store';
import { BillCategoryKey } from '@1bill-app/services/bill/types';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NGXLogger } from 'ngx-logger';
/**
 * Shows different categories like electricity, gas, water, etc.
 */
@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoriesComponent implements OnInit {
  @Input() data: { id: number; name: string; key: string }[];
  @Input() filter: BillsFilterState;
  @Input() showHeading = true;
  @Output() catBtnClick: EventEmitter<{ categoryKey: BillCategoryKey }> = new EventEmitter();
  constructor(private logger: NGXLogger) {}

  ngOnInit() {}
  processData() {
    return ['internet', 'water', 'gas', 'electricity', 'other'].map((cat) => {
      const catData = Array.isArray(this.data)
        ? this.data.find((d) => d.name.toLowerCase() === cat)
        : null;
      if (catData) {
        return { cat, disabled: false, ...catData };
      } else {
        return { cat, disabled: true };
      }
    }) as Partial<{
      id: number;
      name: string;
      key: string;
      cat: string;
      disabled: boolean;
    }>;
    // .sort((a, b) => (a.disabled === true ? 1 : -1))
  }
  onBillTypeBtnClick(categoryKey: BillCategoryKey) {
    this.catBtnClick.emit({ categoryKey });
  }
  isSelected(categoryKey: BillCategoryKey) {
    if (!this.filter?.categoryKeys?.length) return false;
    return this.filter.categoryKeys.indexOf(categoryKey) >= 0;
  }
  trackByFn(index: number, item: any) {
    return item.key;
  }
}
