import { AuthStore } from '@1bill-app/services/auth/state/auth.store';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeAccessToken } from '@1bill-app/services/yodlee/yodlee.types';
import {
  AfterViewInit,
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';

/**
 * Modal for linking bank accounts using fast link
 */
@Component({
  selector: 'app-yodlee-fast-link-modal',
  templateUrl: './yodlee-fast-link-modal.component.html',
  styleUrls: ['./yodlee-fast-link-modal.component.scss'],
})
export class YodleeFastLinkModal implements OnInit, AfterViewInit, OnDestroy {
  subs = new SubSink();
  @ViewChild('yodleeFastLinkIFrame', { static: false }) yodleeFastLinkIFrame: ElementRef;
  constructor(
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private authStore: AuthStore,
    private zone: NgZone,
    private yodleeService: YodleeService,
    private router: Router,
  ) { }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() { }

  loadEvent(event) {
    this.logger.debug('Load Event: ', event);
  }

  ngAfterViewInit() {
    this.loadYodleeFastLink();
  }

  async closeModal() {
    (window as any).fastlink.close();
    await this.modalCtrl.dismiss('backButton');
  }

  // async loadYodleeFastLink() {
  //   const yodleeToken = this.authStore.getValue()?.yodleeToken;
  //   const fastLinkUrl = environment.yodleeFastLinkUrl;
  //   this.logger.debug('Yodlee token: ', yodleeToken);
  //   this.logger.debug('Fastlink url: ', fastLinkUrl);
  //   const formEl = document.createElement('form');
  //   formEl.action = fastLinkUrl;
  //   formEl.target = 'yodleeFastLinkIFrame';
  //   formEl.method = 'POST';
  //   const accessTokenInput = document.createElement('input');
  //   accessTokenInput.type = 'hidden';
  //   accessTokenInput.name = 'accessToken';
  //   accessTokenInput.value = `Bearer ${yodleeToken?.token}`;
  //   formEl.appendChild(accessTokenInput);
  //   const appIdInput = document.createElement('input');
  //   appIdInput.type = 'hidden';
  //   appIdInput.name = 'extraParams';
  //   appIdInput.value = 'configName=Aggregation';
  //   formEl.appendChild(appIdInput);
  //   document.body.appendChild(formEl);
  //   formEl.submit();
  // }

  async loadYodleeFastLink() {
    this.subs.sink = this.yodleeService
      .getAccessToken()
      .subscribe((yodleeToken: YodleeAccessToken) => {
        this.logger.debug('Yodlee token info: ', yodleeToken);
        (window as any).fastlink.close();
        (window as any).fastlink.open(
          {
            fastLinkURL: yodleeToken?.fastLinkUrl,
            accessToken: `Bearer ${yodleeToken?.token}`,
            params: {
              configName: 'Aggregation',
            },
            forceIframe: true,
            onSuccess: (data) => {
              this.logger.log('yodleeonSuccess: ', data);
              if (data?.status === 'SUCCESS') {
                // Reload bank account data
                this.zone.run(() => this.yodleeService.getAccounts().subscribe());
              }
            },
            onError: (data) => {
              // will be called on error. For list of possible message, refer to onError(data) Method.
              this.logger.error('yodleeonError: ', data);
            },
            onClose: (data) => {
              // will be called called to close FastLink. For list of possible message, refer to onClose(data) Method.
              this.logger.log('yodleeonClose: ', data);
              this.yodleeService.getDashboardWealthData().subscribe();
              this.modalCtrl.dismiss('onClose');
            },
            onEvent: (data) => {
              // will be called on intermittent status update.
              this.logger.log('yodleeonEvent: ', data);
            },
          },
          'container-fastlink',
        );
      });
  }

  async handleAddManualAccount() {
    this.router.navigate(['/add-edit-manual-account/add']);
    await this.modalCtrl.dismiss('gotoManual');
  }
}
