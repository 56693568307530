export interface RewardState extends RewardResponse {
  seenCodes: RewardActionCodeMapping[];
  profileRewards: Reward[];
}
export interface RewardResponse {
  rewards: Reward[];
  /**
   * List of action ids to display in profile complete
   */
  pcaCodes: RewardActionCodeMapping[];
  /**
   * @deprecated use tpp
   */
  ttp: number;
  /**
   * Total points to complete profile
   */
  tpp: number;
  /**
   * Indicates whether user has logged in 10 or more times
   */
  oldUser: boolean;
  /**
   * Total profile points completed
   */
  tppc: number;
  ppRatio: number;
  success: boolean;
  /**
   * The total number of points that the user has earned over the duration of their account usage.
   */
   totalEarnedPoints: number;
   /**
    * The total number of points that the user has spent on offers.
    */
   totalRedeemedPoints: number;
   /**
    * The user's total current reward points, i.e. balance. Equivalent to totalEarnedPoints - totalRedeemedPoints
    */
    totalPointsBalance: number;
}
export interface Reward {
  rewardActionId: RewardActionMapping;
  actionCode: RewardActionCodeMapping;
  actionName: string;
  rewardTitle: string;
  rewardDesc: string;
  icon: string;
  color: string;
  showBadge: 0 | 1;
  rewardPoints: number;
  completed: 0 | 1;
  rewardType: string;
  accountId: number;
  rewardId: number;
  isProfilePoint: 0 | 1;
  rewardedAt: string;
  rewardStatus: 'A' | 'I';
  appRoute: string;
  /**
   * @returns 0 or 1
   */
  badgeDisplayed: number;
  rewardIcon?: string;
}

/**
 * @deprecated Use RewardActionCodeMapping instead as the auto-increment
 * 1b_reward_action_id column easily leads to inconsistencies.
 */
export enum RewardActionMapping {
  VERIFY_MOBILE_NUMBER = 1,
  ADD_FIRST_BILL = 2,
  FIRST_CONNECT_EMAIL_WITH_SUPPLIER = 4,
  SUBSEQUENT_CONNECT_EMAIL_WITH_SUPPLIER = 22,
  ADD_PAYMENT_DETAILS = 5,
  SET_NOTIFICATION = 6,
  COMPLETE_PROFILE = 7,
  BILLS_UPLOADED_10 = 8,
  BILLS_UPLOADED_50 = 9,
  BILLS_UPLOADED_100 = 10,
  LINK_BANK_ACCOUNT = 15,
  EMAIL_SCANNER = 16,
  FIRST_SWITCH_WITH_1BILL = 17,
  SUBSEQUENT_SWITCH_WITH_1BILL = 18,
  FIRST_BILL_PAID = 19,
  TEN_BILLS_PAID = 20,
  TWENTY_BILLS_PAID = 21,
}

/**
 * Also defined in API at core/services/RewardService.ts
 * 
 * Please reflect any changes made here in that file as necessary.
 */
 export enum RewardActionCodeMapping {
  VERIFY_MOBILE_NUMBER = 'VERIFY_MOBILE_NUMBER',
  ADD_FIRST_BILL = 'ADD_FIRST_BILL',
  FIRST_CONNECT_EMAIL_WITH_SUPPLIER = 'CONNECT_EMAIL_SUPPLIER',
  ADD_PAYMENT_DETAILS = 'ADD_PAYMENT_DETAILS',
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  COMPLETE_PROFILE = 'COMPLETE_PROFILE',
  BILLS_UPLOADED_10 = 'BILLS_UPLOADED_10',
  BILLS_UPLOADED_50 = 'BILLS_UPLOADED_50',
  BILLS_UPLOADED_100 = 'BILLS_UPLOADED_100',
  LINK_BANK_ACCOUNT = 'LINK_BANK_ACCOUNT',
  EMAIL_SCANNER = 'EMAIL_SCANNER',
  SWITCH_PROVIDER_WITH_1BILL = 'SWITCH_PROVIDER',
  FIRST_BILL_PAID = 'FIRST_BILL_PAID',
  TEN_BILLS_PAID = 'TEN_BILLS_PAID',
  TWENTY_BILLS_PAID = 'TWENTY_BILLS_PAID',
  SUBSEQUENT_CONNECT_EMAIL_WITH_SUPPLIER = 'CONNECT_EMAIL_SUPPLIER_SUBS',
  SWITCH_PROVIDER_WITH_1BILL_PROMOTION_5000 = 'SWITCH_PROVIDER_5K_PROMO',

  /**
   * The reward given to a user when another user signs up with their referral code (and
   * mobile number verification checks have passed).
   */
  REFER_FRIEND_INVITE = 'REFER_FRIEND',
  /**
   * The reward given to a user when they enter someone's referral code during signup (and
   * mobile number verification checks have passed).
   */
  REFER_FRIEND_ACCEPT = 'REFER_ACCEPT',
}
