import { environment } from '@1bill-app/env';
import { WebsocketService } from '@1bill-app/services/websocket.service';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { App, AppState, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
} from '@capacitor/push-notifications';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {
  AlertController,
  MenuController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AFConstants, AppsFlyer } from 'appsflyer-capacitor-plugin';
import { Hub } from 'aws-amplify';
import Fingerprint2 from 'fingerprintjs2';
import { NGXLogger } from 'ngx-logger';
import { from } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import {
  DISPLAY_SIDE_MENU,
  ROUTE_BASE,
  ROUTE_BILLS_PAGE,
  ROUTE_BILL_DETAILS,
  ROUTE_FIND_MORE_SAVINGS,
  ROUTE_HOMEPAGE,
  ROUTE_LANDING,
  ROUTE_LAT_PAY_CALLBACK,
  ROUTE_PAYMENT_OPTIONS,
  SQLITE_TABLE_PUSH_LOGS,
  MOBILE_VIEW_THRESHOLD_PX,
  ROUTE_OFFERS,
  ROUTE_OFFERS_REDEEMED,
  ROUTE_NO_NETWORK,
  REFERRAL_CODE_QUERY_PARAM_KEY,
} from './app.constants';
import { generalMenu } from './app.menu';
import { dummyMenu } from './app.menu';
import { CreatePinScreenComponent } from './components/create-pin-screen/create-pin-screen.component';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { RewardBadgePopupModalComponent } from './components/reward-badge-popup-modal/reward-badge-popup-modal.component';
import { AdminForceRefreshService } from './services/adminForceRefreshService';
import { PINService } from './services/auth/pin.service';
import { AuthQuery } from './services/auth/state/auth.query';
import { AuthService } from './services/auth/state/auth.service';
import { CustomOAuth } from './services/auth/state/CustomOAuth';
import { BillService } from './services/bill/bill.service';
import { CachingService } from './services/caching/caching.service';
import { AppSQLiteLoggerMonitor } from './services/logging/app-sqlite-logger-monitor';
import { NotificationService } from './services/notification/notification.service';
import { RewardService } from './services/reward/reward.service';
import { SQLiteService } from './services/sqlite/sqlite.service';
import { initLogger } from './services/tsLogger';
import { UIService } from './services/ui.service';
import {
  AppTrackingTransparency,
  AppTrackingStatusResponse,
} from 'capacitor-plugin-app-tracking-transparency';

declare var ZohoSalesIQ: any;
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  public appMenu = generalMenu;
  public appdDummyMenu = dummyMenu;
  public isLoggedIn$ = this.authQuery.isLoggedIn$;
  public disableMenu$ = this.uiService.isDisableMenu();
  public hideDesktopHeader$ = this.uiService.isHideDesktopHeader();
  isDesktop$ = this.uiService.isDesktopView().pipe(map((isDesktop) => isDesktop));
  landingPage = ROUTE_LANDING;
  private popupDisplayedData;
  get sideIonMenu() {
    const devWidth = this.platform.width();
    return devWidth > MOBILE_VIEW_THRESHOLD_PX ? 'end' : 'start';
  }

  displaySideMenu = DISPLAY_SIDE_MENU; // change this if u want to display side menu

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.uiService.onResize(this.platform.width());
    // const zohoChatButtonCandidates = document.getElementsByClassName('zsiq_floatmain');
    // const zohoChatButton = zohoChatButtonCandidates.item(0) as HTMLElement;
    // if (event.srcElement.innerWidth < MOBILE_VIEW_THRESHOLD_PX) {
    //   if (zohoChatButton) {
    //     zohoChatButton.style.display = 'none';
    //   }
    // } else {
    //   console.log('Style attribute:', (zohoChatButton as any).style);
    // }
  }

  constructor(
    private _zone: NgZone,
    private logger: NGXLogger,
    private authService: AuthService,
    private router: Router,
    private customOAuth: CustomOAuth,
    private menu: MenuController,
    private alertCtr: AlertController,
    private authQuery: AuthQuery,
    private modalCtrl: ModalController,
    private uiService: UIService,
    private platform: Platform,
    private navCtrl: NavController,
    private wsService: WebsocketService,
    private rewardService: RewardService,
    private statusBar: StatusBar,
    private sqlite: SQLiteService,
    private pinService: PINService,
    private cachingService: CachingService,
    private adminForceRefreshService: AdminForceRefreshService,
    private billService: BillService,
    private notificationService: NotificationService,
  ) {
    this.initializeApp();
    this.cachingService.init();

    if (environment.inMemoryWebApi) {
      console.warn(`InMemoryDataService is enabled.`);
    }
  }

  initializeApp() {
    this.initLocalLogs();
    this.setLoggerCustomParams();
    this.initPushnotificationsListener();
    this.authService.initAmplifyHubListener();
    this.initAuthListener();
    this.initAppUrlOpenListener();
    this.initBackButtonListener();
    this.initNetworkListener();

    initLogger(this.logger);
    this.initAppLockCheck();
    // this.authPopupHandler();
    // this.authService.checkSessionActivity().pipe(untilDestroyed(this)).subscribe();
    // this.authService.checkUserSessionSubscribe();
    this.uiService.onResize(this.platform.width());
    this.initWebSocket();
    // set android status bar color
    this.platform.ready().then(async () => {
      this.statusBar.backgroundColorByHexString('#fff');
      this.statusBar.styleDefault();

      if (Capacitor.isNativePlatform()) {
        this.setUDL();
        if (this.platform.is('android')) {
          this.statusBar.backgroundColorByHexString('#fff');
          this.statusBar.styleDefault();
          if (environment.appsFlyer.enabled) {
            await AppsFlyer.initSDK(environment.appsFlyer.android);
          }
        } else if (this.platform.is('ios')) {
          if (environment.appsFlyer.enabled) {
            await AppsFlyer.initSDK(environment.appsFlyer.ios);
            await this.getStatus();
            await this.requestPermission();
          }
        }
      }
    });

    const checkNameSubscription = this.authService.checkIfUserHasNamesSet();

    this.setupChatbot();
    this.authService
      .isAuthenticated()
      .pipe(filter((value) => value.loggedIn))
      .subscribe(() => {
        this.authService.openLockScreen();
      });
    this.authQuery.select('hasName').subscribe((hasName) => {
      if (hasName) {
        checkNameSubscription.unsubscribe();
      }
    });
  }
  /**
   * AppsFlyer Tracking status
   * @returns
   */
  public async getStatus(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.getStatus();

    this.logger.log('getStatus', response);
    // { status: 'authorized' } for example

    return response;
  }
  /**
   * AppsFlyer Tracking request permission
   * @returns
   */
  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    const response = await AppTrackingTransparency.requestPermission();

    this.logger.log('requestPermission', response);
    // { status: 'authorized' } for example

    return response;
  }

  ngAfterViewInit() {
    setTimeout(() => SplashScreen.hide(), 400);
  }

  setupChatbot() {
    this.logger.debug('Setup chatbot');
    try {
      if (Capacitor.isNativePlatform()) {
        // Ref: https://www.zoho.com/salesiq/help/developer-section/cordova-ionic-installation.html
        const appKey = this.platform.is('ios')
          ? environment.zohoSalesIq.ios.appKey
          : environment.zohoSalesIq.android.appKey;
        const accessKey = this.platform.is('ios')
          ? environment.zohoSalesIq.ios.accessKey
          : environment.zohoSalesIq.android.accessKey;
        ZohoSalesIQ.init(
          appKey,
          accessKey,
          (success) => {
            this.logger.log('ZohoSalesIQ success', success);
            // ZohoSalesIQ.setThemeColorForiOS("#1da061"); // not working right now
          },
          (error) => {
            this.logger.error('ZohoSalesIQ error', error);
          },
        );
        // Ref: https://www.zoho.com/salesiq/help/developer-section/cordova-ionic-sdk-set-visitor-name.html
        // ZohoSalesIQ.setVisitorName(name);
        // ZohoSalesIQ.setVisitorEmail(email);
        // ZohoSalesIQ.setThemeColorForiOS("#1da061");
        // Ref: https://www.zoho.com/salesiq/help/developer-section/cordova-ionic-sdk-set-launcher-visibility.html
        ZohoSalesIQ.showLauncher(false);
      } else {
        // Ref: https://www.zoho.com/salesiq/help/developer-section/js-api-chat-float-visibility.html
        this.isDesktop$.subscribe((isDesktop) => {
          this.logger.log('isDesktop', isDesktop);
          if (isDesktop) {
            setTimeout(() => {
              (window as any).$zoho.salesiq.floatbutton.visible('show');
            }, 1500);
          } else {
            setTimeout(() => {
              (window as any).$zoho.salesiq.floatbutton.visible('hide');
            }, 1500);
          }
        });
      }
    } catch (e) {
      this.logger.log('setupChatbot err', e);
    }
  }
  /**
   * Set a listener for appsFlyer Universal Deep Link
   */
  setUDL() {
    AppsFlyer.addListener(AFConstants.UDL_CALLBACK, (res) => {
      // alert('UDL_CALLBACK ~~>' + JSON.stringify(res));
      if (res.status === 'FOUND') {
        const deepLinkValue = res?.deepLink?.deep_link_value;
        this.handleLink(deepLinkValue);
      } else if (res.status === 'ERROR') {
        console.error('udl error: ' + res?.error);
      }
    });
  }
  /**
   * Handle appsFlyer deeplink redirection
   * @param deepLinkValue
   */
  handleLink(deepLinkValue) {
    // alert('deepLinkValue ~~>' + deepLinkValue);
    switch (deepLinkValue) {
      case 'home':
        this.router.navigateByUrl(`${ROUTE_HOMEPAGE}`);
        break;
      case 'bills':
        this.router.navigateByUrl(`${ROUTE_BILLS_PAGE}`);
        break;
      case 'find-more-savings':
        this.router.navigateByUrl(`${ROUTE_FIND_MORE_SAVINGS}`);
        break;
      case 'offers':
        this.router.navigateByUrl(`${ROUTE_OFFERS}`);
        break;
      case 'offers-redeemed':
        this.router.navigateByUrl(`${ROUTE_OFFERS_REDEEMED}`);
        break;
      default:
        break;
    }
  }

  /**
   * Set logger custom params
   */
  private async setLoggerCustomParams() {
    const fingerprint = await this.getFingerprint();
    const { platform, operatingSystem, model } = await Device.getInfo();

    const { visiorId, timezone } = fingerprint;

    this.authQuery
      .select((val) => val.email)
      .subscribe(async (email) => {
        const httpParams = new HttpParams()
          .set('vid', visiorId)
          .set('user', email ?? 'UnauthenticatedUser')
          .set('tz', timezone)
          .set('mdl', model)
          .set('plt', platform)
          .set('os', operatingSystem)
          .set('version', environment.ver.raw)
          .set('stage', environment.stage);

        if (Capacitor.isNativePlatform()) {
          const { version, build } = await App.getInfo();
          httpParams.set('ver', version + build ? ` (${build})` : '');
        }
        this.logger.setCustomParams(httpParams);
      });
  }
  /**
   * Get fingerprint id for the visitor
   */
  private async getFingerprint() {
    const components = await Fingerprint2.getPromise({
      preprocessor(value) {
        return value;
      },
    });
    const visiorId = Fingerprint2.x64hash128(components.map((c) => c.value).join(''), 31);
    const keys = ['timezone'];
    const data = components
      .filter((c) => keys.includes(c.key))
      .reduce((acc, cur) => {
        acc = { ...acc, [cur.key]: cur.value };
        return acc;
      }, {}) as { timezone: string };
    this.logger.debug('VisitorID: ' + visiorId);
    return { visiorId, ...data };
  }

  // Android back button doesn't work on notifications page, so a manual implementation is required.
  initBackButtonListener() {
    App.addListener('backButton', (backData) => {
      this.logger.debug('history:', window.history);
      if (backData.canGoBack) {
        window.history.back();
      }
    });
  }

  initNetworkListener() {
    this.uiService.networkStatus$.subscribe((networkStatus) => {
      // Navigate to NoNetworkPage only if the user has no connection and is not logged in.
      // If connection status goes off while logged in, it will still cause problems, but the
      // user experience would be jarring as it routes to Login when network is re-connected.
      if (networkStatus === false && !this.authQuery.isLoggedIn) {
        this.router.navigateByUrl(ROUTE_NO_NETWORK);
      }
    });
  }

  initAppUrlOpenListener() {
    App.addListener('appUrlOpen', (data) => {
      Browser.close();
      this.oauthCallbackHandler(data);
      this.zenithCallbackHandler(data);
      this.switchCallbackHandler(data);
      this.latPayCallbackHandler(data);
    });
  }

  async oauthCallbackHandler(data: URLOpenListenerEvent) {
    const urlParams = data.url.split('onebill://callback/?')[1];
    if (urlParams) {
      try {
        this.customOAuth._handleAuthResponse(data.url).then((res) => {
          this._zone.run(async () => {
            this.logger.debug({ res });
            this.navCtrl.navigateRoot([ROUTE_HOMEPAGE]);
          });
        });
      } catch (e) {
        this.logger.error('oauthCallbackHandler', e);
      }
    }
  }

  zenithCallbackHandler(data: URLOpenListenerEvent) {
    const urlParams = data.url.split('onebill://callback/zenith/?')[1];
    if (urlParams) {
      this._zone.run(async () => {
        const modal = document.querySelector(':not(app-root).modal');
        const modalBackdrop = document.querySelector(':not(app-root).modal-backdrop');
        if (modal) {
          modal.remove();
        }
        if (modalBackdrop) {
          modalBackdrop.remove();
        }
        this.router.navigateByUrl(`${ROUTE_PAYMENT_OPTIONS}?` + urlParams);
        // document.location.href = document.location.origin + '/?' + urlParams;
        // const info = await Device.getInfo();
        // if (info.platform === 'ios') {
        //   document.location.href = 'ionic://localhost/auth/login/?' + urlParams;
        // }
        // if (info.platform === 'android') {
        //   document.location.href = 'http://localhost/auth/login/?' + urlParams;
        // }
      });
    }
  }

  switchCallbackHandler(data: URLOpenListenerEvent) {
    try {
      // data.url = 'onebill://callback/cnc/switch/2134?abc=123&def=345';
      if (data.url.includes('onebill://callback/cnc/switch/')) {
        const urlWithBpId = data.url.split('onebill://callback/cnc/switch/')[1];
        this.logger.log('urlWithBpId', urlWithBpId);

        const billPaymentId = urlWithBpId.split('?')[0];
        this.logger.log('billPaymentId', billPaymentId);

        const urlParams = data.url.split(`?`)[1];
        this.logger.log('urlParams', urlParams);
        if (urlParams) {
          this._zone.run(async () => {
            this.router.navigateByUrl(
              `${ROUTE_BILL_DETAILS}/${billPaymentId}` + '?' + urlParams,
            );
          });
        }
      }
    } catch (e) {
      this.logger.error('Error in switchCallbackHandler', e);
      this.router.navigate([]);
    }
  }

  latPayCallbackHandler(data: URLOpenListenerEvent): never {
    throw Error('LatitudePay is no longer available.');
    // try {
    //   this.logger.log('latPayCallbackHandler', data);
    //   const callbackBaseUrl = 'onebill://callback/lat/sale/online';
    //   if (data.url.includes(callbackBaseUrl)) {
    //     this.logger.log(callbackBaseUrl);

    //     const urlParams = data.url.split(`?`)[1];
    //     this.logger.log('urlParams', urlParams);
    //     if (urlParams) {
    //       this._zone.run(async () => {
    //         this.router.navigateByUrl(`${ROUTE_LAT_PAY_CALLBACK}` + '?' + urlParams);
    //       });
    //     }
    //   }
    // } catch (e) {
    //   this.logger.error('Error in latPayCallbackHandler', e);
    //   this.router.navigate([]);
    // }
  }

  // authPopupHandler() {
  //   // get the URL parameters which will include the auth token
  //   const params = window.location.search;
  //   if (window.opener) {
  //     // send them to the opening window
  //     window.opener.postMessage({
  //       source: '1bill-login-redirect',
  //       payload: params,
  //     });
  //     // close the popup
  //     window.close();
  //   }
  // }

  closeMenu() {
    this.menu.getOpen().then((menu) => (menu ? menu.close() : null));
  }

  /**
   * Logs out user.
   */
  async openConfirmLogoutModal() {
    const alert = await this.alertCtr.create({
      cssClass: 'confirm-logout-alert',
      header: 'Log out',
      // subHeader: 'Log out',
      message: 'Are you sure you want to log out of your account?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: () => {
            this.logger.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Log out',
          cssClass: 'alert-accept',
          handler: () => {
            this.logger.log('Confirm logout: blah');
            this.closeMenu();
            this.authService.logout('app.component').subscribe();
            this.authQuery.isLoggedIn$.subscribe({
              next: (isLoggedIn) => {
                if (!isLoggedIn) this.router.navigate([ROUTE_BASE]);
              },
            });
          },
        },
      ],
    });

    await alert.present();
  }

  getVersionText() {
    return environment.ver.raw;
  }

  /**
   * Opens the url.
   * In-app browser in app.
   * External tab in web.
   *
   *
   */
  openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
    });
  }

  initAuthListener() {
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      this.logger.debug('auth payload: ', { event, data, message });
      switch (event) {
        case 'signOut':
          this.logger.log('user signed out');
          this.router.navigate([ROUTE_LANDING], { replaceUrl: true });
          break;
        case 'oAuthSignOut':
          this.logger.log('user oAuthSignOut signed out');
          this.router.navigate([ROUTE_LANDING], { replaceUrl: true });
          break;
        case 'signIn':
          this.initWebSocket();
          this.pinService.openCreateNewPin().then((res) => {
            if (res) {
              this.pinService.openCreatePIN(CreatePinScreenComponent);
            }
          });
          this.authService.getSessionLastUrl().then((url) => {
            if (url) {
              this.authService.clearSessionLastUrl();
              this._zone.run(() => {
                this.router.navigateByUrl(url);
              });
            }
          });
          break;
        case 'signUp':
          this.initWebSocket();
          break;
      }
    });
  }

  async onFnMenuItemClick(url: string) {
    switch (url) {
      case 'feedback-modal':
        return this.presentFeedbackForm();
      default:
        break;
    }
  }

  async presentFeedbackForm() {
    const modal = await this.modalCtrl.create({
      component: FeedbackFormComponent,
      cssClass: 'feedback-form-modal',
    });
    return await modal.present();
  }

  initPushnotificationsListener() {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    // listen to notification received inside app
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.logger.debug('pushNotificationReceived', notification);
        // store log
        this.storePushLog(notification);
        // schedule local notification
        LocalNotifications.schedule({
          notifications: [
            {
              id: new Date().getTime(),
              title: notification.title,
              body: notification.body,
              extra: { ...notification.data, id: notification.id },
              schedule: { at: new Date(Date.now() + 1000 * 5) },
            },
          ],
        });
      },
    );
    // listen to notification tapping outside app
    LocalNotifications.addListener('localNotificationActionPerformed', (action) => {
      this.logger.debug('localNotificationActionPerformed', action);
      // store log
      this.storePushLog(action.notification);
      // navigate
      this.navigateToPage(action.notification.extra);
    });
    // listen to local notification tapping
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action: ActionPerformed) => {
        this.logger.debug('localNotificationActionPerformed', action);
        // store log
        this.storePushLog(action.notification);
        // navigate
        this.navigateToPage(action.notification.data);
      },
    );
  }

  navigateToPage(params: { billId?: number; jobId?: number; appAction?: string }) {
    const { billId, jobId, appAction } = params;
    this.logger.debug('navigateToPage params', params);
    this._zone.run(() => {
      if (appAction === 'OPEN_PAYMENT_METHODS') {
        this.router.navigate([ROUTE_PAYMENT_OPTIONS]).then(() => {
          this.logger.debug('navigateToPage payment options success');
        });
      } else if (billId) {
        this.router.navigate(['/bill', billId]).then(() => {
          this.logger.debug('navigateToPage success');
        });
      } else if (jobId) {
        this.router.navigate(['/bill'], { queryParams: { jobId } }).then(() => {
          this.logger.debug('navigateToPage success');
        });
      }
    });
  }

  splitPaneVisibiltyEvent(ev) {
    this.logger.log('Visibility event: ', ev);
    this.uiService.updateSplitPaneVisiblity(ev.detail.visible);
  }

  initWebSocket() {
    this.logger.log('initWebSocket');
    const presentModal = async (rewardActionId) => {
      await this.rewardService.getRewards().toPromise();
      const modal = await this.modalCtrl.create({
        component: RewardBadgePopupModalComponent,
        cssClass: 'reward-badge-popup-modal',
        componentProps: { rewardActionId },
        swipeToClose: true,
      });
      return await modal.present();
    };
    this.wsService.connect();
    this.adminForceRefreshService.adminForceRefreshEvent().subscribe(() => {
      this.cachingService.clearAll();
      this.billService.fetchBill(true).subscribe();
    });
    this.notificationService.refreshNotificationEvent().subscribe(() => {
      this.billService.fetchBill(true).subscribe();
      this.notificationService.getNotifications(false).subscribe();
    });
    this.rewardService.popup$
      .pipe(
        tap((data) => this.logger.log('popup action', data)),
        filter((data) => this.popupDisplayedData != data),
        tap((data) => this.logger.log('After filter', data)),
        tap((data) => {
          this.popupDisplayedData = data;
        }),
      )
      .subscribe((data) => {
        // open popup for reward
        presentModal(data.type);
      });
  }

  async testPresentModal() {
    await this.rewardService.getRewards().toPromise();
    const modal = await this.modalCtrl.create({
      component: RewardBadgePopupModalComponent,
      cssClass: 'reward-badge-popup-modal',
      componentProps: { rewardActionId: 7 },
    });
    return await modal.present();
  }

  initLocalLogs() {
    this.authService
      .isUserDevAdmin()
      .pipe(filter((isDevAdmin) => isDevAdmin && Capacitor.isNativePlatform()))
      .subscribe(() => {
        this.sqlite.init().then(() => {
          this.logger.registerMonitor(new AppSQLiteLoggerMonitor(this.sqlite));
        });
      });
  }

  storePushLog(notification: any) {
    this.authService
      .isUserDevAdmin()
      .pipe(
        filter((isDevAdmin) => isDevAdmin, Capacitor.isNativePlatform()),
        switchMap(() => this.sqlite.dbReady),
        filter((dbReady) => dbReady),
        switchMap(() => from(AuthService.getAuthenticatedEmail())),
      )
      .subscribe((email) => {
        const { title, body } = notification;
        const id = notification?.extra?.id || notification.id;
        const data = notification?.data || notification?.extra || {};
        const action = data?.appAction || null;

        this.sqlite.run({
          statement: `INSERT INTO ${SQLITE_TABLE_PUSH_LOGS}(id, action, title, body, data, timestamp, user) VALUES(?,?,?,?,?,?,?)`,
          values: [id, action, title, body, JSON.stringify(data), new Date().getTime(), email],
        });
      });
  }

  initAppLockCheck() {
    if (!Capacitor.isNativePlatform()) return;

    App.addListener('appStateChange', async (state: AppState) => {
      // state.isActive contains the active state
      this.logger.log('App state changed. Is active?', state.isActive);
      if (state.isActive) {
        if (this.authService.isSessionTimeout()) {
          await this.authService.openLockScreen();
        }
      }
    });
  }
}
