import { generateArray } from '@1bill-app/helpers/array.helper';
import { Offer } from '@1bill-app/services/offer/offer.type';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSlides } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

/**
 * Component for cards to show best deals.
 */
@Component({
  selector: 'offer-carousel',
  styleUrls: ['./offer-carousel.component.scss'],
  templateUrl: './offer-carousel.component.html',
})
export class OfferCarouselComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  slideOpts = {
    initialSlide: 0,
    slidesPerView: 1.8,
    direction: 'horizontal',
    autoHeight: true,
    speed: 500,
    // width: 600,
  };
  @Input() list: Offer[];
  @Input('loading') isLoading: boolean;
  generateArray = generateArray;

  constructor(private logger: NGXLogger, private router: Router) { }
  async ngOnInit() {
    this.logger.debug('best deals data: ', this.list);
  }

  autoPlaySlides() {
    this.slides.slideNext();
  }

  trackByFn(index: number, item: any) {
    return item.id;
  }
}
