import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface EmailScannerBase {
  username: string;
  password: string;
  emailProvider: string;
  extCredId: string;
  isValid: 1 | 0;
  oauthEnabled: 1 | 0;
}
export interface EmailScanner {
  data: Pick<
    EmailScannerBase,
    'username' | 'emailProvider' | 'extCredId' | 'isValid' | 'oauthEnabled'
  >[];
}

export function createInitialState(): EmailScanner {
  return { data: [] };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'email-scanner', resettable: true })
export class EmailScannerStore extends Store<EmailScanner> {
  constructor() {
    super(createInitialState());
  }
}
