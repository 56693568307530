import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-simple',
  templateUrl: './popover-simple.component.html',
  styleUrls: ['./popover-simple.component.scss'],
})
export class PopoverSimpleComponent implements OnInit {
  @Input() hasBackButton: boolean;
  @Input() popoverTitle: string;
  @Input() description: string;
  @Input() descriptionArray: string[];
  @Input() link: string;
  @Input() linkText: string;
  @Input() buttonText: string;
  @Input() hideButtonArea: boolean = false;
  @Input() showDismissButton: boolean = true;
  constructor(private navCtrl: NavController, private popoverController: PopoverController) {}

  ngOnInit() {}

  openLink() {
    this.popoverController.dismiss();
    this.navCtrl.navigateForward(this.link);
  }

  dismiss(confirmed?: boolean) {
    this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
  }
}
