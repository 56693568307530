import { PIN_ATTEMPT_LIMIT } from '@1bill-app/constants';
import { PINService } from '@1bill-app/services/auth/pin.service';
import { AuthService } from '@1bill-app/services/auth/state/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { KeyboardResize, Keyboard} from '@capacitor/keyboard';
import { Haptics, HapticsImpactStyle } from '@capacitor/haptics';
import {
  AlertController,
  Animation,
  AnimationController,
  ModalController,
} from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum CreatePINView {
  CURRENT_PIN,
  NEW_PIN,
  CONFIRM_PIN,
}

@Component({
  selector: 'app-update-pin-screen',
  templateUrl: './update-pin-screen.component.html',
  styleUrls: ['./update-pin-screen.component.scss'],
})
export class UpdatePinScreenComponent implements OnInit {
  @ViewChild(CodeInputComponent) codeInput: CodeInputComponent;
  @ViewChild(CodeInputComponent, { read: ElementRef }) codeInputEl: ElementRef;
  public CreatePINView = CreatePINView;
  loading$ = this.pinService.loading$;
  public view = CreatePINView.CURRENT_PIN;
  get title() {
    return {
      [CreatePINView.CURRENT_PIN]: 'Enter your PIN',
      [CreatePINView.NEW_PIN]: 'Create a PIN',
      [CreatePINView.CONFIRM_PIN]: 'Re-enter a PIN',
    }[this.view];
  }
  get subtitle() {
    return {
      [CreatePINView.CURRENT_PIN]: 'Please enter your existing PIN',
      [CreatePINView.NEW_PIN]: 'Use this PIN to secure the app',
      [CreatePINView.CONFIRM_PIN]: 'Confirm the PIN',
    }[this.view];
  }
  // private currentPin: string;
  private pin: string;
  private confirmPin: string;
  attemptCount: number = 0;
  attemptCountNew: number = 0;
  incorrectPin = false;
  incorrectConfirmPin = false;

  constructor(
    private pinService: PINService,
    private modalCtrl: ModalController,
    private animationCtrl: AnimationController,
    private alertCtrl: AlertController,
    private authService: AuthService,
  ) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ngOnInit() {}

  async ionViewDidEnter() {
    this.view = CreatePINView.CURRENT_PIN;
    // this.currentPin = await this.pinService.retrieve();
    setTimeout(() => this.focusCodeInput());
  }

  ionViewWillEnter() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ionViewWillLeave() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }

  onClose() {
    // this.currentPin = null;
    this.pin = null;
    this.confirmPin = null;
    this.attemptCount = 0;
    this.attemptCountNew = 0;
    this.incorrectPin = false;
    this.incorrectConfirmPin = false;
    this.dismissLockScreen();
  }

  async onCodeCompleted(value: string) {
    switch (this.view) {
      case CreatePINView.CURRENT_PIN:
        if (await this.pinService.check(value).toPromise()) {
          this.view = CreatePINView.NEW_PIN;
          this.incorrectPin = false;
        } else {
          this.incorrectPin = true;
          Haptics.impact({ style: HapticsImpactStyle.Heavy });
          this.codeInput.reset(false);
          this.focusCodeInput();
          this.triggerPinErrAnimation();
          this.addCSSVariable(
            this.codeInputEl.nativeElement,
            '--item-border-bottom',
            '2px solid var(--ion-color-danger',
          );
          this.attemptCount++;
          if (this.attemptCount >= PIN_ATTEMPT_LIMIT) {
            const alert = await this.alertCtrl.create({
              header: 'Too many attempts',
              message: 'You have entered the PIN incorrectly too many times.',
              buttons: ['ok'],
            });
            await alert.present();
            alert.onDidDismiss().then(() => {
              this.dismissLockScreen();
            });
          }
        }
        this.codeInput.reset(true);
        this.focusCodeInput();
        break;
      case CreatePINView.NEW_PIN:
        this.pin = value;
        this.view = CreatePINView.CONFIRM_PIN;
        this.codeInput.reset(false);
        this.focusCodeInput();
        break;
      case CreatePINView.CONFIRM_PIN:
        this.confirmPin = value;
        if (this.pin === this.confirmPin) {
          await this.pinService.store(this.pin).toPromise();
          this.modalCtrl.dismiss({ success: true });
        } else {
          this.incorrectConfirmPin = true;
          // setTimeout(() => {
          Haptics.impact({ style: HapticsImpactStyle.Heavy });
          this.view = CreatePINView.NEW_PIN;
          this.codeInput.reset(false);
          this.pin = null;
          this.confirmPin = null;
          this.focusCodeInput();
          // });

          this.triggerPinErrAnimation();
          this.addCSSVariable(
            this.codeInputEl.nativeElement,
            '--item-border-bottom',
            '2px solid var(--ion-color-danger',
          );
          this.attemptCountNew++;
          if (this.attemptCountNew >= PIN_ATTEMPT_LIMIT) {
            const alert = await this.alertCtrl.create({
              header: 'Too many attempts',
              message: 'You have entered the PIN incorrectly too many times.',
              buttons: ['ok'],
            });
            await alert.present();
            alert.onDidDismiss().then(() => {
              this.dismissLockScreen();
            });
          }
        }
        break;
      default:
        break;
    }
  }

  async onForgotPin(){
    const alert = await this.alertCtrl.create({
      header: 'Forgot your PIN?',
      message: 'Reset pin by loggin in again.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {}
        }, {
          text: 'Okay',
          role: 'okay',
          handler: () => {}
        }
      ],
    });
    await alert.present();
    alert.onDidDismiss().then(({role}) => {
      if(role==='okay'){
        this.pinService.clear().pipe(
          switchMap(()=>this.authService.logout('update-pin-screen.comp'))
        ).subscribe();
      }
      this.dismissLockScreen();
    });
  }

  async dismissLockScreen() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss();
    }
  }

  focusCodeInput() {
    this.codeInput && this.codeInput.focusOnField && this.codeInput.focusOnField(0);
  }

  triggerPinErrAnimation() {
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(this.codeInputEl.nativeElement)
      .easing('ease-out')
      .duration(1000)
      .keyframes([
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.2 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.3 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.4 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.5 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.6 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.7 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.8 },
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.9 },
      ]);
    animation.play();
  }
  addCSSVariable(el: HTMLElement, property: string, value: string) {
    el.style.setProperty(property, value);
  }
  removeCSSVariable(el: HTMLElement, property: string) {
    el.style.removeProperty(property);
  }
}
