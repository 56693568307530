import {
  PaymentCard,
  PaymentCardService,
} from '@1bill-app/services/payment/payment-card.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { HomeService } from '@1bill-app/services/home/home.service';
import { PopoverController } from '@ionic/angular';
import { ManagePaymentMethodPopoverComponent } from '../popover/manage-payment-method-popover/manage-payment-method-popover.component';

@Component({
  selector: 'app-payment-options-slider',
  templateUrl: './payment-options-slider.component.html',
  styleUrls: ['./payment-options-slider.component.scss'],
})
export class PaymentOptionsSliderComponent implements OnInit {
  constructor(
    private settingsQuery: SettingsQuery,
    private logger: NGXLogger,
    private homeService: HomeService,
    private popoverController: PopoverController,
    private paymentCardService: PaymentCardService,
  ) {}

  @ViewChild('slider') slider: HTMLIonSlidesElement;
  @Output() slideChanged: EventEmitter<{ index: number; methodId: number }> = new EventEmitter(
    true,
  );
  @Output() defaultChosen: EventEmitter<number> = new EventEmitter(false);

  subs = new SubSink();

  accountCards: PaymentCard[];
  accountCards$ = this.settingsQuery.accountCards$;

  defaultAccountCardId: number;
  clicked: boolean = false;

  //paymentInstForm: FormGroup;
  //isFormUntouched: boolean = true;
  loading: HTMLIonLoadingElement;
  saving: boolean;

  sliderOptions = {
    slidesPerView: 1.75,
    centeredSlides: true,
    loop: false,
    spaceBetween: 10,
  };

  async ngOnInit() {
    this.settingsQuery.accountCards$.subscribe({
      next: (accountCards) => {
        this.accountCards = accountCards;
      },
      error: (err) => {
        this.logger.error('Unable to retrieve user account cards from data store:', err);
      },
    });
    this.settingsQuery.defaultAccountCardId$.subscribe({
      next: (defaultAccountCardId) => {
        this.defaultAccountCardId = defaultAccountCardId;
      },
      error: (err) => {
        this.logger.error(
          'Unable to retrieve user default account card ID from data store:',
          err,
        );
      },
    });
  }

  trackByFn(index: number, item: PaymentCard) {
    return item.accountPaymentId;
  }

  getCardBackground(i: any) {
    if (this.accountCards[i].cardType === 'MasterCard') return './assets/cards/MasterCard.png';
    if (this.accountCards[i].cardType === 'Visa') return './assets/cards/Visa.png';
    if (this.accountCards[i].cardType === 'American Express')
      return './assets/cards/American Express.png';
  }

  getCardKind(i: any) {
    if (this.accountCards[i].cardType === 'MasterCard') return 'DEBIT';
    if (this.accountCards[i].cardType === 'Visa') return 'CREDIT';
    if (this.accountCards[i].cardType === 'American Express') return 'CREDIT';
  }

  setToDefault(accountCardId: number) {
    this.defaultChosen.emit(accountCardId);
  }

  onPaymentMethodClick() {
    if (this.clicked) return;
    this.homeService.openZenithAddPaymentCard().then(() => {
      this.clicked = false;
    });
  }

  updateDefaultPaymentMethodId(id: number) {
    this.defaultAccountCardId = id;
  }

  onSlideChange(event: any) {
    this.slider.getActiveIndex().then((index) => {
      if (this.accountCards[index]) {
        this.slideChanged.emit({ index, methodId: this.accountCards[index].accountPaymentId });
      } else {
        this.slideChanged.emit({ index, methodId: undefined });
      }
    });
  }

  resetSlider(card: PaymentCard) {
    let slideIndex = 1;

    if (card.accountPaymentId == this.defaultAccountCardId || this.accountCards.length <= 1) {
      slideIndex = 0;
    }

    this.slider.slideTo(slideIndex);
  }

  async presentEditPaymentMethod(i: any) {
    const popover = await this.popoverController.create({
      component: ManagePaymentMethodPopoverComponent,
      mode: 'ios',
      componentProps: { card: this.accountCards[i] },
    });
    await popover.present();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
