import { HOME_UPCOMING_BILL_LIST_COUNT } from '@1bill-app/constants';
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  BillCategoryKey,
  BillCategoryType,
  GetFailedBill,
  OneBillAccountBillType,
  UserBillPayment,
  UserBillPrediction,
  ViewBillType,
} from './types';

export interface BillResponse {
  data: BillData;
  success: boolean;
}

export type BillData = {
  accountBillTypes: OneBillAccountBillType[];
  billPayments: UserBillPayment[];
  billCategories: BillCategoryType[];
  billTags: string[];
  suppliers: any[];
  aggregated: BillAggregatedData;
  isFirstBill: boolean;
  failedBills: GetFailedBill[];
  emailScannedBillsToVerify: number;
  paymentOptionsBills?: ViewBillType[];
  billPredictions: UserBillPrediction[];
};

export interface BillAggregatedData {
  isFirstBill: boolean;
  totalDueAmount: number;
  totalOverdueAmount: number;
  monthlySavingAmount: number;
  weeklyDueAmount: number;
  monthlyDueAmount: number;
  totalBillSaving: number;
  recentDueData: RecentDueData;

  /** Total for recently uploaded month */
  recentMonthlyDueAmount: number;
  /** Total for recently uploaded week */
  recentWeeklyDueAmount: number;
  /** Total for upcoming bill month */
  upcomingMonthlyDueAmount: number;
  /** Total for upcoming bill week */
  upcomingWeeklyDueAmount: number;

  /** Count for recently uploaded bills */
  recentCount: number;
  /** Count for upcoming bills */
  upcomingCount: number;
}

export interface RecentDueData {
  dueDate?: string;
  dueAmount: number;
  howToPay?: string;
  payInfoMasked: string;
  weekDueAmount: number;
  monthDueAmount: number;
}

export interface BillsFilterState {
  limit?: number;
  categoryKey?: BillCategoryKey;
  /**
   * @deprecated in favor of v3 design (categoryKey)
   * @description for multiple category filter selection
   */
  categoryKeys?: BillCategoryKey[];
  propertyTags?: string[];
  query?: string;
}

export interface BillUIState {
  homePageFilter?: BillsFilterState;
  billsPageFilter?: BillsFilterState;
  historyPageFilter?: BillsFilterState;
}

export interface BillState extends BillData {
  ui: BillUIState;
  loaded: boolean;
  currentBillId: any;
  currentJobId?: number;
  refetch: boolean;
  refetchSource: string;
  forceRefresh?: boolean;
  switchRewardPoints: number;
}

function createInitialState(): BillState {
  return {
    ui: {
      homePageFilter: {
        limit: HOME_UPCOMING_BILL_LIST_COUNT,
        categoryKey: BillCategoryKey.ALL,
        categoryKeys: [], // @deprecated
      },
      billsPageFilter: {
        limit: -1,
        categoryKeys: [],
        propertyTags: [],
      },
      historyPageFilter: {
        limit: -1,
        categoryKeys: [],
        propertyTags: [],
      },
    },
    accountBillTypes: [],
    billCategories: null,
    billPayments: null,
    billTags: null,
    suppliers: null,
    aggregated: null,
    isFirstBill: null,
    loaded: null,
    currentBillId: null,
    refetch: null,
    refetchSource: null,
    forceRefresh: null,
    failedBills: null,
    emailScannedBillsToVerify: null,
    paymentOptionsBills: null,
    switchRewardPoints: null,
    billPredictions: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bill' })
export class BillStore extends Store<BillState> {
  constructor() {
    super(createInitialState());
  }
  updateUI(uiState: Partial<BillUIState>) {
    const prevUIState = this.getValue().ui;
    this.update({ ui: { ...prevUIState, ...uiState } });
  }
}
