import { AuthService } from '@1bill-app/services/auth/state/auth.service';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';
import { ROUTE_HOMEPAGE, ROUTE_LANDING } from '@1bill-app/constants';
import { NavController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import { PINService } from './pin.service';
import { CreatePinScreenComponent } from '@1bill-app/components/create-pin-screen/create-pin-screen.component';
import { selectPersistStateInit } from '@datorama/akita';
import { AuthQuery } from './state/auth.query';

declare let fbq: any;

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private authQuery: AuthQuery,
    private router: Router,
    private logger: NGXLogger,
    private pinService: PINService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return combineLatest([this.authQuery.hasLastActivity$, selectPersistStateInit()]).pipe(
      distinctUntilChanged(),
      switchMap(() =>
        forkJoin([this.authService.isAuthenticated(), this.pinService.hasPin()]),
      ),
      switchMap(([{ loggedIn }, pin]) => {
        if (this.authService.isSessionTimeout(`auth-guard (${state.url})`)) {
          this.authService.setSessionLastUrl(state.url);
          if (Capacitor.isNativePlatform()) {
            if (pin) {
              return from(this.authService.openLockScreen()).pipe(map((_) => loggedIn));
            } else {
              return from(this.pinService.openCreatePIN(CreatePinScreenComponent)).pipe(
                map((_) => loggedIn),
              );
            }
          } else {
            return this.authService.logout('auth.guard').pipe(map((_) => loggedIn));
          }
        }

        return of(loggedIn);
      }),
      switchMap((loggedIn) =>
        this.authService.getCognitoGroups().pipe(
          map((cognitoGroups) => {
            const group = next.data.group;
            const userInGroup = Boolean(
              !group || (group && cognitoGroups.indexOf(group) >= 0),
            );

            return loggedIn && userInGroup;
          }),
        ),
      ),
      map((loggedIn) => {
        if (loggedIn) {
          return true;
        }

        this.authService.setSessionLastUrl(state.url);
        this.router.navigateByUrl(ROUTE_LANDING, { queryParams: { _ref: state.url } });
        return false;
      }),
      take(1),
    );
  }
}

@Injectable({ providedIn: 'root' })
export class UnAuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navCtrl: NavController,
    private logger: NGXLogger,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.isAuthenticated().pipe(
      map(({ loggedIn }) => {
        if (!loggedIn) {
          return true;
        }
        // Should auto-logins count as pixel tagging events?
        // try {
        //   fbq('trackCustom', 'Login');
        // } catch (err) {
        //   this.logger.log('[Login Event] Facebook Pixel tagging is not enabled in the current environment.');
        // }
        this.navCtrl.navigateRoot(ROUTE_HOMEPAGE);
        return false;
      }),
      take(1),
    );
  }
}
