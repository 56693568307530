import { Pipe, PipeTransform } from '@angular/core';
import { invalidPipeArgumentError } from './invalid_pipe_argument_error';

/**
 * Transforms text to all kebab case.
 */
@Pipe({
  name: 'kebabCase',
})
export class KebabCasePipe implements PipeTransform {
  /**
   * @param value The string to transform to kebab-case
   * @param separator The char to split the words for kebabCase. If no separator provided, by
   * defualt space will be used to split the words.
   *
   */
  transform(value: string, separator?: string): string {
    if (!value) return value;
    if (typeof value !== 'string') {
      throw invalidPipeArgumentError(KebabCasePipe, value);
    }
    const words = value.split(separator ? separator : ' ');
    const lowerCaseWords = words.map((word) => word.toLowerCase());
    return lowerCaseWords.join('-');
  }
}
