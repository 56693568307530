import { Component, Input } from '@angular/core';
import { Platform } from '@ionic/angular';
import {
  CustomValidationService,
  DATE_MASK,
} from '../../services/validation/custom-validation.service';
import {
  ControlValueAccessor,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-date-input-field',
  styleUrls: ['./date-input-field.component.scss'],
  template: `
    <div [formGroup]="formGroup" [class]="bordered ? 'bordered-input' : ''">
      <ion-input
        *ngIf="platform.is('desktop') && !isSafariDesktop"
        type="date"
        [formControlName]="formControlName"
        [name]="name"
        [max]="max"
        [min]="min"
        [placeholder]="placeholder"
      ></ion-input>
      
      <!-- SafariDesktop date input -->
      <ion-input
        *ngIf="isSafariDesktop"
        type="text"
        [formControlName]="formControlName"
        [name]="name"
        [max]="max"
        [min]="min"
        [placeholder]="placeholder"
        [appInputMask]="safariDateMask"
      ></ion-input>
      
      <!-- SafariDesktop date input -->

      <ion-datetime
        *ngIf="!platform.is('desktop')"
        [formControlName]="formControlName"
        [name]="name"
        [max]="max"
        [min]="min"
        displayFormat="DD/MM/YYYY"
        placeholder="dd/mm/yyyy"
      ></ion-datetime>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputFieldComponent,
    },
  ],
})
export class DateInputFieldComponent implements ControlValueAccessor {
  // @ViewChild('input', {static:false}) input: ElementRef;
  // TODO: Validation msg remove while typing
  // TODO:
  disabled;
  @Input() formControlName: string;
  @Input() formGroup: FormGroup;
  @Input() name: string;
  @Input() min: string;
  @Input() max: string;
  @Input() placeholder = 'dd/mm/yyyy';
  @Input() bordered = false;
  safariDateMask = DATE_MASK;
  isSafariDesktop: boolean;
  constructor(
    public platform: Platform,
    private _cusValidation: CustomValidationService,
    private logger: NGXLogger,
  ) {
    this.isSafariDesktop = this._cusValidation.isSafariDesktop();
  }

  writeValue(obj: any): void {
    // this.input.nativeElement.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange(event) {
    this.logger.debug(event);
  }

  onTouched() {
    this.logger.log('rouch');
  }
}
