// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BillUsageChartComponent } from './components/bill-usage-chart/bill-usage-chart.component';
import { LatPaymentCallbackComponent } from './pages/bill/components/lat-payment-callback/lat-payment-callback.component';
import { EmailScannerCrudComponent } from './pages/email-scanner/components/email-scanner-crud/email-scanner-crud.component';
// HIDEREFERRALCODES
// import { ReferralPage } from './pages/referral/referral.page';
import { AuthGuard, UnAuthGuard } from './services/auth/auth.guard';
import { NativeAppGuard } from './services/auth/native-app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/landing',
    pathMatch: 'full',
  },
  {
    path: 'app',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    // canActivate: [AuthGuard],
    // runGuardsAndResolvers: 'always',
  },
  {
    path: 'chart',
    component: BillUsageChartComponent,
  },
  {
    path: 'accounts',
    loadChildren: () =>
      import('./pages/yodlee-accounts/yodlee-accounts.module').then(
        (m) => m.YodleeAccountsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'email-scanner-jobs',
    loadChildren: () =>
      import('./pages/email-scanner-jobs/email-scanner-jobs.module').then(
        (m) => m.EmailScannerJobsPageModule,
      ),
  },
  {
    path: 'transactions',
    loadChildren: () =>
      import('./pages/yodlee-transactions/yodlee-transactions.module').then(
        (m) => m.YodleeTransactionsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'credit-score',
    loadChildren: () =>
      import('./pages/credit-score/credit-score.module').then((m) => m.CreditScorePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'upload-file',
    loadChildren: () =>
      import('./pages/upload-file/upload-file.module').then((m) => m.UploadFilePageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'first-bill',
  //   children: [
  //     {
  //       path: 'select',
  //       loadChildren: () => import('./pages/first-bill/first-bill-select/first-bill-select.module').then( m => m.FirstBillSelectPageModule),
  //     },
  //     {
  //       path: 'upload',
  //       loadChildren: () => import('./pages/first-bill/first-bill-upload/first-bill-upload.module').then( m => m.FirstBillUploadPageModule)
  //     },

  //   ],
  //   canActivate: [AuthGuard, BillGuard]
  // },
  {
    path: 'file/:id',
    loadChildren: './pages/bill/file/file.module#FilePageModule',
    canActivate: [AuthGuard],
  },
  // The route below is used for switching process.
  {
    path: 'bill/:id',
    loadChildren: () => import('./pages/bill/bill.module').then((m) => m.BillPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'bill',
    loadChildren: () => import('./pages/bill/bill.module').then((m) => m.BillPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'app/bills',
    loadChildren: () => import('./pages/bills/bills.module').then((m) => m.BillsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-info',
    loadChildren: () =>
      import('./pages/settings/payment-information/payment-information.module').then(
        (m) => m.PaymentInformationPageModule,
      ),
  },
  // HIDEREFERRALCODES
  // {
  //   path: 'referral',
  //   loadChildren: () =>
  //     import('./pages/referral/referral.module').then((m) => m.ReferralPageModule),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'app/settings',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/settings/settings/settings.module').then(
            (m) => m.SettingsPageModule,
          ),
      },
      {
        path: 'contact-details',
        loadChildren: () =>
          import('./pages/settings/contact-details/contact-details.module').then(
            (m) => m.ContactDetailsPageModule,
          ),
      },
      {
        path: 'id-details',
        loadChildren: () =>
          import('./pages/settings/id-details/id-details.module').then(
            (m) => m.IdDetailsPageModule,
          ),
      },
      // {
      //   path: 'payment-instructions',
      //   loadChildren: () =>
      //     import('./pages/settings/payment-instructions/payment-instructions.module').then(
      //       (m) => m.PaymentInstructionsPageModule,
      //     ),
      // },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./pages/settings/notifications/notifications-settings.module').then(
            (m) => m.NotificationsPageModule,
          ),
      },
      {
        path: 'reminders',
        loadChildren: () =>
          import('./pages/settings/reminders/reminders.module').then(
            (m) => m.RemindersPageModule,
          ),
      },
      {
        path: 'password',
        loadChildren: () =>
          import('./pages/settings/password/password.module').then(
            (m) => m.PasswordPageModule,
          ),
      },
      // not used anymore
      // {
      //   path: 'qantas-frequent-flyer',
      //   loadChildren: () =>
      //     import('./pages/settings/qantas-frequent-flyer/qantas-frequent-flyer.module').then(
      //       (m) => m.QantasFrequentFlyerPageModule,
      //     ),
      // },
      // not used anymore
      // {
      //   path: 'dashboard-preferences',
      //   loadChildren: () =>
      //     import('./pages/settings/dashboard-preferences/dashboard-preferences.module').then(
      //       (m) => m.DashboardPreferencesPageModule,
      //     ),
      // },
      {
        path: 'account-delete',
        loadChildren: () =>
          import('./pages/settings/account-delete/account-delete.module').then(
            (m) => m.AccountDeletePageModule,
          ),
      },
    ],
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'app/history',
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () =>
  //         import('./pages/history-list/history-list.module').then(
  //           (m) => m.HistoryListPageModule,
  //         ),
  //     },
  //     // {
  //     //   path: 'bill',
  //     //   loadChildren: () => import('./pages/history/history-bill/history-bill.module').then( m => m.HistoryBillPageModule)
  //     // },
  //   ],
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'take-photo',
    loadChildren: () =>
      import('./pages/take-photo/take-photo.module').then((m) => m.TakePhotoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-your-bill/:billType',
    loadChildren: () =>
      import('./pages/upload-your-bill/upload-your-bill.module').then(
        (m) => m.UploadYourBillPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'email-bills',
    loadChildren: () =>
      import('./pages/email-bills/email-bills.module').then((m) => m.EmailBillsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'first-bill-upload',
    loadChildren: () =>
      import('./pages/first-bill/upload-bill/upload-bill.module').then(
        (m) => m.UploadBillPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'connect-email',
    loadChildren: () =>
      import('./pages/connect-email/connect-email.module').then(
        (m) => m.ConnectEmailPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'lat-pay-failed',
    component: LatPaymentCallbackComponent,
  },
  {
    path: 'lat-pay-successful',
    component: LatPaymentCallbackComponent,
  },
  {
    path: 'lat-pay',
    component: LatPaymentCallbackComponent,
  },
  {
    path: 'achievements',
    loadChildren: () =>
      import('./pages/achievements/achievements.module').then((m) => m.AchievementsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then((m) => m.FaqPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'contact-us',
    loadChildren: () =>
      import('./pages/contact-us/contact-us.module').then((m) => m.ContactUsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'email-scanner',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/email-scanner/email-scanner.module').then(
            (m) => m.EmailScannerPageModule,
          ),
      },
      {
        path: ':provider/:crud',
        component: EmailScannerCrudComponent,
      },
    ],
  },
  {
    path: 'diagnostics',
    canActivate: [AuthGuard],
    data: { group: 'DevAdmin' },
    children: [
      {
        path: 'app-logs',
        loadChildren: () =>
          import('./pages/settings/diagnostics/app-logs/app-logs.module').then(
            (m) => m.AppLogsPageModule,
          ),
      },
      {
        path: 'app-log/:id',
        loadChildren: () =>
          import('./pages/settings/diagnostics/app-log-details/app-log-details.module').then(
            (m) => m.AppLogDetailsPageModule,
          ),
      },
      {
        path: 'push-logs',
        loadChildren: () =>
          import('./pages/settings/diagnostics/push-logs/push-logs.module').then(
            (m) => m.PushLogsPageModule,
          ),
      },
      {
        path: 'push-log/:id',
        loadChildren: () =>
          import('./pages/settings/diagnostics/push-log-details/push-log-details.module').then(
            (m) => m.PushLogDetailsPageModule,
          ),
      },
    ],
  },
  // not used anymore
  // {
  //   path: 'dashboard-preferences',
  //   loadChildren: () =>
  //     import('./pages/settings/dashboard-preferences/dashboard-preferences.module').then(
  //       (m) => m.DashboardPreferencesPageModule,
  //     ),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'points-info',
    loadChildren: () =>
      import('./pages/points-info/points-info.module').then((m) => m.PointsInfoPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'get-better-deals',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './pages/get-better-deals/get-better-deals-filters/get-better-deals-filters.module'
          ).then((m) => m.GetBetterDealsFiltersPageModule),
      },
      {
        path: 'products',
        loadChildren: () =>
          import(
            './pages/get-better-deals/get-better-deals-products/get-better-deals-products.module'
          ).then((m) => m.GetBetterDealsProductsPageModule),
      },
      {
        path: 'signup',
        loadChildren: () =>
          import(
            './pages/get-better-deals/get-better-deals-signup/get-better-deals-signup.module'
          ).then((m) => m.GetBetterDealsSignupPageModule),
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'find-more-savings',
    loadChildren: () =>
      import('./pages/find-more-savings/find-more-savings.module').then(
        (m) => m.FindMoreSavingsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'net-income',
    loadChildren: () =>
      import('./pages/net-income/net-income.module').then((m) => m.NetIncomePageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'expenses/:id',
  //   loadChildren: () =>
  //     import('./pages/net-income/expenses/expenses.module').then((m) => m.ExpensesPageModule),
  // },
  {
    path: 'add-edit-manual-account/:id',
    loadChildren: () =>
      import('./pages/add-edit-manual-account/add-edit-manual-account.module').then(
        (m) => m.AddManualAccountPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'cash-screen',
    loadChildren: () =>
      import('./pages/cash-screen/cash-screen.module').then((m) => m.CashScreenPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-options/:id',
    loadChildren: () =>
      import('./pages/payment-options/payment-options.module').then(
        (m) => m.PaymentOptionsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'payment-options',
    loadChildren: () =>
      import('./pages/payment-options/payment-options.module').then(
        (m) => m.PaymentOptionsPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('./pages/user-management/user-management.module').then(
        (m) => m.UserManagementPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: `link-bill-screen/:accountPaymentId`,
    loadChildren: () =>
      import('./pages/payment-options/link-bill-screen/link-bill-screen.module').then(
        (m) => m.LinkBillScreenPageModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./pages/unsubscribe/unsubscribe.module').then((m) => m.UnsubscribePageModule),
  },

  // {
  //   path: 'landing-page',
  //   loadChildren: () =>
  //     import('./pages/landing-page/landing-page.module').then((m) => m.LandingPagePageModule),
  // },
  {
    path: 'app/user-profile',
    loadChildren: () =>
      import('./pages/user-profile/user-profile.module').then((m) => m.UserProfilePageModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'payment-settings',
  //   loadChildren: () =>
  //     import('./pages/payment-settings/payment-settings.module').then(
  //       (m) => m.PaymentSettingsPageModule,
  //     ),
  // },
  // {
  //   path: 'account-view',
  //   loadChildren: () =>
  //     import('./pages/cash-screen/account-view/account-view.module').then(
  //       (m) => m.AccountViewPageModule,
  //     ),
  // },
  // Note: always put path: '**' route at the end of list
  {
    path: 'force-app-update',
    loadChildren: () =>
      import('./pages/force-app-update/force-app-update.module').then(
        (m) => m.ForceAppUpdatePageModule,
      ),
    canActivate: [NativeAppGuard],
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./pages/dock/dashboard/dashboard.module').then( m => m.DashboardPageModule),
  //   canActivate: [UnAuthGuard]
  // },

  // {
  //   path: 'bills-tab',
  //   loadChildren: () => import('./pages/dock/bills-tab/bills-tab.module').then( m => m.BillsTabPageModule),
  //   canActivate: [UnAuthGuard]

  // },

  // {
  //   path: 'add-bill',
  //   loadChildren: () => import('./pages/dock/add-bill/add-bill.module').then( m => m.AddBillPageModule),
  //   canActivate: [UnAuthGuard]
  // },

  // {
  //   path: 'rewards',
  //   loadChildren: () => import('./pages/dock/rewards/rewards.module').then( m => m.RewardsPageModule),
  //   canActivate: [UnAuthGuard]
  // },
  // {
  //   path: 'add-profile',
  //   loadChildren: () => import('./pages/dock/add-profile/add-profile.module').then( m => m.AddProfilePageModule),
  //   canActivate: [UnAuthGuard]
  // },

  // {
  //   path: 'app/add-bill-manually',
  //   loadChildren: () =>
  //     import('./pages/add-bill-manually/add-bill-manually.module').then(
  //       (m) => m.AddBillManuallyPageModule,
  //     ),
  // },

  {
    path: '**',
    loadChildren: () =>
      import('./pages/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundPageModule,
      ),
  },

  // Note: always put path: '**' route at the end of list
  // {
  //   path: '**',
  //   loadChildren: () =>
  //     import('./pages/page-not-found/page-not-found.module').then(
  //       (m) => m.PageNotFoundPageModule,
  //     ),
  // },

  // {

  //   path: 'notifications',
  //   loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  // },

  //   path: 'notifications',
  //   loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  // },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
