import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { generalMenu, dummyMenu } from 'src/app/app.menu';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {

  @Input() dummy: boolean;
  appMenu = generalMenu;
  appDummyMenu = dummyMenu;
 
  routeFragment: string = null;
  get currentRoute() {
    return this.routeFragment ? this.router.url.split(`#${this.routeFragment}`)[0] : this.router.url;
  }
  constructor(private router: Router) {}


  get menuToDisplay() {
    return this.dummy == false ? this.appMenu : this.appDummyMenu;
  }

  ngOnInit(): void {
    this.router.routerState.root.fragment.subscribe((fragmentUpdate) => {
      this.routeFragment = fragmentUpdate;
    });
  }
}
