import { BillsFilterState } from '@1bill-app/services/bill/bill.store';
import { Component,   HostListener, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import {IonSlides} from '@ionic/angular';
import { from, Observable } from 'rxjs';
/**
 * Component to show tags used for filtering and showing what is currently displayed
 * for the bills list.
 */
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() data: string[];
  @Input() filter: BillsFilterState;
  @ViewChild(IonSlides) slides: IonSlides;
  @ViewChild('filter') myFilter: ElementRef;
  isBeginning$: Observable<boolean>;
  isEnd$: Observable<boolean>;

  @Output() tagClick: EventEmitter<{ tag: string }> = new EventEmitter();
  constructor() {}

  sliderOptions = {
    slidesPerView: this.checkScreen(),
  };

  ngAfterViewInit() {
    this.updateSlidesPerView();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSlidesPerView();
  }

  async updateSlidesPerView() {
    const width = window.innerWidth;
    const swiper = await this.slides.getSwiper();
    if (width >= 768 && width <= 912 ) {
      swiper.params.slidesPerView = 5;
    } else {
      swiper.params.slidesPerView = 3;
    }
    this.slides.update();
  }

  ngOnInit() {
   
  }

  trackItemsById(index, item) {
    return item.id;
  }

  slideNext() {
    if (this.slides) {
      this.slides.slideNext();
    }
  }
  slidePrev() {
    if (this.slides) {
      this.slides.slidePrev();
    }
  }

  checkScreen(){
    if(window.innerWidth>=768){
        // console.log("width", window.innerWidth);
        return 5;
    }else{
        return 3;
    }
}

  updateSliderIconState() {
    this.isEnd$ = from(this.slides.isEnd());
    this.isBeginning$ = from(this.slides.isBeginning());
  }  

  onTagClick(tag: string) {
    this.tagClick.emit({ tag });
  }
  isSelected(tag: string) {
    if (!this.filter?.propertyTags?.length) return false;

    return this.filter.propertyTags.indexOf(tag) >= 0;
  }
}
