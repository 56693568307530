import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AddressTypes } from '../../pages/credit-score/credit-score.page';
import { IonSearchbar } from '@ionic/angular';
import { GoogleAddressService } from '@1bill-app/services/google/google-address.service';

@Component({
  selector: 'app-google-address-search',
  styleUrls: ['./google-address-search.component.scss'],
  template: `
    <!--Ion searchbar-->
    <div style="width: 100%">
      <ion-searchbar
        style="padding:0"
        placeholder="Search Address"
        #searchInput
        [(ngModel)]="addressSearchString"
        (ionCancel)="cancel()"
        (ionChange)="updateSearch()"
        animated="true"
        showCancelButton="always"
      >
      </ion-searchbar>
      <ion-list>
        <ion-item
          *ngFor="let item of addressSearchItems"
          tappable
          (click)="setAddress(item)"
        >
          {{ item.desc }}
        </ion-item>
        <img
          *ngIf="addressSearchItems.length"
          style="height: 15px; width: auto; float: right;margin: 10px;"
          src="./assets/google/powered_by_google_on_white_hdpi.png"
          alt="powered by Google"
        />
      </ion-list>
    </div>
  `,
})
export class GoogleAddressSearchComponent implements OnInit {
  @Input() passedString?: string;
  @ViewChild('searchInput', { static: false }) searchInput: IonSearchbar;
  // tslint:disable-next-line:max-line-length
  @Output() update: EventEmitter<{
    success: boolean;
    data: { desc: string; placeId: string };
  }> = new EventEmitter<{
    success: boolean;
    data: { desc: string; placeId: string };
  }>();
  addressSearchItems: { desc: string; placeId: string }[] = [];
  addressSearchString: string;
  constructor(private _gAddSrv: GoogleAddressService) {}

  ngOnInit() {
    if (this.passedString) {
      this.addressSearchString = this.passedString;
      this.updateSearch();
    }
    setTimeout(() => {
      this.searchInput.setFocus();
    }, 5);
  }

  setAddress(item: { desc: string; placeId: string }) {
    this.update.emit({ success: true, data: item });
    this.addressSearchItems = [];
  }

  updateSearch() {
    if (!this.addressSearchString) {
      return;
    }
    this._gAddSrv.getPlacePredictions(this.addressSearchString).then((res) => {
      this.addressSearchItems = res;
    });
  }

  cancel() {
    this.update.emit({ success: false, data: null });
    this.addressSearchItems = [];
  }
}
