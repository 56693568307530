import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { BILL_FEE, PAYMENT_COMBINED_MODAL_ID } from '@1bill-app/constants';
import {
  SelectablePaymentMethod,
  getCardIcon,
  PaymentCard,
} from '@1bill-app/services/payment/payment-card.service';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-checkout-footer',
  templateUrl: './checkout-footer.component.html',
  styleUrls: ['./checkout-footer.component.scss'],
})
export class CheckoutFooterComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private router: Router,
    private modalController: ModalController,
  ) {}

  nickname: string = "";

  @Input() totalBillAmount: number;
  @Input() selectedMethod: SelectablePaymentMethod;
  @Output() onGotoSelectPayment: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onPayButtonClicked: EventEmitter<boolean> = new EventEmitter(false);

  get fee() {
    return this.totalBillAmount * BILL_FEE;
  }

  get totalPayAmount() {
    return this.totalBillAmount + this.fee;
  }

  ngOnInit() {
  //  console.log("TEST", this.selectedMethod.nickname)
  }

  getCardTitle(card: SelectablePaymentMethod): string {
    if (!card) return 'No payment method';
    switch (card.type) {
      case 'card': {
        switch (card.cardType.toLowerCase()) {
          case 'mastercard': {
            return 'Mastercard';
          }
          case 'visa': {
            return 'Visa Card';
          }
          case 'american express': {
            return 'American Express Card';
          }
          default: {
            this.logger.error(
              'CheckoutFooterComponent::getCardTitle() unhandled card type:',
              card.cardType,
            );
          }
        }
        break;
      }
      case 'account': {
        // Not implemented yet
      }
      default: {
        console.error(
          'CheckoutFooterComponent.getCardTitle() unhandled payment method type:',
          card.type,
        );
      }
    }
  }


  onSelectPaymentAdvanceClicked() {
    this.onGotoSelectPayment.emit(true);
  }

  getCardIcon(method: SelectablePaymentMethod) {
    switch (method.type) {
      case 'card': {
        return getCardIcon(method.cardType);
      }
      case 'account': {
        // Not implemented yet
      }
      default: {
        console.error(
          'CheckoutFooterComponent.getCardIcon() unhandled method type:',
          method.type,
        );
      }
    }
  }

  payButtonClicked() {
    this.onPayButtonClicked.emit(true);
  }

  /**
   * Handle footer payment message for method
   *  */
  getPaymentMessage(method: SelectablePaymentMethod): string {
    // Handle type
    switch (method.type) {
      case 'account': {
        return 'Payments via bank accounts is not implemented yet.';
      }
      break;
      case 'card': {
        return 'Thanks for using 1Bill to pay your bills. Your payment will be verified and processed within 2 Business Days. Once verified your bill will be paid via BPAY to your provider on the next Banking Business Day.';
      }
      break;
      default: {
        this.logger.warn('CheckoutFooterComponent::getPaymentMessage() unhandled type:', method.type);
      }
    }
    return 'Thanks for using 1Bill to pay your bills.';
  }

  routeToDisabledLink(link: string) {
    this.modalController.dismiss(null, null, PAYMENT_COMBINED_MODAL_ID);
    this.router.navigateByUrl(link);
  }
}
