import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TEXTAREA_MAX_LENGTH } from '@1bill-app/constants';

@Component({
  template: `
    <div class="confirm-terminate-account-modal-component-wrapper">
      <div class="modal-head">
        <h2>Delete your account?</h2>
      </div>
      <div class="modal-body-message">
        <p>Please confirm that you want to close your 1bill account and tell us why.</p>
      </div>
      <div class="form-wrapper">
        <ion-label class="form-label">Termination reason</ion-label>
        <ion-textarea class="form-textarea" [(ngModel)]="terminationReason" placeholder="Tell us why you wish to leave 1bill" [maxlength]="textareaMax" rows="4"></ion-textarea>
      </div>
      <button class="terminate-button" (click)="dismiss(true)">Delete my account</button>
    </div>
  `,
  styleUrls: ['./confirm-terminate-account-modal.component.scss'],
})
export class ConfirmTerminateAccountModalComponent {
  constructor(private modalController: ModalController) {}
  terminationReason: string;
  textareaMax = TEXTAREA_MAX_LENGTH;

  /**
   * Dismisses the modal with the textarea input as `terminationReason`. The termination request will only be
   * completed if the dismiss role is `'confirm'`.
   * @param confirmed Whether the user clicked the 'Delete my account' button to confirm account termination.
   */
  dismiss(confirmed?: boolean) {
    if (confirmed) {
      this.modalController.dismiss({ terminationReason: this.terminationReason }, 'confirm');
    } else {
      this.modalController.dismiss(null, 'cancel');
    }
  }
}