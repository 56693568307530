import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RemovePinScreenComponent } from './remove-pin-screen.component';
import { CodeInputModule } from 'angular-code-input';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [RemovePinScreenComponent],
  imports: [
    CommonModule,
    IonicModule,
    CodeInputModule,
  ]
})
export class RemovePinScreenModule { }
