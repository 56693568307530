import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { NotificationChannel } from '../notification/notification.type';
import { PaymentCard } from '../payment/payment-card.service';

export interface Settings {
  address: string;
  addressFull?: string;
  addressJson: {
    city: string;
    state: string;
    country: string;
    placeId: string;
    postcode: string;
    streetNo: string;
    streetName: string;
    streetType: string;
  };
  billAccountId: number;
  billEmail: string;
  // crmAccountId: number;
  email: string;
  dateOfBirth?: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  mobileVerified: boolean;
  notifyMethod: NotificationChannel[];
  notifySubscribeTopics?: NotifySubscribeTopic[];
  paymentMethod: string;
  isPMUpdateBill?: boolean;
  postcode: string;
  reminderDays: number[];
  zohoCrmAccountId: number;
  identificationSelected: string;
  idPassport: any;
  idDriverLicence: any;
  idMedicare: any;
  accountCards: PaymentCard[];
  defaultAccountCardId: number;
  preferences: AccountPreference[];
  _lastModified?: string;
}

export enum NotifySubscribeTopic {
  BILL_REMINDER = 'BILL_REMINDER',
  OVERDUE_REMINDER = 'OVERDUE_REMINDER',
  BILL_TRANSACTIONS = 'BILL_TRANSACTIONS',
  BILL_RECOMMENDATIONS = 'BILL_RECOMMENDATIONS',
  BILL_UPLOADS = 'BILL_UPLOADS',
  EMAIL_SCANNER = 'EMAIL_SCANNER',
  APP_VERSION = 'APP_VERSION',
  MULTI_USER_ACCESS = 'MULTI_USER_ACCESS',
}

export interface AccountPreference {
  itemName: string;
  order: number;
  prefType: string;
  show: boolean;
}

export interface SettingsOptions {
  option: string;
  origin?: 'MOBILE_VERIFICATION';
  settingsData: Partial<Settings>;
}

export function createInitialState(): Settings {
  return {
    address: null,
    addressJson: null,
    billAccountId: null,
    billEmail: null,
    // crmAccountId: null,
    email: null,
    dateOfBirth: null,
    firstName: null,
    lastName: null,
    mobileNumber: null,
    notifyMethod: null,
    notifySubscribeTopics: null,
    paymentMethod: null,
    isPMUpdateBill: null,
    postcode: null,
    reminderDays: null,
    zohoCrmAccountId: null,
    identificationSelected: null,
    idPassport: null,
    idDriverLicence: null,
    idMedicare: null,
    accountCards: [],
    defaultAccountCardId: null,
    preferences: null,
    mobileVerified: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'settings', resettable: true })
export class SettingsStore extends Store<Settings> {
  constructor() {
    super(createInitialState());
  }
}
