
import { PublishEventAction, WebsocketService } from './websocket.service';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { filter, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class AdminForceRefreshService {
      constructor(
        private wsService: WebsocketService,
        private logger: NGXLogger,
      ) {
      }

  adminForceRefreshEvent() {
    return this.wsService.messages$.pipe(
      tap((data) => this.logger.log('clearCacheIfAdminRequests', data)),
      filter(
        (data) =>
          data.event === PublishEventAction.ADMIN_FORCE_REFRESH_USER_DEVICE
      ),
    )
  }
}
