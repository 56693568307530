import { BillService } from '@1bill-app/services/bill/bill.service';
import { PaymentCard, PaymentCardService } from '@1bill-app/services/payment/payment-card.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { Settings } from '@1bill-app/services/settings/settings.store';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { showError } from 'src/app/services/custom-functions/custom-functions';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  @Input() paymentCard: PaymentCard;
  @Input() loading: boolean;
 
  get cardTypeKey() {
    return this.paymentCard?.cardType?.toLowerCase();
  }

  get isDefaultCard() {
    return (
      this.settingsQuery.getValue()?.defaultAccountCardId ===
      this.paymentCard?.accountPaymentId
    );
  }

  constructor(
    private logger: NGXLogger,
    public query: SettingsQuery,
    private settingsQuery: SettingsQuery,
  ) {}

  public settingsData: Settings;
  accountCards: PaymentCard[];
  private route: ActivatedRoute;
  subs = new SubSink(); 
  accountSettings: Settings;
  Isloading: HTMLIonLoadingElement;


ngOnInit() {}

  getCardImg(cardType: string) {
    if (!cardType) return null;
    switch (cardType.toLowerCase()) {
      case 'visa':
        return '/assets/cards/Visa.png';
      case 'mastercard':
        return '/assets/cards/MasterCard.png';
      case 'american express':
        return '/assets/cards/American Express.png';
      default:
        return null;
    }
  }

  getMaskedCardNumber() {
    const cardNumber = this.paymentCard?.cardNumber;
    const fourDigits = cardNumber?.substr(cardNumber.length - 4);
    return `•••• ${fourDigits}`;
  }
}
