import { Reward } from '@1bill-app/services/reward/reward.type';
import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-popover-achievements',
  styleUrls: ['./popover-achievements.component.scss'],
  templateUrl: './popover-achievements.component.html',
})
export class PopoverAchievementsComponent implements OnInit {
  reward: Reward;

  constructor(private navParams: NavParams) {
    this.reward = this.navParams.get('achievementType');
  }

  ngOnInit() {}
}
