import { UserBillPrediction } from '@1bill-app/services/bill/types';

import { Component, Input } from '@angular/core';

import { ViewBillType } from '@1bill-app/services/bill/types';
import dayjs from 'dayjs';

import { PopoverSimpleComponent } from '../popover/popover-simple/popover-simple.component';

import { PopoverController } from '@ionic/angular';

import { CategoryService } from '@1bill-app/services/category/category.service';
import { Router } from '@angular/router';
import { ROUTE_HOMEPAGE } from '@1bill-app/constants';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-bill-predictions',
  templateUrl: './bill-predictions.component.html',
  styleUrls: ['./bill-predictions.component.scss'],
})
export class BillPredictionsComponent {
  @Input() predictions: UserBillPrediction[];

  constructor(
    private popoverController: PopoverController,
    private categoryService: CategoryService,
    private router: Router,
    private logger: NGXLogger,
  ) {}

  getBillDueDate(prediction: UserBillPrediction) {
    const dateFormat = 'DD MMM YYYY';
    return dayjs(prediction.predictedDueDate).format(dateFormat);
  }

  onPredictionClick(prediction: UserBillPrediction) {
    this.logger.log('Clicked prediction:', prediction);
    this.router.navigate(['/bill'], {
      queryParams: { _source: ROUTE_HOMEPAGE, billPaymentId: prediction.billPaymentId },
    });
  }

  getCardIconUrl(prediction: UserBillPrediction) {
    return (
      prediction.supplierLogoUrl ||
      this.categoryService.getCategoryIconUrl(prediction.categoryKey)
    );
  }

  presentLearnMorePopup() {
    this.popoverController
      .create({
        component: PopoverSimpleComponent,
        cssClass: 'onebill-popover-simple',
        componentProps: {
          description:
            "We don't receive any information from your billers.\nWe predict your bills based on the bills uploaded.",
          hasBackButton: true,
          hideButtonArea: true,
          buttonText: 'Explore More Bills',
        },
      })
      .then((popover) => {
        popover.present();
      });
  }
}
