import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore } from './user.store';
import { UserState } from './user.types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  constructor(
    protected store: UserStore,
  ) {
    super(store);
  }

  multiUserAccesses$ = this.select('multiUserAccesses');

  /**
   * Accounts that can access current user's (who is using the app) account.
   * This also includes MUA Requests that other users have sent to current user.
   */
  AccountsCanAccessMeMUA$ = this.select('multiUserAccesses').pipe(
    map((requests) => {
      return requests?.filter(
        (request) => request.accessedUserId === request.viewingAccountId,
      );
    }),
  );

  /**
   * Accounts that current user (who is using the app) can access
   * This also includes MUA Invites that other users have sent current user.
   */
  AccountsICanAccessMUA$ = this.select('multiUserAccesses').pipe(
    map((requests) => {
      return requests?.filter(
        (request) => request.accessorUserId === request.viewingAccountId,
      );
    }),
  );

  isLoading$ = this.selectLoading();

  get userSalary$() {
    return this.select(['salaryPerInterval', 'salaryInterval']);
  }
  get pinEnabled$() {
    return this.select('pinEnabled');
  }
  get loginCount$() {
    return this.select('loginCount');
  }

  manageAs$ = this.select('manageAs');
  accessType$ = this.select('accessType');

  get manageAs() {
    return this.store.getValue().manageAs;
  }

  get accessType() {
    return this.store.getValue().accessType;
  }

  get userHasFullAccess() {
    const { accessType, manageAs } = this.store.getValue();
    return !manageAs || accessType === 'FULL';
  }

  get userCanAddBills() {
    const { accessType, manageAs } = this.store.getValue();
    return !manageAs || accessType === 'FULL' || accessType === 'ADD_BILL';
  }

  get isAccessingAsOtherUser() {
    return this.manageAs ? true : false;
  }

  fetchUser() {
    return this.getValue();
  }
}
