import { standardDateToSafariDate } from '../validation/custom-validation.service';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { APP_CONFIG } from '@1bill-app/constants';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

/**
 * Solve browser compatibility issues for Safari
 * and other browsers on date.
 */
export function solveBrowserCompatibility(data, isSafariDesktop: boolean) {
  let prefillValues = {};
  if (data.idDriverLicence && isSafariDesktop) {
    prefillValues = {
      ...prefillValues,
      ...{
        idDriverLicence: {
          id: data.idDriverLicence.id,
          expiry: standardDateToSafariDate(data.idDriverLicence.expiry),
        },
      },
    };
  }
  if (data.idMedicare && isSafariDesktop) {
    prefillValues = {
      ...prefillValues,
      ...{
        idMedicare: {
          id: data.idMedicare.id,
          expiry: standardDateToSafariDate(data.idMedicare.expiry),
        },
      },
    };
  }
  if (data.idPassport && isSafariDesktop) {
    prefillValues = {
      ...prefillValues,
      ...{
        idPassport: {
          id: data.idPassport.id,
          expiry: standardDateToSafariDate(data.idPassport.expiry),
        },
      },
    };
  }

  return prefillValues;
}

export function formatMobileNumber(mobileNumber, country: CountryCode = 'AU') {
  try {
    const phoneNumber = parsePhoneNumber(mobileNumber, country);
    return phoneNumber.number;
    // if(phoneNumber.isValid()){

    // }
  } catch (e) {
    console.error('Error in mobile number formatting:', e);
  }
  return mobileNumber;
}

export async function showError(err: any, danger = false) {
  if (!err) return;

  const toastCtrl = new ToastController();
  const message = err.statusText || err.message || err;
  const toast = await toastCtrl.create({
    message,
    color: danger ? 'danger' : 'warning',
    duration: APP_CONFIG.TOAST_ERROR_DURATION * 2,
    cssClass: ['toast-error'],
    buttons: [
      {
        icon: 'close-circle-outline',
        role: 'cancel',
      },
    ],
  });
  await toast.present();
}
