import { ONEBILL_APP_ID, ROUTE_HOMEPAGE, ROUTE_PAYMENT_OPTIONS } from '@1bill-app/constants';
import { Capacitor } from '@capacitor/core';
import { AFInit } from 'appsflyer-capacitor-plugin';
import { LoggerConfig, NgxLoggerLevel } from 'ngx-logger';
import { amplify } from './config/amplify.dev';
import { getVer } from './utils';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';
const webAppUrl = isLocalhost ? 'http://localhost:8100' : 'https://dev.app.1bill.com';
const apiBaseUrl = 'https://api.dev.app.1bill.com/';
const serviceUrl = {
  svcApiUrlA: apiBaseUrl + 'v2a/',
  svcApiUrlB: apiBaseUrl + 'v2b/',
  svcApiUrlC: apiBaseUrl + 'v2c/',
  svcReport: apiBaseUrl + 'report-v2/',
  svcNotify: apiBaseUrl + 'notify-v2/',
  svcPay: apiBaseUrl + 'pay-v2/',
  svcEml: apiBaseUrl + 'email/',
  svcImgEnhancer: apiBaseUrl + 'image/',
};
const cncApiUrl = 'https://connect-api.dev.app.1bill.com/api/v1/';

export const environment = {
  stage: 'dev',
  ver: getVer('dev'),
  production: false,
  inMemoryWebApi: false,
  webAppUrl,
  isNative: Capacitor.isNativePlatform(),
  amplify,
  // API urls
  apiBaseUrl: apiBaseUrl,
  svcApiUrlA: serviceUrl.svcApiUrlA,
  svcApiUrlB: serviceUrl.svcApiUrlB,
  svcApiUrlC: serviceUrl.svcApiUrlC,
  svcReport: serviceUrl.svcReport,
  notifyApiUrl: serviceUrl.svcNotify,
  imageApiUrl: serviceUrl.svcImgEnhancer,
  payApiUrl: serviceUrl.svcPay,
  svcEmlUrl: serviceUrl.svcEml,
  cncApiUrl: cncApiUrl,
  wsUrl: 'wss://ws.dev.app.1bill.com/v1',
  loggerConfig: {
    serverLoggingUrl: serviceUrl.svcReport + 'app/log',
    level: NgxLoggerLevel.TRACE,
    serverLogLevel: NgxLoggerLevel.OFF,
    enableSourceMaps: true,
  } as LoggerConfig,
  user: {
    countryCallingCode: '+61',
  },
  zohoSalesIq: {
    ios: {
      appKey: '%2BbuIHJMMHWl%2Fv72j1lNmTP3NvqwR729uwoQJ1mKV%2BOOKmiLbq2lH8w%3D%3D_au',
      accessKey:
        'C2cd%2FbsjqpBVwabh6d2WsDJAKI%2BCMW6aYNy7yXD4kdLc2GjUKdjkZcld%2FtGRqWqf09wPFFor92xE7Q7d8RKV7UYNq1qjktYdEDpUm4DUe30%3D',
    },
    android: {
      appKey: '%2BbuIHJMMHWl%2Fv72j1lNmTP3NvqwR729uwoQJ1mKV%2BOOKmiLbq2lH8w%3D%3D_au',
      accessKey:
        'C2cd%2FbsjqpBVwabh6d2WsD0pBGSCiLn%2FUvae72KHlXWeoI2tBmx7kUc1BxyNu%2B8pEFGztu7KSwuM8mwvt8XEbNqtS1IgXYfovoNmhGfH97k%3D',
    },
  },
  appsFlyer: {
    enabled: true,
    android: {
      devKey: 'KeKCuSW57F23R3tJbrzTJA', // your AppsFlyer devKey
      isDebug: true,
      appID: ONEBILL_APP_ID.android, // your ios appID
      waitForATTUserAuthorization: 10, //time for the sdk to wait before launch - IOS 14 ONLY!
      registerOnDeepLink: true,
    } as AFInit,
    ios: {
      devKey: 'KeKCuSW57F23R3tJbrzTJA', // your AppsFlyer devKey
      isDebug: true,
      appID: ONEBILL_APP_ID.ios, // your ios appID
      waitForATTUserAuthorization: 10, //time for the sdk to wait before launch - IOS 14 ONLY!
      registerOnDeepLink: true,
    } as AFInit,
  },
  zenith: {
    url: 'https://payuat.b2bpay.com.au/Online/v3',
    // url: 'https://payuat.b2bpay.com.au/Online/v2',
    fingerprint: 'b5d4ec9695702b5f040ac3dc6dc2ffbd004c459c',
    apiKey: 'fd63df13-e435-401f-b465-a76b5a3cf642',
    redirectUrl: Capacitor.isNativePlatform()
      ? 'dev.onebill://callback/zenith/' // for ios & android
      : webAppUrl + ROUTE_PAYMENT_OPTIONS,
    callbackUrl: serviceUrl.svcPay + 'zenith_payment_callback',
  },
  pdfOpts: {
    imgConvertPPI: 350, // pixel per inch: 72, 96, 150, 300, 350, 400, 500, 600
  },
  appTerms: 'https://www.1bill.com/terms',
  appPrivacyPolicy: 'https://www.1bill.com/privacy',
  appPrivacyCollectionPolicy: 'https://www.1bill.com/privacy-collection-notice',
  feedback: {
    url: serviceUrl.svcReport + 'app/feedback',
  },
  switchCCIframeUrl: 'https://test.connect.1bill.com/step/set-customer',
  switchRedirectUrl: Capacitor.isNativePlatform()
    ? 'dev.onebill://callback/cnc/switch' // for ios & android
    : webAppUrl + '/bill',
  findDealsCCIframeUrl: 'https://test.connect.1bill.com/step/set-customer',
  findDealsRedirectUrl: Capacitor.isNativePlatform()
    ? 'dev.onebill://callback/cnc/switch' // for ios & android
    : webAppUrl + ROUTE_HOMEPAGE,
  yodleeTokenStorageKey: '1billYodleeToken',
  mbaTokenStorageKey: '1billMbaToken',
  offerEndpointUrl: 'https://test.awards.1bill.com',
  popoverJobInfoUpdateTimeout: 8000,
  localStorageDbName: 'onebill-dev',
  apiCachingConfig: {
    expiryDays: 1,
    cacheKey: '_onebill_dev_',
  },
  imageValidationOpts: {
    minWidth: 794,
    minHeight: 794,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
