import { SelectableBill, ViewBillType } from '@1bill-app/services/bill/types';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-bill-bundle-select',
  styleUrls: ['./bill-bundle-select.component.scss'],
  templateUrl: './bill-bundle-select.component.html',
})
export class BillBundleSelectComponent {
  constructor() {}

  @Input() allBills: ViewBillType[] = [];
  @Input() selectedBills: SelectableBill[] = [];
  @Input() initialBill: ViewBillType;
  @Output() onCheckoutBundleAction: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onSelectBundleAction: EventEmitter<ViewBillType[]> = new EventEmitter(null);

  ngOnInit() {}

  selectBundleAction(selectedBills: ViewBillType[]) {
    this.onSelectBundleAction.emit(selectedBills);
  }

  checkoutBundleAction(proceed: boolean) {
    this.onCheckoutBundleAction.emit(proceed);
  }
}
