import {  
  ROUTE_UPLOAD_YOUR_BILL,
  ROUTE_EMAIL_BILLS,
  ROUTE_CONNECT_EMAIL ,
  ROUTE_EMAIL_SCANNER,
} from '@1bill-app/constants';
 
import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { PopoverController, NavController, Platform } from '@ionic/angular';
 
import { BillQuery } from '@1bill-app/services/bill/bill.query';
 
import { BillService } from '@1bill-app/services/bill/bill.service';
 
import { BillStore } from '@1bill-app/services/bill/bill.store';
 
import { isBillPaid } from '@1bill-app/services/bill/helper';
 
import { BillCategoryKey, GetBillOption, ViewBillType } from '@1bill-app/services/bill/types';
 
 
import { ActivatedRoute } from '@angular/router';
 
import { LoadingController } from '@ionic/angular';
 
import { NGXLogger } from 'ngx-logger';
 
import { map } from 'rxjs/operators';
 
import { SubSink } from 'subsink';
 
import { CategoryService } from '@1bill-app/services/category/category.service';
 
import { getDayCount,isDateValid } from '@1bill-app/helpers/date.helpers';
 
 
@Component({
  selector: 'app-add-bill-popover',
  templateUrl: './add-bill-popover.component.html',
  styleUrls: ['./add-bill-popover.component.scss'],
})
export class AddBillPopoverComponent implements OnInit {
  @Input() hasBackButton: boolean;
  @Input() popoverTitle: string;
  @Input() description: string;
  @Input() descriptionArray: string[];
  @Input() link: string;
  @Input() linkText: string;
  @Input() buttonText: string;
  @Input("billPaymentId") billPaymentId;
  @Input("peakUsage") peakUsage;
  @Input("meterNumber") meterNumber;
  
  billTitle: string = 'energy';
  billDetails$ = this.billQuery.billDetails$;
  billLoading$ = this.billQuery.isLoading$;
  billDetails: ViewBillType;
  jobId: number;
  accountBillTypeId;
  isBillEditing = false;
  isBillFailed = false;
  tagName = null;
  duplicateName: string;
  billTagExists = false;
  tagTooShort = false;
  savingTag = false;
  gotInitialTag = false;
  billPaid = false;
  title = 'title';
  billRating: number;
  matchingBillsOfType: ViewBillType[];
  subs = new SubSink();
  done = false;
  isDisabled: boolean;
 
  constructor(
    private popoverController: PopoverController,  
    private navCtrl: NavController,
    private billQuery: BillQuery,
    private billStore: BillStore,
    private logger: NGXLogger,
    private categorySvc: CategoryService,
    private route: ActivatedRoute,
    public platform: Platform,
    private billService: BillService,
    private loadingController: LoadingController,
    private categoryService: CategoryService,) {}
 
  mediaType = MEDIA_TYPE;
 
  peakUsageInput: number;
  meterNumberInput: number;
  isData: boolean;
  hideButtonList = true;
  contentIndex: 0 | 1 | 2 = 0
 
  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }

  showAddBill() {
      this.popoverController.dismiss();
      this.billQuery.showAppMenu.emit('showAddBillPopup')
  }
 
  hasData(){
    if(this.billPaymentId.peakUsage === null || this.billPaymentId.meterNumber === null) {
      this.isDisabled = true
    } this.isDisabled = false;
  }
 
  billDays() {
    if (this.billPaymentId.amount <= 0) {
      return 'All paid up!';
    }
    const now = new Date().toISOString();
    const dueDays = isDateValid(this.billPaymentId.dueDate) ? getDayCount(now, this.billPaymentId.dueDate) : null;
    const daysText =
      dueDays >= -1 && dueDays <= 1 ? Math.abs(dueDays) + ' day' : Math.abs(dueDays) + ' days';
    return Number(this.billPaymentId.amount ? (dueDays < 0 ? daysText + ' overdue' : 'Due in ' + daysText) : '');
  }
 
 
 async saveBill() {
 
    await this.billService.updateUsageAndMeter({
    billPaymentId: this.billDetails.billPaymentId,
    peakUsage: this.peakUsageInput,
    meterNumber: this.meterNumberInput }).subscribe();
    
    this.popoverController.dismiss();
    await this.presentNextPop(1,this.billDetails.billPaymentId,"bill-added-popover");


   this.done = true;
   return this.done;
  }


  
  async presentNextPop(index: number, latestbillId: number,myClass: string) {
    this.popoverController.dismiss();
    const popover = await this.popoverController.create({
      component: AddBillPopoverComponent,
      componentProps: 
      {billPaymentId: latestbillId,
      contentIndex: index}, 
      cssClass: myClass,
    });
    return await popover.present();
  }



  getDetails(categoryKey: BillCategoryKey) {
    return this.categorySvc.getCategoryDetails(categoryKey);
  }
 
 
  ngOnInit() {
 
   this.showAddBill()
   this.hasData();
 
    this.billService.getBill().subscribe();
   
    const jobId = this.route.snapshot.queryParams?.jobId;
 
    if (this.billPaymentId) {
      this.billPaymentId = Number(this.billPaymentId);
 
      this.billStore.update({
        currentBillId: this.billPaymentId,
      });
      this.logger.debug('Using bill payment id: ' + this.billPaymentId);
    } else {
      this.jobId = Number(jobId);
 
      this.billStore.update({
        currentJobId: this.jobId,
      });
      this.logger.debug('Using job id: ' + this.jobId);
      this.billDetails$ = this.billQuery.failedBillDetails$;
      this.isBillFailed = true;
    }
 
    this.subs.sink = this.billDetails$.subscribe((res) => {
      if (res.length) {
        this.billDetails = res[0];
        this.accountBillTypeId = this.billDetails.accountBillTypeId;
 
        if (!this.gotInitialTag) {
          this.tagName = this.billDetails.billTag;
 
          this.gotInitialTag = true;
        }
 
        // this.billDetails.datePaid ? (this.billPaid = true) : (this.billPaid = false);
        this.billPaid = isBillPaid(this.billDetails);
        this.title = this.billDetails.provider;
        this.billRating = this.billDetails.rating;
 
        // Set matching bills of type once current bill's details are fetched
        this.billQuery
          .getBillsForView(GetBillOption.ALL_BILLS)
          .pipe(
            map((bills) =>
              bills.filter(
                (bill) =>
                  bill.categoryKey === this.billDetails.categoryKey &&
                  bill.accountBillTypeId !== this.billDetails.accountBillTypeId,
              ),
            ),
          )
          .subscribe((viewBills) => {
            this.matchingBillsOfType = viewBills;
          });
      }
    });
 
 
  }
 
  async uploadYourBill(mediaType: MEDIA_TYPE) {
    if (mediaType === MEDIA_TYPE.PDF_FILE || mediaType === MEDIA_TYPE.IMG_FILE) {
      this.navCtrl.navigateForward([ROUTE_UPLOAD_YOUR_BILL, mediaType]);
      this.popoverController.dismiss();
    }
  }

  // async switchConsent() {
  //   const popover = await this.popoverController.create({
  //     component: SwitchAndSaveConsentComponent,
  //     cssClass: 'switch-and-save-consent-popover',
  //   });

  //   return await popover.present();
  // }

  
 
 
  sendEmailInfoHandler() {
    this.emailBill();
  }
 
  emailScannerPage() {
    this.navCtrl.navigateForward([ROUTE_EMAIL_SCANNER]);
    this.popoverController.dismiss();
  }
 
  emailBill() {
    this.navCtrl.navigateForward([ROUTE_EMAIL_BILLS]);
    this.popoverController.dismiss();
  }
 
  connectEmail() {
    this.navCtrl.navigateForward([ROUTE_CONNECT_EMAIL]);
    this.popoverController.dismiss();
  }
 
 
   openLink() {
   this.popoverController.dismiss();
   }
 
   dismiss(confirmed?: boolean) {
     this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
   }
}
 
export enum MEDIA_TYPE {
  PDF_FILE = 'pdf',
  IMG_FILE = 'img',
}
 










