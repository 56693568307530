import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Transform amount with negative value.
 * Adds CR in suffix for the amount with negative value,
 * and remove negative sign from it.
 */
@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'currencyCrSign',
})
export class CurrencyCrSignPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, responseType: 'html' | 'value' = 'html', fontSize = '12px'): SafeHtml {
    if (!value) return value;
    value = String(value);
    const cr =
      responseType === 'html'
        ? this.sanitizer.bypassSecurityTrustHtml(
            value.substring(1, value.length) +
              `<span class="credit-sign" style="font-size: ${fontSize};">CR</span>`,
          )
        : value.substring(1, value.length) + 'CR';
    return value.charAt(0) === '-' ? cr : value;
  }
  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
