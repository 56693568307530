import { generateArray } from '@1bill-app/helpers/array.helper';
import { isBillOverdue, isBillPaid } from '@1bill-app/services/bill/helper';
import { appProductTypeDefaultImg, appProductTypes, BillCategoryKey, PaidPaymentMethod, ViewBillType } from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { Job } from '@1bill-app/services/status/types';
import { UserQuery } from '@1bill-app/services/user/user.query';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { PopoverFailedComponent } from '../popover-failed/popover-failed.component';

/**
 * Cards to be displayed based upon array of cards passed.
 */
@Component({
  selector: 'app-bill-card-single',
  templateUrl: './bill-card-single.component.html',
  styleUrls: ['./bill-card-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillCardSingleComponent {
  @Input('isPending') isAwaitingVerification = false;
  @Input() routePath: string;
  @Input() bill: ViewBillType;
  @Input() loading: boolean;
  @Input() job: Job;
  @Input() isTechnicalIssue = false;

  constructor(
    private categorySvc: CategoryService,
    private popoverController: PopoverController,
    private userQuery: UserQuery,
  ) {}

  get userHasFullAccess() {
    return this.userQuery.userHasFullAccess;
  }

  paidPaymentMethod = PaidPaymentMethod;
  prevCardsId: number[];
  public isBillPaid = isBillPaid;
  public isBillOverdue = isBillOverdue;
  public generateArray = generateArray;
  @Output() cardClick: EventEmitter<{
    billPaymentId?: number;
    jobId?: number;
  }> = new EventEmitter();
  @Output() payNowClick: EventEmitter<{ billPaymentId: number }> = new EventEmitter();
  get jobBillInfo() {
    let barcode = this.job?.barcode.find(b => b?.billInfo?.billerName != null)?.billInfo;
    if (!barcode) barcode = this.job?.barcode.find(b => b?.billInfo != null)?.billInfo;
    return barcode;
  }
  
  getDetails(categoryKey: BillCategoryKey) {
    return this.categorySvc.getCategoryDetails(categoryKey);
  }
  
  getCardIconUrl() {
    if (this.bill.supplierLogoUrl) {
      return this.bill.supplierLogoUrl;
    } else {
      return (
        appProductTypes.find((p) => p.key === this.bill.categoryKey)?.imgUrl ||
        appProductTypeDefaultImg
      );
    }
  }

  getDueText(bill) {
    if (isBillOverdue(bill)) return 'Overdue';
    return 'Due date';
  }

  onCardClick() {
    if (!this.bill.billPaymentId) this.cardClick.emit({ jobId: this.bill.jobId });
    else this.cardClick.emit({ billPaymentId: this.bill.billPaymentId });
  }

  onPayNowClick(billPaymentId: number) {
    this.payNowClick.emit({ billPaymentId });
  }

  async presentPopover(ev: any) {
    // FIXME: Button click event inside ion-card click event is not clickable in this case
    
    const popover = await this.popoverController.create({
      component: PopoverFailedComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    return await popover.present();
  }
}
