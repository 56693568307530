import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { PaymentCard } from '@1bill-app/services/payment/payment-card.service';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { SubSink } from 'subsink';
import { Settings } from '@1bill-app/services/settings/settings.store';
import { ROUTE_LINK_BILLS_SCREEN } from '@1bill-app/constants';

@Component({
  selector: 'app-linked-bills-list',
  templateUrl: './linked-bills-list.component.html',
  styleUrls: ['./linked-bills-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkedBillsListComponent implements OnInit {
  constructor(private settingsService: SettingsService, private cdRef: ChangeDetectorRef) {}

  @Input() paymentCard: PaymentCard;
  @Input() bills: ViewBillType[];
  @Output() swapped: EventEmitter<number> = new EventEmitter(false);
  @Output() unlinked: EventEmitter<number> = new EventEmitter(false);
  moreBills: ViewBillType[];
  linkBillScreenRoute = ROUTE_LINK_BILLS_SCREEN;

  subs = new SubSink();
  accountSettings: Settings;

  /**
   * How many bills are shown on the page before 'View all' is clicked
   */
  private readonly BILLS_SHOWN_DEFAULT_COUNT = 1;

  get billsShownDefault() {
    return this.bills?.slice(0, this.BILLS_SHOWN_DEFAULT_COUNT) ?? [];
  }

  public accordionState: 'expand' | 'collapse' = 'collapse';
  // Determines text content of the accordion toggle based on whether or not the list is expanded or collapsed.
  public accordionToggleText = this.accordionState === 'expand' ? 'View Less' : 'View All';

  ngOnInit() {
    if (!this.bills) {
      console.error('LinkedBillsListComponent: No bills passed through Input() decorator.');
    }
    this.subs.sink = this.settingsService.fetch().subscribe({
      next: async (data) => {
        this.accountSettings = data;
        // this.logger.log('user settings data fetched:', JSON.stringify(r));
      },
      error: async (e) => {},
    });
  }

  toggleAccordionState() {
    this.accordionState = this.accordionState === 'expand' ? 'collapse' : 'expand';
    this.accordionToggleText = this.accordionState === 'expand' ? 'View Less' : 'View All';
    this.updateMoreBills();
  }

  async updateMoreBills() {
    this.moreBills =
      this.accordionState === 'collapse'
        ? []
        : this.bills?.slice(this.BILLS_SHOWN_DEFAULT_COUNT) ?? [];
    this.detectChanges();
  }

  onBillSwapped(swappedBillPaymentId: number) {
    this.swapped.emit(swappedBillPaymentId);
  }

  onBillUnlinked(unlinkedBillPaymentId: number) {
    this.bills = this.bills.filter((bill) => bill.billPaymentId !== unlinkedBillPaymentId);
    this.updateMoreBills();
    if (unlinkedBillPaymentId !== null && unlinkedBillPaymentId !== undefined) {
      this.unlinked.emit(unlinkedBillPaymentId);
    }
  }

  /**
   * If PaymentCard has a nickname, return nickname.
   * Otherwise, return cardTitle
   */
  getCardTitle() {
    if (!this.paymentCard) return undefined;
    if (Boolean(this.paymentCard.nickname)) return this.paymentCard?.nickname;
    else return this.paymentCard?.cardType;
  }

  getSubheadingText() {
    //If bills are linked to this payment method
    if (this.bills.length > 0) {
      return 'Any linked bills will always use this payment method by default.';
    }
    //If no bills are linked to this payment method
    else {
      return (
        'Link a bill to this payment method in seconds and always pay this bill using ' +
        this.getCardTitle() +
        '.'
      );
    }
  }

  public detectChanges() {
    this.cdRef.detectChanges();
  }
}
