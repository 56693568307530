import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { startWith } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  isLoggedIn$ = this.select((state) => !!state.token);
  isLoading$ = this.selectLoading().pipe(startWith(false));
  isSocialLoading$ = this.select((state) => state.ui?.socialLoading);
  authProvider$ = this.select((state) => state.authProvider);
  hasLastActivity$ = this.select((state) => !!state.lastActivity);
  lastActivity = this.getValue().lastActivity;

  constructor(protected store: AuthStore, private logger: NGXLogger) {
    super(store);
  }
  get isLoggedIn() {
    return !!this.getValue().token;
  }
  getCredentials() {
    return this.getValue().credentials;
  }
  get userEmail() {
    return this.getValue().email;
  }
  getErrorMessage(err: any) {
    if (!err) {
      return;
    }
    this.logger.warn('AuthService::getErrorMessage', err);
    let message = err.message || err;
    if (err.code) {
      // console.log('error message body:', err);
      switch (err.code) {
        case 'NotAuthorizedException':
          if (message.includes('Incorrect username')) {
            message = 'Email address/password do not match.';
          }
          break;
        case 'UserNotFoundException':
          message = 'Email address is not recognised.';
          break;
        case 'UsernameExistsException':
          message = 'An account with the given email already exists.';
          break;
        case 'InvalidParameterException':
          if (message.includes('Invalid phone number format')) {
            return message;
          }
          message = 'Password entered failed to meet the requirements.';
          break;
        case 'InvalidPasswordException':
          return message;
        default:
          message = 'Error encountered!';
          break;
      }
    }
    return message;
  }
}
