import { RequireNewPasswordModalComponent } from '@1bill-app/components/require-new-password-modal/require-new-password-modal.component';
import {
  APP_CONFIG,
  ROUTE_SCREEN_LOCK,
  SESSION_TIMEOUT_UNIT,
  SESSION_TIMEOUT_VALUE,
  STORAGE_APP_STATE_STORE,
  TEXT_SESSION_EXPIRED_TOAST,
  ROUTE_MOBILE_NUMBER,
  LAT_PAY_CALLBACK_SESSION_EXPIRED_SOURCE,
  ROUTE_UPDATE_NAME,
} from '@1bill-app/constants';
import { environment } from '@1bill-app/env';
import { isDateExpired } from '@1bill-app/helpers/date.helpers';
import { jsonParseSafe } from '@1bill-app/helpers/object.helper';
import { CachingService } from '@1bill-app/services/caching/caching.service';
import { HomeService } from '@1bill-app/services/home/home.service';
import { AppSecurityService } from '@1bill-app/services/app-security.service';
import { RewardService } from '@1bill-app/services/reward/reward.service';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { StorageItemKeys, StorageService } from '@1bill-app/services/storage.service';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { UserService } from '@1bill-app/services/user/user.service';
import { Inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import {
  ClientMetaData,
  CognitoHostedUIIdentityProvider,
} from '@aws-amplify/auth/lib/types/Auth';
import { Capacitor } from '@capacitor/core';
import { applyTransaction, PersistState, resetStores } from '@datorama/akita';
import {
  LoadingController,
  ModalController,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { NGXLogger } from 'ngx-logger';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, take } from 'rxjs/operators';
import { PINService } from '../pin.service';
import { AuthStore } from './auth.store';

declare let fbq: any;
declare let ZohoSalesIQ: any;

export type Credentials = {
  firstName?: string;
  lastName?: string;
  email: string;
  password: string;
};
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    @Inject('persistStorage') private persistStorage: PersistState,
    private authStore: AuthStore,
    private logger: NGXLogger,
    private platform: Platform,
    private homeService: HomeService,
    private storageService: StorageService,
    private toastCtrl: ToastController,
    private userService: UserService,
    private userQuery: UserQuery,
    private router: Router,
    private settingsService: SettingsService,
    private pinService: PINService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private cachingService: CachingService,
    private loadingController: LoadingController,
    private rewardService: RewardService,
    private fb: Facebook,
    private appSecurityService: AppSecurityService,
  ) {}

  private static checkUserRegistrationStatusInterval: NodeJS.Timeout;

  setLoading(loading?: boolean) {
    this.authStore.setLoading(loading);
  }

  setError<T>(error: T) {
    this.authStore.setError(error);
  }

  setSocialLoading(loading?: boolean) {
    this.authStore.updateUI({ socialLoading: loading });
  }

  setCredentials(cred: Partial<Credentials>) {
    this.authStore.update({ credentials: cred });
  }

  async checkEmailNotExists(email: string) {
    applyTransaction(() => {
      this.authStore.setLoading(true);
      this.authStore.update({ credentials: { email } });
    });
    try {
      const data = await Auth.confirmSignUp(email, '000000', {
        // If set to False, the API will throw an AliasExistsException error
        // if the phone number/ email used already exists as an alias with a different user
        forceAliasCreation: false,
      });
      // return this.logger.log(data);
      this.authStore.setLoading(false);
    } catch (err) {
      this.authStore.setLoading(false);
      switch (err.code) {
        case 'UserNotFoundException':
          return true;
        case 'NotAuthorizedException':
          return false;
        case 'AliasExistsException':
          // Email alias already exists
          return false;
        case 'CodeMismatchException':
          return false;
        case 'ExpiredCodeException':
          return false;
        default:
          return false;
      }
    }
  }

  async openZohoChat() {
    const loadElement = await this.loadingController.create({
      message: 'Loading chat widget...',
      mode: 'ios',
    });
    try {
      await loadElement.present();
      const { user } = await this.isAuthenticated().toPromise();
      const name = user?.attributes?.given_name;
      const email = user?.attributes?.email;
      if (Capacitor.isNativePlatform()) {
        // Ref: https://www.zoho.com/salesiq/help/developer-section/cordova-ionic-sdk-open-chat-.html
        if (name != null) ZohoSalesIQ.setVisitorName(name);
        ZohoSalesIQ.setVisitorEmail(email);
        ZohoSalesIQ.show();
        loadElement?.dismiss();
      } else {
        setTimeout(() => {
          // Ref: https://www.zoho.com/salesiq/help/developer-section/js-api-visitor-name.html
          (window as any).$zoho.salesiq.visitor.name(name);
          (window as any).$zoho.salesiq.visitor.email(email);
          // Ref: https://help.zoho.com/portal/en/community/topic/open-salesiq-chat-window-on-button-click
          document.getElementById('zsiq_agtpic').click();
          loadElement?.dismiss();
        }, 3000);
      }
    } catch (error) {
      this.logger.error('Error opening Zoho chat widget:', error);
      loadElement?.dismiss();
    }
  }

  async presentRequireNewPasswordModal(props: { email: string; user: CognitoUser }) {
    const modal = await this.modalCtrl.create({
      component: RequireNewPasswordModalComponent,
      cssClass: 'modal--require-new-password',
      componentProps: props,
    });
    await modal.present();
  }

  login(cred: Credentials) {
    applyTransaction(() => {
      this.authStore.setLoading(true);
      this.authStore.update({ credentials: cred });
    });
    return from(Auth.signIn({ username: cred.email, password: cred.password })).pipe(
      // Clear any API cache if there is
      switchMap((res) => from(this.cachingService.clearAll()).pipe(map(() => res))),
      mergeMap((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.presentRequireNewPasswordModal({ email: cred.email, user });
          this.authStore.setLoading(false);
          return of(null);
        } else if (!user.attributes.given_name || !user.attributes.family_name) {
          this.logger.log('User does not have name(s) set; presenting update name screen.');
          this.router.navigateByUrl(ROUTE_UPDATE_NAME);
        } else {
          return of(user);
        }
      }),
      filter((user) => user),
      tap((result) => {
        this.authStore.update({
          userConfirmed: result.attributes['email_verified'],
          userAttributes: result.attributes,
        });
      }),
      mergeMap((result) => {
        if (!result.attributes['email_verified']) return of(result);
        return from(Auth.currentSession()).pipe(
          tap((session) => {
            this.authStore.update({
              token: session.getIdToken().getJwtToken(),
              loggedIn: true,
            });
          }),
          map(() => result),
        );
      }),
      tap(() => this.authStore.setLoading(false)),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  signup(cred: Credentials) {
    applyTransaction(() => {
      this.authStore.setLoading(true);
      this.authStore.update({ credentials: cred });
    });
    const clientMetadata = {
      stage: environment.stage,
      webAppUrl: environment.webAppUrl,
    };
    return from(
      Auth.signUp({
        username: cred.email,
        password: cred.password,
        clientMetadata,
        attributes: {
          // phone_number:'',
          given_name: cred.firstName,
          family_name: cred.lastName,
        },
      }),
    ).pipe(
      // Clear any API cache if there is
      switchMap((res) => from(this.cachingService.clearAll()).pipe(map(() => res))),
      tap((result) => {
        try {
          fbq('trackCustom', 'Sign Up');
        } catch (err) {
          this.logger.log(
            '[Sign Up Event] Facebook Pixel tagging is not enabled in the current environment.',
          );
        }
        this.authStore.update({
          userConfirmed: result.userConfirmed,
          username: result.user.getUsername(),
          codeDeliveryDetails: result.codeDeliveryDetails,
        });
      }),
      mergeMap((result) => {
        if (!result.userConfirmed) return of(result);
        return from(Auth.currentSession()).pipe(
          tap((session) => {
            this.authStore.update({
              token: session.getIdToken().getJwtToken(),
              loggedIn: true,
            });
          }),
          map(() => result),
        );
      }),
      tap(() => this.authStore.setLoading(false)),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  /**
   *
   * Add user provided mobile number in cognito, still unverified
   *
   * @param  mobileNumber - User's mobile number
   */
  addMobileNumber(mobileNumber: string) {
    this.logger.debug('adding mobile number...');
    const clientMetadata = {
      stage: environment.stage,
      webAppUrl: environment.webAppUrl,
    };
    // const user = await Auth.currentAuthenticatedUser();
    // return Auth.updateUserAttributes(user, { phone_number: mobileNumber }, clientMetadata);
    this.authStore.setLoading(true);
    this.authStore.update({ unconfirmedMobile: mobileNumber });

    return this.userService.checkIfMobileNumberIsUnique({ mobileNumber }).pipe(
      switchMap((verify) => {
        if (verify.mobileIsBlacklisted) {
          verify.mobileIsUnique = false;
          this.appSecurityService.presentBlacklistedMobileAlert();
          return Auth.signOut();
        }
        return of({ mobileIsUnique: verify.mobileIsUnique, alreadyVerified: null });
      }),
      filter((verify) => {
        if (verify.mobileIsUnique) {
          return true;
        } else {
          this.authStore.setLoading(false);
          return false;
        }
      }),
      switchMap(() => from(Auth.currentAuthenticatedUser())),
      switchMap((user) =>
        from(Auth.updateUserAttributes(user, { phone_number: mobileNumber }, clientMetadata)),
      ),
      switchMap(() => from(Auth.currentAuthenticatedUser())),
      switchMap((user) => {
        this.logger.debug('User', user);
        if (user.attributes.phone_number_verified) {
          return of({ alreadyVerified: true });
        } else {
          return of({ alreadyVerified: false });
        }
      }),
      tap(() => {
        this.authStore.setLoading(false);
      }),
      catchError((error) => {
        this.logger.error({ error });
        applyTransaction(() => {
          this.authStore.setError('Something went wrong. Please try again later.');
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  /**
   *
   * Verifies user's mobile number on submission
   *
   * @param  code - Verification code
   */
  verifyMobileNumber(code, mobileNumber) {
    this.logger.debug('verifying mobile number...');
    this.authStore.setLoading(true);
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user) => from(Auth.verifyUserAttributeSubmit(user, 'phone_number', code))),
      tap((verRes) => {
        this.logger.log('Verification Result: ', verRes);
      }),
      switchMap((verification) => this.settingsService.fetch()),
      switchMap((settings) =>
        this.settingsService.saveSettingDetailsOnly({
          option: 'CONTACT',
          origin: 'MOBILE_VERIFICATION',
          settingsData: { ...settings, mobileNumber, mobileVerified: true },
        }),
      ),
      tap((settUpd) => {
        this.logger.log('settUpd', settUpd);
      }),
      filter((settUpd) => {
        if (settUpd.success) {
          return true;
        } else {
          this.authStore.setError(settUpd.message);
          this.authStore.setLoading(false);
          return false;
        }
      }),
      switchMap(() => this.rewardService.getRewards()),
      switchMap(() => this.homeService.getHome()),
      tap(() => {
        this.authStore.update({ mobileConfirmed: true, unconfirmedMobile: null });
        this.authStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  checkIfMobileVerified(): Observable<boolean> {
    return of(null).pipe(
      tap(() => {
        this.logger.debug('Checking mobile verification...');
        this.authStore.setLoading(true);
      }),
      switchMap(() => from(Auth.currentUserInfo())),
      tap((res) => {
        this.logger.debug('Current user: ', res);
      }),
      map((user) => {
        if (user.attributes.phone_number_verified) {
          return true;
        } else {
          return false;
        }
      }),
      tap(() => {
        this.authStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  /**
   * Each time user routes to a new page, we need to check if they have their name set.
   * This is because the user can cancel (close app or tab) when they reach the page to
   * set their given and family names, and then log in (or enter a route manually in
   * the browser address bar) which would cause issues.
   */
  checkIfUserHasNamesSet() {
    return this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart && !event.url.includes(ROUTE_UPDATE_NAME),
        ),
      )
      .subscribe(async (event) => {
        Auth.currentUserInfo().then((info) => {
          if (info?.attributes?.family_name && info?.attributes?.given_name) {
            this.authStore.update({ hasName: true });
          } else if (info) {
            this.router.navigateByUrl(ROUTE_UPDATE_NAME);
          }
        });
      });
  }

  logout(source: string) {
    this.logger.log('Logout Source', source);
    if (source === LAT_PAY_CALLBACK_SESSION_EXPIRED_SOURCE) {
      this.showSessionTimeoutMsg();
    }

    return from(Auth.signOut()).pipe(
      tap(() => {
        this.clearAuthState();
      }),
      catchError((err) => {
        this.authStore.setError(err);
        throw err;
      }),
    );
  }

  confirmSignUp(email: string, code: string, autoSignin?: boolean) {
    this.authStore.setLoading(true);
    this.logger.debug('confirming singup...');
    const clientMetadata = {
      stage: environment.stage,
      webAppUrl: environment.webAppUrl,
    };
    return from(Auth.confirmSignUp(email, code, { clientMetadata })).pipe(
      tap(() => this.authStore.update({ userConfirmed: true })),
      mergeMap((result) => {
        if (!!autoSignin) {
          this.logger.debug('auto signin in progress...');
          const { password } = this.authStore.getValue().credentials;
          return from(Auth.signIn({ username: email, password })).pipe(map(() => result));
        }
        return of(result);
      }),
      filter(() => (autoSignin ? true : false)),
      mergeMap((result) => {
        this.logger.debug('retrieve current session...');
        return from(Auth.currentSession()).pipe(
          tap((session) => {
            applyTransaction(() => {
              this.authStore.update({
                token: session.getIdToken().getJwtToken(),
                loggedIn: true,
              });
              this.authStore.setLoading(false);
            });
          }),
          map(() => result),
        );
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  resendEmailVerificationCode(email: string) {
    this.logger.debug('Re-sending verification code...');
    this.authStore.setLoading(true);
    return from(Auth.resendSignUp(email)).pipe(
      tap((res) => {
        this.logger.log('Res from resendSignUp', res);
      }),
      map((res) => res.CodeDeliveryDetails.DeliveryMedium === 'EMAIL'),
      tap(() => {
        this.authStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.authStore.setError(error);
          this.authStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  federatedSignIn(provider: CognitoHostedUIIdentityProvider, customState?: string) {
    customState = JSON.stringify({
      ...(customState ? JSON.parse(customState) : {}),
      provider,
    });
    applyTransaction(() => {
      this.setSocialLoading(true);
      this.authStore.update({ authProvider: provider });
    });
    return from(Auth.federatedSignIn({ provider, customState })).pipe(
      catchError((err) => {
        applyTransaction(() => {
          this.authStore.setError(err);
          this.setSocialLoading(false);
        });
        throw err;
      }),
    );
  }

  clearAuthProviderState() {
    this.authStore.update({ authProvider: null });
  }

  googleSignIn() {
    return Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }

  appleSignIn() {
    return Auth.federatedSignIn({ customProvider: 'SignInWithApple' });
  }

  forgotPassword(
    email: string,
    clientMetadata?: ClientMetaData,
  ): Promise<{
    CodeDeliveryDetails: {
      AttributeName: string;
      DeliveryMedium: string;
      Destination: string;
    };
  }> {
    return Auth.forgotPassword(email, clientMetadata);
  }

  forgotPasswordSubmit(
    email: string,
    code: string,
    password: string,
    clientMetadata?: ClientMetaData,
  ) {
    return Auth.forgotPasswordSubmit(email, code, password, clientMetadata);
  }

  refreshAuthSession() {
    Auth.currentSession()
      .then((session: any) => {
        this.authStore.update({ token: session.getIdToken().getJwtToken(), loggedIn: true });
      })
      .catch((err) => this.logger.debug('error', err));
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.homeService.triggerFetchHomeData('AuthService::refreshAuthSession()', true);
        this.authStore.update({ email: user?.attributes?.email });
      })
      .catch(() => {});
  }

  clearAuthState() {
    this.cachingService.clearAll();
    this.persistStorage.clearStore('auth');
    localStorage.removeItem(STORAGE_APP_STATE_STORE);
    resetStores();
    this.storageService.clearExcept([StorageItemKeys.SESSION_LAST_URL]);
    // this.pinService.clear(); // donot remove pin after being created.
  }

  async changePassword(data: { oldPassword: string; newPassword: string }) {
    const { oldPassword, newPassword } = data;
    const user = await Auth.currentAuthenticatedUser();
    return Auth.changePassword(user, oldPassword, newPassword);
  }

  initAmplifyHubListener() {
    this.refreshAuthSession();
    Hub.listen('auth', async ({ payload: { event, data, message } }) => {
      this.logger.debug('auth payload: ', { event, data, message });
      switch (event) {
        case 'signIn':
          {
            // User sign in, verify that the backend process was completed successfully
            const complete = await this.userService.getUserRegistrationComplete();
            if (!complete) {
              this.logger.warn('User status complete is false.');
              // Handler code here
            }

            this.logger.log('user signed in:', event); //[ERROR] My-Logger - user signed in
            this.userService.triggerUserLogin();

            // Facebook Pixel tagging is only enabled in prod environment, so fbq will be undefined in other environments
            try {
              fbq('trackCustom', 'Login');
            } catch (err) {
              this.logger.log(
                '[Login Event] Facebook Pixel tagging is not enabled in the current environment.',
              );
            }
            this.refreshAuthSession();
          }
          break;
        case 'signUp':
          {
            this.refreshAuthSession();
            this.logger.log('user signed up');
          }
          break;
        case 'cognitoHostedUI':
          {
            this.logger.log('cognitoHostedUI user signin');

            // User has signed in via federatedSignIn; check that login status is valid
            this.loadingController
              .create({
                message:
                  'Please wait while we set up your account. This will take a few moments.',
                mode: 'ios',
              })
              .then((loader) => {
                loader.present();
                loader.onWillDismiss().then(() => {
                  // track Facebook SDK event for native apps - Complete registration
                  const getProperProviderName = (
                    authProvider: CognitoHostedUIIdentityProvider,
                  ) => {
                    switch (authProvider) {
                      case CognitoHostedUIIdentityProvider.Amazon: {
                        return 'Amazon';
                      }
                      case CognitoHostedUIIdentityProvider.Apple: {
                        return 'Apple';
                      }
                      case CognitoHostedUIIdentityProvider.Cognito: {
                        return 'Cognito';
                      }
                      case CognitoHostedUIIdentityProvider.Facebook: {
                        return 'Facebook';
                      }
                      case CognitoHostedUIIdentityProvider.Google: {
                        return 'Google';
                      }
                      default: {
                        return authProvider;
                      }
                    }
                  };
                  this.fb.logEvent('Complete registration', {
                    registrationMethod: getProperProviderName(
                      this.authStore.getValue().authProvider,
                    ),
                  });
                  this.refreshAuthSession();
                });
                if (!AuthService.checkUserRegistrationStatusInterval) {
                  AuthService.checkUserRegistrationStatusInterval = setInterval(async () => {
                    const registerUserComplete = await this.checkIfUserRegistrationComplete();
                    if (registerUserComplete) {
                      clearInterval(AuthService.checkUserRegistrationStatusInterval);
                      loader.dismiss();
                      // HIDEREFERRALCODES
                      // if (this.userQuery.getValue().loginCount < 2) {
                      //   const refCode = await this.userService.getInputReferralCode();
                      //   if (!refCode?.code?.length) {
                      //     this.router.navigate([ROUTE_UPDATE_NAME], { queryParams: { socialSignInAuth: 1 } });
                      //   }
                      // }
                    }
                  }, 3000);
                }
              });
          }
          break;
        case 'customOAuthState':
          {
            const state: any = jsonParseSafe(data);
            this.authStore.update({ authProvider: state ? state.provider : null });
            this.logger.log('customOAuthState');
          }
          break;
        case 'cognitoHostedUI_failure':
          {
            this.refreshAuthSession();
            this.logger.log('cognitoHostedUI user signin');
          }
          break;
        case 'signOut':
          {
            this.logger.log('user signed out');
            this.clearAuthState();
          }
          break;
        case 'oAuthSignOut':
          {
            this.logger.log('oauth user signed out');
            this.clearAuthState();
          }
          break;
        case 'cognitoHostedUI_failure':
          {
            this.refreshAuthSession();
            this.logger.log('cognitoHostedUI_failure');
          }
          break;
        case 'customState_failure':
          {
            this.refreshAuthSession();
            this.logger.log('customState_failure');
          }
          break;
        case 'signIn_failure':
          {
            this.logger.log('user sign in failed');
          }
          break;
        case 'configured':
          {
            this.logger.log('the Auth module is configured');
          }
          break;
      }
    });
  }

  private isDesktop() {
    return this.platform.is('desktop');
  }

  public isAuthenticated(): Observable<{ loggedIn: boolean; user: any }> {
    return from(Auth.currentAuthenticatedUser()).pipe(
      map((user) => {
        // console.log('user',user);
        return { loggedIn: true, user };
      }),
      catchError(() => {
        return of({ loggedIn: false, user: null });
      }),
    );
  }

  public clearProviderState() {
    this.authStore.update({ authProvider: null });
  }

  updateLastActivity() {
    const { lastActivity, loggedIn } = this.authStore.getValue();
    // this.logger.debug('lastActivity',lastActivity);
    this.authStore.update({ lastActivity: new Date().toISOString() });
    if (loggedIn && isDateExpired(lastActivity, SESSION_TIMEOUT_VALUE, SESSION_TIMEOUT_UNIT)) {
      // this.logger.debug('dateExpired',lastActivity, SESSION_TIMEOUT_VALUE, SESSION_TIMEOUT_UNIT);
      // this.showSessionTimeouMsg();
      this.isAuthenticated()
        .pipe(
          switchMap(({ loggedIn }) => (loggedIn ? this.logout('auth.service') : '')),
          tap(() => window.location.reload()),
        )
        .subscribe();
    } else {
      this.logger.debug(
        '!dateExpired',
        lastActivity,
        SESSION_TIMEOUT_VALUE,
        SESSION_TIMEOUT_UNIT,
      );
    }
  }

  isSessionTimeout(source?: string) {
    const { lastActivity } = this.authStore.getValue();
    // this.logger.debug('lastActivity', lastActivity, ', source: ' + source);
    this.resetLastActivity();
    if (isDateExpired(lastActivity, SESSION_TIMEOUT_VALUE, SESSION_TIMEOUT_UNIT)) {
      // this.logger.debug('dateExpired',lastActivity, SESSION_TIMEOUT_VALUE, SESSION_TIMEOUT_UNIT);
      if (Capacitor.getPlatform() === 'web') {
        this.showSessionTimeoutMsg();
      }
      return true;
    } else {
      this.logger.debug(
        '!dateExpired',
        lastActivity,
        SESSION_TIMEOUT_VALUE,
        SESSION_TIMEOUT_UNIT,
        ', source: ' + source,
      );
    }
    return false;
  }

  resetLastActivity() {
    this.authStore.update({ lastActivity: new Date().toISOString() });
  }

  async showSessionTimeoutMsg() {
    const message = TEXT_SESSION_EXPIRED_TOAST;
    const toast = await this.toastCtrl.create({
      message,
      duration: APP_CONFIG.TOAST_ERROR_DURATION,
      color: 'danger',
      buttons: [
        {
          side: 'end',
          icon: 'close-outline',
          handler: () => toast.dismiss(),
        },
      ],
      // cssClass: ['toast-error']
    });
    await toast.present();
  }

  checkSessionActivity() {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationStart),
      map(() => {
        this.updateLastActivity();
      }),
    );
    // return !Capacitor.isNativePlatform()
    //   ? this.router.events.pipe(
    //     filter((event) => event instanceof NavigationStart),
    //     map(() => {
    //       this.updateLastActivity();
    //     }),
    //   )
    //   : of();
  }

  checkUserSessionSubscribe() {
    this.userService.getUser().subscribe({
      next: (response) => {
        if (!response.data) {
          Auth.currentAuthenticatedUser().then((cognitoUser: CognitoUser) => {
            Auth.currentSession().then((session) => {
              const refreshToken = session.getRefreshToken();
              cognitoUser.refreshSession(refreshToken, (err, session) => {
                if (err) {
                  if (err?.code === 'UserNotFoundException') {
                    this.logger.log('User does not exists in cognito');
                    this.logout('auth.service');
                  }
                } else {
                  this.logger.log('🙆 Tokens refreshed!', session);
                }
              });
            });
          });
        }
      },
      error: (err) => {
        this.logger.warn(err);
      },
    });
  }

  async checkIfUserRegistrationComplete() {
    return await this.userService.getUserRegistrationComplete();
  }

  public getCognitoGroups(): Observable<string[]> {
    return from(Auth.currentSession()).pipe(
      map((session) => session.getAccessToken().payload['cognito:groups'] || []),
      catchError(() => of([])),
    );
  }

  public isUserInGroup(groupName: string) {
    return this.getCognitoGroups().pipe(
      map((cognitoGroups) => {
        const userInGroup = Boolean(cognitoGroups.indexOf(groupName) >= 0);

        return userInGroup;
      }),
    );
  }

  public isUserDevAdmin() {
    return this.isUserInGroup('DevAdmin');
  }

  public setSessionLastUrl(url: string) {
    this.storageService.setItem({ key: StorageItemKeys.SESSION_LAST_URL, value: url });
  }

  public clearSessionLastUrl() {
    this.storageService.removeItem(StorageItemKeys.SESSION_LAST_URL);
  }

  public getSessionLastUrl() {
    return this.storageService.getItem(StorageItemKeys.SESSION_LAST_URL);
  }

  public async openLockScreen() {
    if (Capacitor.isNativePlatform()) {
      if (!(await this.pinService.hasPin())) return;

      return this.navCtrl.navigateRoot([ROUTE_SCREEN_LOCK], {
        queryParams: { _source: this.router.url },
        animated: false,
      });
    }
  }

  public static async getAuthenticatedEmail() {
    let email: string;
    try {
      const user = await Auth.currentAuthenticatedUser();
      email = user?.attributes?.email || null;
    } catch (e) {}
    return email;
  }

  public resendMobileVerificationCode() {
    return from(Auth.verifyCurrentUserAttribute('phone_number'));
  }
}
// export function simulateRequest(cred: Credentials) {
//   return timer(2000).pipe(
//     mapTo({
//       firstName: 'test',
//       token: 'token',
//     }),
//   );
// }
