/**
 * https://www.jstips.co/en/javascript/picking-and-rejecting-object-properties/
 */

import { _mapKeys, _snakeCase } from './lodash.helper';

export function pick(obj, keys) {
  return keys
    .map((k) => (k in obj ? { [k]: obj[k] } : {}))
    .reduce((res, o) => Object.assign(res, o), {});
}
export function reject(obj, keys) {
  return Object.keys(obj)
    .filter((k) => !keys.includes(k))
    .map((k) => Object.assign({}, { [k]: obj[k] }))
    .reduce((res, o) => Object.assign(res, o), {});
}
/**
 * Function to sort alphabetically an array of objects by some specific key.
 * Ref: https://ourcodeworld.com/articles/read/764/how-to-sort-alphabetically-an-array-of-objects-by-key-in-javascript
 * @param property Key of the object to sort.
 */
export function dynamicSort(property: string) {
  let sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return (a, b) => {
    // tslint:disable-next-line: triple-equals
    if (sortOrder == -1) {
      return b[property].localeCompare(a[property]);
    } else {
      return a[property].localeCompare(b[property]);
    }
  };
}
export function filterPropertyById(arr: any[], id, prop: string) {
  const filter = arr.filter((x) => x.id === id);
  if (!filter) {
    return '';
  }
  if (filter.length < 1) {
    return '';
  }
  return filter[0][prop] ? filter[0][prop] : '';
}

export function objectKeysToSnakeCase(obj: any) {
  return _mapKeys(obj, (v, k) => _snakeCase(k));
}
/**
 * Safely parse string to json without throwing error,
 * return null in case of error
 * @param data
 */
export function jsonParseSafe<T>(data: string): T | null {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
  }
  return null;
}

/**
 * Perform deep equal checks for two objects. if there are any differences in value, it will return false
 * @param x : object 1
 * @param y : object 2
 */
export function objDeepEquals(x, y) {
  if (x === y)
    return true;
  // if both x and y are null or undefined and exactly the same
  if (!(x instanceof Object) || !(y instanceof Object))
    return false;
  // if they are not strictly equal, they both need to be Objects
  if (x.constructor !== y.constructor)
    return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  let p;
  for (p in x) {
    if (!x.hasOwnProperty(p))
      continue;
    // other properties were tested using x.constructor === y.constructor
    if (!y.hasOwnProperty(p))
      return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined
    if (x[p] === y[p])
      continue;
    if ((x[p] === null && y[p] !== null) || (x[p] !== null && y[p] === null))
      return false;
    // if they have the same strict value or identity then they are equal
    if (typeof (x[p]) !== "object")
      return false;
    // todo maybe we need to turn on the following check as well 
    // Numbers, Strings, Functions, Booleans must be strictly equal
    // if (!RXBox.equals(x[p], y[p]))
    //     return false;
  }
  for (p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p))
      return false;
  }
  return true;
}