export enum ZenithPaymentMode {
  MAKE_PAYMENT = 0,
  TOKENISE = 1,
  CUSTOM_PAYMENT = 2,
};
/**
 * Interface for Zenith Payment Plugin Options.
 * @see https://pay.b2bpay.com.au/Online/PaymentDemo
 */
export interface ZenithPaymentOptions {
  /**
   * Plugin access url.
   */
  url: string;
  /**
   * Encoded credentials as per requirement
   */
  fingerprint: string;
  /**
   * As provided by Zenith
   */
  apiKey: string;
  /**
   * 0 - Make Payment
   * 1 - Tokenise
   * 2 - Custom Payment
   * Default value is 0.
   */
  mode: ZenithPaymentMode;
  /**
   * Will return with the result in the query string
   */
  redirectUrl: string;
  /**
   * Will post the result in a secured way
   */
  callbackUrl?: string;
  /**
   * Plugin Title. Default: 'Process Payment'
   */
  title?: string;
  /**
   * Required if mode 0 (make payment mode) or 2 (make custom payment).
   */
  customerName?: string;
  /**
   * Required if mode 0 (make payment mode) or 2 (make custom payment).
   */
  customerReference?: string;
  /**
   * If the customer needs to send additional reference. Will be passed on to the merchant reconciliation file (PDF & CSV)
   */
  additionalReference?: string;
  /**
   * Required if mode 0 (make payment mode) or 2 (make custom payment).
   */
  paymentAmount?: number;
  /**
   * Contact number
   */
  contactNumber?: string;
  /**
   * Email address to which invoice will be emailed if the merchant is configured.
   */
  customerEmail?: string;
  /**
   * Australian Business number. Used for reward programs if the Program is enabled to provide reward points.
   */
  abn?: string;
  /**
   * Customer company name.
   */
  companyName?: string;
  /**
   * Used to make a payment using a card proxy which is generated using mode '1'.
   */
  cardProxy?: string;
  /**
   * Unique payment id provided by the merchant.
   */
  merchantUniquePaymentId?: string;
  /**
   * This will hide the program header including program logo. Default is 'true'.
   */
  hideHeader?: boolean;
  /**
   * This will hide the merchant logo if any. Default is 'false'.
   */
  hideMerchantLogo?: boolean;
  /**
   * This will hide the Terms and Conditions. Default is 'false'.
   */
  hideTermsAndConditions?: boolean;
  sendConfirmationEmailToMerchant?: boolean;
  /**
   * Show the applicable fees for the token at the end of the process. Default is false.
   */
  showFeeOnTokenising?: boolean;
}
