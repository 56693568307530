import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { EmailScannerBase, EmailScannerStore } from './email-scanner.store';

@Injectable({
  providedIn: 'root'
})
export class EmailScannerService {

  constructor(private api: ApiService,
    private store: EmailScannerStore,) { }

  fetch() {
    this.store.setLoading(true);
    return this.api.listEmailScannerConfig().pipe(
      map(response => {
        if (response.success) {
          this.store.update({ data: response.data });
        }
        this.store.setLoading(false);
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        this.store.setLoading(false);
        throw error;
      }),
    );
  }
  updateEmailConfig(payload: Required<EmailScannerBase>) {
    this.store.setLoading(true);
    return this.api.updateEmailScannerConfig(payload).pipe(
      tap(()=>this.store.setLoading(false)),
      map(response => response),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        this.store.setLoading(false);
        throw error;
      }),
    );
  }
  initEmailConfig(payload: EmailScannerBase) {
    this.store.setLoading(true);
    return this.api.initEmailScannerConfig(payload).pipe(
      tap(()=>this.store.setLoading(false)),
      mergeMap((res) => {
        if(res.success){
          return this.fetch();
        }
        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setLoading(false);
        this.store.setError(error);
        throw error;
      }),
    );
  }
  removeEmailConfig(payload: Pick<EmailScannerBase, "extCredId">) {
    this.store.setLoading(true);
    return this.api.removeEmailScannerConfig(payload).pipe(
      tap(()=>this.store.setLoading(false)),
      mergeMap((res) => {
        if(res.success){
          return this.fetch();
        }
        return of(res);
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        this.store.setLoading(false);
        throw error;
      }),
    );
  }
  getNewTokenUrlGmail() {
    this.store.setLoading(true);
    return this.api.getNewTokenUrlGmail().pipe(
      tap(()=>this.store.setLoading(false)),
      map(res=>res),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        this.store.setLoading(false);
        throw error;
      }),
    );
  }



}
