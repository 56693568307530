import { Injectable, EventEmitter } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { YodleeStore } from './yodlee.store';
import { YodleeState } from './yodlee.types';

@Injectable({ providedIn: 'root' })
export class YodleeQuery extends Query<YodleeState> {
  constructor(protected store: YodleeStore) {
    super(store);
  }

  isLoading$ = this.selectLoading();
  accounts$ = this.select((state) => state.accounts);
  summaries$ = this.select((state) => state.summaries);
  wealthCategories$ = this.select((state) => state.wealthCategories);
  netIncomeCategories$ = this.select((state) => state.netIncomeCategories);
  accountDetails$ = this.select((state) =>
    state.accounts?.find((account) => account.id === state.currentAccountId),
  );
  categorySummaries$ = this.select('categorySummaries');
  netWealthChartData$ = this.select('netWealthChartData');
  transactions$ = this.select('transactions');

  accountListUpdated$: EventEmitter<string> = new EventEmitter(null);

  /** Net income page data */
  availableDurations$ = this.select('availableDurations');
  netWealthDetailedChartData$ = this.select('netWealthDetailedChartData');
  currentDuration$ = this.select('currentDuration');
  currentExpenseCategories$ = this.select('currentCategories').pipe(
    map((categories) => categories?.filter((category) => category.type === 'EXPENSE')),
  );
  currentIncomeCategories$ = this.select('currentCategories').pipe(
    // Transfer types result in income percentages being incorrect, because the percentage shown on
    // the net income items is of the entire category type (INCOME, EXPENSE, etc.) so grouping them
    // together could be problematic.
    map((categories) =>
      categories?.filter((category) => ['INCOME' /* 'TRANSFER' */].includes(category.type)),
    ),
  );
  netIncomedifferencePerc$ = this.select('netIncomeDiffPerc');
  netIncomediff$ = this.select('netIncomeDiff');

  cashScreenChartData$ = this.select('cashScreenChartData');
  accountViewChartData$ = this.select('accountViewChartData');
  currentNetIncomeTotal$ = this.select('currentNetIncomeTotal');
  netIncomeCurrentDurationDate$ = this.select('netIncomeCurrentDurationDate');
  netIncomePrevDurationDate$ = this.select('netIncomePrevDurationDate');
  cashScreenCurrentDurationDate$ = this.select('cashScreenCurrentDurationDate');
  accountViewCurrentDurationDate$ = this.select('accountViewCurrentDurationDate');
  expensesCurrentDurationDate$ = this.select('expensesCurrentDurationDate');
}
