import { EXPLANATION_NOTES_SIMPLE } from '@1bill-app/constants';
import { RewardQuery } from '@1bill-app/services/reward/reward.query';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { PopoverController } from '@ionic/angular';
import Highcharts, { Options as HighchartsOptions } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import { NGXLogger } from 'ngx-logger';
import { PopoverSimpleComponent } from '../popover/popover-simple/popover-simple.component';

HighchartsMore(Highcharts);
// HighchartsSolidGauge(Highcharts)

@Component({
  selector: 'app-credit-score-meter',
  templateUrl: './credit-score-meter.component.html',
  styleUrls: ['./credit-score-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditScoreMeterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input('score') value: number;
  @Input('scoreDate') scoreDate: string;
  @Input('loading') isLoading: boolean;

  highcharts = Highcharts;
  chartOptions: any = {
    // chart: {
    //   width: '20%'
    // }
  } as HighchartsOptions;
  colorCode: string = '#52C681';
  ratingDescription: string = 'Good';
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) chart.reflow();
      } catch (err) {
        console.warn('chartCallback', err);
      }
    }, 500);
  };
  
  constructor(
    private logger: NGXLogger,
    private cdRef: ChangeDetectorRef,
    public rewardQuery: RewardQuery,
    public popoverController: PopoverController,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    this.logger.debug('ngOnChanges SimpleChanges=>', changes);
    if (changes.value) {
      const { currentValue, previousValue, firstChange } = changes.value;
      if (currentValue !== previousValue) {
        this.initHicharts();
        this.cdRef.detectChanges();
      }
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.logger.debug('ngAfterViewInit', this.value);
    this.initHicharts();
  }

  async presentInfoPopover() {
    const popover = await this.popoverController.create({
      component: PopoverSimpleComponent,
      cssClass: 'my-custom-class',
      // translucent: true,
      mode: 'ios',
      componentProps: {
        popoverTitle: EXPLANATION_NOTES_SIMPLE.CREDIT_SCORE_TITLE,
        description: EXPLANATION_NOTES_SIMPLE.CREDIT_SCORE_DESC,
      },
    });
    return await popover.present();
  }

  getValue(value: number) {
    if (value > 1000) {
      return 1000;
    } else if (value < 0) {
      return 0;
    } else {
      return value;
    }
  }

  initHicharts() {
    if (this.value < 0) {
      this.logger.debug('Setting score to 0 as its negative.', this.value);
      this.value = 0;
      this.colorCode = '#EB7A7A';
      this.ratingDescription = 'Bad';
    } else if (this.value < 250) {
      this.colorCode = '#EB7A7A';
      this.ratingDescription = 'Bad';
    } else if (this.value >= 250 && this.value < 500) {
      this.colorCode = '#FADB9E';
      this.ratingDescription = 'Average';
    } else if (this.value >= 500 && this.value < 750) {
      this.colorCode = '#51BD89';
      this.ratingDescription = 'Good';
    } else if (this.value >= 750 && this.value <= 1000) {
      this.colorCode = '#0C7241';
      this.ratingDescription = 'Excellent';
    } else {
      this.logger.debug('Setting score to null.', this.value);
      this.value = null;
      this.colorCode = '#DF5353';
    }

    this.chartOptions = {
      chart: {
        type: 'gauge',
        backgroundColor: 'transparent',
        borderWidth: 0,
        plotBackgroundColor: 'transparent',
        plotShadow: false,
        plotBorderWidth: 0,
        height: 110,
      },
      tooltip: {
        enabled: false,
      },
      title: null,

      pane: {
          startAngle: -90,
          endAngle: 90,
          
          size: '100%',
          center: [ "50%", "87%" ],
          background: [
            {
              backgroundColor: 'transparent',
              innerRadius: '100%',
              outerRadius: '100%',
              borderWidth: 0,
              shape: 'arc',
              rounded: true,
              padding: 0,
            },
          ],
      },
      yAxis: {
          lineWidth: 0,
          tickWidth: 0,
          minorTickInterval: null,
          tickAmount: 0,
          showFirstLabel: false,
          showLastLabel: false,
          min: 0,
          max: 1000,
      },
      xAxis: {
        minPadding: 0,
        maxPadding: 0,
      },
      plotOptions: {
          gauge: {
              dial: {
                  radius: '120%',
                  backgroundColor: '#BFC6D4 ',
                  shape: 'arc',
                  borderColor: '#BFC6D4',
                  borderWidth: 1,
                  baseWidth: 2,
                  topWidth: 1,
                  baseLength: '80%', // of radius
                  rearLength: '0%'
              },
              pivot: {
                radius: 1,
                borderWidth: 1,
                borderColor: '#BFC6D4',
                backgroundColor: '#BFC6D4',
              },
          },
          series: {
            dataLabels: {
                align: 'center',
                enabled: true,
                format: this.ratingDescription,
                color: this.colorCode,
                textOutline: this.colorCode,
                style: {
                  textOutline: 'none'
                },
                borderColor: this.colorCode,
                borderWidth: 1,
                padding: 2,
                x: 0,
                y: 2,
                crop: false,
                overflow: 'none'
            }
        }
      },
      credits: {
        enabled: false,
      },
      series: [{
          data: [this.value]
      }]
    };

    // Highcharts.chart('creditScoreChart', this.chartOptions);

    // Chart.pluginService.register({
    //   beforeDraw: (chart) => {
    //     const width = chart.width,
    //       height = chart.height,
    //       ctx = chart.ctx;

    //     // ctx.restore();
    //     ctx.clearRect(0, 0, width, height);
    //     ctx.font = '400 40px "Open Sans"';
    //     // ctx.fontColor = '#fff';
    //     ctx.textBaseline = 'top';

    //     const text = this.value && this.value.toString(),
    //       textX = Math.round((width - ctx.measureText(text).width) / 2),
    //       textY = height / 2;

    //     if (chart) {
    //       ctx.fillText(text, textX, textY);
    //       ctx.save();
    //     }
    //   }
    // });
  }

  getTitleColor() {
    const styles = { color: this.colorCode };
    return styles;
  }

  // getScoreValueColor(value: number) {
  //   const red = 'rgba(255, 99, 132, 0.7)';
  //   const redBorder = 'rgba(255, 99, 132, 1)';
  //   const orange = 'rgb(255, 159, 64, 0.7)';
  //   const orangeBorder = 'rgb(255, 159, 64)';
  //   const green = 'rgba(54,182,115, 1)';
  //   const greenBorder = 'rgba(54,182,115, 1)';

  //   let color = red;
  //   let border: string;
  //   if (value < 500) {
  //     color = red;
  //     border = redBorder;
  //   } else if (value < 800) {
  //     color = orange;
  //     border = orangeBorder;
  //   } else {
  //     color = green;
  //     border = greenBorder;
  //   }
  //   return { color };
  // }
}
