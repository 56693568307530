import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

interface OutputEvent {
  success: boolean;
  data?: {
    desc: string;
    placeId: string;
  }
}

@Component({
  selector: 'app-bill-address-search-modal',
  templateUrl: './bill-address-search-modal.component.html',
  styleUrls: ['./bill-address-search-modal.component.scss'],
})
export class BillAddressSearchModalComponent implements OnInit {
  @Input() isModal = true;
  @Input() address: string;
  @Output() addressSelected: EventEmitter<OutputEvent> = new EventEmitter();
  /* {
    
    success: boolean;
  }en */
  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
  ) { }

  ngOnInit() {
    this.logger.log(this.address);
  }

  setAddress(address: any) {
    this.logger.debug('address', address);
    if (this.isModal) {
      this.modalCtrl.dismiss(address)
    } else {
      this.addressSelected.next(address)
    }
  }
}
