import { PAY_BILL_POINTS_REWARD } from '@1bill-app/constants';
import { BillCategoryKey, ViewBillType, ParsedBillData } from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bill-checkout-card',
  styleUrls: ['./bill-checkout-card.component.scss'],
  template: ` <div class="bill-info-wrapper">
    <div class="upper-card-section">
      <div class="left-sector">
        <div class="supplier-logo-wrapper">
          <ion-img
            [src]="getCardIconUrl(bill)"
            class="supplier-logo"
            alt="{{ bill.provider }} Logo"
          ></ion-img>
        </div>
        <div class="left-sector-information-wrapper">
          <p class="provider-display">{{ bill.provider }}</p>
          <p class="brief-display">{{ bill.category }}</p>
        </div>
      </div>
      <div class="right-sector">
        <div class="category-icon-wrapper" title="{{ bill.category }}">
          <span class="category-name" *ngIf="showTypeText">{{ bill.category }}</span>
          <ion-icon
            [src]="getBillTypeIconSource(bill.categoryKey)"
            class="category-icon"
          ></ion-icon>
        </div>
        <div class="due-date-information-wrapper">
          <p class="due-date-text">Due date</p>
          <p class="due-date-display">{{ bill.dueDate | date: 'dd MMM yy' }}</p>
        </div>
      </div>
    </div>
    <div class="lower-card-section">
      <div class="left-sector">
        <p class="amount-text">Amount</p>
        <p class="amount-display" [ngClass]="{ 'on-checkout': showPaySection }">{{ bill.amount | currency }}</p>
      </div>
      <div class="right-sector" *ngIf="showPaySection">
        <p>Pay now, earn</p>
        <div class="earn-button-wrapper">
          <ion-button class="earn-button"> {{ earnedPointsAmount }}pts </ion-button>
        </div>
      </div>
      <div class="right-sector" *ngIf="!showPaySection">
        <p class="period-text">Billing period</p>
        <p class="period-display">{{ bill.billStartDate | date: 'dd MMM \u2019yy' }} - {{ bill.billEndDate | date: 'dd MMM \u2019yy' }}</p>
      </div>
    </div>
  </div>`,
})
export class BillCheckoutCardComponent {
  constructor(private categoryService: CategoryService) {}

  @Input() bill: ViewBillType | ParsedBillData;
  @Input() showTypeText = true;
  @Input() showPaySection = true;
  readonly earnedPointsAmount = PAY_BILL_POINTS_REWARD;
  billDueDate: string;

  ngOnInit() {}

  getBillTypeIconSource(key: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(key)?.icon;
  }

  getCardIconUrl(bill: ViewBillType | ParsedBillData) {
    // ParsedBillData will always have supplierLogoUrl.
    return bill.supplierLogoUrl || this.categoryService.getCardIconUrl(bill as ViewBillType);
  }
}
