import { RewardQuery } from '@1bill-app/services/reward/reward.query';
import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

interface CreditScoreMeterSegment {
  scoreMax: number;
  color: string;
  leftPercentage: number;
  topPercentage: number;
}

@Component({
  selector: 'app-dashboard-credit-score-meter',
  templateUrl: './dashboard-credit-score-meter.component.html',
  styleUrls: ['./dashboard-credit-score-meter.component.scss'],
})
export class DashboardCreditScoreMeterComponent implements OnInit {
  @Input('score') creditScore: number;
  @Input('loading') isLoading: boolean;
  selectedSegment: CreditScoreMeterSegment;

  /**
   * Defines segments of the credit score meter in the new design. The segments' purposes is to
   * display the circular plot point according to the user's credit score.
   * There are seven fixed points in total matching seven coloured segments of the design graph.
   *
   * scoreMax properties are assuming even segments for the entire graph, i.e.
   * segment 1 = 1/7th required score (out of 1000), etc.
   */
  private readonly scoreZones: CreditScoreMeterSegment[] = [
    {
      scoreMax: 143,
      color: '#e0683c',
      topPercentage: 60,
      leftPercentage: 16,
    },
    {
      scoreMax: 286,
      color: '#ef8738',
      topPercentage: 44,
      leftPercentage: 19,
    },
    {
      scoreMax: 429,
      color: '#f2a042',
      topPercentage: 26,
      leftPercentage: 30,
    },
    {
      scoreMax: 571,
      color: '#f5d164',
      topPercentage: 19,
      leftPercentage: 48,
    },
    {
      scoreMax: 714,
      color: '#88c672',
      topPercentage: 26,
      leftPercentage: 66,
    },
    {
      scoreMax: 857,
      color: '#52874d',
      topPercentage: 44,
      leftPercentage: 77,
    },
    {
      scoreMax: 1000,
      color: '#407440',
      topPercentage: 60,
      leftPercentage: 80,
    },
  ];

  constructor(private logger: NGXLogger, public rewardQuery: RewardQuery) {}

  ngOnInit() {
    this.selectedSegment = this.scoreZones.find(
      (scoreZone) => this.creditScore <= scoreZone.scoreMax,
    );
  }
}
