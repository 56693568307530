import { ROUTE_HOMEPAGE } from '@1bill-app/constants';
import { RedeemedOffer } from '@1bill-app/services/offer/offer.type';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-offer-card',
  templateUrl: './offer-card.component.html',
  styleUrls: ['./offer-card.component.scss'],
  animations: [
    trigger('cardFlip', [
      state(
        'default',
        style({
          transform: 'none',
        }),
      ),
      state(
        'flipped',
        style({
          transform: 'rotateY(180deg)',
        }),
      ),
      transition('default => flipped', [animate('400ms')]),
      transition('flipped => default', [animate('200ms')]),
    ]),
  ],
})
export class OfferCardComponent implements OnInit {
  state: string = 'default';
  @Input() offer: RedeemedOffer;
  @Input() showBack: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() showFrontCardNumber = false;
  constructor(private router: Router) {}

  ngOnInit() {
    // handle if details not found
    if(!this.offer?.cardId){
      this.router.navigate([ROUTE_HOMEPAGE]);
    }
  }

  cardClicked() {
    this.onClick.emit();
    if (!this.showBack) return;

    if(!this.offer.cardActivated){
      this.router.navigate(['/app/offers/redeemed/' + this.offer.redeemId]);
      return;
    }
    if (this.state === 'default') this.state = 'flipped';
    else this.state = 'default';
  }
}
