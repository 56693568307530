import { PopoverFailedComponent } from '@1bill-app/components/popover-failed/popover-failed.component';
import { isBillOverdue, isBillPaid } from '@1bill-app/services/bill/helper';
import {
  BillCategoryKey,
  BillRating,
  ViewBillType,
  BILL_RATINGS_CONFIG,
  BillRatingConfigItem,
} from '@1bill-app/services/bill/types';
import { Job } from '@1bill-app/services/status/types';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { generateArray } from '@1bill-app/helpers/array.helper';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { billIsAwaitingApproval } from '@1bill-app/services/bill/helper';

@Component({
  selector: 'app-bill-block-item',
  templateUrl: './bill-block-item.component.html',
  styleUrls: ['./bill-block-item.component.scss'],
})
export class BillBlockItemComponent implements OnInit {
  @Input() bill: ViewBillType;
  @Input() job: Job;
  @Input() dummy: boolean = false;
  @Input('isPending') isAwaitingVerification = false;
  @Input() loading: boolean;
  @Input() isTechnicalIssue = false;
  @Input() isOffer = false;
  @Input() showBillStatus = true;

  @Output() cardClick: EventEmitter<{
    billPaymentId?: number;
    jobId?: number;
  }> = new EventEmitter();
  @Output() payNowClick: EventEmitter<{ billPaymentId: number }> = new EventEmitter();
  isBeingPaid: boolean = false;

  public isBillPaid = isBillPaid;
  public isBillOverdue = isBillOverdue;
  public generateArray = generateArray;
  public billIsAwaitingApproval = billIsAwaitingApproval;

  billLoading$ = this.billQuery.isLoading$;
  public isBillLoading$ = this.billQuery.isLoading$;

  readonly perfectRating = BillRating.Perfect;
  activeRating: BillRatingConfigItem;

  expandRateCompare = false;

  get jobBillInfo() {
    let barcode = this.job?.barcode?.find((b) => b?.billInfo?.billerName != null)?.billInfo;
    if (!barcode) barcode = this.job?.barcode?.find((b) => b?.billInfo != null)?.billInfo;
    return barcode;
  }

  get userCanPayBills() {
    return this.userQuery.userHasFullAccess;
  }

  constructor(
    private logger: NGXLogger,
    private datePipe: DatePipe,
    private popoverController: PopoverController,
    private billService: BillService,
    private billQuery: BillQuery,
    private categoryService: CategoryService,
    private userQuery: UserQuery,
  ) {}

  ngOnInit() {
    this.billService.billPayingEvent$.subscribe((payingBillIds: number[]) => {
      this.isBeingPaid = payingBillIds?.includes(this.bill?.billPaymentId);
    });
    if (!this.dummy) {
      this.activeRating = BILL_RATINGS_CONFIG.find(
        // Null bill ratings count as Excellent deals, as well as those that exceed BillRating.Excellent value
        (configItem) =>
          configItem.rating === this.bill.rating ||
          (configItem.rating === BillRating.Perfect &&
            this.bill.rating > BillRating.Perfect) ||
          !this.bill.rating,
      );
    }
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }

  onPayNowClick(event: Event, bill: ViewBillType) {
    event.stopPropagation();
    this.presentPaymentCombinedModal(bill);
  }

  async onReadMoreClick(event) {
    event.preventDefault();
    event.stopPropagation();

    const popover = await this.popoverController.create({
      component: PopoverFailedComponent,
      cssClass: 'my-custom-class',
      // event: ev,
      translucent: true,
    });
    return await popover.present();
  }

  onCardClick() {
    if (!this.bill.billPaymentId) {
      this.cardClick.emit({ jobId: this.bill.jobId });
    } else {
      this.cardClick.emit({ billPaymentId: this.bill.billPaymentId });
    }
  }

  isFailedBill() {
    return !this.bill?.billPaymentId;
  }
  isSucceededBill() {
    return this.bill.billPaymentId;
  }
  getBillDueDate(bill: ViewBillType) {
    const dateFormat = 'dd MMM y';
    return isBillPaid(bill)
      ? bill.datePaid
        ? this.datePipe.transform(bill.datePaid, dateFormat)
        : '-'
      : bill.dueDate
      ? this.datePipe.transform(bill.dueDate, dateFormat)
      : '-';
  }

  getDetails(categoryKey: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(categoryKey);
  }

  getDueText(bill) {
    if (isBillOverdue(bill)) return 'Overdue';
    return 'Due date';
  }

  async presentPopover(ev: any) {
    // FIXME: Button click event inside ion-card click event is not clickable in this case

    const popover = await this.popoverController.create({
      component: PopoverFailedComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    return await popover.present();
  }

  presentPaymentCombinedModal(viewBillType: ViewBillType) {
    this.billService.presentPaymentCombinedModal(viewBillType);
  }

  toggleRateCompare(event: PointerEvent) {
    event.stopPropagation();
    this.expandRateCompare = !this.expandRateCompare;
  }
}
