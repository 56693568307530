import { PaymentCombinedModalComponent } from '@1bill-app/components/payment-combined-modal/payment-combined-modal.component';
import {
  APP_CONFIG,
  PAYMENT_COMBINED_MODAL_ID,
  ROUTE_SETTINGS_CONTACT_DETAILS,
  ROUTE_SETTINGS_PAYMENT_INFO,
} from '@1bill-app/constants';
import { environment } from '@1bill-app/env';
import { toggleArrayItems } from '@1bill-app/helpers/array.helper';
import { ERROR_CODES } from '@1bill-app/helpers/errors.helper';
import { SelectablePaymentMethod } from '@1bill-app/services/payment/payment-card.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, NgZone } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ApiService, BillPaymentFailedErrorCodes, SwitchProcessCallbackResponse } from '../api/api.service';
import { RewardService } from '../reward/reward.service';
import { StatusService } from '../status/status.service';
import { JobMeta } from '../status/types';
import { UploadService } from '../upload/upload.service';
import { BillData, BillResponse, BillsFilterState, BillStore } from './bill.store';
import { ViewBillType } from './types';
import { SettingsService } from '../settings/settings.service';
import { UIService } from '../ui.service';

@Injectable({
  providedIn: 'root',
})
// export class BillService extends ObservableStore<CoreState> {
// TODO: Add CoreState
export class BillService {
  constructor(
    private api: ApiService,
    private uploadService: UploadService,
    private billStore: BillStore,
    private statusService: StatusService,
    private uiService: UIService,
    private logger: NGXLogger,
    private rewardService: RewardService,
    private alertController: AlertController,
    private ngZone: NgZone,
    private navCtrl: NavController,
    private toastController: ToastController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private settingsService: SettingsService,
  ) {}

  /**
   * Emits the IDs of bills that have their payment processing in order to show
   * progress spinners, etc.
   */
  public billPayingEvent$ = new EventEmitter<number[]>(null);

  svcApiUrlA = environment.svcApiUrlA;

  fetchBill(forceRefresh = false) {
    return this.api.getData<BillResponse>(this.svcApiUrlA + 'getBill', forceRefresh).pipe(
      tap(() => this.billStore.setLoading(true)),
      map((response) => {
        this.logger.log('BillService::fetchBill() => fetched from network');
        this.billStore.update({ ...response.data, loaded: true });
        return response;
      }),
      catchError((err) => {
        this.billStore.setError(err);
        throw err;
      }),
      finalize(() => this.billStore.setLoading(false)),
    );
  }

  upload(file: File, uploadUrl: string) {
    return this.uploadService.uploadFile<{ data: any }>(uploadUrl, file).pipe(
      map((response) => {
        // uploading
        // this.setUploadingState(true);
        return response;
      }),
      catchError((error: HttpErrorResponse) =>
        this.handleError(error, ERROR_CODES.ADD_BILL__UPLOAD_ERROR),
      ),
    );
  }

  private handleError(err: any, errCode?: any): never {
    this.billStore.setError(err);
    this.logger.error('Server error: ', err);
    throw err;
  }

  getBillUploadUrl(fileName: string, mime: string, type: string) {
    return this.api.getUploadUrl(fileName, mime, type).pipe(
      map((response) => response),
      catchError((err) => this.handleError(err, ERROR_CODES.ADD_BILL__GET_SIGNED_URL_ERROR)),
    );
  }

  addBillToQueue(data: {
    url: string;
    imgURLs: string[];
    urlType: string;
    category: string;
    mediaType: string;
    productType: string;
    channel: string;
    addedAt: string;
    jobMeta: JobMeta;
    s3KeyOnlyMode: boolean; // s3-url-to-key
  }) {
    this.logger.log('productType', data.productType);
    return this.api.addBillToQueue(data).pipe(
      tap((response) => this.statusService.updateLatestJobId(Number(response.data.jobId))),
      mergeMap((response) => {
        this.statusService.updateAddedJobs(Number(response.data.jobId));
        return this.statusService
          .checkPollFetch()
          .pipe(map((status) => ({ queue: response, status })));
      }),
      tap(() => {
        this.logger.log('Set job meta values to null');
        this.statusService.updateJobMeta({
          directDebit: null,
          alreadyPaid: null,
          cusSelected: null,
        });
      }),
      map((response) => {
        return response.queue;
      }),
      finalize(() => this.statusService.checkPollFetch()),
      catchError((err) => this.handleError(err, ERROR_CODES.ADD_BILL__SEND_TO_QUEUE_ERROR)),
    );
  }

  processManualEnteredBill(
    billFormData: any,
    jobInputData: {
      url: string;
      imgURLs: string[];
      urlType: string;
      category: string;
      mediaType: string;
      productType: string;
      channel: string;
      addedAt: string;
      jobMeta: JobMeta;
      s3KeyOnlyMode: boolean; // s3-url-to-key
    },
  ) {
    const data = { billFormData, jobInputData };
    return this.api.processManualBill(data).pipe(
      tap((response) => this.statusService.updateLatestJobId(Number(response.data.jobId))),
      mergeMap((response) => {
        this.statusService.updateAddedJobs(Number(response.data.jobId));
        return this.statusService
          .checkPollFetch()
          .pipe(map((status) => ({ queue: response, status })));
      }),
      map((response) => {
        return response.queue;
      }),
      finalize(() => this.statusService.checkPollFetch()),
      catchError((err) => this.handleError(err, ERROR_CODES.ADD_BILL__SEND_TO_QUEUE_ERROR)),
    );
  }

  // async presentJobInfoPopover() {
  //   return { directDebit: null, alreadyPaid: null, cusSelected: false }
  //   const popover = await this.popoverController.create({
  //     component: PopoverJobInfoUpdateComponent,
  //     cssClass: 'onebill-medium-popover',
  //     // translucent: true,
  //     mode: 'ios',
  //   });
  //   await popover.present();
  //   const { data } = await popover.onDidDismiss();
  //   if (data && data.type == 'OK') {
  //     const { directDebit, alreadyPaid } = data.info;
  //     return { directDebit, alreadyPaid, cusSelected: true };
  //     // this.updateJobMetaInfo({ directDebit, alreadyPaid, jobId });
  //   } else {
  //     return { directDebit: null, alreadyPaid: null, cusSelected: false };
  //   }
  // }

  // updateJobMetaInfo(data: { directDebit: boolean; alreadyPaid: boolean; jobId: any }) {
  //   this.logger.log('data', data);
  //   return this.api.updateJobMetaInfo(data).toPromise();
  // }
  updateHomePageFilter(billsFilter: BillsFilterState) {
    const uiState = this.billStore.getValue().ui;
    const state = uiState.homePageFilter;
    const { categoryKeys, categoryKey } = billsFilter;
    if (categoryKeys?.length > 0) {
      billsFilter.categoryKeys = toggleArrayItems(categoryKeys, state.categoryKeys);
    }
    const filter = {
      ...this.billStore.getValue().ui.homePageFilter,
      ...billsFilter,
      categoryKey,
    };
    this.billStore.updateUI({ homePageFilter: filter });
  }
  updateBillsPageFilter(billsFilter: BillsFilterState, setUpdated = false) {
    const uiState = this.billStore.getValue().ui;
    const state = uiState.billsPageFilter;
    const { categoryKeys, propertyTags } = billsFilter;
    if (categoryKeys?.length > 0) {
      billsFilter.categoryKeys = toggleArrayItems(categoryKeys, state.categoryKeys);
    }
    if (propertyTags?.length > 0) {
      if (setUpdated) {
        billsFilter.propertyTags = billsFilter.propertyTags.filter((t) => t);
      } else {
        billsFilter.propertyTags = toggleArrayItems(
          propertyTags.filter((t) => t),
          state.propertyTags,
        );
      }
    }
    const filter = { ...this.billStore.getValue().ui.billsPageFilter, ...billsFilter };
    this.billStore.updateUI({ billsPageFilter: filter });
  }
  updateHistoryPageFilter(billsFilter: BillsFilterState, setUpdated = false) {
    const uiState = this.billStore.getValue().ui;
    const state = uiState.historyPageFilter;
    const { categoryKeys, propertyTags } = billsFilter;
    if (categoryKeys?.length > 0) {
      billsFilter.categoryKeys = toggleArrayItems(categoryKeys, state.categoryKeys);
    }
    if (propertyTags?.length > 0) {
      if (setUpdated) {
        billsFilter.propertyTags = billsFilter.propertyTags.filter((t) => t);
      } else {
        billsFilter.propertyTags = toggleArrayItems(
          propertyTags.filter((t) => t),
          state.propertyTags,
        );
      }
    }
    const filter = { ...this.billStore.getValue().ui.historyPageFilter, ...billsFilter };
    this.billStore.updateUI({ historyPageFilter: filter });
  }
  /**
   * Returns cached value if present
   * @param {boolean} fetch - force fetch from api
   */
  getBill(fetch: boolean = false): Observable<BillData> {
    if (this.billStore.getValue().loaded && !fetch && !this.billStore.getValue().refetch) {
      return of(this.billStore.getValue());
    }
    this.billStore.update({ refetch: false });
    return this.fetchBill(fetch).pipe(map((res) => res.data));
  }

  async presentPaymentCombinedModal(bill: ViewBillType) {
    const userSettings = await this.settingsService.fetch().toPromise();
    const { isMissing, code } = SettingsService.identifyMissingPhoneNumber(userSettings);

    if (isMissing) {
      await this.uiService.presentActionDeniedModal(
        code === 'mobileNumber'
          ? 'You must add and verify your phone number'
          : 'You must verify your phone number',
        'before paying a bill.',
        code === 'mobileNumber' ? 'Add and verify mobile number' : 'Verify mobile number',
        ROUTE_SETTINGS_CONTACT_DETAILS,
      );
      return;
    }
    this.modalController
      .create({
        id: PAYMENT_COMBINED_MODAL_ID,
        component: PaymentCombinedModalComponent,
        componentProps: {
          initialBill: bill,
          availableCards: [],
        },
        cssClass: 'payment-combined-modal',
      })
      .then((modalResult) => {
        modalResult.present();
        modalResult.onWillDismiss().then(
          (dismissResult: {
            data?: {
              bill: ViewBillType;
              bills: ViewBillType[];
              selectedPaymentMethod: SelectablePaymentMethod;
            };
            role?: string;
          }) => {
            this.logger.log('Payment Combined Modal dismiss data:', dismissResult);
            if (dismissResult.role !== 'confirm') return;

            // Helps bypass the mobile verification requirement for testing purposes.
            // dismissResult.data.selectedPaymentMethod = {
            //   type: 'card',
            //   cardId: 83,
            //   isDefault: true,
            //   isSelected: true,
            // };

            const { bill, bills, selectedPaymentMethod } = dismissResult.data;
            if (selectedPaymentMethod) {
              // if bundled, bills will be defined; if single, bill will be defined
              if (bill) {
                // Jira ticket OB-281: Prevent sending API call if billPaymentId is null
                if (!bill.billPaymentId) {
                  return this.toastController
                    .create({
                      color: 'danger',
                      duration: 3000,
                      message: 'Sorry, an error occurred. Please try again later.',
                    })
                    .then((toast) => {
                      toast.present();
                      this.api.verifyBillsStatus(dismissResult);
                    });
                }
                switch (selectedPaymentMethod.type) {
                  case 'card':
                    {
                      this.payNow([bill.billPaymentId], selectedPaymentMethod.cardId);
                    }
                    break;
                  case 'account':
                    {
                      console.error('Not implemented yet.');
                    }
                    break;
                  default: {
                    console.error(
                      'BillService.presentPaymentCombinedModal() unhandled payment type for single:',
                      selectedPaymentMethod.type,
                    );
                  }
                }
              } else if (bills?.length) {
                const payingBillIds = bills.map((bill) => bill.billPaymentId);
                // Jira ticket OB-281: Prevent sending API call if billPaymentId is null
                if (payingBillIds.some((billId) => billId === null || billId === undefined)) {
                  return this.toastController
                    .create({
                      color: 'danger',
                      duration: 3000,
                      message: 'Sorry, an error occurred. Please try again later.',
                    })
                    .then((toast) => {
                      toast.present();
                      this.api.verifyBillsStatus(dismissResult);
                    });
                }
                switch (selectedPaymentMethod.type) {
                  case 'card':
                    {
                      this.payNow(payingBillIds, selectedPaymentMethod.cardId);
                    }
                    break;
                  case 'account':
                    {
                      console.error('Not implemented yet.');
                    }
                    break;
                  default: {
                    console.error(
                      'BillService.presentPaymentCombinedModal() unhandled payment type for bundle:',
                      selectedPaymentMethod.type,
                    );
                  }
                }
              }
            }
          },
        );
      });
  }

  // Unused?
  async presentPayViaCardAlert(b: ViewBillType, accountCardId: number, cardType?: string) {
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          this.logger.log('Confirm Cancel: blah');
        },
      },
      {
        text: 'Pay via Credit card',
        handler: () => {
          this.ngZone.run(() => {
            this.payNow([b.billPaymentId], accountCardId);
          });
        },
      },
    ];

    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert',
      header: 'Pay bill now?',
      subHeader: cardType
        ? `Please confirm if you want to pay the bill using your ${cardType} card.`
        : 'Please confirm if you want to pay the bill now.',
      message: 'Note: There is a payment fee of 1.5% of the total bill.',
      buttons: b.paymentReady != 1 ? buttons.splice(2, 1) : buttons,
    });

    await alert.present();
  }

  payNow(billPaymentIds: number[], accountPaymentId: number) {
    this.billPayingEvent$.emit(billPaymentIds);
    this.payBillNow({ billPaymentIds, accountPaymentId }).subscribe((res) => {
      this.billPayingEvent$.emit([]);
      const success = res.successCount === billPaymentIds.length;
      if (success) {
        this.presentToast(
          'success',
          res.successCount === 1
            ? 'Your bill payment is awaiting approval.'
            : 'Your bill bundle payment is awaiting approval.',
        );
      } else {
        if (res.missingCardDetails) {
          this.presentToastAddCard(success, 'Please add payment details to pay now.');
        } else if (res.firstMonthPaymentLimitExceeded) {
          this.presentFirstMonthPaymentExceededModal(res.firstMonthPaymentLimit);
        } else {
          if (res.successCount > 0) {
            this.presentToast(
              'warning',
              `Only ${res.successCount} of ${billPaymentIds.length} bills paid successfully.`,
            );
          } else {
            this.presentToast(
              'danger',
              billPaymentIds.length === 1
                ? 'Sorry, your bill could not be paid.'
                : 'Sorry, your bill bundle could not be paid.',
            );
          }
        }
      }
    });
  }

  /**
   * Pays the bill(s) right now. - billPaymentIds
   */
  payBillNow(data: { billPaymentIds: number[]; accountPaymentId: number }): Observable<{
    successCount: number;
    missingCardDetails?: boolean;
    firstMonthPaymentLimit?: number;
    firstMonthPaymentLimitExceeded?: boolean;
  }> {
    this.billStore.setLoading(true);
    return this.api.payBillNow(data).pipe(
      switchMap((res) => {
        this.logger.log('payBundleNow result:', res);
        const successCount = res.billPaymentInfo.filter(
          (billPayment) => billPayment.success,
        ).length;

        // Log failed bills
        const failedBills = res.billPaymentInfo.filter((billPayment) => !billPayment.success);
        failedBills.forEach((failedBill) => {
          this.logger.error('Failed bill:', {
            billPaymentId: failedBill.billPaymentId,
            code: failedBill.code,
            error: failedBill.error,
          });
        });

        if (res.success) {
          return forkJoin({
            bill: this.fetchBill(true),
            rewards: this.rewardService.getRewards(),
          }).pipe(
            map(() => {
              return { successCount };
            }),
          );
        }
        const paymentLimitExceededBill = failedBills.find(
          (failedBill) =>
            failedBill.code === BillPaymentFailedErrorCodes.FIRST_MONTH_PAYMENT_LIMIT_EXCEEDED,
        );
        return of({
          successCount,
          missingCardDetails: failedBills.some(
            (failedBill) =>
              failedBill.code === BillPaymentFailedErrorCodes.CARD_DETAILS_MISSING,
          ),
          firstMonthPaymentLimit: paymentLimitExceededBill.firstMonthPaymentLimit,
          firstMonthPaymentLimitExceeded: !!paymentLimitExceededBill,
        });
      }),
      catchError((err) => {
        this.logger.error('Error in payBillNow', err);
        return of({ successCount: 0 });
      }),
      finalize(() => this.billStore.setLoading(false)),
    );
  }

  updateTag(data: { accountBillTypeId: any; tag: string }) {
    return this.api.updateBillTag(data).pipe(
      tap((res) => {
        this.logger.log(res, 'successfully updated!');
        this.presentToast('success', 'Tag saved successfully.');
      }),
      catchError((err) => {
        this.logger.error('updateTag', err);
        this.presentToast('danger', 'Error in saving Tag.');
        throw err;
      }),
    );
  }

  showLatPayPopUp(billPaymentIds: number[]): never {
    throw Error('LatitudePay is no longer available.');
  }

  /**
   * Opens the url.
   * In-app browser in app.
   * External tab in web.
   */
  private openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
      toolbarColor: 'ffffff',
    });

    // Browser.addListener('browserFinished', () => {
    //   this.logger.log('native browser closed');
    //   this.homeService.triggerFetchHomeData('BillPage::Switch open browser openWindow closed');
    //   this.betterDealProcessing = false;
    //   this.latPayProcessing = false;
    // });
  }
  async presentToast(color: 'success' | 'danger' | 'warning', message: string) {
    const toast = await this.toastController.create({
      message,
      duration: APP_CONFIG.TOAST_SUCCESS_DURATION,
      color,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    toast.present();
    color === 'success' ? this.fetchBill().subscribe() : '';
  }

  async presentToastAddCard(success: boolean, message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 10000,
      color: success ? 'success' : 'warning',
      buttons: [
        {
          text: 'Go',
          icon: 'arrow-redo-outline',
          side: 'end',
          handler: () => {
            this.navCtrl.navigateForward([ROUTE_SETTINGS_PAYMENT_INFO]);
          },
        },
      ],
    });
    toast.present();
    success ? this.fetchBill().subscribe() : '';
  }

  getLatPayBundleIframeUrl(data: {
    billPaymentIds: number[];
    isSourceNative: boolean;
  }): never {
    throw Error('LatitudePay is no longer available.');
    // return this.api.latCreateOnlineBundlePurchase(data).pipe(
    //   map((res) => {
    //     return { ...res, message: 'Success!' };
    //   }),
    //   catchError((err) => {
    //     this.logger.error('Error in getLatPayBundleIframeUrl', err);
    //     return of({
    //       success: false,
    //       message:
    //         'Sorry, LatitudePay cannot process your bundled bills at this time. Please try again later.',
    //     });
    //   }),
    // );
  }

  updateUsageAndMeter(data: { billPaymentId: any; peakUsage: any; meterNumber: any }) {
    return this.api.UsageAndMeter(data).pipe(
      tap((res) => {
        this.logger.log(res, 'successfully updated!');
        this.presentToast('success', 'Usage and Meter number saved successfully.');
      }),
      catchError((err) => {
        this.logger.error('usageMeter', err);
        this.presentToast('danger', 'Error in saving usage or meter.');
        throw err;
      }),
    );
  }

  updateLatPaySaleCallback(data: {
    token: string;
    reference: string;
    message: string;
    result: string;
    signature: string;
  }): never {
    throw Error('LatitudePay is no longer available.');
    // this.billStore.setLoading(true);
    // return this.api.updateLatPaySaleCallback(data).pipe(
    //   switchMap((res) => {
    //     if (res.success) {
    //       of({ success: true, message: 'Bill paid successfully.' });
    //       // return forkJoin({
    //       //   bill: this.fetchBill(),
    //       //   rewards: this.rewardService.getRewards(),
    //       // }).pipe(
    //       //   map(() => {
    //       //     return { success: true, message: 'Bill paid successfully.' };
    //       //   }),
    //       // );
    //     }
    //     return of({
    //       success: false,
    //       message: 'Something went wrong!',
    //     });
    //   }),
    //   catchError((err) => {
    //     this.logger.error('Error in updateBillPaidLatPay', err);
    //     return of({ success: false, message: 'Something went wrong!' });
    //   }),
    //   finalize(() => this.billStore.setLoading(false)),
    // );
  }

  billPredict() {
    return this.api.getBillPrediction().pipe(
      tap(() => this.billStore.setLoading(true)),
      map((response) => {
        this.billStore.update({ billPredictions: response.data });
        return response;
      }),
      catchError((err) => {
        this.billStore.setError(err);
        throw err;
      }),
      finalize(() => this.billStore.setLoading(false)),
    );
  }

  /**
   * Presents a modal to the user if they try to pay a bill that exceeds the first month payment limit.
   *
   * @param paymentLimit Limit of bills that can be paid in the account's first month - default 3, but
   * real value can be given by API.
   */
  async presentFirstMonthPaymentExceededModal(paymentLimit = 3) {
    return await this.uiService.presentActionDeniedModal(
      'Payment limit exceeded',
      `New users can only pay ${paymentLimit} bills in the first month after signup.`,
      'Okay',
    );
  }
  
  async updateSwitch(p: SwitchProcessCallbackResponse) {
    if (
      p.cncRefId &&
      p.billTypeId &&
      p.callbackStatus &&
      (p.callbackStatus.toLocaleLowerCase() === 'success' ||
        p.callbackStatus.toLocaleLowerCase() === 'successful')
    ) {
      this.logger.log('updateSwitch', p);

      const loading = await this.loadingController.create({
        message: 'Updating data...',
      });
      await loading.present();

      try {
        const response = await this.api.switchProgressCallBack(p).toPromise();

        if (response?.success === false) {
          await this.presentToast('warning', 'Switch info already updated.');
        }
      } finally {
        await loading.dismiss();
      }
    }
  }
}

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};
