import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, startWith } from 'rxjs/operators';
import { EmailScanner, EmailScannerStore } from './email-scanner.store';

@Injectable({ providedIn: 'root' })
export class EmailScannerQuery extends Query<EmailScanner> {
  isLoading$ = this.selectLoading().pipe(startWith(false));
  error$ = this.selectError().pipe(startWith(false));
  emailConfig$ = this.getEmailconfig();
  emailConfig = this.userEmailConfig;

  constructor(protected store: EmailScannerStore) {
    super(store);
  }
  get userEmailConfig() {
    return this.getValue();
  }

  getEmailconfig(){
    return this.select(store => store.data)
  }
  getEmailconfigSelected(emailProvider){
    return this.select(store => store.data).pipe(
      map(res => {
        return res.find(val => val.emailProvider == emailProvider)
      })
    )
  }
}
