import { formatDateChart, isChartDateValid } from '@1bill-app/helpers/date.helpers';
import { BarChartData } from '@1bill-app/services/bill/types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import Highcharts from 'highcharts';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-bill-usage-chart',
  templateUrl: './bill-usage-chart.component.html',
  styleUrls: ['./bill-usage-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillUsageChartComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(private logger: NGXLogger, private cdRef: ChangeDetectorRef) {}

  @Input('data') value: BarChartData[];
  @Input('energyUnit') energyUnit: string;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  // colorCode: string = '#52C681';
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) chart?.reflow();
      } catch (err) {
        console.warn('chartCallback error:', err);
      }
    }, 500);
  };

  ngOnChanges(changes: SimpleChanges) {
    // this.logger.debug('ngOnChanges SimpleChanges=>', changes);
    if (changes.value) {
      const { currentValue, previousValue, firstChange } = changes.value;
      if (currentValue !== previousValue) {
        this.initHicharts();
        this.cdRef.detectChanges();
      }
    }
  }

  ngOnInit() {
    // this.initHicharts();
  }

  ngAfterViewInit() {
    // this.logger.debug('ngAfterViewInit', this.value);
    this.initHicharts();
  }

  // async presentInfoPopover() {
  //   const popover = await this.popoverController.create({
  //     component: PopoverSimpleComponent,
  //     cssClass: 'onebill-popover-simple',
  //     // translucent: true,
  //     mode: 'ios',
  //     componentProps: {
  //       data: {
  //         title: EXPLANATION_NOTES_SIMPLE.CREDIT_SCORE_TITLE,
  //         desc: EXPLANATION_NOTES_SIMPLE.CREDIT_SCORE_DESC
  //       },
  //     },
  //   });
  //   return await popover.present();
  // }

  initHicharts() {
    this.logger.debug('initialising highcharts');
    const orderedValue = _.orderBy(
      this.value?.map((v) => ({ ...v, order: v?.bbox[0] })),
      ['order'],
    ).filter((v) => v.text);
    const usageData = orderedValue?.map((v) => v.usage);
    const isDate = orderedValue?.every((v) => v.date);
    let categories;
    if (isDate) categories = orderedValue?.map((v) => v.date);
    else categories = orderedValue?.map((v) => v.text);
    this.logger.debug('Usage data: ', orderedValue);
    this.chartOptions = {
      chart: {
        renderTo: 'chart',
        type: 'column',
        styledMode: true,
        backgroundColor: 'transparent',
        className: 'bill-usage-chart',
        height: 150,
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      yAxis: [
        {
          className: 'chart-y-axis',
          title: {
            text: '',
          },
        },
      ],
      scrollbar: {
        enabled: true,
      },

      tooltip: {
        backgroundColor: '#FCFFC5',
        borderColor: 'black',
        borderRadius: 10,
        borderWidth: 3,
        formatter: function () {
          const isDate = isChartDateValid(String(this.x));
          let date = _.startCase(_.lowerCase(String(this.x)));
          if (isDate) date = formatDateChart(String(this.x));
          return `<b>${date}</b>: ${this.y}`;
        },
      },

      xAxis: {
        categories,
        labels: {
          formatter: function (x) {
            let date: string = x?.value;
            const isDate = isChartDateValid(x?.value);
            if (isDate) date = formatDateChart(x?.value);
            if (typeof date === 'number') return ' ';
            return _.startCase(_.lowerCase(date));
          },
        },
        max: 8,
        min: 0,
        ordinal: false,
      },

      plotOptions: {
        column: {
          borderRadius: 2,
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Usage',
          data: usageData as any,
          pointPadding: 0,
          type: 'column',
        },
      ],
    };
    this.cdRef.detectChanges();
  }
}
