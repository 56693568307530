import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { environment } from '@1bill-app/env';
import { BillCategoryKey } from '@1bill-app/services/bill/types';
import { Browser } from '@capacitor/browser';
@Component({
  selector: 'app-popover-better-deal-tnc',
  templateUrl: './popover-better-deal-tnc.component.html',
  styleUrls: ['./popover-better-deal-tnc.component.scss'],
})
export class PopoverBetterDealTncComponent implements OnInit {
  @Input() categoryKey: BillCategoryKey;
  providersLink = `https://www.compareandconnect.com.au/energy-providers#providers`;
  termsLink = environment.appTerms;
  privacyPolicy = environment.appPrivacyPolicy;
  constructor(private popoverController: PopoverController) {}
  providersLinkElectAndGas = `https://www.compareandconnect.com.au/compare-electricity-and-gas-prices#providers`;
  providersLinkBroadband = `https://www.compareandconnect.com.au/broadband-providers#providers`;
  ngOnInit() {
    if (
      this.categoryKey === BillCategoryKey.ELECTRICITY ||
      this.categoryKey === BillCategoryKey.GAS
    ) {
      this.providersLink = this.providersLinkElectAndGas;
    } else {
      this.providersLink = this.providersLinkBroadband;
    }
  }
  onClickOkay() {
    this.popoverController.dismiss(true, 'dismiss');
  }

  /**
   * Opens the url.
   * In-app browser in app.
   * External tab in web.
   *
   *
   */
  openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
    });
  }
}
