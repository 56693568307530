import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { IonInput } from '@ionic/angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
// Text-mask
import { createTextMaskInputElement } from 'text-mask-core';

@Directive({
  selector: '[appInputMask]',
  providers: [IonInput],
})
export class InputMaskDirective implements OnInit, OnDestroy {

  @Input('appInputMask') private mask: Array<any> = [];
  private onDestroy: Subject<void> = new Subject<void>();

  constructor(public ionInput: IonInput) { }

  public ngOnInit() {
    this.configureInput();
  }

  public ngOnDestroy() {
    this.onDestroy.next();
  }

  public async configureInput() {
    const input = await this.ionInput.getInputElement();
    const maskedInput = createTextMaskInputElement({
      inputElement: input,
      mask: this.mask,
      guide: false
    });
    this.ionInput
      .ionChange
      .pipe(takeUntil(this.onDestroy))
      .subscribe((event: CustomEvent) => {
        const { value } = event.detail;
        maskedInput.update(value);
        this.ionInput.value = input.value;
      });
  }
}
