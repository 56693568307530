import { APP_CONFIG } from '@1bill-app/constants';
import { environment } from '@1bill-app/env';
import { objDeepEquals } from '@1bill-app/helpers/object.helper';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { PopoverController, ToastController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeStore } from '@1bill-app/services/yodlee/yodlee.store';
import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-popover-update-balance',
  templateUrl: './popover-update-balance.component.html',
  styleUrls: ['./popover-update-balance.component.scss'],
})
export class PopoverUpdateBalanceComponent implements OnInit {
  constructor(
    private yodleeQuery: YodleeQuery,
    private logger: NGXLogger,
    private yodleeService: YodleeService,
    private toastCtrl: ToastController,
    private zone: NgZone,
    private activatedRoute: ActivatedRoute,
    private popoverController: PopoverController,
  ) {
    this.initForm();
  }

  isFormUntouched: boolean = true;
  manualAccountForm: FormGroup;
  saving: boolean;
  isPullToRefresh = false;

  subs = new SubSink();
  id: string;

  accountDetails$ = this.yodleeQuery.accountDetails$;
  accountDetails: YodleeAccount;

  private _wealthType: 'asset' | 'liability' = 'asset';

  get wealthType() {
    return this._wealthType;
  }

  set wealthType(input: 'asset' | 'liability') {
    this._wealthType = input;
    this.manualAccountForm.patchValue({ accountType: null });
  }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.queryParams?.id;
    this.logger.log('MY ID: ', this.id);
    //this.id = Number(this.route.snapshot.queryParams?.id);
    this.accountDetails$.subscribe((accountDetails) => {
      this.accountDetails = accountDetails;
    });

    this.manualAccountForm.setValue({
      accountType: this.accountDetails.accountType.toLowerCase(),
      accountName: this.accountDetails.accountName,
      accountValue: this.accountDetails.balance.amount,
    });

    this.manualAccountForm.valueChanges.subscribe((result) => {
      if (
        objDeepEquals(
          {
            accountType: this.accountDetails.accountType.toLowerCase(),
            accountName: this.accountDetails.accountName,
            accountValue: this.accountDetails.balance.amount,
          },
          result,
        )
      ) {
        this.isFormUntouched = true;
      } else {
        // this.logger.log('value changed');
        this.isFormUntouched = false;
      }
    });
  }

  initForm() {
    this.isFormUntouched = true;

    this.manualAccountForm = new FormGroup({
      accountType: new FormControl(null, [Validators.required]),
      accountName: new FormControl(null, [Validators.required]),
      accountValue: new FormControl(null, [Validators.required]),
    });
  }

  async onSave() {
    try {
      const formData = {
        ...this.manualAccountForm.value,
      };

      this.logger.debug(formData);
      this.logger.debug(this.manualAccountForm.value);
      this.saving = true;

      await this.yodleeService.editManualAccount(formData, Number(this.id)).toPromise();
      // Reload bank account data
      await this.zone.run(async () => {
        await this.yodleeService.getAccounts().toPromise();
        this.showSuccess('Balance has successfully been updated.', 'success');
        this.isFormUntouched = true;
      });
    } catch (err) {
      if (!environment.production) {
        console.error(err);
      }
      // send error log to server
      this.logger.error('id onSave', err);
      // display error toast
      this.showError('Error occurred while updating balance!');
    }
    this.saving = false;
    this.dismiss(true);
  }

  async showSuccess(message: string, color = undefined) {
    if (!message) {
      return;
    }
    const toast = await this.toastCtrl.create({
      message,
      duration: APP_CONFIG.TOAST_ERROR_DURATION,
      color: color,
      cssClass: ['toast-success'],
    });
    toast.present();
  }

  async showError(err: any, danger = false) {
    if (!err) {
      return;
    }
    const message = err.statusText || err.message || err;
    const toast = await this.toastCtrl.create({
      message,
      duration: APP_CONFIG.TOAST_ERROR_DURATION,
      color: danger ? 'danger' : 'warning',
      // cssClass: ['toast-error']
    });
    await toast.present();
  }

  dismiss(updated?: boolean) {
    this.popoverController.dismiss(null, updated ? 'confirm' : 'cancel');
  } 
}
