import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween)

import { Platform } from '@ionic/angular';
import {
  AbstractControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { logger } from '../../services/tsLogger';
@Injectable({
  providedIn: 'root',
})
export class CustomValidationService {
  constructor(public platform: Platform) {}

  /*
   * Detect if browser is safari.
   * */

  isSafariDesktop(): boolean {
    if (!this.platform.is('desktop')) {
      return false;
    }

    return isUsingSafariDesk();
  }
}
/*
 * General constants.
 * */
const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
const SAFARI_DATE_INPUT = 'DD/MM/YYYY';
const MIN_AGE = 18;
const MAX_AGE = 100;
const SAFARI_DATE_REGEX: RegExp = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/; // dd/mm/yyyy;
const STANDARD_DATE_REGEX: RegExp = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/; // yyyy-mm-dd;

export const DATE_MASK = [/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /[1-2]/, /\d/, /\d/, /\d/];

export const PHONE_MASK = [
  /[0]/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
];

/*
 * For all other identity validation dates.
 * */
export const MIN_DATE = dayjs().format(STANDARD_DATE_FORMAT);
export const MAX_DATE = dayjs().add(10, 'year').format(STANDARD_DATE_FORMAT);

/*
 * For date of birth.
 * */
export const MIN_DOB = dayjs().subtract(MAX_AGE, 'year').format(STANDARD_DATE_FORMAT);
export const MAX_DOB = dayjs().subtract(MIN_AGE, 'year').format(STANDARD_DATE_FORMAT);

export function safariDateToStandardDate(date: string) {
  if (date) {
    return dayjs(date, SAFARI_DATE_INPUT).format(STANDARD_DATE_FORMAT);
  } else {
    return null;
  }
}

export function standardDateToSafariDate(date: string) {
  if (date) {
    return dayjs(date, STANDARD_DATE_FORMAT).format(SAFARI_DATE_INPUT);
  } else {
    return null;
  }
}

export function validateDOB(platform: Platform): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let DOB = control.value;
    // logger.debug('DOB:' + DOB);
    if (!DOB || DOB === null || DOB === '') {
      return null;
    }
    let dobRe: RegExp;
    const isSafariDesk = new CustomValidationService(platform).isSafariDesktop();

    isSafariDesk ? (dobRe = SAFARI_DATE_REGEX) : (dobRe = STANDARD_DATE_REGEX); // assign regex

    DOB = dayjs(DOB, isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT).format(
      isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT,
    );
    // logger.debug(DOB);
    const valid = dobRe.test(DOB);

    if (!valid) {
      return { invalidDOB: true, message: 'Invalid date.' };
    }

    const dateOfBirth = dayjs(DOB, isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT);
    const dateOfBirthValidity =
      dayjs().diff(dayjs(dateOfBirth), 'year') >= MIN_AGE && dateOfBirth !== undefined;

    if (dateOfBirthValidity) {
      return null;
    } else {
      return { invalidDOB: true, message: 'Age must be greater than 18 years.' };
    }
  };
}

export function validateExpiryDate(platform: Platform): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let DATE = control.value;
    let dateRe: RegExp;
    const isSafariDesk = new CustomValidationService(platform).isSafariDesktop();

    isSafariDesk ? (dateRe = SAFARI_DATE_REGEX) : (dateRe = STANDARD_DATE_REGEX); // assign regex

    DATE = dayjs(DATE, isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT).format(
      isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT,
    );
    // logger.debug(DATE);
    const valid = dateRe.test(DATE);

    if (!valid) {
      return { invalidExpiryDate: true, message: 'Invalid date.' };
    }

    const dateOfExpiry = dayjs(DATE, isSafariDesk ? SAFARI_DATE_INPUT : STANDARD_DATE_FORMAT);

    if (dateOfExpiry.isBetween(MIN_DATE, MAX_DATE)) {
      return null;
    } else {
      if (dateOfExpiry.isBefore(MIN_DATE)) {
        return { invalidExpiryDate: true, message: 'Date must be future date.' };
      } else if (dateOfExpiry.isAfter(MAX_DATE)) {
        return {
          invalidExpiryDate: true,
          message: 'Date must not be more than 10 years from today.',
        };
      }
    }
  };
}

function isUsingSafariDesk(): boolean {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

/*
 * Conditional validation adapted from: https://medium.com/ngx/3-ways-to-implement-conditional-validation-of-reactive-forms-c59ed6fc3325
 * */
/**
 * makes the field required if the predicate function returns true
 */
export function requiredIfValidator(predicate) {
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  };
}
export type BooleanFn = () => boolean;
export function conditionalValidator(
  predicate: BooleanFn,
  validator: ValidatorFn,
  errorNamespace?: string,
): ValidatorFn {
  // logger.debug('predicate',predicate);
  return (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    let error = null;
    if (predicate()) {
      error = validator(formControl);
    }
    if (errorNamespace && error) {
      const customError = {};
      customError[errorNamespace] = error;
      error = customError;
    }
    return error;
  };
}
