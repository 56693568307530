import { BillCalendarEventColours, ROUTE_BILLS_PAGE } from '@1bill-app/constants';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { isBillOverdue, isBillPaid } from '@1bill-app/services/bill/helper';
import { GetBillOption, ViewBillType } from '@1bill-app/services/bill/types';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CalendarComponent } from 'ionic2-calendar';
import { NGXLogger } from 'ngx-logger';

export interface BillEvent {
  title: string;
  startTime: Date;
  endTime: Date;
  allDay: boolean;
  colour: string;
  bill: ViewBillType;
}
@Component({
  selector: 'app-bills-calendar',
  templateUrl: './bills-calendar.component.html',
  styleUrls: ['./bills-calendar.component.scss'],
})
export class BillsCalendarComponent implements OnInit {
  @ViewChild(CalendarComponent) myCal: CalendarComponent;

  //List of calendar events (an event is a bill)
  eventSource: BillEvent[] = [];
  viewTitle: string;
  routeBillsPage = ROUTE_BILLS_PAGE;

  calendar = {
    mode: 'month',
    currentDate: new Date(),
  };

  //List shows unpaid bills by default, until billStatusFilter is changed.
  public billsDisplayList$ = this.billQuery.getBillsPageUpcomingBills(GetBillOption.ALL_BILLS);
  billsDisplayList: ViewBillType[];

  public isBillPaid = isBillPaid;
  public isBillOverdue = isBillOverdue;

  constructor(private billQuery: BillQuery, private logger: NGXLogger) {}

  ngOnInit() {
    this.billsDisplayList$.subscribe(async (billsList) => {
      this.billsDisplayList = billsList;
      this.eventSource = this.createBillEvents();
    });
  }

  //Go to next month
  next() {
    this.myCal.slideNext();
  }

  //Go to last month
  back() {
    this.myCal.slidePrev();
  }

  onViewTitleChanged(title) {
    this.viewTitle = title;
  }

  /**
   * Creates Calendar events for all of user's bills.
   * @returns list of bill events to display on calendar
   */
  createBillEvents() {
    this.eventSource = [];
    let billEvents = [];

    this.billsDisplayList.forEach((bill) => {
      billEvents.push(this.createEventFromBill(bill));
    });

    this.logger.log('Bill Events:', billEvents);

    return billEvents;
  }

  /**
   * Takes a ViewBillType object as input, and creates a calendar event for that bill.
   * @param bill
   * @returns billEvent object
   */
  createEventFromBill(bill: ViewBillType) {
    //If `Bill In Credit`, use billEndDate`. Else, use dueDate
    const startDay =
      bill?.datePaid == null && bill.amount <= 0
        ? new Date(bill.billEndDate)
        : new Date(bill.dueDate);
    //If `Bill In Credit`, use billEndDate`. Else, use dueDate
    let endDay =
      bill?.datePaid == null && bill.amount <= 0
        ? new Date(bill.billEndDate)
        : new Date(bill.dueDate);
    endDay.setDate(startDay.getDate() + 1);

    const startTime = new Date(
      Date.UTC(startDay.getUTCFullYear(), startDay.getUTCMonth(), startDay.getUTCDate()),
    );
    const endTime = new Date(
      Date.UTC(endDay.getUTCFullYear(), endDay.getUTCMonth(), endDay.getUTCDate()),
    );

    const billEvent = <BillEvent>{
      title: bill.provider + ' (' + bill.billPaymentId + ')',
      startTime: startTime,
      endTime: endTime,
      allDay: true,
      colour: this.getBillEventColour(bill),
      bill: bill,
    };

    return billEvent;
  }

  /**
   * Determines what colour a bill event is to be displayed as on the calendar.
   * @param bill
   * @returns colour
   */
  getBillEventColour(bill: ViewBillType) {
    if (bill.paymentPending === 1) return BillCalendarEventColours.processingPayment;
    if (isBillOverdue(bill)) return BillCalendarEventColours.overdue;
    //  If Paid or in Credit
    if (isBillPaid(bill) || (bill?.datePaid == null && bill.amount <= 0))
      return BillCalendarEventColours.paid;
    return BillCalendarEventColours.upcomingUnpaid;
  }

  /**
   * Get list of events and return all bills from the events
   * @param events
   */
  getBillsFromEventList(events: any[]) {
    return events.map((event) => event.bill);
  }
}
