export interface NotificationState {
  notifications: NotificationItem[];
  hasBillTransaction: boolean;
}

export interface NotificationItem {
  id: string;
  data: any;
  createdAt: string;
  notificationType: NotificationItemType;
  read?: boolean;
}

export enum NotificationItemType {
  BILL_REMINDER = 'BILL_REMINDER',
  OVERDUE_REMINDER = 'OVERDUE_REMINDER',
  BILL_TRANSACTIONS = 'BILL_TRANSACTIONS',
  BILL_SAVINGS = 'BILL_SAVINGS',
  APP_VERSION = 'APP_VERSION',
  MULTI_USER_ACCESS = 'MULTI_USER_ACCESS',
}

export enum NotificationChannel {
  EMAIL = 'E',
  SMS = 'S',
  DEVICE = 'D',
}
