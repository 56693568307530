export const DASHBOARD_PREFERENCES = [
  {
    displayName: 'Complete Profile',
    itemName: 'complete_profile',
    order: 1,
    prefType: 'D',
    show: true,
  },
  {
    displayName: 'Credit Score',
    itemName: 'credit_score',
    order: 2,
    prefType: 'D',
    show: true,
  },
  {
    displayName: 'Payment Methods',
    itemName: 'payment_methods',
    order: 3,
    prefType: 'D',
    show: true,
  },
  {
    displayName: 'Bill Management and Wealth Summary',
    itemName: 'bill_and_wealth',
    order: 4,
    prefType: 'D',
    show: true,
  },
  {
    displayName: 'Switch Status',
    itemName: 'switch_status',
    order: 5,
    prefType: 'D',
    show: true,
  },
];
