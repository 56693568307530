import { PopoverController } from '@ionic/angular';
import { BillService } from '@1bill-app/services/bill/bill.service';
import {
  PaymentCard,
  PaymentCardService,
} from '@1bill-app/services/payment/payment-card.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { Settings } from '@1bill-app/services/settings/settings.store';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { Component, Input, OnInit } from '@angular/core';
import { AlertController, LoadingController, Platform } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { showError } from 'src/app/services/custom-functions/custom-functions';
import { HomeService } from '@1bill-app/services/home/home.service';

@Component({
  selector: 'app-manage-payment-method-popover',
  templateUrl: './manage-payment-method-popover.component.html',
  styleUrls: ['./manage-payment-method-popover.component.scss'],
})
export class ManagePaymentMethodPopoverComponent implements OnInit {
  @Input() card: PaymentCard;
  loading: HTMLIonLoadingElement;
  saving: boolean;
  accountSettings: Settings;
  subs = new SubSink();
  accountCards$ = this.settingsQuery.accountCards$;

  get anyCards$() {
    return this.accountCards$.pipe(map((cards) => cards?.length > 0));
  }

  get anyBankAccounts$() {
    return this.yodleeQuery.select('accounts').pipe(map((accounts) => accounts?.length > 0));
  }

  constructor(
    private popoverController: PopoverController,
    private alertController: AlertController,
    private settingsService: SettingsService,
    private loadingController: LoadingController,
    private logger: NGXLogger,
    private billService: BillService,
    public platform: Platform,
    private settingsQuery: SettingsQuery,
    private yodleeQuery: YodleeQuery,
    private paymentCardService: PaymentCardService,
    private homeService: HomeService,
  ) {}

  ngOnInit() {
    this.fetchData();
  }

  async presentUpdateBalance(event) {
    const popover = await this.popoverController.create({
      component: ManagePaymentMethodPopoverComponent,
      mode: 'ios',
    });
    await popover.present();
    popover.onDidDismiss().then((updated) => {
      if (updated) {
        // this.fetchChartData().subscribe();
      }
    });
  }

  async removeCard(card: PaymentCard, lastCard = false) {
    //slidingItem.closeOpened();
    let isDefault = false;
    let displayMessage = '';

    if (this.card.accountPaymentId == this.accountSettings.defaultAccountCardId)
      isDefault = true;

    //Remove Only Credit Card
    if (lastCard) {
      displayMessage =
        'Note: This is your only credit card linked for automatic payments. If you remove all your cards, all your bills will need to be paid manually using the Pay Now option';
    } //Removing Default Credit Card
    else if (isDefault) {
      displayMessage =
        'Warning: You are attempting to remove your Default payment method. Doing this will mean that all bills not yet linked to an alternative automatic payment method will have to be paid manually, using the Pay Now option, unless you select a new default card';
    } //Removing Credit Card (not Default)
    else {
      displayMessage =
        'Are you sure you want to remove this credit card? Any bills linked to this card for automatic payment will either need to be paid manually, using the Pay Now option, or will be paid with your default payment method, if you have one set up.';
    }

    const alert = await this.alertController.create({
      // cssClass: 'my-custom-class',
      header: 'Remove this card?',

      message: displayMessage,
      // lastCard
      //   ? 'NOTE: This is your only card linked. If you remove all your cards, all your bills will be set to manual payment.'
      //   : null,

      subHeader: card.cardType + ' ' + card.cardNumber,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: (blah) => {
            this.logger.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Remove',
          cssClass: 'alert-accept',
          handler: (blah) => {
            this.logger.log('Confirm Remove: blah');
            this.onRemove(card);
          },
        },
      ],
    });

    await alert.present();
  }

  async replaceCard(replacingCardId: number) {
    await this.homeService.openZenithAddPaymentCard(replacingCardId);
  }

  async onRemove(card: PaymentCard) {
    this.loading = await this.loadingController.create({
      message: 'Removing card...',
    });
    this.loading.present();
    try {
      this.saving = true;
      await this.paymentCardService.removePaymentCard(card).toPromise();
      await this.fetchData(true); // Should always refetch data since the linkedPaymentMethodId of bills will change
    } catch (err) {
      // send error log to server
      this.logger.error('onRemove card', err);
      // display error toast
      showError('Error occurred while saving settings data!!', true);
    } finally {
      this.popoverController.dismiss();
      this.loading.dismiss();
      this.saving = false;
    }
  }

  async fetchData(fetchBill = false) {
    // const loading = await this.loadingController.create({
    //   message: 'Loading settings data...',
    // });
    // await loading.present();
    if (fetchBill) {
      //fetch bill to show update in payment info
      this.subs.sink = this.billService.fetchBill().subscribe();
    }
    this.subs.sink = this.settingsService.fetch().subscribe({
      next: async (data) => {
        this.accountSettings = data;
        //this.isFormUntouched = true;
        // this.logger.log('user settings data fetched:', JSON.stringify(r));
        this.logger.log('dismiss loading controller', this.accountSettings);
        // await loading.dismiss();
      },
      error: async (e) => {
        // await loading.dismiss();
        this.logger.error('unable to fetch user settings data', e);
      },
    });
  }

  dismiss(
    updated = false,
    dismissData?: {
      action: string;
      data: any;
    },
  ) {
    this.popoverController.dismiss(dismissData, updated ? 'confirm' : 'cancel');
  }
}
