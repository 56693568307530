import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { first } from 'lodash';
import { map, mergeMap, startWith } from 'rxjs/operators';
import { Settings, SettingsStore } from './settings.store';

@Injectable({ providedIn: 'root' })
export class SettingsQuery extends Query<Settings> {
  isLoading$ = this.selectLoading();
  error$ = this.selectError().pipe(startWith(false));
  accountCards$ = this.select('accountCards');
  defaultAccountCardId$ = this.select('defaultAccountCardId');
  preferences$ = this.select('preferences');
  homePaymentCard$ = this.select('accountCards')
    .pipe(
      mergeMap(cards =>
        this.select('defaultAccountCardId')
          .pipe(
            map(defaultAccountCardId => {
              const defaultCard = cards?.find(card => card.accountPaymentId === defaultAccountCardId);
              return defaultCard ?? first(cards);
            })
          )
      )
    );

  constructor(protected store: SettingsStore) {
    super(store);
  }
}
