import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-wealth-single',
  templateUrl: './wealth-single.component.html',
  styleUrls: ['./wealth-single.component.scss'],
})
export class WealthSingleComponent implements OnInit {
  @Input() type: string;
  @Input() displayType: string;
  @Input() amount: number;
  @Input() numberOfAccounts: number;
  @Input() setupButton: boolean = false;
  @Output() expandClicked: EventEmitter<void> = new EventEmitter();

  get iconSrc() {
    const hasIcon = ['cash', 'creditCard', 'loan', 'investment'].includes(this.type);
    if (hasIcon) return `/assets/icon/v3/wealth-management/${this.type}.svg`;
    return '/assets/icon/v3/wealth-management/investment.svg';
  }

  get displayAmount() {
    return this.amount;
  }

  constructor(private router: Router) {}

  ngOnInit() {}

  onClick() {
    this.router.navigate(['/../cash-screen'], {
      queryParams: { type: this.type },
    });
  }
}
