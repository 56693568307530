import { EmailScannerJob } from '@1bill-app/services/email-scanner-jobs/email-scanner-jobs.store';
import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

interface OutputEvent {
  directDebit: boolean;
  alreadyPaid: boolean;
}

@Component({
  selector: 'app-popover-job-info-update',
  templateUrl: './popover-job-info-update.component.html',
  styleUrls: ['./popover-job-info-update.component.scss'],
})
export class PopoverJobInfoUpdateComponent implements OnInit {
  constructor(private popoverController: PopoverController, private logger: NGXLogger) {}

  @Input() showAdditionalInfo: boolean = false;
  @Input() parsedInfo: EmailScannerJob['parsed'];
  directDebit: boolean;
  alreadyPaid: boolean;
  dismissTimer: NodeJS.Timeout;

  ngOnInit() {
    // ** For OB-760, the timer has been disabled for now as the popup is requested to not be skippable.
    // Added timeout (8 seconds by default) to avoid issue on iOS device
    // this.dismissTimer = setTimeout(() => {
    //   this.onClickOkay(true);
    // }, environment.popoverJobInfoUpdateTimeout);
  }

  // skip() {
  //   this.popoverController.dismiss({ type: 'SKIP' });
  // }

  onClickOkay(isTimeout: boolean = false) {
    // Clear timer if the user has pressed ok
    // if (!isTimeout) clearTimeout(this.dismissTimer);

    this.logger.log('onClickOkay', {
      directDebit: this.directDebit,
      alreadyPaid: this.alreadyPaid,
    });
    this.popoverController.dismiss({
      type: 'OK',
      info: {
        directDebit: this.directDebit,
        alreadyPaid: this.alreadyPaid,
      },
    });
  }
}
