import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, from, throwError, forkJoin, of } from 'rxjs';
import { catchError, startWith, switchMap } from 'rxjs/operators';
import { Auth } from 'aws-amplify';
import { InterceptorSkipHeader } from './enum';
import { AuthService } from '../auth/state/auth.service';
import { TEXT_SESSION_EXPIRED } from '@1bill-app/constants';
import { Capacitor } from '@capacitor/core';
import { UserQuery } from '../user/user.query';
import { NGXLogger } from 'ngx-logger';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userQuery: UserQuery,
    private logger: NGXLogger,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader.Authorization)) {
      req = req.clone({ headers: req.headers.delete(InterceptorSkipHeader.Authorization) });
      req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
      return next.handle(req);
    }
    return from(Auth.currentSession()).pipe(
      switchMap((session) => {
        if (!req.headers.has(InterceptorSkipHeader.Authorization)) {
          const token = session.getIdToken().getJwtToken();
          if (token) {
            if (
              !Capacitor.isNativePlatform() &&
              this.authService.isSessionTimeout(`auth-interceptor (${req.url})`)
            ) {
              this.authService.logout('auth.interceptor').subscribe();
              return throwError(TEXT_SESSION_EXPIRED);
            }
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
          }

          // set viewAs email in header if exists
          const accessType = this.userQuery.accessType;
          const manageAs = this.userQuery.manageAs;
          // console.log('DATA:', accessType, manageAs);
          if (manageAs && accessType)
            req = req.clone({
              headers: req.headers.set('x-manage-as', `${manageAs}-${accessType}`),
            }); //TODO: We'll incode in base64 in future
        } else {
          req = req.clone({
            headers: req.headers.delete(InterceptorSkipHeader.Authorization),
          });
        }
        if (!req.headers.has('Content-Type')) {
          req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        }
        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
        return next.handle(req);
      }),
    );
  }
}
