import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-compare-validation-error',
  templateUrl: './modal-compare-validation-error.component.html',
  styleUrls: ['./modal-compare-validation-error.component.scss'],
})
export class ModalCompareValidationErrorComponent implements OnInit {
  @Input() text1: string;
  @Input() text2: string;
  @Input() btnText: string;
  @Input() route: string;
  @Input() routeSource: string
  constructor(
    private modalCtrl: ModalController,
    private router: Router,
  ) { }

  ngOnInit() { }

  close() {
    this.modalCtrl.dismiss(null, 'close');
  }

  btnClick() {
    if (this.route) {
      this.modalCtrl.dismiss();
      this.router.navigate([this.route], { queryParams: { ...this.routeSource ? { _source: this.routeSource } : {} } });
    }
  }
}
