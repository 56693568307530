import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from './../../services/home/home.service';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import {
  AccountHistoryItem,
  YodleeAccount,
  YodleeAccountGroup,
} from '@1bill-app/services/yodlee/yodlee.types';
import { first } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ROUTE_ACCOUNT_VIEW, ROUTE_HOMEPAGE } from '@1bill-app/constants';
import { getLastOfInterval } from '@1bill-app/helpers/date.helpers';
import dayjs from 'dayjs';
import { PaymentCard } from '@1bill-app/services/payment/payment-card.service';
@Component({
  selector: 'app-track-spending',
  templateUrl: './track-spending.component.html',
  styleUrls: ['./track-spending.component.scss'],
})
export class TrackSpendingComponent {
  constructor(
    private homeService: HomeService,
    private yodleeQuery: YodleeQuery,
    private logger: NGXLogger,
    private yodleeService: YodleeService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  @Input() card: PaymentCard;
  @Input() accounts: YodleeAccount[];
  subs = new SubSink();

  readonly ROUTE_ACCOUNT_VIEW = ROUTE_ACCOUNT_VIEW;
  id: any;

  async ngOnInit() {
    this.logger.log('ACCCC NUMBER:' + this.card.cardNumber);
    //this.id = this.route.snapshot.params.id;
    this.subs.sink = this.yodleeService.getAccounts().subscribe();

    this.checkIfAccountExistsInWealth();
    this.accounts?.forEach((account) => {
      this.logger.log('ACC NUMBER:' + account.accountNumber);
    });
  }

  handleLinkBankAccount() {
    this.homeService.presentLinkBankAccountModal();
  }

  async checkIfAccountExistsInWealth() {
    this.accounts?.forEach(async (account) => {
      this.logger.log(
        'COMPARING ',
        account.accountNumber.slice(-4),
        'AND ',
        this.card.cardNumber.slice(-4),
      );
      if (account.accountNumber.slice(-4) == this.card.cardNumber.slice(-4)) {
        this.id = account.id;
        return true;
      }
    });
    this.id = '';
    return false;
  }

  gotoAccountView() {
    this.logger.log('ID:', this.id);
    if (this.checkIfAccountExistsInWealth())
      this.router.navigateByUrl(ROUTE_ACCOUNT_VIEW, { queryParams: { id: this.id } });
  }
}
