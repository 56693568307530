import { environment } from '@1bill-app/env';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class CachingService {
  config: { cacheKey: string; expiryDays: number; }

  constructor(private storage: Storage, private logger: NGXLogger) {
    this.config = environment.apiCachingConfig;
  }

  /** Initialise local storage for caching APIs */
  async init() {
    await this.storage.create();
    this.logger.debug('CachingService: Database has been created');
  }

  /** Set Cache for URL */
  async setCacheRequest(url: string, data: any): Promise<any> {
    const TTL = this.config.expiryDays * 24 * 60 * 60;
    const validUntil = (new Date().getTime()) + TTL * 1000;
    const key = `${this.config.cacheKey}${url}`;
    this.logger.debug(`CachingService: Storing API cache for "${url}", expired in ${this.config.expiryDays} days`);
    return this.storage.set(key, { validUntil, data });
  }

  /** Load cached API data */
  async getCachedRequest(url: string): Promise<any> {
    const currentTime = new Date().getTime();
    const key = `${this.config.cacheKey}${url}`;

    const storedValue = await this.storage.get(key);

    if (!storedValue) {
      return null;
    } else if (storedValue.validUntil < currentTime) {
      this.logger.debug(`CachingService: Cleaning up the cache "${url}" as it has already expired.`);
      await this.storage.remove(url);
      return null;
    } else {
      this.logger.debug(`CachingService: Getting cache "${url}"`);
      return storedValue.data;
    }
  }

  /** Clear all cache */
  async clearAll() {
    await this.storage.clear();
    this.logger.debug('CachingService: Database has been cleared.');
  }
}
