import { environment } from '@1bill-app/env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  config: { url: string };
  constructor(private logger: NGXLogger, private http: HttpClient) {
    this.config = environment.feedback;
  }
  public async sendFeedback(
    timestamp: string,
    message: string,
    name: string,
    email: string,
    screenshot: string | undefined,
    deviceInfo: any | undefined,
    appInfo: any | undefined,
  ): Promise<void> {
    const methodName = 'sendFeedback';

    const headers = new HttpHeaders()
      .append('Accept', 'application/json')
      .append('Content-Type', 'application/json');
    const body = {
      appInfo,
      deviceInfo,
      name,
      email,
      message,
      screenshot,
      timestamp,
      environment: environment.stage,
      version: environment.ver.raw,
    };

    try {
      this.logger.debug(methodName, `before POST ${this.config.url}`, body);
      await this.http
        .post<void>(this.config.url, JSON.stringify(body), {
          headers,
          withCredentials: false,
        })
        .toPromise();
      this.logger.debug(methodName, `after POST ${this.config.url}`);
    } catch (e) {
      this.logger.error(methodName, e);
      throw e;
    }
  }
}
