import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { NGXLogger } from 'ngx-logger';

interface StorageItem {
  key: StorageItemKeys;
  value: any;
}

export enum StorageItemKeys {
  FIRST_BILL_UPLOADED = 'first-bill-uploaded',
  // CREDIT_SCORE = 'credit-score',
  // OVERDUE_REMINDER = 'overdue-reminder',
  // VIEWED_BILLS = 'viewed-bills',
  // JOBS_INFO_SHOWN = 'jobs-info-shown'
  SESSION_LAST_URL = 'session_last_url',
  REPLACE_CARD_ID = 'replace-card-id',
  MANAGE_AS = 'manage-as',
  ACCESS_TYPE = 'access-type',
}

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private logger: NGXLogger) {}

  async setItem({ key, value }: StorageItem) {
    await Storage.set({
      key,
      value: value?.toString(),
    }).catch((err) => {
      this.logger.error('Error setting item: ', err);
    });
  }

  async getItem(key: StorageItemKeys) {
    const { value } = await Storage.get({ key });
    // console.log('Got item: ', value);
    return value;
  }

  async removeItem(key: StorageItemKeys) {
    await Storage.remove({ key });
  }

  async keys() {
    const { keys } = await Storage.keys();
    // console.log('Got keys: ', keys);
    return keys;
  }

  async clear() {
    await Storage.clear();
  }
  async clearExcept(ignoreKeys: string[]) {
    const { keys } = await Storage.keys();
    for (const key of keys) {
      if (ignoreKeys.indexOf(key) === -1) {
        await Storage.remove({ key });
      }
    }
  }
}
