import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import dayjs from 'dayjs';
import { sum, first, last, groupBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';

enum BillsSalaryChartPeriod {
  ONE_MONTH = '1m',
  THREE_MONTHS = '3m',
  SIX_MONTHS = '6m',
  ALL = 'All',
}

interface BillsSalaryChartData {
  /**
   * Summed due amount of bills due in a particular month
   */
  billsAmount: number;
  /**
   * Format: YYYY/MM
   */
  monthId: string;
  /**
   * Whether selected by the user by clicking or default; data will be displayed in .chart-overview-wrapper
   */
  active?: boolean;
  /**
   * Height (in px) of the expenses (lower) sector of the bar, in darker green
   */
  expensesHeight?: number;
}

@Component({
  selector: 'app-bills-salary-chart',
  templateUrl: './bills-salary-chart.component.html',
  styleUrls: ['./bills-salary-chart.component.scss'],
})
export class BillsSalaryChartComponent {
  @Input() salaryPerMonth: number;
  @Output() chartLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();

  loadingChart = true;
  selectedBarAmount: number;
  selectedBarMonth: string;
  avgLineHeightOffset: number;
  barChartData: {
    seriesData: BillsSalaryChartData[];
    barChartAvg: number;
  };

  selectedPeriod: BillsSalaryChartPeriod = BillsSalaryChartPeriod.ONE_MONTH;

  /**
   * Used in the template to display all options the user can select from.
   */
  readonly ALL_CHART_PERIODS = [
    BillsSalaryChartPeriod.ONE_MONTH,
    BillsSalaryChartPeriod.THREE_MONTHS,
    BillsSalaryChartPeriod.SIX_MONTHS,
    BillsSalaryChartPeriod.ALL,
  ];

  readonly BAR_HEIGHT = 200; // Height of each bar on the graph in pixels

  /** CSS `padding` property of the .chart-container element. This needs to be defined here as
   * it is used in correctly positioning the average plot.
   */
  readonly CHART_CONTAINER_PADDING_PX = 8;

  get seriesDataToDisplay() {
    return this.barChartData?.seriesData.slice(0, this.getAmount(this.selectedPeriod)) ?? [];
  }

  constructor(private billQuery: BillQuery, private logger: NGXLogger) {}

  ngOnInit() {
    this.billQuery.selectLoading().subscribe((isLoading) => {
      // getBillsChartData() relies on billPayments data existing to fill chart with data,
      // so only perform this function once data is loaded.
      if (isLoading === false) {
        this.loadChart();
      }
    });
  }

  /**
   * Reloads chart, to be used by parent components when expected input values change, e.g. salary.
   * @param forceSalaryTo Force salary amount. This is used as when parent components expect a change, the async data passed by the parent may not be updated immediately.
   */
  public reloadChart(forceSalaryTo?: number) {
    if (forceSalaryTo !== undefined) {
      this.salaryPerMonth = forceSalaryTo;
    }
    this.loadChart();
  }

  private getAmount(chartPeriod: BillsSalaryChartPeriod) {
    switch (chartPeriod) {
      case BillsSalaryChartPeriod.ONE_MONTH: {
        return 1;
      }
      case BillsSalaryChartPeriod.THREE_MONTHS: {
        return 3;
      }
      case BillsSalaryChartPeriod.SIX_MONTHS: {
        return 6;
      }
      default: {
        const userBills = [...this.billQuery.getValue().billPayments];
        // Returns the earliest user's bill due date difference in months, to a maximum of 12
        return Math.min(
          12,
          Math.max(
            6,
            dayjs().diff(
              userBills.sort((bill1, bill2) =>
                dayjs(bill1.dueDate).isBefore(bill2.dueDate) ? -1 : 1,
              )[0].dueDate,
              'months',
            ),
          ),
        );
      }
    }
  }

  async billChartClickEvent(chartBar: BillsSalaryChartData) {
    this.barChartData?.seriesData.forEach((bar) => (bar.active = false));
    chartBar.active = true;

    const month = dayjs(chartBar.monthId);
    this.selectedBarMonth = month.format('MMMM YYYY');
    this.selectedBarAmount = chartBar.billsAmount;
  }

  selectPeriod(period: BillsSalaryChartPeriod) {
    this.selectedPeriod = period;
    this.loadChart();
  }

  async loadChart() {
    /**
     * Data to be fed into the chart via template
     */
    let seriesData: BillsSalaryChartData[] = [];

    if (!Array.isArray(this.billQuery.getValue().billPayments)) {
      this.logger.warn('No user bill payments.');
      return;
    }
    const userBills = [...this.billQuery.getValue().billPayments];

    const totalMonthsToDisplay = this.getAmount(this.selectedPeriod);

    // Get last 12 months data
    for (let i = 0; i < totalMonthsToDisplay; i++) {
      const month = dayjs().subtract(i, 'months');
      seriesData.push({
        monthId: month.format('YYYY/MM'),
        billsAmount: 0,
      });
    }

    const earliestChartDate = last(seriesData).monthId;

    const earliestBillDueDate =
      first(
        userBills
          .filter((bill) => !dayjs(bill.dueDate).isBefore(earliestChartDate))
          .sort((bill1, bill2) => (dayjs(bill1.dueDate).isBefore(bill2.dueDate) ? -1 : 1)),
      )?.dueDate ?? earliestChartDate;

    // Group each user's bill sorted into the months by their due dates
    const billPayments = groupBy(
      userBills.filter((bill) =>
        // Only select bills whose due date are equal to or later than the earliest date on the chart
        dayjs(bill.dueDate).isAfter(earliestChartDate, 'month'),
      ),
      (bill) => {
        return dayjs(bill.dueDate).format('YYYY/MM');
      },
    );

    // Set the bill amount of each month to the total amount of bills due in that month
    Object.keys(billPayments).forEach((monthKey) => {
      seriesData.find((seriesDate) => {
        return seriesDate.monthId === monthKey;
      }).billsAmount += sum(billPayments[monthKey].map((billPayment) => billPayment.amount));
    });

    /**
     * Defines all items with chart data filled
     */
    const nonNullSeriesItems = seriesData.filter(
      (bar) =>
        dayjs(bar.monthId).isAfter(earliestBillDueDate) ||
        bar.monthId === dayjs(earliestBillDueDate).format('YYYY/MM'),
    );

    /**
     * Defines the amounts of all filled chart data
     */
    const amountMap = nonNullSeriesItems.map((bar) => bar.billsAmount);
    const barChartAvg = sum(amountMap) / amountMap.length;
    const barChartMax = Math.max(...amountMap);

    seriesData = seriesData.map((seriesItem) => {
      const chartIndividualHeight =
        (seriesItem.billsAmount / Math.max(barChartMax, 1)) * this.BAR_HEIGHT;
      const salaryIndividualValue =
        (this.salaryPerMonth / (seriesItem.billsAmount || 1)) * this.BAR_HEIGHT;

      return {
        ...seriesItem,
        expensesHeight: dayjs(seriesItem.monthId).add(1, 'month').isBefore(earliestBillDueDate)
          ? 5
          : Math.max(
              5,
              (chartIndividualHeight / (chartIndividualHeight + salaryIndividualValue)) *
                this.BAR_HEIGHT,
            ),
      };
    });

    const barHeightAvg =
      sum(
        nonNullSeriesItems.map(
          (item) => seriesData.find((sd) => sd.monthId === item.monthId).expensesHeight,
        ),
      ) / (nonNullSeriesItems.length || 1);

    this.avgLineHeightOffset =
      this.BAR_HEIGHT -
      Math.max(barHeightAvg, 1) -
      // padding-top property of .chart-container
      this.CHART_CONTAINER_PADDING_PX +
      // offset in px of .average-dashed-line from .average-line element
      8;

    this.logger.debug(
      `avgLineHeightOffset Calculation: (${this.BAR_HEIGHT} - ${barHeightAvg}) - ${this.CHART_CONTAINER_PADDING_PX} + 8 = ${this.avgLineHeightOffset}`,
    );

    // Find latest bar and display its data
    const maxBar = last(
      seriesData.sort((element1, element2) =>
        dayjs(element1.monthId).isAfter(element2.monthId) ? 1 : -1,
      ),
    );
    this.billChartClickEvent(maxBar);

    this.barChartData = { seriesData, barChartAvg };
    this.loadingChart = false;

    // This output is to allow tab-home.page.ts to know when the chart has loaded, and to display 'Add your first bill' buttons if there are no
    // bills in the user's account. This should require a more permanent solution, as the buttons will never load if this chart fails to complete.
    // BillQuery::selectLoading() functionality does not seem to work optimally, as the data only becomes available after several seconds, where
    // the loading status is set true and false at least once before the data is really available, requiring this workaround.
    this.chartLoaded.emit(true);
  }
}
