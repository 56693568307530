import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
export interface UploadState {
  isUploading: boolean;
  hasUploaded: boolean;
  hasUploadErr: boolean;
}

function createInitialState(): UploadState {
  return {
    isUploading: null,
    hasUploaded: null,
    hasUploadErr: null,
  };
}

@Injectable({ providedIn: 'root'})
@StoreConfig({ name: 'upload'})
export class UploadStore extends Store<UploadState> {
  constructor() {
    super(createInitialState());
  }
}
