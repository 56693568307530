import { ROUTE_NET_INCOME } from '@1bill-app/constants';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-wealth-summary-card',
  templateUrl: './wealth-summary-card.component.html',
  styleUrls: ['./wealth-summary-card.component.scss'],
})
export class WealthSummaryCardComponent {
  @Input() categorySummaries: any[];
  spendingChartLoading = false;

  constructor(
    private router: Router,
    private logger: NGXLogger,
  ) {}

  routeToBreakdown() {
    this.router.navigateByUrl(ROUTE_NET_INCOME);
  }
}
