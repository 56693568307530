import { DISPLAY_SIDE_MENU } from '@1bill-app/constants';
import { UIService } from '@1bill-app/services/ui.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  ChangeDetectionStrategy,
  ElementRef,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { IonToolbar, MenuController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import '../../helpers/string.helper';
import { map } from 'rxjs/operators';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { NotificationQuery } from '@1bill-app/services/notification/notification.query';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { UserAccountData } from '@1bill-app/services/user/user.types';
/**
 * Component to show header with a logo and back button.
 */
@Component({
  selector: 'app-header-logo',
  styleUrls: ['./header-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header-logo.page.html',
})
export class HeaderLogoComponent implements OnInit, AfterViewInit {
  @ViewChild(IonToolbar, { read: ElementRef }) toolbarRef: ElementRef;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() showBackButton = true;
  @Input() buttonType: 'back' | 'close' = 'back';
  @Input() text: string = 'Back';
  @Input() imageTitle = false;
  @Input() icon = undefined;
  @Input() defaultHref = undefined;
  @Input() showProfilePic = false;
  @Input() showRightActionBtns = DISPLAY_SIDE_MENU; // disabled side menu
  @Input() moreInfoBtn = false;
  @Input() headerStyle: 'primary' | 'secondary' | 'secondary-no-border' | 'primary-dashboard';
  @Input() dummy: boolean;
  @Output() backClick: EventEmitter<any> = new EventEmitter();
  @Output() moreInfo: EventEmitter<any> = new EventEmitter();

  //User's Name Details
  firstName: string;
  lastName: string;
  initials = '';

  normalRoute: string = '/app/user-profile';
  dummyRoute: string = '/add-profile';

  appNeedsUpdate$ = this.uiService
    .newAppVersionAvailable()
    .pipe(map((res) => res.newVersionAvailable));

  totalNotifications$ = this.notificationQuery.totalNotifications$;

  referredBroker: string;
  private readonly WHITE_LABEL_LOGO_DIRECTORY = 'assets/brands/WhiteLabel';
  private readonly WHITE_LABEL_LOGOS = {
    'Entourage': 'WL_Entourage.svg',
    'LoanMarket': 'WL_LoanMarket.svg',
    'ONDA': 'WL_ONDA.svg',
  };

  constructor(
    private logger: NGXLogger,
    private menu: MenuController,
    private uiService: UIService,
    private userQuery: UserQuery,
    private settingsQuery: SettingsQuery,
    private notificationQuery: NotificationQuery,
    private settingsService: SettingsService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // If settings is fetched here, it will prevent the need to make this fetch on every other page that uses it
    // to get initials data. Since it is a common component, it can also reduce the need to introduce settings
    // calls on newly implemented pages that would feature this component.

    this.settingsService.fetch().subscribe();
    this.showRightActionBtns = DISPLAY_SIDE_MENU; // disabled side menu
    if (this.showProfilePic) {
      this.settingsQuery
        .select(['firstName', 'lastName'])
        .pipe(
          map((nameData) => {
            if (nameData.firstName?.length) {
              this.initials = nameData.lastName?.length
                ? nameData.firstName.charAt(0) + nameData.lastName.charAt(0)
                : nameData.firstName.charAt(0);
            } else {
              this.initials = '';
            }
            this.cdRef.detectChanges();
          }),
        )
        .subscribe();
    }

    this.userQuery.select('user').subscribe((user: UserAccountData) => {
      if (user) {
        this.referredBroker = user.referredBroker;
      }
    });
  }

  // get routeLink() {
  //   return this.dummy == false ? this.normalRoute : this.dummyRoute;
  // }

  ngAfterViewInit() {
    this.updateSafeAreaTop();
  }
  
  getBrokerLogoSource(broker: string): string {
    return `${this.WHITE_LABEL_LOGO_DIRECTORY}/${this.WHITE_LABEL_LOGOS[broker]}`;
  }

  openMenu() {
    this.menu.open('main-menu');
  }
  
  updateSafeAreaTop() {
    const safeAreaTop = getComputedStyle(document.documentElement).getPropertyValue(
      '--ion-safe-area-top',
    );
    this.logger.debug('safeAreaTop', safeAreaTop);

    if (safeAreaTop.extractNumber() > 28) {
      const elements: HTMLElement[] = [];
      elements.push(this.toolbarRef.nativeElement);

      elements
        .filter((el) => !!el)
        .forEach((el) => {
          el.style.setProperty('--ion-safe-area-top', '28px');
        });
    }
  }

  onBackClick(event?: any) {
    this.backClick.emit(event);
  }
  
  presentInfoPopover(event?: any) {
    this.moreInfo.emit(event);
  }
}
