import { GOOGLE_OAUTH_GMAIL_INSTRUCTIONS, MOBILE_VIEW_THRESHOLD_PX } from '@1bill-app/constants';
import { EmailScannerQuery } from '@1bill-app/services/email-scanner/email-scanner.query';
import { EmailScannerService } from '@1bill-app/services/email-scanner/email-scanner.service';
import { EmailScannerBase } from '@1bill-app/services/email-scanner/email-scanner.store';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Browser } from '@capacitor/browser';
import {
  ActionSheetController,
  AlertController,
  IonContent,
  NavController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { SOCIAL_LOGIN_OPTS2 } from 'src/app/app.menu';
import { SubSink } from 'subsink';

const SUPPORTED_ROUTES: string[] = ['create', 'update'];
const SUPPORTED_MAIL_SERVICE: { key: string; emailDomain: string; oauthEnabled: boolean }[] = [
  { key: 'gmail', emailDomain: 'gmail.com', oauthEnabled: true },
  { key: 'hotmail', emailDomain: 'hotmail.com', oauthEnabled: false },
  { key: 'yahoo', emailDomain: 'yahoo.com', oauthEnabled: false },
];
@Component({
  selector: 'app-email-scanner-crud',
  templateUrl: './email-scanner-crud.component.html',
  styleUrls: ['./email-scanner-crud.component.scss'],
})
export class EmailScannerCrudComponent implements OnInit, OnDestroy {
  constructor(
    private platform: Platform,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    public toastController: ToastController,
    private query: EmailScannerQuery,
    private svc: EmailScannerService,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private ngZone: NgZone,
    private logger: NGXLogger,
  ) {
    this.initForm();
  }
  
  @ViewChild(IonContent, { static: false }) content: IonContent;
  @ViewChild('infoDetails') infoDetails: ElementRef;
  showMoreInfo = true;
  _source: string = '/email-scanner';
  title = 'Email scanner';
  loading$ = this.query.isLoading$;
  emailProvider: string;
  emailCredForm: FormGroup;
  isFormUntouched: boolean;
  isFormSubmitted: boolean;
  operationType: 'create' | 'update';
  oauthEnabled: boolean;
  emailConfig$ = this.query.emailConfig$;
  emailConfigSelected: Pick<EmailScannerBase, 'username' | 'emailProvider' | 'extCredId' | 'isValid'>;
  public isLoading$ = this.query.isLoading$;
  public error$ = this.query.error$;
  social = SOCIAL_LOGIN_OPTS2;
  googleOauthEmailInsts = GOOGLE_OAUTH_GMAIL_INSTRUCTIONS;
  
  subs = new SubSink();
  get isDesktopView() {
    return this.platform.width() >= MOBILE_VIEW_THRESHOLD_PX;
  }
  ngOnInit() {
    this.emailProvider = this.route.snapshot.paramMap.get('provider');
    this.title = this.route.snapshot.queryParamMap.get('providerName');
    this.operationType = this.route.snapshot.paramMap.get('crud') as 'create' | 'update';

    if (!SUPPORTED_ROUTES.includes(this.operationType)) {
      this.presentToastWithMessage('Invalid operation!', 'warning');
      this.navCtrl.navigateBack([this._source]);
      return;
    }

    this.query
      .getEmailconfigSelected(this.emailProvider)
      .pipe(
        map((res) => {
          const { isPresent, emailDomain, oauthEnabled } = getEmailProviderSettings(
            this.emailProvider,
          );
          this.oauthEnabled = oauthEnabled;
          if (!isPresent) {
            this.presentToastWithMessage('Your email provider could not be found!', 'warning');
            this.navCtrl.navigateBack([this._source]);
            return;
          }

          this.emailConfigSelected = res;
          if (oauthEnabled && this.operationType == 'create') {
            // this.setOauthEnabledConfig(this.operationType, this.emailProvider);
          } else if (res && this.operationType == 'update') {
            this.showMoreInfo = false;
            this.emailConfigSelected = res;
            this.patchForm(this.emailConfigSelected);
          } else if (this.operationType == 'create') {
            // new config
            this.patchForm({ emailProvider: this.emailProvider }, true);
          } else {
            this.presentToastWithMessage('Invalid operation!', 'warning');
            this.navCtrl.navigateBack([this._source]);
            return;
          }
        }),
      )
      .subscribe()
      .unsubscribe();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async onOauthSignIn(operationType: 'create' | 'update', emailProvider) {
    if (operationType == 'create' && emailProvider == 'gmail') {
      const { url, success } = await this.svc.getNewTokenUrlGmail().toPromise();
      if (success && url) {
        this.openWindowOauth(url);
      } else {
        this.presentToastWithMessage('Something went wrong!', 'warning');
      }
    }
  }
  openWindowOauth(url: string) {
    Browser.open({
      presentationStyle: 'popover',
      url,
      windowName: '_self',
      toolbarColor: 'ffffff',
    });

    Browser.addListener('browserFinished', () => {
      this.logger.log('browserFinished event fired...');

      this.ngZone.run(() => {
        this.navCtrl.navigateBack([this._source], { queryParams: { refetch: true } });
      });
    });
  }

  initForm() {
    this.isFormUntouched = true;
    this.isFormSubmitted = false;
    this.emailCredForm = new FormGroup({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required]),
      emailProvider: new FormControl('', [Validators.required]),
      extCredId: new FormControl('', [Validators.required]),
    });
  }
  patchForm(
    formValue: {
      username?: string;
      password?: string;
      emailProvider: string;
      extCredId?: string;
    },
    newCred?,
  ) {
    if (newCred) {
      this.emailCredForm.get('extCredId').clearValidators();
      this.emailCredForm.get('extCredId').updateValueAndValidity();
    }
    this.emailCredForm.patchValue(formValue);
  }

  toggleUpdate() {
    this.isFormUntouched = false;
  }

  async presentMoreActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      cssClass: 'bill-action-sheet',
      buttons: [
        {
          text: 'Remove',
          cssClass: 'delete-button',
          handler: () => this.confirmRemoveCred(),
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
        },
      ],
      mode: 'ios',
    });
    await actionSheet.present();
  }

  async confirmRemoveCred() {
    const alert = await this.alertController.create({
      cssClass: 'bill-alert',
      header: 'Are you sure?',
      message: 'We won’t be able to scan email if credentials is removed.',
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: () => {
            this.ngZone.run(() => {
              this.onRemove();
            });
          },
        },
      ],
    });

    await alert.present();
  }

  async onSave() {
    this.isFormSubmitted = true;
    if (this.emailCredForm.invalid) {
      return;
    }
    if (this.operationType == 'update') {
      const formValues = this.emailCredForm.getRawValue();
      this.svc.updateEmailConfig(formValues).subscribe(({ success, message }) => {
        if (success) {
          this.presentToastWithMessage('Successfully updated credentials!', 'success');
          this.navCtrl.navigateBack([this._source]);
        } else if (message == 'CONNECTION_FAILED') {
          this.presentToastWithMessage('Email inbox could not be accessed!', 'warning');
        } else {
          this.presentToastWithMessage('Error in updating credentials!!!', 'warning');
        }
      });
    } else if (this.operationType == 'create') {
      const formValues = this.emailCredForm.getRawValue();
      this.svc.initEmailConfig(formValues).subscribe(({ success, message }) => {
        if (success) {
          this.presentToastWithMessage('Successfully stored credentials!', 'success');
          this.navCtrl.navigateBack([this._source]);
        } else if (message == 'CONNECTION_FAILED') {
          this.presentToastWithMessage('Email inbox could not be accessed!', 'warning');
        } else {
          this.presentToastWithMessage('Error in storing credentials!!!', 'warning');
        }
      });
    }
  }

  async onRemove() {
    const formValues = this.emailCredForm.getRawValue();
    delete formValues.password;
    this.svc.removeEmailConfig(formValues).subscribe(({ success }) => {
      if (success) {
        this.presentToastWithMessage('Successfully removed credentials!', 'success');
        this.navCtrl.navigateBack([this._source]);
      } else {
        this.presentToastWithMessage('Error in removing credentials!!!', 'warning');
      }
    });
  }

  async presentToastWithMessage(message, status: 'success' | 'warning') {
    const toast = await this.toastController.create({
      message,
      duration: 4000,
      color: status,
    });
    await toast.present();
  }

  openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
    });
  }

  moreInfoToggle() {
    if (this.infoDetails && this.infoDetails.nativeElement) {
      const el = this.infoDetails.nativeElement as HTMLElement;
      const scrollHeight = el.scrollHeight;
      const clientHeight = el.clientHeight;
      if (clientHeight === scrollHeight) {
        el.style.height = '0px';
        el.style.opacity = '0';
        el.classList.add('collapsed');
      } else {
        el.style.height = scrollHeight + 'px';
        el.style.opacity = '1';
        if (el.classList.contains('collapsed')) {
          el.classList.remove('collapsed');
        }
      }
      this.showMoreInfo = !this.showMoreInfo;
    }
  }
  scrollToTop() {
    // this.logger.debug('BillPage::scrollToTop() => call');
    if (this.content) {
      // this.logger.debug('BillPage::scrollToTop() => scroll content');
      this.content.scrollToTop(800);
    }
  }
  scrollToBottom() {
    // this.logger.debug('BillPage::scrollToBottom() => call');
    if (this.content) {
      // this.logger.debug('BillPage::scrollToBottom() => scroll content');
      this.content.scrollToBottom(800);
    }
  }
}

const getEmailProviderSettings = (provider: string) => {
  provider = provider.toLowerCase();
  const present = SUPPORTED_MAIL_SERVICE.find((val) => val.key == provider);
  if (present && present.key) {
    return { ...present, emailProvider: provider, isPresent: true };
  }
  return { emailProvider: null, emailDomain: null, oauthEnabled: null, isPresent: false };
};
