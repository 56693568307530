import { isDateInPast } from '@1bill-app/helpers/date.helpers';
import { UserBillPayment, BillCategoryKey, ViewBillType } from './types';
import { appProductTypes } from './types';

export function isBillPaid(bill: Partial<UserBillPayment>) {
  if (bill.paymentPending || billIsAwaitingApproval(bill)) {
    return false;
  }
  if (bill.amount && bill.amount < 0) {
    // for negative amount
    return true;
  }
  return bill.amount && bill.datePaid && bill.amount === bill.amountPaid;
}

/**
 * Requires a bill's `payNowPaymentInitiated` and `payNowPaymentApproved` properties
 * to determine whether or not it is awaiting admin approval.
 */
export function billIsAwaitingApproval(bill: Partial<UserBillPayment> | Partial<ViewBillType>) {
  return bill?.payNowPaymentInitiated && !bill?.payNowPaymentApproved;
}

export function isBillOverdue(bill: Partial<UserBillPayment>) {
  if (bill.amount && bill.amount < 0 && !bill.paymentPending) {
    // for negative amount
    return false;
  }
  const past = isDateInPast(bill.dueDate);
  const retval =
    bill.amount &&
    ((!bill.datePaid && bill.amount !== bill.amountPaid) || bill.paymentPending || bill.payNowPaymentInitiated) &&
    past;
  return retval;
}

export function formatQueryParams(data) {
  const ret = [];
  for (let d in data) ret.push(d + '=' + data[d]);
  return ret.join('&');
}

/**
 * Determine whether or not a bill can be switched to different providers.
 * @param categoryKey Bill category key
 * @returns `true` if switchable, `false` otherwise
 */
export function canSwitch(categoryKey: BillCategoryKey) {
  return appProductTypes.find((val) => val.key === categoryKey)?.canSwitch;
}
