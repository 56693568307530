import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SelectablePaymentMethod } from '@1bill-app/services/payment/payment-card.service';
import { ModalController } from '@ionic/angular';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { PAYMENT_COMBINED_MODAL_ID, ROUTE_SETTINGS_CONTACT_DETAILS, ROUTE_SETTINGS_PAYMENT_INFO } from '@1bill-app/constants';
import { Router } from '@angular/router';
import _ from 'lodash';
import { CategoryService } from '@1bill-app/services/category/category.service';

@Component({
  selector: 'app-bill-bundle-checkout',
  templateUrl: './bill-bundle-checkout.component.html',
  styleUrls: ['./bill-bundle-checkout.component.scss'],
})
export class BillBundleCheckoutComponent implements OnInit {
  constructor(private modalController: ModalController, private router: Router, private categoryService: CategoryService) {}
  @Input() bills: ViewBillType[];
  @Input() selectedMethod: SelectablePaymentMethod;
  @Input() completeAccountDetails: boolean;
  @Output() removeAction: EventEmitter<number> = new EventEmitter(null);
  @Output() onGotoSelectPayment: EventEmitter<boolean> = new EventEmitter(null);
  totalSumOfBills: number;
  CONTACT_DETAILS_PAGE = ROUTE_SETTINGS_CONTACT_DETAILS;

  ngOnInit() {
    this.totalSumOfBills = _.sum(this.bills.map((bill) => bill.amount));
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }

  removeAllBills() {
    this.removeAction.emit(-1);
  }

  removeBill(bill: ViewBillType) {
    if (this.bills.length === 1) {
      // Removing last bill; same action as removeAllBills()
      this.removeAllBills();
      return;
    }
    this.bills = this.bills.filter(
      (filteringBill) => filteringBill.accountBillTypeId !== bill.accountBillTypeId,
    );

    this.totalSumOfBills = _.sum(this.bills.map((b) => b.amount));
    this.removeAction.emit(bill.accountBillTypeId);
  }

  gotoSelectPayment(act: boolean) {
    this.onGotoSelectPayment.emit(act);
  }

  gotoContactDetails() {
    this.modalController.dismiss(null, null, PAYMENT_COMBINED_MODAL_ID);
    this.router.navigateByUrl(ROUTE_SETTINGS_CONTACT_DETAILS);
  }

  gotoAddPaymentMethod() {
    this.modalController.dismiss(null, null, PAYMENT_COMBINED_MODAL_ID);
    this.router.navigateByUrl(ROUTE_SETTINGS_PAYMENT_INFO);
  }

  dismiss() {
    this.modalController.dismiss(null, 'cancel', PAYMENT_COMBINED_MODAL_ID);
  }

  dismissConfirm() {
    // Dismiss with multiple bills
    this.modalController.dismiss(
      {
        selectedPaymentMethod: this.selectedMethod,
        bills: this.bills,
      },
      'confirm',
      PAYMENT_COMBINED_MODAL_ID,
    );
  }
}
