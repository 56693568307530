import { environment } from '@1bill-app/env';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { NGXLogger } from 'ngx-logger';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { formatMobileNumber } from '../custom-functions/custom-functions';
import { getAddressDetails, GoogleAddressService } from '../google/google-address.service';
import { RewardService } from '../reward/reward.service';
import { UIService } from '../ui.service';
import { Settings, SettingsOptions, SettingsStore } from './settings.store';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  googleAddressService: GoogleAddressService;

  constructor(
    private api: ApiService,
    private store: SettingsStore,
    private rewardService: RewardService,
    private uiService: UIService,
    private logger: NGXLogger,
  ) {
    // Only initialise GoogleAddressService if internet connection is enabled. If it isn't,
    // an error will be thrown and this component, as well as all that inject this component,
    // will also fail to construct.
    this.uiService.getNetworkStatus().then((status) => {
      if (status.connected) {
        this.googleAddressService = new GoogleAddressService();
      }
    });
    // super({});
    // this.setState({ settings: settingsInitialState }, 'init_settings_state');
  }

  /**
   * Get from store if exists otherwise fetch from network
   * @param age in miliseconds
   * @returns
   */
  getCache(age?: number) {
    const lastModified = this.store.getValue()._lastModified;

    const isExpiredFn = () =>
      !isNaN(age) && new Date().getTime() - age < new Date(lastModified).getTime();

    if (lastModified && !isExpiredFn()) {
      return of(this.store.getValue());
    } else {
      return this.fetch();
    }
  }

  fetch() {
    return this.api.getSettings().pipe(
      map((response) => {
        this.store.update({ ...response, _lastModified: new Date().toISOString() });
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        throw error;
      }),
    );
  }

  saveContactDetails(payload: SettingsOptions) {
    const { settingsData } = payload;
    if (settingsData && settingsData.mobileNumber) {
      settingsData.mobileNumber = formatMobileNumber(settingsData.mobileNumber);
    }
    // get addressJson from placeid
    return of(settingsData.addressFull).pipe(
      mergeMap((address) => {
        if (address) {
          return from(this.googleAddressService.getPlacePredictions(address)).pipe(
            map((res) => {
              return { hasPlaceId: true, placeId: res[0].placeId };
            }),
          );
        } else {
          return of({ hasPlaceId: false });
        }
      }),
      tap((val) => {
        this.logger.debug('after placeid search', val);
      }),
      mergeMap((res: { hasPlaceId: boolean; placeId?: string }) => {
        if (res.hasPlaceId && res.placeId) {
          return from(getAddressDetails(res.placeId)).pipe(
            map((res) => {
              return { addressJson: res.result };
            }),
          );
        } else {
          return of({ addressJson: null });
        }
      }),
      tap((val) => {
        this.logger.debug('after address details search', val);
      }),
      map((res) => {
        return { ...payload, settingsData: { ...settingsData, ...res } };
      }),
      tap((val) => {
        this.logger.debug('After formatting data', val);
      }),
      mergeMap((newPayload) => this.api.putSettings(newPayload)),
      tap((setUpd) => {
        if (setUpd.success) {
          this.store.setError(null);
        } else {
          this.store.setError(setUpd.message);
        }
      }),
      filter((setUpd) => setUpd.success),
      mergeMap(() =>
        this.updateCognito({
          firstName: settingsData.firstName,
          lastName: settingsData.lastName,
          mobileNumber: settingsData.mobileNumber,
        }),
      ),
      mergeMap(() => this.rewardService.getRewards()),
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        throw error;
      }),
    );
  }

  /**
   * Updates first name, last name and mobile number of cognito user.
   *
   * @param data
   */
  private updateCognito(data: { firstName: string; lastName: string; mobileNumber: any }) {
    this.logger.debug('updateCognito...');
    const clientMetadata = {
      stage: environment.stage,
      webAppUrl: environment.webAppUrl,
    };
    const updateObject = {
      given_name: data.firstName,
      family_name: data.lastName,
      phone_number: data.mobileNumber,
    };
    if (!data.mobileNumber) {
      delete updateObject.phone_number;
    }
    return from(Auth.currentAuthenticatedUser()).pipe(
      switchMap((user) => from(Auth.updateUserAttributes(user, updateObject, clientMetadata))),
      catchError((error) => {
        throw error;
      }),
    );
  }

  saveSettingDetails(payload: SettingsOptions) {
    if (payload.settingsData && payload.settingsData.mobileNumber) {
      payload.settingsData.mobileNumber = formatMobileNumber(
        payload.settingsData.mobileNumber,
      );
    }

    return this.api.putSettings(payload).pipe(
      mergeMap((res) => this.rewardService.getRewards()),
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        throw error;
      }),
    );
  }

  saveNames(payload: SettingsOptions) {
    return this.api.putSettings(payload).pipe(
      tap((response) => {
        this.updateCognito({
          firstName: payload.settingsData.firstName,
          lastName: payload.settingsData.lastName,
          mobileNumber: undefined,
        }).subscribe();
      }),
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        throw error;
      }),
    );
  }

  saveSettingDetailsOnly(payload: SettingsOptions) {
    if (payload.settingsData && payload.settingsData.mobileNumber) {
      payload.settingsData.mobileNumber = formatMobileNumber(
        payload.settingsData.mobileNumber,
      );
    }
    return this.api.putSettings(payload).pipe(
      catchError((error: HttpErrorResponse) => {
        this.store.setError(error);
        throw error;
      }),
    );
  }

  identifyMissingPersonalDetails(
    settings: Settings,
    idDriverLicenseRequired = true,
  ): { isMissing: boolean; message: string; missingExtra?: string; missingCount?: number } {
    const missing = [];
    let missingExtra = null;
    if (!settings.firstName) {
      missing.push('first name');
    }
    if (!settings.lastName) {
      missing.push('last name');
    }
    if (!settings.mobileNumber) {
      missing.push('mobile number');
    }
    if (!settings.dateOfBirth) {
      missing.push('date of birth');
    }
    if (!settings.address) {
      missing.push('address');
    }
    // driving license mandatory in switch
    if (
      (!settings.idDriverLicence?.id || !settings.idDriverLicence?.state) &&
      idDriverLicenseRequired
    ) {
      missing.push('driver license');
      missingExtra = 'driver_license';
    }

    if (missing.length === 0) {
      return { isMissing: false, message: null };
    } else if (missing.length === 1) {
      return { isMissing: true, message: missing[0], missingExtra };
    }

    let retVal = '';
    for (let i = 0; i < missing.length; i++) {
      if (i === 0) {
        retVal += ` ${missing[i]}`;
      } else if (i === missing.length - 1) {
        retVal += ` and ${missing[i]}`;
      } else {
        retVal += `, ${missing[i]}`;
      }
    }
    // return `<b>${retVal}</b>`;
    return { isMissing: true, message: retVal, missingExtra, missingCount: missing.length };
  }
  static identifyMissingPhoneNumber(settings: Settings): {
    isMissing: boolean;
    code: 'mobileNumber' | 'mobileVerification';
  } {
    let isMissing = false;
    let code = null;
    if (!settings.mobileNumber) {
      code = 'mobileNumber';
      isMissing = true;
    } else if (settings.mobileNumber && !settings.mobileVerified) {
      code = 'mobileVerification';
      isMissing = true;
    }
    return { isMissing, code };
  }
}
