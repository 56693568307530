import { AuthOptions, OAuthOpts } from '@aws-amplify/auth/lib/types';
import { urlOpener } from '@1bill-app/helpers/auth.helper';
import { Capacitor } from '@capacitor/core';

const isLocalhost = location.hostname === 'localhost' || location.hostname === '127.0.0.1';
const webAppUrl = isLocalhost ? 'http://localhost:8100' : 'https://dev.app.1bill.com';

const redirectUri = Capacitor.isNativePlatform() ? 'dev.onebill://callback/' : webAppUrl + '/auth/landing';

const oauth = {
  domain: 'auth.dev.app.1bill.com',
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: redirectUri,
  redirectSignOut: redirectUri,
  responseType: 'code',
  urlOpener,
} as OAuthOpts;

const auth = {
  identityPoolId: 'ap-southeast-2:f819bd84-0b49-4359-8093-548383e53213',
  region: 'ap-southeast-2',
  identityPoolRegion: 'ap-southeast-2',
  userPoolId: 'ap-southeast-2_mga8nlJKw',
  userPoolWebClientId: '15b8p1909uc99g78e49ebsbko6',
  oauth,
} as AuthOptions;

export const amplify = {
  Auth: auth,
};
