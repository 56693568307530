import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Transform amount with negative value.
 * Adds DR in suffix for the amount with negative value,
 * and remove negative sign from it.
 */
@Pipe({
  name: 'currencyDrSign',
})
@Injectable({
  providedIn: 'root',
})
export class CurrencyDrSignPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(
    value: number | string,
    responseType: 'html' | 'value' = 'html',
    fontSize = '12px',
    prependDollarSign = false,
  ): SafeHtml | string {
    const prependValue = prependDollarSign ? '$' : '';
    if (!value) return prependValue + value;
    value = String(value);
    const cr =
      responseType === 'html'
        ? (this.sanitizer.bypassSecurityTrustHtml(
            prependValue +
              value.substring(1, value.length) +
              `<span class="credit-sign" style="font-size: ${fontSize};">DR</span>`,
          ) as SafeHtml)
        : ((value.substring(1, value.length) + 'DR') as string);
    return value.charAt(0) === '-' ? cr : prependValue + value;
  }
  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }
}
