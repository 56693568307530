import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, timer } from 'rxjs';
import {
  map,
  catchError,
  finalize,
  tap,
  concatMap,
  filter,
  take,
  mergeMap,
} from 'rxjs/operators';
import { STATUS_POLLING_INTERVAL_MS } from '@1bill-app/constants';
import { ApiService } from '../api/api.service';
import { StatusData, StatusStore, StatusUIState } from './status.store';
import { JobMeta, JobState } from './types';
import { AppSecurityService } from '../app-security.service';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(
    private statusStore: StatusStore,
    private zone: NgZone,
    private logger: NGXLogger,
    private api: ApiService,
    private appSecurityService: AppSecurityService,
  ) {}
  fetchStatus() {
    return this.zone.runOutsideAngular(() => {
      const zone = this.zone;
      return this.api.getStatus().pipe(
        tap(() => zone.run(() => this.statusStore.setLoading(true))),
        map((response) => {
          const state = { ...response.data, success: response.success };
          zone.run(() => {
            this.statusStore.update({ ...response.data, success: response.success });
          });
          if (response.data.job.items.some((jobItem) => jobItem.state === JobState.BLACKLISTED_ADDRESS)) {
            this.appSecurityService.presentBlacklistedAddressAlert();
          }
          return state as StatusData;
        }),
        catchError((err) => {
          zone.run(() => {
            this.statusStore.setError(err);
          });
          throw err;
        }),
        finalize(() => zone.run(() => this.statusStore.setLoading(false))),
      );
    });
  }
  checkPollFetch() {
    return this.fetchStatus().pipe(
      mergeMap((status) => {
        const jobs = status.job.items;
        const isJobOnProgress =
          jobs.length > 0 &&
          jobs.filter((job) => job.state === JobState.ONPROGRESS).length > 0;
        if (isJobOnProgress) {
          return this.pollFetch();
        }
        return of(status);
      }),
    );
  }
  updateAddedJobs(jobId: number) {
    return this.statusStore.update((state) => ({
      addedJobIds: [...state.addedJobIds, jobId],
    }));
  }
  updateLatestJobId(jobId: number) {
    return this.statusStore.update(() => ({
      latestJobId: jobId,
    }));
  }
  updateJobMeta(data: JobMeta) {
    this.logger.log('updateJobMeta', { data });
    return this.statusStore.update(() => ({
      jobMeta: data,
    }));
  }
  pollFetch(hasInitialDelay?: boolean) {
    return this.zone.runOutsideAngular(() => {
      if (this.statusStore.getValue().ui?.isPolling) {
        return of({}) as Observable<StatusData>;
      }
      applyTransaction(() => {
        this.statusStore.setLoading(true);
        this.statusStore.update({ ui: { isPolling: true, isPollingComplete: false } });
      });

      return this.zone.runOutsideAngular(() => {
        const zone = this.zone;
        return timer(
          hasInitialDelay ? STATUS_POLLING_INTERVAL_MS : 0,
          STATUS_POLLING_INTERVAL_MS,
        ).pipe(
          tap(() => this.logger.debug('status polling refetch')),
          concatMap(() => this.api.getStatus()),
          map((response) => {
            zone.run(() => {
              applyTransaction(() => {
                this.statusStore.update({
                  ...response.data,
                  success: response.success,
                });
                this.statusStore.setLoading(false);
              });
            });
            return response.data;
          }),
          filter((data) => {
            return (
              data.job.items.filter((job) => job.state === JobState.ONPROGRESS).length === 0
            );
          }),
          take(1),
          finalize(() => {
            applyTransaction(() => {
              this.statusStore.update({
                ui: {
                  isJobOnProgress: false,
                  isPolling: false,
                  isPollingComplete: true,
                },
              });
              this.statusStore.setLoading(false);
            });
          }),
          catchError((err: HttpErrorResponse) => {
            zone.run(() => {
              this.statusStore.setError(err);
            });
            throw err;
          }),
        );
      });
    });
  }
  updateUI(uiState: Partial<StatusUIState>) {
    return this.statusStore.updateUI(uiState);
  }
}
