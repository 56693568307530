import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { logger as tsLogger } from '../tsLogger';

@Injectable({
  providedIn: 'root',
})
export class GoogleAddressService {
  autoCompleteService: google.maps.places.AutocompleteService;
  geocodeService: google.maps.Geocoder;
  constructor() {
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.geocodeService = new google.maps.Geocoder();
  }

  getPlacePredictions(searchStr: string): Promise<{ desc: string; placeId: string }[]> {
    return new Promise((resolve, reject) => {
      this.autoCompleteService.getPlacePredictions(
        {
          input: searchStr,
          componentRestrictions: {
            country: 'au',
          },
        },
        (predictions, status) => {
          if (status === 'OK') {
            resolve(
              predictions.map((val) => {
                return { desc: val.description, placeId: val.place_id };
              }),
            );
          } else {
            resolve([]);
          }
        },
      );
    });
  }
  getPlacePredictionsFromGeocode(searchStr : string): Promise<{ result: GoogleAddressDetails; status: string }> {
    return new Promise((resolve,reject) => {
      this.geocodeService.geocode(
        {
          address: searchStr,
          componentRestrictions: {
            country: 'au',
          },
        },
        (result, status) => {          
            let placeId = result[0].place_id
            const res = { ...formatGoogleAddress(result[0]), placeId }
            resolve({ result: res, status });                  
        },        

      )
    })
  }

}
function formatGoogleAddress(googleData: any) {
  try {
    return {
      country: getAddressComponent(googleData, 'country'),
      city: getAddressComponent(googleData, 'locality'),
      postcode: getAddressComponent(googleData, 'postal_code'),
      state: getAddressComponent(googleData, 'administrative_area_level_1'),
      streetName: getStreet(getAddressComponent(googleData, 'route')).name,
      streetNo: getAddressComponent(googleData, 'street_number'),
      subpremise: getAddressComponent(googleData, 'subpremise'),
      streetType: getStreet(getAddressComponent(googleData, 'route', true)).type,
    };
  } catch (e) {
    tsLogger.error('Error in Address: ', e);
  }
}

/**
 * Gets short name of address component.
 */
function getAddressComponent(addressObj, componentName, fullName = false) {
  for (const item of addressObj.address_components) {
    for (const subItem of item.types) {
      if (subItem === componentName) {
        if (fullName) {
          return item.long_name;
        } else {
          return item.short_name;
        }
      }
    }
  }
}

function getStreet(streetAddressString): { name: string; type: string } {
  try {
    if (streetAddressString) {
      const saArray = streetAddressString.split(' ');

      if (saArray.length > 1) {
        return {
          type: saArray.pop(),
          name: saArray.join(' '),
        };
      } else {
        return {
          name: streetAddressString,
          type: null,
        };
      }
    } else {
      return { name: null, type: null };
    }
  } catch (e) {
    tsLogger.error('Error in getStreet: ', e);
    return { name: null, type: null };
  }
}

export function getAddressDetails(
  placeId: string,
): Promise<{ result: GoogleAddressDetails; status: string }> {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        placeId,
      },
      (result, status) => {
        // tsLogger.debug('result', result);
        // tsLogger.debug('status', status);
        const res = { ...formatGoogleAddress(result[0]), placeId }
        resolve({ result: res, status });
      },
    );
  });
}
export interface GoogleAddressDetails {
  city: string;
  state: string;
  country: string;
  placeId: string;
  postcode: string;
  streetNo: string;
  streetName: string;
  streetType: string;
  subpremise?: string;
}