import {
  getTransactionBillerLogo,
  getTransactionBillerName,
  getTransactionStatus
} from '@1bill-app/helpers/yodlee.helper';
import { YodleeTransaction } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-yodlee-transaction-list',
  templateUrl: './yodlee-transaction-list.component.html',
  styleUrls: ['./yodlee-transaction-list.component.scss'],
})
export class YodleeTransactionListComponent implements OnInit {
  @Input() transactions: YodleeTransaction[];
  getTransactionBillerLogo = getTransactionBillerLogo;
  getTransactionBillerName = getTransactionBillerName;
  getTransactionStatus = getTransactionStatus;

  get displayedTransactions() {
    return this.transactions?.slice(0, 3);
  }

  constructor(private homeService: HomeService) {}

  ngOnInit() {}

  handleLinkBankAccount() {
    this.homeService.presentLinkBankAccountModal();
  }
}
