import { PopoverFailedComponent } from '@1bill-app/components/popover-failed/popover-failed.component';
import { BetterDealQuery } from '@1bill-app/services/better-deal/better-deal.query';
import { BetterDealService } from '@1bill-app/services/better-deal/better-deal.service';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { isBillOverdue, isBillPaid } from '@1bill-app/services/bill/helper';
import {
  appProductTypeDefaultImg,
  appProductTypes,
  BillCategoryKey,
  ViewBillType,
} from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-bill-card-saving-single',
  templateUrl: './bill-card-saving-single.component.html',
  styleUrls: ['./bill-card-saving-single.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillCardSavingSingleComponent {
  constructor(
    private categorySvc: CategoryService,
    private popoverController: PopoverController,
    private billQuery: BillQuery,
    private betterDealService: BetterDealService,
    private betterDealQuery: BetterDealQuery,
  ) {}

  @Input() bill: ViewBillType;
  public isBillPaid = isBillPaid;
  public isBillOverdue = isBillOverdue;
  switchPoints$ = this.billQuery.switchRewardPoints$;
  public BillCategoryKey = BillCategoryKey;
  subs = new SubSink();
  betterDealProcessing$ = this.betterDealQuery.select('processing');

  getDetails(categoryKey: BillCategoryKey) {
    return this.categorySvc.getCategoryDetails(categoryKey);
  }

  getCardIconUrl() {
    if (this.bill.supplierLogoUrl) {
      return this.bill.supplierLogoUrl;
    } else {
      return (
        appProductTypes.find((p) => p.key === this.bill.categoryKey)?.imgUrl ||
        appProductTypeDefaultImg
      );
    }
  }

  getDueText(bill) {
    if (isBillOverdue(bill)) return 'Overdue';
    return 'Due date';
  }

  canSwitch(categoryKey) {
    return appProductTypes.find((val) => val.key === categoryKey)?.canSwitch;
  }

  async presentPopover(ev: any) {
    // FIXME: Button click event inside ion-card click event is not clickable in this case

    const popover = await this.popoverController.create({
      component: PopoverFailedComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    return await popover.present();
  }

  presentTncPopUp() {
    this.betterDealService.presentTncPopUp(this.bill);
  }
}
