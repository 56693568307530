import {
  getFullMonthName,
  getNativeMonthsOffset,
  getNativeWeeksOffset,
} from '@1bill-app/helpers/date.helpers';
import { CurrencyDrSignPipe } from '@1bill-app/pipes/currency-dr-sign.pipe';
import { NumberFormatPipe } from '@1bill-app/pipes/number-format.pipe';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { CashChartResponse } from '@1bill-app/services/yodlee/yodlee.types';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import dayjs from 'dayjs';
//import Highcharts from 'highcharts';
import * as Highcharts from 'highcharts/highstock';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-cash-chart',
  templateUrl: './cash-chart.component.html',
  styleUrls: ['./cash-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashChartComponent implements OnInit, AfterViewInit, OnChanges {
  constructor(
    private yodleeQuery: YodleeQuery,
    private logger: NGXLogger,
    private cdRef: ChangeDetectorRef,
    private currencyDrSignPipe: CurrencyDrSignPipe,
    private numberFormatPipe: NumberFormatPipe,
  ) {}

  @Input('balanceMap') balanceMap: Map<string, number>;
  @Input() interval: 'W' | 'M' | 'Y';
  @Input() type: 'cash-screen' | 'account-view';
  @Input() hasAny: boolean;
  @Input() set chartData(input) {
    this._chartData = input;
    if (input?.chartData?.length > 0) {
      setTimeout(() => (this.cashScreenChart as any)?.chart.reflow(), 500);
      this.initHighcharts();
    }
  }

  
  @ViewChild('cashScreenChart') cashScreenChart: ElementRef;

  private _chartData: CashChartResponse;

  get chartData() {
    return this._chartData;
  }

  subs = new SubSink();
  title: string;

  currentBalTotal: number;
  currentBalanceText: string;
  balanceChangeText: string;
  balChangeIcon: string;
  balAdjustmentTextColor: string;

  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) {
          //chart.reflow();
        }
      } catch (err) {
        console.warn('chartCallback', err);
      }
    }, 1000);
  };

  ngOnInit() {
    const chartElement = this.cashScreenChart as any;
    chartElement?.chart?.showLoading();
  }

  reflow() {
    (this.cashScreenChart as any)?.chart?.reflow();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.logger.log('ngOnChanges() triggered chart data:', this.chartData);
    this.currentBalTotal = this.chartData?.reqBalanceTotal;

    if (changes.chartData) {
      this.currentBalanceText = this.getCurrentBalanceText();
      this.balanceChangeText = this.getBalanceChangeText();
      const { currentValue, previousValue } = changes.chartData;
      if (currentValue !== previousValue) {
        this.initHighcharts();
        this.cdRef.detectChanges();
      }
    }
  }

  async showLoading() {
    (this.cashScreenChart as any)?.chart?.showLoading();
  }

  async hideLoading() {
    (this.cashScreenChart as any)?.chart?.hideLoading();
  }

  async ngAfterViewInit() {
    this.initHighcharts();
    this.cdRef.detectChanges();
  }

  initHighcharts() {
    const chartElement = this.cashScreenChart as any;
    const amounts = this.chartData?.chartData.map((data) => data?.amount).reverse();
    const dates = this.chartData?.chartData.map((data) => data?.label).reverse();
    const currencyDrSignPipe = this.currencyDrSignPipe;
    const numberFormatPipe = this.numberFormatPipe;
    this.chartOptions = {
      chart: {
        type: 'column',
        dashStyle: 'dot',
        height: 250,
        marginBottom: 38,
        className: 'cash-chart',
        id: '',
        panning: true,
        pinchType: 'x',
        zoomType: '',
        resetZoomButton: {
          theme: {
            style: {
              display: 'none',
            },
          },
        },
      },
      title: {
        text: '',
      },

      tooltip: {
        followTouchMove: false,

        formatter: function () {
          return `Your balance for <b>${getFullMonthName(
            String(this.x),
          )}</b> is <b>$${currencyDrSignPipe.transform(
            numberFormatPipe.transform(this.y, 3, 2, 2),
            'value',
          )}</b>`;
        },
      },
      noData: {
        style: {
          fontWeight: 'bold',
          fontSize: '15px',
          color: '#303030',
        },
      },

      xAxis: {
        panning: true,
        reversed: true,
        categories: dates,
        min: 0,
        max: 3.75,
        tickPositioner: function () {
          const ticks = this.series[0].xData;
          let result = [];
          for (let index = 0; index < ticks.length; index++) {
            result.push(ticks[index]);
          }
          return result;
        },
        showEmpty: false,
        labels: {
          enabled: true,
          autoRotation: 0,
          formatter: function (label) {
            const date = dayjs(label?.value, 'YYYY-MM-DD');
            return date.isValid() ? date.format('MMM').toUpperCase() : label?.value;
          },
        },
      },
      yAxis: {
        panning: false,
        title: {
          text: null,
        },
        labels: {
          enabled: false,
        },
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
          stacking: 'normal',
        },
        series: {
          animation: false,
        },
      },

      navigator: { enabled: false },
      scrollbar: { enabled: false },
      series: [
        {
          marker: { enabled: true },
          name: '',
          id: 'data',
          data: amounts,
          color: '#11DC93',
          negativeColor: '#eb7a7a',
          borderRadius: 5,
          pointWidth: 60,
          showInLegend: false,
          minPointLength: 24,
          dataLabels: {
            formatter: function () {
              return `${numberFormatPipe.transform(this.y, 3, 1, 2, true) as string}`;
            },
            enabled: true,
            crop: false,
            overflow: 'justify',
            color: 'white',
            verticalAlign: 'bottom',

            x: 0,
            y: -6,
            style: {
              textOutline: false,
            },
          },
        },
      ],
    } as any;

    // Set extremes to keep column heights constant
    (this.cashScreenChart as any)?.chart.yAxis[0].setExtremes(
      Math.min(this.chartData?.chartMin, 0),
      Math.max(this.chartData?.chartMax, 0),
    );

    this.hideLoading();
    this.cdRef.detectChanges();
  }

  getCurrentBalanceText() {
    if (!this.interval || !this.chartData) return;

    const reqOffset =
      this.interval === 'M'
        ? getNativeMonthsOffset(this.chartData.reqPeriod)
        : getNativeWeeksOffset(this.chartData.reqPeriod);

    const intervalText =
      this.interval === 'M'
        ? `${dayjs(this.chartData.reqPeriod).format('MMM YY')}\u2019`
        : `${dayjs(this.chartData.reqPeriod).format('DD MMM YY')}\u2019`;
    return reqOffset === 0 ? 'current balance' : `at ${intervalText}`;
  }

  getBalanceChangeText() {
    if (!this.interval || !this.chartData) return;

    // Temporary: this function should really be in the parent component,
    // and passed through Input() to this component.
    const getOffsetText = () => {
      if (this.interval !== 'M' && this.interval !== 'W')
        throw 'CashChartComponent::getBalanceText unhandled interval';
      const reqOffset =
        this.interval === 'M'
          ? getNativeMonthsOffset(this.chartData.reqPeriod)
          : getNativeWeeksOffset(this.chartData.reqPeriod);
      const availableLabels = [...this.chartData.chartData]
        .sort((a, b) => (a.date > b.date ? -1 : 1))
        .map((cd) => cd.label);

      switch (this.interval) {
        case 'M':
          {
            return reqOffset === 0
              ? 'last month'
              : `${dayjs(this.chartData.prevPeriod).format('MMM YY')}\u2019`;
          }
          break;
        case 'W':
          {
            return availableLabels[1]?.toLowerCase() ?? '(no previous week)';
          }
          break;
        default: {
          return (
            'CashChartComponent::getBalanceChangeText()::getOffsetText() unhandled interval: ' +
            this.interval
          );
        }
      }
    };

    if (this.chartData.balanceDifference > 0) {
      this.balChangeIcon = './assets/icon/v3/bal-increase-arrow.svg';
      this.balAdjustmentTextColor = '#1da061;';
    } else if (this.chartData.balanceDifference < 0) {
      this.balChangeIcon = './assets/icon/v3/bal-decrease-arrow.svg';
      this.balAdjustmentTextColor = '#eb7a7a';
    } else {
      this.balChangeIcon;
      this.balAdjustmentTextColor = '#828B9A';
    }

    return `$${Math.abs(Number(this.chartData.balanceDifference))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
      (${Math.abs(this.chartData.balanceDifferenceRatio * 100).toFixed(
        2,
      )}%) from ${getOffsetText()}`;
  }
}
