import { PIN_ATTEMPT_LIMIT, ROUTE_HOMEPAGE, ROUTE_SCREEN_LOCK } from '@1bill-app/constants';
import { PINService } from '@1bill-app/services/auth/pin.service';
import { AuthService } from '@1bill-app/services/auth/state/auth.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { ImpactStyle, Haptics } from '@capacitor/haptics';
import { KeyboardResize, Keyboard } from '@capacitor/keyboard';
import { Device } from '@ionic-native/device/ngx';
import {
  AlertController,
  Animation,
  AnimationController,
  LoadingController,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { NGXLogger } from 'ngx-logger';
import { NativeBiometric } from 'capacitor-native-biometric';
import { UIService } from '@1bill-app/services/ui.service';

@Component({
  selector: 'app-lock-screen',
  templateUrl: './lock-screen.component.html',
  styleUrls: ['./lock-screen.component.scss'],
})
export class LockScreenComponent implements OnInit, AfterViewInit {
  @ViewChild(CodeInputComponent) codeInput: CodeInputComponent;
  @ViewChild(CodeInputComponent, { read: ElementRef }) codeInputEl: ElementRef;
  hasBiometricAuth = false;
  showFallback = true;
  private _source: string;

  /**
   * After this many milliseconds expire on the pin check, timeout and assume user has no connection or API issue
   */
  private readonly PIN_CHECK_EXPIRY_MS = 30000;
  /**
   * After this many milliseconds expire on the pin check, warn user about their connection
   */
  private readonly PIN_CHECK_WARNING_MS = 10000;
  private readonly PIN_CHECK_WARNING_MESSAGE =
    'This is taking a while. Is your device connected to the internet?';

  connected = true;

  pin: string;
  loading$ = this.pinService.loading$;
  attemptCount: number = 0;
  loadingPage = true;

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private authService: AuthService,
    private pinService: PINService,
    private route: ActivatedRoute,
    private router: Router,
    private navCtrl: NavController,
    private animationCtrl: AnimationController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private device: Device,
    private loadingController: LoadingController,
    private uiService: UIService,
    private cdRef: ChangeDetectorRef,
  ) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  async ngOnInit() {
    this.uiService.networkStatus$.subscribe((connected) => {
      this.connected = connected;
      this.loadingPage = false;
      this.cdRef.detectChanges();
    });
    this._source = this.route.snapshot.queryParamMap.get('_source');
    if (this._source === ROUTE_SCREEN_LOCK) {
      // resolve circular routing
      this._source = ROUTE_HOMEPAGE;
    }
  }

  async ngAfterViewInit() {
    try {
      const available = await NativeBiometric.isAvailable();
      // Remove fingerprint support for android less than 9
      const isLessThanAndroid9 =
        this.platform.is('android') && parseFloat(this.device.version) < 9;
      this.hasBiometricAuth = available.isAvailable && !isLessThanAndroid9;
      if (this.hasBiometricAuth) {
        this.openBiometricAuth();
      } else {
        setTimeout(() => this.focusCodeInput(), 600);
      }
    } catch (err) {
      this.logger.error('lock screen initialization error', err);
    }
  }

  ionViewWillEnter() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ionViewWillLeave() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }

  async openBiometricAuth() {
    try {
      await NativeBiometric.verifyIdentity({
        reason: 'Your session timeout',
      });
      // const pin = await this.pinService.retrieve();
      setTimeout(() => {
        // this.pin = pin;
        this.onCodeCompleted(null, true);
      });
    } catch (err) {
      this.focusCodeInput();
      this.logger.error('Biometric auth open error', err);
    }
  }

  focusCodeInput() {
    this.codeInput && this.codeInput.focusOnField && this.codeInput.focusOnField(0);
  }

  async dismissLockScreen() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss();
    }
  }

  onCodeChanged() {
    this.removeCSSVariable(this.codeInputEl.nativeElement, '--item-border-bottom');
  }

  async onCodeCompleted(event, biometric = false) {
    // const storedPin = await this.pinService.retrieve();
    this.logger.debug('_source:', this._source);
    this.logger.debug('event:', event);
    // if (event === storedPin || this.pin === storedPin) {

    const loader = await this.loadingController.create({ message: 'Signing you in...' });
    const warningTimer = setTimeout(() => {
      loader.message = this.PIN_CHECK_WARNING_MESSAGE;
    }, this.PIN_CHECK_WARNING_MS);
    const expiryTimer = setTimeout(() => {
      loader.dismiss();
      throw Error('Timed out on pin check.');
    }, this.PIN_CHECK_EXPIRY_MS);
    await loader.present();

    let pinResult;
    if (biometric || (pinResult = await this.pinService.check(event).toPromise())) {
      this.authService.resetLastActivity();
      this.navCtrl.setDirection('root');
      this.router.navigateByUrl(this._source);
      this.dismissLockScreen();
      clearInterval(warningTimer);
      clearInterval(expiryTimer);
      loader.dismiss();
    } else {
      Haptics.impact({ style: ImpactStyle.Heavy });
      clearInterval(warningTimer);
      clearInterval(expiryTimer);
      loader.dismiss();
      this.codeInput.reset(false);
      this.focusCodeInput();
      this.triggerPinErrAnimation();
      this.addCSSVariable(
        this.codeInputEl.nativeElement,
        '--item-border-bottom',
        '2px solid var(--ion-color-danger)',
      );
      this.attemptCount++;
      if (this.attemptCount >= PIN_ATTEMPT_LIMIT) {
        const alert = await this.alertCtrl.create({
          header: 'Too many attempts',
          message: 'You have entered the PIN incorrectly too many times.',
          buttons: ['OK'],
        });
        await alert.present();
        alert.onDidDismiss().then(() => {
          this.forgot();
        });
      }
    }
  }

  forgot() {
    this.authService.logout('lock-screen.comp');
    this.dismissLockScreen();
  }

  triggerPinErrAnimation() {
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(this.codeInputEl.nativeElement)
      .easing('ease-out')
      .duration(1000)
      .keyframes([
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.2 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.3 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.4 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.5 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.6 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.7 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.8 },
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.9 },
      ]);
    animation.play();
  }

  addCSSVariable(el: HTMLElement, property: string, value: string) {
    el.style.setProperty(property, value);
  }
  removeCSSVariable(el: HTMLElement, property: string) {
    el.style.removeProperty(property);
  }
}
