import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import {
    capEchoOptions,
    capSQLiteQueryOptions,
    capSQLiteRunOptions,
    SQLiteConnection,
    SQLiteDBConnection,
    CapacitorSQLite
} from '@capacitor-community/sqlite';
import { AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { SQLITE_DB_NAME } from '@1bill-app/constants';
import { createSchema } from './schema';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class SQLiteService {
    dbReady = new BehaviorSubject(false);

    sqlite: SQLiteConnection;
    platform: string;
    isService: boolean;
    database: string;
    db: SQLiteDBConnection;
    constructor(
        private http: HttpClient,
        private logger: NGXLogger,
        private alertCtrl: AlertController,
    ) {
    }

    async init() {
        this.logger.debug('SQLite: initializing plugin...');
        await this.initializePlugin();
        this.logger.debug('SQLite: initializing database...');
        await this.initializeDatabase();
        this.dbReady.next(true);
    }
    /**
     * Plugin Initialization
     */
    private initializePlugin(): Promise<boolean> {
        return new Promise(resolve => {
            this.platform = Capacitor.getPlatform();
            this.logger.debug("SQLiteService::initializePlugin() platform " + this.platform)
            const sqlitePlugin: any = CapacitorSQLite;
            this.sqlite = new SQLiteConnection(sqlitePlugin);
            this.isService = true;
            this.logger.debug("SQLiteService::initializePlugin() isService " + this.isService);
            resolve(true);
        });
    }

    private async initializeDatabase() {
        this.database = SQLITE_DB_NAME;
        if (this.sqlite != null) {
            try {
                // initialize the connection
                this.db = await this.sqlite.createConnection(this.database, false, 'no-encryption', 1);

                // open db
                await this.db.open();

                // create tables in db
                let ret: any = await this.db.execute(createSchema);
                this.logger.debug('SQLiteService::initializePlugin() ret.changes.changes in db ' + ret.changes.changes)
                if (ret.changes.changes < 0) {
                    return Promise.reject(new Error("Execute createSchema failed"));
                }

                // create synchronization table 
                ret = await this.db.createSyncTable();
                if (ret.changes.changes < 0) {
                    return Promise.reject(new Error("Execute createSyncTable failed"));
                }

                // set the synchronization date
                const syncDate: string = "2020-11-26T08:30:25.000Z";
                await this.db.setSyncDate(syncDate);

                return Promise.resolve(this.db);
            } catch (err) {
                return Promise.reject(err);
            }
        } else {
            return Promise.reject(new Error(`no connection open for ${this.database}`));
        }
    }

    async echo(options: capEchoOptions) {
        return CapacitorSQLite.echo(options);
    }

    query(options: capSQLiteQueryOptions) {
        return CapacitorSQLite.query({ database: this.database, values: [], ...options });
    }
    run(options: capSQLiteRunOptions) {
        return CapacitorSQLite.run({ database: this.database, values: [], ...options });
    }
}