import { FeedbackService } from '@1bill-app/services/feedback.service';
import { Component, OnInit } from '@angular/core';
import {
  AlertController,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { environment } from '@1bill-app/env';
import { AuthQuery } from '@1bill-app/services/auth/state/auth.query';
import { Auth } from 'aws-amplify';
import { PopoverSimpleComponent } from '../popover/popover-simple/popover-simple.component';
import { EXPLANATION_NOTES_SIMPLE } from '@1bill-app/constants';
import { Device } from '@capacitor/device';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss'],
})
export class FeedbackFormComponent implements OnInit {
  //
  public includeDeviceInfo = true;
  private includeAppInfo = true;
  public includeScreenshot = false;
  // form
  public message: string;
  public name: string;
  public email: string;
  public cbDeviceInfo = true;
  public cbScreenshot = true;
  constructor(
    private modalCtrl: ModalController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private feedbackService: FeedbackService,
    private authQuery: AuthQuery,
    private popoverController: PopoverController,
  ) {}

  ngOnInit() {
    this.loadUserInfo();
  }
  async loadUserInfo() {
    const loading = await this.loadingCtrl.create({ message: 'Loading user info...' });
    try {
      await loading.present();
      const { attributes } = await Auth.currentAuthenticatedUser();
      this.email = attributes.email;
      this.name = [attributes['given_name'], attributes['family_name']]
        .filter((n) => n)
        .join(' ');
    } catch (err) {}
    loading.dismiss();
  }
  onClose() {
    this.modalCtrl.dismiss({ dismiss: true });
  }
  /**
   * Sends the feedback.
   */
  public async onSend(): Promise<void> {
    const loading = await this.loadingCtrl.create({ message: 'Sending feedback...' });
    try {
      await loading.present();
      const deviceInfo = await Device.getInfo();
      const appInfo = {
        webEnv: environment.stage,
        webVersion: environment.ver.version,
        webRevision: environment.ver.revision,
        webBranch: environment.ver.branch,
      };

      await this.feedbackService.sendFeedback(
        new Date().toISOString(),
        this.message,
        this.name,
        this.email,
        undefined,
        this.includeDeviceInfo && this.cbDeviceInfo ? deviceInfo : undefined,
        this.includeAppInfo ? appInfo : undefined,
        // this.includeScreenshot ? this.screenshotFormData : undefined
      );
      await loading.dismiss();
      const alert = await this.alertCtrl.create({
        mode: 'ios',
        cssClass: 'bill-alert alert-icon',
        buttons: ['OK'],
        message:
          '<img src="assets/icon/alert/success.svg" alt="warning-icon" /><h2 class="alert-title">Feedback Sent</h2><p class="alert-text">Thank you, We will get back to you shortly.</p>',
      });
      await alert.present();
      this.modalCtrl.dismiss();
    } catch (e) {
      await loading.dismiss();
      const alert = await this.alertCtrl.create({
        buttons: ['OK'],
        header: 'Send Feedback',
        subHeader: 'Could not send feedback',
      });
      await alert.present();
    }
  }
  async presentInfoPopover() {
    const popover = await this.popoverController.create({
      component: PopoverSimpleComponent,
      cssClass: 'onebill-popover-simple',
      // translucent: true,
      mode: 'ios',
      componentProps: {
        popoverTitle: EXPLANATION_NOTES_SIMPLE.FEEDBACK_TITLE,
        description: EXPLANATION_NOTES_SIMPLE.FEEDBACK_DESC,
      },
    });
    return await popover.present();
  }
}
