import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RewardService } from '../reward/reward.service';
import { NGXLogger } from 'ngx-logger';

export function getCardIcon(cardKey: string) {
  switch (cardKey.toLowerCase()) {
    case 'mastercard': {
      return 'assets/brands/MasterCard-Card-logos75x56-MC.png';
    }
    case 'visa': {
      return 'assets/brands/Visa-Card-logos75x56-Visa.png';
    }
    case 'american express': {
      return 'assets/brands/American_Express-Card-logos75x56-Amex.png';
    }
  }
}

export interface PaymentCard {
  token: string;
  cardType: string;
  cardNumber: string;
  cardHolderName: string;
  cardExpiry: string;
  clientReference?: string;
  isRestrictedCard?: string;
  customerFee?: string;
  merchantFee?: string;
  processingAmount?: string;
  paymentAmount?: string;
  callbackStatus: string;
  accountPaymentId: number;
  status: string;
  nickname: string;
}

export interface PaymentCardState {
  card: PaymentCard;
  loading: boolean;
  loaded: boolean;
  saving: boolean;
  saved: boolean;
  error: any;
}

export const paymentCardInitialState = {
  card: null,
  loading: false,
  loaded: false,
  saving: false,
  saved: false,
  error: null,
};

export interface SelectablePaymentMethod {
  /**
   * Whether the card is currently the user's default payment method, only applicable to cards or bank accounts (not implemented yet).
   */
  isDefault: boolean;
  /**
   * Whether the user has currently selected this payment method during checkout. Initially `true` if `isDefault` is `true`.
   */
  isSelected: boolean;
  /**
   * Type of the payment method.
   */
  type: 'card' | 'account';
  /**
   * Only applicable if `type`=`'card'`, the last 4 card digits to be displayed to the user.
   */
  cardLast4?: string;
  /**
   * Only applicable if `type`=`'card'`, the brand of card i.e. mastercard, visa, etc.
   */
  cardType?: string;
  /**
   * Only applicable if `type`=`'card'`, the `accountPaymentId` according to the PaymentCard interface
   */
  cardId?: number;
  /**
   * Whether the user can use this payment method.
   */
  disabled?: boolean;
  /**
   * Informative text to the user about why they can't use this payment method.
   */
  disabledReason?: string;
  /**
   * Optional text that appears alongside the disabled reason that can inform the user of a solution for the payment method disability.
   */
  disabledLinkText?: string;
  /**
   * The link that directs the user to the solution to using their payment method.
   */
  disabledLinkHref?: string;
  /**
   * The nickname of the payment method given by the user.
   */
  nickname?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentCardService {
  constructor(
    private api: ApiService,
    private rewardService: RewardService,
    private logger: NGXLogger,
  ) {}

  get() {
    return this.api.getPaymentCard().pipe(
      map((response) => {
        if (!response.success) {
          throw Error('Error fetching payment card');
        }

        return response;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  save(paymentCard: PaymentCard) {
    return this.api.addPaymentCard(paymentCard).pipe(
      mergeMap((response) => this.rewardService.getRewards().pipe(map(() => response))),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  removePaymentCard(card: PaymentCard) {
    return this.api.removeCard(card).pipe(
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.logger.error(error);
        throw error;
      }),
    );
  }

  updatePaymentCard(card: PaymentCard) {
    return this.api.setDefaultCard(card).pipe(
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.logger.error(error);
        throw error;
      }),
    );
  }

  replacePaymentCard(newPaymentCard: PaymentCard, oldAccountPaymentId: number) {
    return this.api.replacePaymentCard(newPaymentCard, oldAccountPaymentId).pipe(
      mergeMap((response) => this.rewardService.getRewards().pipe(map(() => response))),
      catchError((error: HttpErrorResponse) => this.handleError(error)),
    );
  }

  updateCardNickname(accountPaymentId: number, nickname: string) {
    return this.api.setCardNickname(accountPaymentId, nickname).pipe(
      map((response) => {
        return [response];
      }),
      catchError((error: HttpErrorResponse) => {
        this.logger.error(error);
        throw error;
      }),
    );
  }

  /**
   * Links a user's bills to a specified payment method. When this bill is being
   * paid by schedule, the specified payment method will be used instead of the
   * user's default account card.
   * @param billPaymentId Bill payment ID of the bill to be linked.
   * @param accountPaymentId The account payment ID of the payment method to be
   * linked.
   */
  linkBillsToPaymentMethod(billPaymentIds: number[], accountPaymentId: number) {
    return this.api.linkBills(billPaymentIds, accountPaymentId);
  }

  /**
   * Unlinks a bill from a payment method. The payment method used for that bill
   * would then be the user's default.
   * @param billPaymentId The BP ID of the bill to be unlinked.
   * @param accountPaymentId The payment ID of the account from which the bill will
   * be unlinked.
   */
  unlinkBillFromPaymentMethod(billPaymentId: number) {
    return this.api.unlinkBill(billPaymentId);
  }
  private handleError(err: any): never {
    console.error('Server error: ', err);
    throw err;
  }
}
