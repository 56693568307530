/*
// v1 deprecated
export const createSchema = `

CREATE TABLE IF NOT EXISTS app_logs (
    id INTEGER PRIMARY KEY NOT NULL,
    level INTEGER NOT NULL,
    timestamp DATETIME NOT NULL,
    fileName TEXT,
    lineNumber INTEGER,
    message TEXT,
    additional TEXT
);

CREATE TABLE IF NOT EXISTS push_logs (
    id TEXT PRIMARY KEY NOT NULL,
    action TEXT,
    title TEXT,
    body TEXT,
    data TEXT,
    timestamp DATETIME NOT NULL
);

`;
*/
export const createSchema = `
DROP TABLE IF EXISTS app_logs;
DROP TABLE IF EXISTS push_logs;

CREATE TABLE IF NOT EXISTS app_logs_v2 (
    id INTEGER PRIMARY KEY NOT NULL,
    level INTEGER NOT NULL,
    fileName TEXT,
    lineNumber INTEGER,
    message TEXT,
    additional TEXT,
    timestamp DATETIME NOT NULL,
    user TEXT
);

CREATE TABLE IF NOT EXISTS push_logs_v2 (
    id TEXT PRIMARY KEY NOT NULL,
    action TEXT,
    title TEXT,
    body TEXT,
    data TEXT,
    timestamp DATETIME NOT NULL,
    user TEXT
);

`;