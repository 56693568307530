const GENERAL_ERROR_CODES = {
  // 1XX
};
const DASHBOARD_ERROR_CODES = {
  // 2XX
};
const HISTORY_ERROR_CODES = {
  // 3XX
};
const ADD_BILL_ERROR_CODES = {
  // 4XX
  ADD_BILL__UPLOAD_ERROR: '400',
  ADD_BILL__SEND_TO_QUEUE_ERROR: '401',
  ADD_BILL__FETCH_ERROR: '402',
  ADD_BILL__GET_SIGNED_URL_ERROR: '403',
  ADD_BILL__GET_BARCODE_ERROR: '500'
};
const BILL_DETAILS_ERROR_CODES = {
  // 4XX
};
export const ERROR_CODES = {
  ...GENERAL_ERROR_CODES,
  ...DASHBOARD_ERROR_CODES,
  ...HISTORY_ERROR_CODES,
  ...ADD_BILL_ERROR_CODES,
  ...BILL_DETAILS_ERROR_CODES
};
