import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { App, AppInfo } from '@capacitor/app';
import { HttpEvent, HttpHandler, HttpRequest, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { version } from '../../../../package.json';
import { InterceptorSkipHeader } from './enum';
import { environment } from '@1bill-app/env';

@Injectable({
  providedIn: 'root',
})
export class CustomHeaderInterceptor implements HttpInterceptor {
  constructor(private platform: Platform) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(InterceptorSkipHeader.CustomHeader)) {
      req = req.clone({ headers: req.headers.delete(InterceptorSkipHeader.CustomHeader) });
      return next.handle(req);
    }
    const getAppInfo = () => Capacitor.isNativePlatform() ? App.getInfo() : Promise.resolve(null as AppInfo);
    return from(Promise.all([Device.getInfo(), getAppInfo()])).pipe(
      switchMap(([deviceInfo, appInfo]) => {
        const modifiedReq = req.clone({
          headers: req.headers
            .set('X-Client-Info', this.getClientData(deviceInfo, appInfo))
            .set('X-Version', 'v2'),
        });
        return next.handle(modifiedReq);
      }),
    );
  }
  getClientData(deviceInfo: DeviceInfo, appInfo: AppInfo) {
    return encodeURIComponent(
      JSON.stringify({
        platform: deviceInfo.platform,
        os: deviceInfo.operatingSystem,
        appVer: Capacitor.isNativePlatform() ? `${appInfo.version}(${appInfo.build})` : version,
        ver: environment.ver.raw,
      }),
    );
  }
}
