import { Injectable } from '@angular/core';
import {
  BillCategoryKey,
  appProductTypes,
  AppProductType,
  ViewBillType,
  appProductTypeDefaultImg,
} from '../bill/types';
@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor() {}

  getCategoryDetails(categoryKey: BillCategoryKey) {
    try {
      return appProductTypes.find((val) => val.key === categoryKey);
    } catch (e) {
      return null;
    }
  }

  getCardIconUrl(bill: ViewBillType) {
    return (
      bill.supplierLogoUrl || this.getCategoryIconUrl(bill.categoryKey)
    );
  }

  getCategoryIconUrl(categoryKey: string) {
    return appProductTypes.find((p) => p.key === categoryKey)?.imgUrl || appProductTypeDefaultImg
  }

  categoryTitle(catId: AppProductType['key']) {
    return appProductTypes.find((val) => val.key === catId)?.title;
  }
}
