import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamelCasePipe } from './camel-case.pipe';
import { CurrencyCrSignPipe } from './currency-cr-sign.pipe';
import { CurrencyDrSignPipe } from './currency-dr-sign.pipe';
import { KebabCasePipe } from './kebab-case.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { SortByPipe } from './sort-by.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { NumberFormatPipe } from './number-format.pipe';
import { SortSuppliers } from './sort-suppliers.pipe';
@NgModule({
  declarations: [
    CamelCasePipe,
    KebabCasePipe,
    CurrencyCrSignPipe,
    CurrencyDrSignPipe,
    SortByPipe,
    SafeUrlPipe,
    DateAgoPipe,
    NumberFormatPipe,
    SortSuppliers,
  ],
  exports: [
    CamelCasePipe,
    KebabCasePipe,
    CurrencyCrSignPipe,
    CurrencyDrSignPipe,
    SortByPipe,
    SafeUrlPipe,
    DateAgoPipe,
    NumberFormatPipe,
    SortSuppliers,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
