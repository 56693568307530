import { getDayCount } from "@1bill-app/helpers/date.helpers";
import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { BetterDealState, BetterDealStore } from "./better-deal.store";

@Injectable({ providedIn : 'root'})
export class BetterDealQuery extends Query<BetterDealState> {

    isLoading$ = this.selectLoading();
    bill$ = this.select((state) => state.bill);    
    productPayload$ = this.select((state) => state.productPayload);
    products$ = this.select((state) => state.products);
    product = this.select('product');
    
    titleTypes$ = this.select((state) => state.titleTypes);
    streetTypes$ = this.select((state) => state.streetTypes);
    concessionTypes$ = this.select((state) => state.concessionTypes);
    lifeSupportEquipmentTypes$ = this.select((state) => state.lifeSupportEquipmentTypes);
    
    constructor(protected store: BetterDealStore){
        super(store);
    }

    getFullAddressText(){
        let addressFormatted = '';
        if(this.getValue().subpremise != null){
            addressFormatted += this.getValue().subpremise + ' ';
        }
        addressFormatted += this.getValue().streetNo + ' ';
        addressFormatted += this.getValue().streetName + ' ';
        addressFormatted += this.getValue().streetType + ', ';
        addressFormatted += this.getValue().suburb + ' ';
        addressFormatted += this.getValue().state + ' ';
        addressFormatted += this.getValue().postcode;  
        return addressFormatted;
    }

    getBillPeriod(){
        if(this.bill$ != null)
            return (this.getValue().bill?.billDays ?? getDayCount(this.getValue().bill?.billStartDate, this.getValue().bill?.billEndDate) + 1);
        return;
    }

}