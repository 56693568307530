import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RedeemCardPopoverComponent } from '@1bill-app/components/popover/redeem-card-popover/redeem-card-popover.component';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-promo-code-card',
  templateUrl: './promo-code-card.component.html',
  styleUrls: ['./promo-code-card.component.scss'],
})
export class PromoCodeCardComponent {
  @Input() pointsAmount: number;
  @Input() dollarAmount: number;
  @Output() confirmRedeem: EventEmitter<number> = new EventEmitter();

  constructor(private popoverController: PopoverController) {}

  startRedeemPromoCode() {
    if (!this.pointsAmount || !this.dollarAmount) return;
    this.popoverController.create({
      component: RedeemCardPopoverComponent,
      cssClass: 'redeem-points-popover',
      componentProps: {
         points: this.pointsAmount,
      },
    }).then((popover) => {
      popover.present();
      popover.onWillDismiss().then((dismissResult) => {
        if (dismissResult.role === 'confirm') {
          this.confirmRedeem.emit(this.pointsAmount);
        }
      })
    });
  }
}
