import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popover-failed',
  template:`
  <ion-list>
    <ion-item lines="none" detail="false">
      Some of the details on your bill need to be verified. An operator is checking this, and the
      updated/confirmed details should be available shortly.
    </ion-item>
  </ion-list>
  `,
  styleUrls: ['./popover-failed.component.scss'],
})
export class PopoverFailedComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
