import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  simpleLoading: HTMLIonLoadingElement;
  constructor(private loadingCtrl: LoadingController, private logger: NGXLogger) {}

  async presentLoading(opts?: LoadingOptions) {
    this.simpleLoading = await this.loadingCtrl.create({
      cssClass: 'custom-loading-service',
      message: 'Please wait...',
      ...opts
    });
    await this.simpleLoading.present();
    return this.simpleLoading;
  }
  async dismissLoading() {
    this.simpleLoading && (await this.simpleLoading.dismiss());
    this.logger.debug('Loading dismissed!');
    return true;
  }
}
