import { PASSWORD_LENGTH } from "../pages/auth/auth-constants";

export interface ValidationMsgIF {
  [name: string]: { type: string; message: string }[];
}
export const getValidationMsgs = () => {
  return {
    username: [
      { type: 'required', message: 'Username is required.' },
      { type: 'minlength', message: 'Username must be at least 5 characters long.' },
      { type: 'maxlength', message: 'Username cannot be more than 25 characters long.' },
      { type: 'pattern', message: 'Your username must contain only numbers and letters.' },
      { type: 'validUsername', message: 'Your username has already been taken.' },
    ],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email.' },
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
      { type: 'minlength', message: `Password must be at least ${PASSWORD_LENGTH} characters long.` },
      {
        type: 'pattern',
        message:
          'Your password must contain at least one upper case, one lower case letter and one number.',
      },
    ],
    matchingPasswords: [
      { type: 'areEqual', message: 'Password not equal.' },
    ],
    confirmPassword: [
      { type: 'required', message: 'Confirm password is required.' },
    ],
    verificationCode: [
      { type: 'required', message: 'Verification code is required.' },
      { type: 'minlength', message: 'Verification code is at least 6 characters.' },
    ],
    firstName: [
      { type: 'required', message: 'First name is required.' },
    ],
    lastName: [
      { type: 'required', message: 'Last name is required.' },
    ],
    mobileNumber: [
      { type: 'required', message: 'Mobile number is required.' },
      { type: 'validCountryPhone', message: 'Mobile number is invalid.' },
    ],
    oldPwd: [
      { type: 'required', message: 'Old password is required.' },
    ],
    newPwd: [
      { type: 'minlength', message: 'Password must be at least 5 characters long.' },
      { type: 'pattern', message: 'Your password must contain at least one upper case, one lower case letter and one number. It must also contain characters and numbers only.' },
      { type: 'required', message: 'New password is required.' },
    ],
    newPwdConfirm: [
      { type: 'minlength', message: 'Password must be at least 5 characters long.' },
      { type: 'pattern', message: 'Your password must contain at least one upper case, one lower case letter and one number. It must also contain characters and numbers only.' },
      { type: 'required', message: 'Confirm new password is required.' },
      { type: 'passwordMismatch', message: 'Passwords not matching.' },
    ],
  } as ValidationMsgIF;
};
