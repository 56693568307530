import { Pipe, PipeTransform } from '@angular/core';
import { invalidPipeArgumentError } from './invalid_pipe_argument_error';

/**
 * Transforms text to all camel case.
 */
@Pipe({
  name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {
  /**
   * @param value The string to transform to camelCase
   * @param separator The char to split the words for camelCase. If no separator provided, by
   * defualt space will be used to split the words.
   *
   */
  transform(value: string, separator?: string): string {
    if (!value) return value;
    if (typeof value !== 'string') {
      throw invalidPipeArgumentError(CamelCasePipe, value);
    }
    const words = value.split(separator ? separator : ' ');
    const camelCaseWords = words.map((word, index) => {
      return index === 0
        ? word.toLowerCase()
        : word[0].toUpperCase() + word.substr(1).toLowerCase();
    });
    return camelCaseWords.join('');
  }
}
