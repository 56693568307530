import { TransactionItem } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, OnInit, Input } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-recent-transaction-item',
  templateUrl: './recent-transaction-item.component.html',
  styleUrls: ['./recent-transaction-item.component.scss'],
})
export class RecentTransactionItemComponent implements OnInit {
  constructor() {}

  @Input('item') recentTransactionItem: TransactionItem;
  amountString: string;
  monthString: string;
  dayString: string;

  ngOnInit() {
    this.dayString = this.recentTransactionItem.date.match(/\d\d$/)[0];
    this.monthString = dayjs(this.recentTransactionItem.date).format('MMM').toUpperCase();
  }
}
