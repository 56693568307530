import { Component, Input, Output, OnInit, HostListener, EventEmitter } from '@angular/core';
import { SelectablePaymentMethod } from '@1bill-app/services/payment/payment-card.service';
import { ModalController, AlertController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { Router } from '@angular/router';
import { BILL_FEE, ROUTE_PAYMENT_OPTIONS } from 'src/app/app.constants';
import { Browser } from '@capacitor/browser';
import { getCardIcon } from '@1bill-app/services/payment/payment-card.service';



@Component({
  selector: 'app-select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss'],
})
export class SelectPaymentMethodComponent implements OnInit {
  constructor(
    private alertController: AlertController,
    private modalController: ModalController,
    private logger: NGXLogger,
    private router: Router,
  ) {}
  @Input() availableMethods: SelectablePaymentMethod[];
  @Input() billAmount: number;
  @Output() onSelectedPaymentMethod: EventEmitter<SelectablePaymentMethod> = new EventEmitter(null);

  /**
   * For use in template.
   */
  getCardIcon = getCardIcon;

  get fee() {
    return this.billAmount * BILL_FEE;
  }

  get totalAmount() {
    return this.billAmount + this.fee;
  }

  ngOnInit() {}

  onSelectMethod(method: SelectablePaymentMethod) {
    const previousSelection = this.availableMethods.find((paymentMethod) => paymentMethod.isSelected);
    if (previousSelection) previousSelection.isSelected = false;
    method.isSelected = true;
    this.onSelectedPaymentMethod.emit(method);
  }

  onPayButtonClicked() {
    this.dismiss(true);
  }

  routeToAddPayment() {
    this.alertController.create({
      header: 'Before you do this...',
      message: 'You will be taken to the payment information page for this action. You will lose the progress you have made for bill selection and checkout. Are you sure this is what you want?',
      buttons: [ 
        {
          text: 'No, stay here',
          role: 'secondary',
          handler: () => {
          }
        },
        {
          text: 'Yes, add a payment method',
          cssClass: 'alert-accept',
          handler: () => {
            this.dismiss();
            this.router.navigateByUrl(ROUTE_PAYMENT_OPTIONS);
          },
        },
      ],
    }).then((alertResult) => {
      alertResult.present();
    });
  }

  routeToDisabledLink(link: string) {
    this.dismiss();
    this.router.navigateByUrl(link);
  }

  dismiss(confirmed?: boolean) {
    this.modalController.dismiss(
      null,
      confirmed ? 'confirm' : 'cancel',
    );
  }
}
