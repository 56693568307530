import { ViewBillType } from '@1bill-app/services/bill/types';
import { NotificationItem } from '@1bill-app/services/notification/notification.type';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-notification-overdue',
  templateUrl: './notification-overdue.component.html',
  styleUrls: ['./notification-overdue.component.scss'],
})
export class NotificationOverdueComponent {
  @Output() dismissClicked: EventEmitter<string> = new EventEmitter();
  @Input() notificationItems: { notificationItem: NotificationItem, bill: ViewBillType }[];
  @Input() isLoading: boolean;
  subs = new SubSink();

  constructor() { }

  dismissNotification(id: string) {
    this.dismissClicked.emit(id);
  }

  trackByFn(item: NotificationItem) {
    return item.id;
  }
}
