import {
  AccountHistoricalBalance,
  YodleeAccount,
} from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
@Component({
  selector: 'app-balance-activity-item',
  templateUrl: './balance-activity-item.component.html',
  styleUrls: ['./balance-activity-item.component.scss'],
})
export class BalanceActivityItemComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private yodleeQuery: YodleeQuery,
  ) {}

  @Input() data: AccountHistoricalBalance;
  @Input() previousData: AccountHistoricalBalance;
  @Input() last: boolean;
  
  balanceTitle: string;
  balanceAdjustment: number;
  balAdjustmentTextColor: string;
  colorGreen: string = '#1da061';
  colorRed: string = '#eb7a7a';
  balAdjustmentSign: string;
  day: any;
  month: any;
  year: string;
  id: number;
  currentBalanceAmount: number;
  accountHistoricalBalances: AccountHistoricalBalance[];
  yodleeService: any;
  accountDetails$ = this.yodleeQuery.accountDetails$;
  accountDetails: YodleeAccount;
  date = dayjs().format('YYYY-MM-DD');
  interval: 'M' | 'W' | 'Y' = 'M';

  subs = new SubSink();

  public accordionState: 'expand' | 'collapse' = 'collapse';
  public accordionToggleText = this.accordionState === 'expand' ? 'View Less' : 'View All';

  get hasAnyTransactionData() {
    return this.data.transactionData.length;
  }

  ngOnInit() {
    let date = dayjs(this.data.asOfDate).format('MMM');
    this.day = dayjs(this.data.asOfDate).format('DD');
    this.month = dayjs(this.data.asOfDate).format('MMM').toUpperCase();
    this.year = dayjs(this.data.asOfDate).format('YYYY');

    this.currentBalanceAmount = this.data.balance.amount;

    if (this.last) {
      this.balanceTitle = 'Earliest data';
      //this.balanceAdjustment = '';
    } else if (this.data.isAsset) {
      //If Asset
      if (this.data?.balance.amount > this.previousData?.balance.amount) {
        this.balanceTitle = 'Balance Increase';
        this.balAdjustmentSign = '+';

        this.balanceAdjustment =
          this.data?.balance.amount - this.previousData?.balance.amount;

        this.balAdjustmentTextColor = '#1da061';
      } else if (
        this.data?.balance.amount < this.previousData?.balance.amount
      ) {
        this.balanceTitle = 'Balance Decrease';
        this.balAdjustmentSign = '-';

        this.balanceAdjustment =
          this.previousData?.balance.amount - this.data?.balance.amount;

        this.balAdjustmentTextColor = '#eb7a7a';
      }
    } else {
      //If Liability
      if (this.data?.balance.amount > this.previousData?.balance.amount) {
        this.balanceTitle = 'Balance Increase';
        this.balAdjustmentSign = '+';

        this.balanceAdjustment =
          this.data?.balance.amount - this.previousData?.balance.amount;

        this.balAdjustmentTextColor = '#eb7a7a';
      } else if (
        this.data?.balance.amount < this.previousData?.balance.amount
      ) {
        this.balanceTitle = 'Balance Decrease';
        this.balAdjustmentSign = '-';

        this.balanceAdjustment =
          this.previousData?.balance.amount - this.data?.balance.amount;
        this.balAdjustmentTextColor = '#1da061';
      }
    }
  }

  toggleAccordionState() {
    if (!this.hasAnyTransactionData) return;
    this.accordionState = this.accordionState === 'expand' ? 'collapse' : 'expand';
    this.accordionToggleText = this.accordionState === 'expand' ? 'View Less' : 'View All';
  }

  getChangeSign(categoryType: string) {
    if (categoryType === 'EXPENSE') return '-';
    return '+';
  }
}
