import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController, PopoverController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { SelectablePaymentMethod } from '@1bill-app/services/payment/payment-card.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { Observable } from 'rxjs';
import { sum } from 'lodash';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { GetBillOption } from '@1bill-app/services/bill/types';
import {
  BILL_FEE,
  PAYMENT_COMBINED_MODAL_ID,
} from '@1bill-app/constants';
import _ from 'lodash';
import { UIService } from '@1bill-app/services/ui.service';
import { PopoverSimpleComponent } from '../popover/popover-simple/popover-simple.component';

enum Stage {
  INITIAL,
  CHECKOUT_SINGLE,
  SELECT_BUNDLE,
  CHECKOUT_BUNDLE,
  SELECT_PAYMENT_METHOD,
}

@Component({
  selector: 'app-payment-combined-modal',
  templateUrl: './payment-combined-modal.component.html',
  styleUrls: ['./payment-combined-modal.component.scss'],
})
export class PaymentCombinedModalComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private modalController: ModalController,
    private settingsQuery: SettingsQuery,
    private billQuery: BillQuery,
    private uiService: UIService,
  ) {}

  /**
   * The bill that the user was viewing when they clicked "Pay now" to open
   * the payment modal component.
   */
  @Input() initialBill: ViewBillType;
  @ViewChild('slider') slider: IonSlides;
  availableMethods: SelectablePaymentMethod[];
  userDefaultCardId: number;
  selectedMethod: SelectablePaymentMethod;
  cardIsDefault: boolean = true;
  allBills: ViewBillType[] = [];
  billAmount$: Observable<number>;
  navigationReady: boolean = false;
  private _selectedBills: ViewBillType[] = [];

  /**
   * Zenith support status
   *
   * - 0 = Zenith is supported for the bill(s)
   * - 1 = Zenith is not supported because BPAY Reference Number or Biller Code is missing
   * - 2 = Missing ZenithPay credentials (`paymentReady` = `0`)
   */
  zenithSupport: 0 | 1 | 2;

  totalPayAmount: number;
  initialModalHeight: number;
  completeAccountDetails: boolean;
  /**
   * True if the user is making a bundled payment, i.e. on the 'Select bundled bills' or
   * 'Bill bundle checkout' page. If true, expect and handle multiple selectedBills.
   */
  bundling: boolean = false;
  /**
   * Prevent updatePaymentStatus() from automatically updating the payment method when being run.
   * updatePaymentStatus() is run each time the stage is changed, and moving from the
   * SelectPaymentMethod screen back to the checkout screen would reset the payment method.
   */
  preventDefaultUpdateMethod: boolean = false;

  /**
   * For use in the template.
   */
  public stages = Stage;
  _stage = Stage.INITIAL;
  modalTitle = 'Pay bill';

  /**
   * If true, any card payment methods will be automatically selected over other payment
   * methods if possible to pay via ZenithPay (behaviour defined in updatePaymentStatus())
   */
  alwaysAutoSelectCard = true;

  get selectedBills(): ViewBillType[] {
    return this._selectedBills;
  }

  set selectedBills(bills: ViewBillType[]) {
    this._selectedBills = bills;
    this.totalPayAmount = _.sum(bills.map((bill) => bill.amount)) * (1 + BILL_FEE);
    // this.updatePaymentStatus();
  }
;
  ngOnInit() {
    // Zenith supported for first bill
    this.zenithSupport = this.initialBill.paymentReady === 1 ? 0 : 2;
    this.settingsQuery
      .select([
        'accountCards',
        'defaultAccountCardId',
        'firstName',
        'lastName',
        'mobileNumber',
        'dateOfBirth',
        'address',
      ])
      .subscribe((joinResult) => {
        try {
          this.userDefaultCardId = joinResult.defaultAccountCardId;
          this.availableMethods = joinResult.accountCards
            .map(
              (accountCard, index) =>
                ({
                  cardId: accountCard.accountPaymentId,
                  cardLast4: accountCard.cardNumber.match(/\d\d\d\d/g).pop(),
                  type: 'card',
                  cardType: accountCard.cardType,
                  isDefault: joinResult.defaultAccountCardId === accountCard.accountPaymentId,
                  nickname: accountCard.nickname,

                  // isSelected will initially be default if there is one, if not, the first payment card attached to their account if any
                  isSelected:
                    this.zenithSupport === 0 &&
                    (joinResult.defaultAccountCardId === accountCard.accountPaymentId ||
                      (!joinResult.defaultAccountCardId && index === 0)),
                } as SelectablePaymentMethod),
            )
            .sort((a) => {
              return a.isDefault ? -1 : 1;
            });

          this.completeAccountDetails =
            !!joinResult.firstName &&
            !!joinResult.lastName &&
            !!joinResult.mobileNumber &&
            !!joinResult.dateOfBirth &&
            !!joinResult.address;

          this.logger.log(
            'PaymentCombinedModalComponent::onInit subscription payment methods:',
            this.availableMethods,
          );
          this.navigationReady = true;
          this.selectedMethod = this.availableMethods.find(
            (availableMethod) => availableMethod.isSelected,
          );
        } catch (err) {
          console.error('Error getting account cards and card default:', err);
        }
      });

    this.billAmount$ = new Observable<number>((subscriber) => {
      subscriber.next(
        !!this.selectedBills
          ? sum(this.selectedBills.map((selectedBill) => selectedBill.amount))
          : this.initialBill.amount,
      );
    });

    // Locate bills from home service
    this.billQuery
      .getHomeUpcomingBillsNoFilter(GetBillOption.UNPAID_BILLS)
      .subscribe((billsResult) => {
        // Bills that are pending should not be selectable
        this.allBills = billsResult.filter((bill) => bill.paymentPending !== 1);
      });

    this.selectedBills = [this.initialBill];
  }

  ngAfterViewInit() {
    this.slider.lockSwipes(true);
  }

  onPayBillModalAction(actionIdentifier: number) {
    // Default case
    if (actionIdentifier === undefined) return;
    switch (actionIdentifier) {
      case 1:
        {
          // Selected 'Pay now' button without bundle
          this.updateStage(Stage.CHECKOUT_SINGLE);
        }
        break;
      case 2:
        {
          // Selected 'Start bundle payment'
          this.updateStage(Stage.SELECT_BUNDLE);
        }
        break;
      default: {
        this.logger.error(
          'Unhandled action identifier on PayBillModalAction:',
          actionIdentifier,
        );
      }
    }
    this.modalTitle = this.getModalTitleByStage();
  }

  /**
   * Fired when the user is on the select 2 or more bills page, and continues to checkout
   * @param selectedBills The bills that the user has selected
   */
  checkoutBundleAction(act: boolean) {
    if (act) {
      this.updatePaymentStatus();
      this.updateStage(Stage.CHECKOUT_BUNDLE);
      this.modalTitle = this.getModalTitleByStage();
    }
  }

  /**
   * Fired when the user selects or deselects a bill on the select bill bundle page.
   * @param selectedBills The bills that have been selected.
   */
  selectBundleAction(selectedBills: ViewBillType[]) {
    this.selectedBills = selectedBills;
  }

  /**
   * Fired when the user is on the bill bundle checkout page, and removes a bill
   * @param billId The ID of the bill being removed
   */
  onBundleCheckoutRemoveAction(billId: number) {
    // -1 representing the 'Remove all' button on the BillBundleCheckout
    if (billId === -1) {
      this.selectedBills = [];
      this.onBackClicked(this._stage);
      return;
    }
    this.selectedBills = this.selectedBills.filter(
      (selectedBill) => selectedBill.accountBillTypeId !== billId,
    );
    this.updateSumAmount();
    this.updatePaymentStatus();

    // // Enable ZenithPay if the removed bill was the only non-Zenith-supported bill
    // if (this.selectedMethod.type === 'card' && !this.selectedBills.some(selectedBill => !selectedBill.paymentReady)) {
    //   this.selectedMethod.disabled = false;
    // }
  }

  /**
   * Update Zenith Payment support status, and update the relevant payment methods accordingly.
   */
  private updatePaymentStatus() {
    if (this.bundling) {
      this.zenithSupport = this.selectedBills.some(
        // The 'paymentReady' property may be `null` instead of 0 if not available
        (selectedBill) => !selectedBill.paymentReady,
      )
        ? 2
        : 0;
    } else {
      this.zenithSupport = this.initialBill.paymentReady === 1 ? 0 : 1;
    }

    /**
     * Previously used to check if the payment exceeded LatitudePay limits.
     * If there are any other limits in payment technology, this constant can be used.
     */
    const limitsExceeded = false;

    if (!this.preventDefaultUpdateMethod) {
      const userHasAnyCards = !!this.availableMethods.filter(
        (availableMethod) => availableMethod.type === 'card',
      ).length;

      // Currently, cards are the only available payment method.
      if (!userHasAnyCards) {
        this.uiService.presentActionDeniedModal('No payment methods', 'You must have a payment method set in order to pay a bill.', 'Set a payment method', '/payment-options');
        this.modalController.dismiss();
        return;
      }

      // Set all card payment methods' disabled status according to the Zenith status
      this.availableMethods
        .filter((availableMethod) => availableMethod.type === 'card')
        .forEach((availableMethod) => {
          availableMethod.disabled = this.zenithSupport === 1 || this.zenithSupport === 2;
          availableMethod.disabledReason =
            this.zenithSupport === 1
              ? 'This bill cannot be paid because it is missing a BPAY Reference Number or Biller Code.'
              : this.zenithSupport === 2
              ? 'Sorry, this bill is not payable via 1Bill.'
              : undefined;
        });

      if (this.zenithSupport === 0 && userHasAnyCards && this.alwaysAutoSelectCard) {
        this.selectedMethod.isSelected = false;

        // Select the linked payment method ONLY if ALL selected bills have the payment
        // method as their default.
        const lastPaymentMethodId = this.selectedBills[0]?.linkedPaymentMethodId;

        /**
         * `true` if every selected bill has the same linked payment method.
         */
        const allSameLinkedMethods = this.selectedBills
          .slice(1)
          .every((selectedBill) => selectedBill.linkedPaymentMethodId === lastPaymentMethodId);
          
        if (allSameLinkedMethods) {
          this.selectedMethod =
            this.availableMethods.find(
              (availableMethod) => availableMethod.cardId === lastPaymentMethodId,
            ) ?? this.availableMethods.find((availableMethod) => availableMethod.isDefault);
        } else {
          // ~~ Set default payment card as the new default method if one is available
          this.selectedMethod.isSelected = false;
          this.selectedMethod = this.availableMethods.find(
            (availableMethod) => availableMethod.cardId === this.userDefaultCardId,
          );
        }

        if (!this.selectedMethod) {
          // Else, set selected card to the first card method if available
          const findCardMethod = this.availableMethods.find(
            (availableMethod) => availableMethod.type === 'card',
          );
          if (findCardMethod) {
            this.selectedMethod = findCardMethod;
          }
        }
      }
      this.selectedMethod.isSelected = true;
    }
  }

  private updateSumAmount(newSelectedBills: ViewBillType[] = this.selectedBills) {
    this.totalPayAmount = sum(newSelectedBills.map((b) => b.amount)) * (1 + BILL_FEE);
    return this.totalPayAmount;
  }

  onGotoSelectPayment(act: boolean) {
    if (act) {
      this.updateStage(Stage.SELECT_PAYMENT_METHOD);
      this.modalTitle = this.getModalTitleByStage();
    }
  }

  updateStage(to: Stage) {
    const noBundlingStages = [Stage.CHECKOUT_SINGLE];
    const bundlingStages = [Stage.SELECT_BUNDLE, Stage.CHECKOUT_BUNDLE];
    // Some components, like SelectPaymentMethodComponent, can appear
    //   in situations where bundling is true and others where it is false.
    if (noBundlingStages.includes(to)) {
      this.selectedBills = [this.initialBill];
      this.updatePaymentStatus();
      this.bundling = false;
    } else if (bundlingStages.includes(to)) {
      this.bundling = true;
    }

    if (to === Stage.SELECT_PAYMENT_METHOD) {
      // Do not auto-select if going to payment method select screen; it should remain
      // the same as whatever is selected
      this.alwaysAutoSelectCard = false;
    }

    // Slider is only used for animation; lock swipes from user movement
    this.slider.lockSwipes(false);
    this.slider.slideTo(to);
    this.slider.lockSwipes(true);

    /* Zenith status may change on moving screens, e.g. selecting multiple bills, with some
    // non-Zenith supported, and going back to single-checkout a bill that has Zenith support.
    // updateZenithStatus does a number of things so it's good to have it occur during the
    // asynchronous animation. */
    // this.updatePaymentStatus();

    this.preventDefaultUpdateMethod = false;
    this.alwaysAutoSelectCard = true;

    // Stage update needs to be performed asynchronously using setTimeout
    setTimeout(() => {
      this._stage = to;
      this.modalTitle = this.getModalTitleByStage();
    }, 0);
  }

  private getModalTitleByStage() {
    switch (this._stage) {
      case Stage.INITIAL: {
        return 'Pay bill';
      }
      case Stage.SELECT_BUNDLE: {
        return 'Select 2 or more bills to bundle';
      }
      case Stage.CHECKOUT_SINGLE: {
        return 'Checkout';
      }
      case Stage.CHECKOUT_BUNDLE: {
        return 'Checkout bundled bills';
      }
      case Stage.SELECT_PAYMENT_METHOD: {
        return 'Select payment method';
      }
      default: {
        this.logger.error('No modal title found for stage:', this._stage);
        return 'PaymentCombinedModalComponent::getModalTitle() - no title found.';
      }
    }
  }

  onBackClicked(fromStage: Stage) {
    switch (fromStage) {
      case Stage.SELECT_BUNDLE:
        {
          this.updateStage(Stage.INITIAL);
        }
        break;
      case Stage.CHECKOUT_SINGLE:
        {
          this.updateStage(Stage.INITIAL);
        }
        break;
      case Stage.CHECKOUT_BUNDLE:
        {
          this.updateStage(Stage.SELECT_BUNDLE);
        }
        break;
      case Stage.SELECT_PAYMENT_METHOD:
        {
          this.updateStage(this.bundling ? Stage.CHECKOUT_BUNDLE : Stage.CHECKOUT_SINGLE);
        }
        break;
      default: {
        this.logger.error(
          'PaymentCombinedModalComponent.onBackClicked() - unhandled stage:',
          fromStage,
        );
      }
    }
  }

  updateSelectedPaymentMethod(newMethod: SelectablePaymentMethod, fromStage: Stage) {
    this.preventDefaultUpdateMethod = true;
    this.selectedMethod = newMethod;
    this.selectedMethod.isSelected = true;
    this.selectedMethod.isDefault =
      !!newMethod.cardId && newMethod.cardId === this.userDefaultCardId;
    this.onBackClicked(fromStage);
  }

  dismiss(confirmed = false) {
    this.modalController.dismiss(
      null,
      confirmed ? 'confirm' : 'cancel',
      PAYMENT_COMBINED_MODAL_ID,
    );
  }
}
