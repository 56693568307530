import { canSwitch } from '@1bill-app/services/bill/helper';
import { BillCategoryKey, BillRating, ViewBillType } from '@1bill-app/services/bill/types';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bill-savings',
  styleUrls: ['./bill-savings.component.scss'],
  templateUrl: './bill-savings.component.html',
})
export class BillSavingsComponent {
  @Input() bill: ViewBillType;

  readonly perfectRating = BillRating.Perfect;

  get billDescription() {
    switch (this.bill.rating) {
      case BillRating.Bad:
        return "the plan you're on is more than 10% more expensive than what you could be paying";
      case BillRating.Ordinary:
        return "the plan you're on is 5-10% more expensive than what you could be paying";
      case BillRating.Good:
        return "the plan you're on is up to 5% more expensive than what you could be paying";
      default: //BillRating.Excellent:
        return 'you are already on the cheapest plan in our system!';
    }
  }

  /**
   * Determines if bill is at the best possible rating.
   * Bills that don't have a rating value are considered to be at best rating.
   */
  get isBestRating() {
    return this.bill.rating == null || this.bill.rating >= this.perfectRating;
  }

  canSwitch() {
    return canSwitch(this.bill.categoryKey);
  }

  constructor() {}
}
