import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeStore } from '@1bill-app/services/yodlee/yodlee.store';
import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { ChangeDetectorRef, Component, Input, NgZone, OnDestroy } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { getCurrencySymbol } from '@angular/common';
@Component({
  selector: 'app-asset-liability-item',
  templateUrl: './asset-liability-item.component.html',
  styleUrls: ['./asset-liability-item.component.scss'],
})
export class AssetLiabilityItemComponent implements OnDestroy {
  subs = new SubSink();
  @Input() account: YodleeAccount;

  constructor(
    private actionSheetController: ActionSheetController,
    private yodleeService: YodleeService,
    private loadingController: LoadingController,
    private yodleeStore: YodleeStore,
    private cdf: ChangeDetectorRef,
    private ngZone: NgZone,
    private alertController: AlertController,
    private logger: NGXLogger,
    private router: Router,
  ) {}

  curr = getCurrencySymbol('USD', 'wide');

  navigate() {
    this.router.navigate(['cash-screen/account-view', this.account.id]);
  }

  async handleShowOrHideAccount() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    const newValue = !this.account?.showOnDashboard;
    try {
      await this.yodleeService.updateAccount(this.account?.id, newValue).toPromise();
      await this.yodleeService.getAccounts().toPromise();
    } catch (error) {
      this.logger.error('Error show/hide account: ', error);
      this.errorAlert();
    } finally {
      await loading.dismiss();
    }
  }

  async errorAlert() {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message:
        '<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">Something went wrong! Please try again later.</p>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Got it',
        },
      ],
    });

    await alert.present();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  async handleUnlinkAccount() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    try {
      await this.yodleeService.unlinkAccount(this.account?.id).toPromise();
      await this.yodleeService.getAccounts().toPromise();
    } catch (error) {
      this.logger.error('Error unlink account: ', error);
      this.errorAlert();
    } finally {
      await loading.dismiss();
    }
  }

  async presentAlertUnlink() {
    const title = 'Are you sure to unlink this account?';
    const message = 'You can always re-link your account on setting page';
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">${title}</h2><p class="alert-text">${message}</p>`,
      buttons: [
        {
          text: 'Yes',
          handler: () => this.ngZone.run(() => this.handleUnlinkAccount()),
        },
        { text: 'Cancel' },
      ],
    });

    await alert.present();
  }

  async verifyAccountBalance() {
    this.subs.sink = this.yodleeService
      .verifyAccounts(this.account.providerAccountId)
      .subscribe(async (result) => {
        const title = 'Your Accounts Balance';
        const message = result?.map((a) => `${a?.accountName} - ${a?.balance?.amount}`);
        const alert = await this.alertController.create({
          mode: 'ios',
          cssClass: 'bill-alert alert-icon',
          message: `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">${title}</h2><p class="alert-text">${message}</p>`,
          buttons: [
            {
              text: 'Okay',
            },
          ],
        });

        await alert.present();
      });
  }

  async presentAccountAction() {
    const showOnDashboard = this.account?.showOnDashboard;
    const showOrHide = showOnDashboard ? 'Hide' : 'Show';
    const actionSheetConfig: any = {
      cssClass: 'bill-action-sheet',
      buttons: [
        // {
        //   text: `${showOrHide} on Dashboard`,
        //   cssClass: 'delete-button',
        //   handler: async () => {
        //     this.actionSheetController.dismiss();
        //     this.ngZone.run(() => this.handleShowOrHideAccount());
        //   },
        // },
        {
          text: `Unlink account`,
          cssClass: 'delete-button mt-10',
          handler: async () => {
            this.actionSheetController.dismiss();
            this.presentAlertUnlink();
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
        },
      ],
      mode: 'ios',
    };
    const actionSheet = await this.actionSheetController.create(actionSheetConfig);
    await actionSheet.present();
  }
}
