import { _orderBy } from '@1bill-app/helpers/lodash.helper';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
import { map, mergeMap } from 'rxjs/operators';
import { BillQuery } from '../bill/bill.query';
import { YodleeQuery } from '../yodlee/yodlee.query';
import { NotificationService } from './notification.service';
import { NotificationStore } from './notification.store';
import { NotificationState, NotificationItemType } from './notification.type';
import { canSwitch } from '../bill/helper';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends Query<NotificationState> {
  constructor(
    protected store: NotificationStore,
    private notificationService: NotificationService,
    private billQuery: BillQuery,
    private yodleeQuery: YodleeQuery,
    private logger: NGXLogger,
  ) {
    super(store);
  }

  isLoading$ = this.selectLoading();
  notifications$ = this.select('notifications');
  overdueNotifications$ = this.select('notifications').pipe(
    map((notifications) =>
      notifications?.filter(
        (notification) => notification.notificationType === 'OVERDUE_REMINDER',
      ),
    ),
    map((notifications) =>
      _orderBy(notifications, [(item) => item?.data?.overdueDays], ['desc']),
    ),
    mergeMap((notifications) => {
      return this.billQuery.getBillsForView().pipe(
        map((bills) =>
          notifications.map((notificationItem) => {
            const bill = bills?.find(
              (bill) => bill.billPaymentId === notificationItem?.data?.billPaymentId,
            );
            return { bill, notificationItem };
          }),
        ),
      );
    }),
  );
  otherNotifications$ = this.select('notifications').pipe(
    map((notifications) =>
      notifications?.filter(
        (notification) =>
          // Condition 1: 'Other' notifications are not overdue reminders or bill savings
          (notification.notificationType !== NotificationItemType.OVERDUE_REMINDER &&
            notification.notificationType !== NotificationItemType.BILL_SAVINGS) ||
          // Condition 2: Bill savings notifications can be shown only if they are switchable (electricity or gas).
          // This change is also made in API, but this will prevent the notification appearing for other bill types
          // by residual notifications.
          (notification.notificationType === NotificationItemType.BILL_SAVINGS &&
            (notification.data.categoryKey ? canSwitch(notification.data.categoryKey) : true)),
      ),
    ),
    map((notifications) =>
      _orderBy(
        notifications,
        [(notificationItem) => dayjs(notificationItem.createdAt).toDate()],
        ['desc'],
      ),
    ),
    mergeMap((notifications) => {
      return this.billQuery.getBillsForView().pipe(
        map((bills) =>
          notifications.map((notificationItem) => {
            const bill = bills?.find(
              (bill) => bill.billPaymentId === notificationItem?.data?.billPaymentId,
            );
            return { bill, notificationItem };
          }),
        ),
      );
    }),
  );
  totalNotifications$ = this.select('notifications').pipe(
    map(
      (notifications) => notifications?.filter((notification) => !notification?.read)?.length,
    ),
  );
  hasBillTransaction$ = this.select('hasBillTransaction');
  allNotifications$ = this.select('notifications').pipe(
    map((notifications) =>
      _orderBy(
        notifications,
        [(notificationItem) => dayjs(notificationItem.createdAt).toDate()],
        ['desc'],
      ),
    ),
    mergeMap((notifications) => {
      return this.billQuery.getBillsForView().pipe(
        map((bills) =>
          notifications.map((notificationItem) => {
            const bill = bills?.find(
              (bill) => bill.billPaymentId === notificationItem?.data?.billPaymentId,
            );
            return { bill, notificationItem };
          }),
        ),
      );
    }),
  );
}
