import { PopoverBetterDealTncComponent } from '@1bill-app/components/popover/popover-better-deal-tnc/popover-better-deal-tnc.component';
import {
  APP_CONFIG,
  ROUTE_SETTINGS_CONTACT_DETAILS,
  ROUTE_SETTINGS_ID_DETAILS,
} from '@1bill-app/constants';
import {
  ApiService,
  SwitchProcessCallbackResponse,
} from '@1bill-app/services/api/api.service';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { appProductTypes, BillCategoryKey } from '@1bill-app/services/bill/types';
import { HomeQuery } from '@1bill-app/services/home/home.query';
import { HomeService } from '@1bill-app/services/home/home.service';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { Settings, SettingsStore } from '@1bill-app/services/settings/settings.store';
import { UIService } from '@1bill-app/services/ui.service';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  LoadingController,
  ModalController,
  NavController,
  PopoverController,
  ToastController,
} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { BillAddressSearchModalComponent } from '../../pages/bill/components/bill-address-search-modal/bill-address-search-modal.component';

enum ScreenView {
  SEARCH,
  PRODUCT_LIST,
}

@Component({
  selector: 'app-find-better-deals',
  templateUrl: './find-better-deals.component.html',
  styleUrls: ['./find-better-deals.component.scss'],
})
export class FindBetterDealsComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private homeService: HomeService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private toastCtrl: ToastController,
    private zone: NgZone,
    private settingsService: SettingsService,
    private settingsStore: SettingsStore,
    private api: ApiService,
    private router: Router,
    private loadingCtrl: LoadingController,
    private route: ActivatedRoute,
    private billService: BillService,
    private homeQuery: HomeQuery,
    private popoverController: PopoverController,
    private uiService: UIService,
  ) {}

  @Input() billCategoryKey: BillCategoryKey;
  @Input('loading') isLoading: boolean;
  public BillCategoryKey = BillCategoryKey;
  // public products = appProductTypes.filter(item =>
  //   [
  //     BillCategoryKey.ELECTRICITY,
  //     BillCategoryKey.GAS,
  //     ...environment.stage === 'dev' ? [BillCategoryKey.INTERNET] : []
  //     // BillCategoryKey.INTERNET,
  //     // BillCategoryKey.INSURANCE
  //   ].indexOf(item.key) >= 0
  // );
  // FEATURE_FLAG: products
  public products$ = this.homeQuery.featureFlags$.pipe(
    map((flags) => {
      const fbdFlag = flags.find((f) => f.startsWith('ENABLE_FBD:'));
      let billCategoryKeys = [];
      if (fbdFlag) {
        const split = fbdFlag.split('ENABLE_FBD:');
        if (split.length > 1) {
          billCategoryKeys = split[1].split(',').map((s) => s.trim());
        }
      }
      return appProductTypes.filter((item) => billCategoryKeys.indexOf(item.key) >= 0);
    }),
  );
  categoryKeySelected: BillCategoryKey;
  placeId: any;
  settings: Settings;
  loading: HTMLIonLoadingElement;
  ScreenView = ScreenView;
  currentView: ScreenView = ScreenView.PRODUCT_LIST;
  address: string;

  ngOnInit() {
    this.route.queryParams.subscribe((p) => {
      this.logger.log('switch received params:', p);
      this.updateSwitch(p as SwitchProcessCallbackResponse);
    });
  }
  async updateSwitch(p: SwitchProcessCallbackResponse) {
    if (
      p.cncRefId &&
      p.callbackStatus &&
      (p.callbackStatus.toLocaleLowerCase() === 'success' ||
        p.callbackStatus.toLocaleLowerCase() === 'successful')
    ) {
      this.logger.log('updateSwitch', p);

      const loading = await this.loadingCtrl.create({
        message: 'Updating data...',
      });
      await loading.present();
      try {
        const response = await this.api.switchProgressCallBack(p).toPromise();
        if (response?.success === false) {
          await this.presentToast('warning', 'Switch info already updated.');
        }
        await this.fetchData();
        await loading.dismiss();
      } catch (err) {
        await this.fetchData();
        await loading.dismiss();
      }
      this.router.navigate(['.'], { relativeTo: this.route });
    }
  }

  async onProductClick(key: BillCategoryKey) {
    const userPlaceId = this.settingsStore.getValue().addressJson?.placeId;
    this.categoryKeySelected = key;
    const validationSuccess = await this.validation();
    if (userPlaceId && validationSuccess) {
      this.selectAddressFromProfile(userPlaceId);
    } else if (validationSuccess) {
      setTimeout(() => {
        this.currentView = ScreenView.SEARCH;
      }, 400);
    }
  }

  async presentTncPopUp(key: BillCategoryKey) {
    const popover = await this.popoverController.create({
      component: PopoverBetterDealTncComponent,
      cssClass: 'find-better-deals-popover',
      mode: 'ios',
      componentProps: {
        categoryKey: key,
      },
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();

    if (role === 'dismiss') {
      this.onProductClick(key);
    }
  }

  async onAddressSelected(event: any) {
    this.logger.debug('setAddress', event);
    this.placeId = event?.data?.placeId;
    if (event?.success) {
      await this.findBetterDeals();
    }
    setTimeout(() => {
      this.currentView = ScreenView.PRODUCT_LIST;
    }, 400);
  }

  async selectAddressFromProfile(placeId: string) {
    this.logger.log('Using this address place ID from profile:', placeId);
    this.placeId = placeId;
    await this.findBetterDeals();
    setTimeout(() => {
      this.currentView = ScreenView.PRODUCT_LIST;
    }, 400);
  }

  async findBetterDeals() {
    await this.displayLoading();
    await this.homeService
      .findBetterDeals(this.categoryKeySelected, this.settings, this.placeId)
      .toPromise()
      .catch((err) => {
        this.logger.error('findBetterDeals error', err);
      });
    await this.hideLoading();
  }

  async validation() {
    this.settings = await this.settingsService.fetch().toPromise();
    const { isMissing, missingExtra, missingCount } =
      this.settingsService.identifyMissingPersonalDetails(this.settings);

    if (isMissing) {
      // await this.presentInsufficientDataToast(missingExtra);
      await this.presentInsufficientDataModal(missingCount > 1 ? null : missingExtra);
      return;
    }

    return true;
  }

  /**
   * @deprecated use presentInsufficientDataModal
   * @param missingExtra
   */
  async presentInsufficientDataToast(missingExtra) {
    let message =
      'Please complete your contact details section in settings in order to get a better deal.';
    if (missingExtra === 'driver_license') {
      message = 'Please add your driver license in settings in order to get a better deal.';
    }
    const toast = await this.toastCtrl.create({
      message,
      duration: 5000,
      color: 'warning',
      buttons: [
        {
          text: 'Go',
          icon: 'arrow-redo-outline',
          side: 'end',
          handler: () => {
            this.zone.run(() => {
              if (missingExtra === 'driver_license') {
                this.navCtrl.navigateForward([ROUTE_SETTINGS_ID_DETAILS], {
                  queryParams: { _source: this.router.url },
                });
              } else {
                this.navCtrl.navigateForward([ROUTE_SETTINGS_CONTACT_DETAILS], {
                  queryParams: { _source: this.router.url },
                });
              }
            });
          },
        },
      ],
    });
    toast.present();
  }
  async presentInsufficientDataModal(missingExtra) {
    let text1 = 'Please complete your profile details in settings';
    let text2 = 'in order to get a better deal!';
    let buttonText = 'Complete profile details';
    let route = ROUTE_SETTINGS_CONTACT_DETAILS;
    const routeSource = this.router.url;

    if (missingExtra === 'driver_license') {
      text1 = 'Please add your driver license in settings';
      text2 = 'in order to get a better deal!';
      buttonText = 'Add driver license';
      route = ROUTE_SETTINGS_ID_DETAILS;
    }
    const modal = await this.uiService.presentActionDeniedModal(
      text1,
      text2,
      buttonText,
      route,
    );

    return modal;
  }

  async presentAddressInputModal() {
    const modal = await this.modalCtrl.create({
      component: BillAddressSearchModalComponent,
    });
    await modal.present();

    return modal;
  }

  async displayLoading() {
    if (!this.loading?.present) {
      this.loading = await this.loadingCtrl.create({
        mode: 'ios',
        message: 'Processing, please wait...',
      });
    }
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading?.dismiss) {
      await this.loading.dismiss();
    }
  }

  async presentToast(color: 'success' | 'danger' | 'warning', message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: APP_CONFIG.TOAST_SUCCESS_DURATION,
      color,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    toast.present();
    color === 'success' ? this.refreshData() : '';
  }

  refreshData() {
    this.billService.fetchBill().subscribe();
  }

  async fetchData() {
    const loading = await this.loadingCtrl.create({
      message: 'Loading data...',
    });
    await loading.present();

    this.billService.fetchBill().subscribe(() => {
      loading.dismiss();
    });
  }
}
