import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { YodleeStore } from '@1bill-app/services/yodlee/yodlee.store';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import _ from 'lodash';
import { getNativeWeeksOffset } from '@1bill-app/helpers/date.helpers';
import dayjs from 'dayjs';

@Component({
  selector: 'app-date-select-toolbar',
  templateUrl: './date-select-toolbar.component.html',
  styleUrls: ['./date-select-toolbar.component.scss'],
})
export class DateSelectToolbarComponent {
  constructor(private yodleeStore: YodleeStore, private yodleeQuery: YodleeQuery) {}

  @Input() availablePeriods: string[];
  @Input() currentDurationDate: string;
  @Input() disabled: boolean;
  @Input('initialPeriod') interval: 'M' | 'W' | 'Y';
  @Input('context') chartContext: 'net-income' | 'cash-screen' | 'account-view' | 'expenses';
  @Input() enabledPeriods: ('W' | 'M' | 'Y')[];
  @Output() nextDurationClick: EventEmitter<string> = new EventEmitter();
  @Output() prevDurationClick: EventEmitter<string> = new EventEmitter();
  @Output() onIntervalChange: EventEmitter<'M' | 'W' | 'Y'> = new EventEmitter();

  loading$ = this.yodleeQuery.isLoading$;

  initialAvailablePeriods: {
    interval: 'M' | 'W' | 'Y';
    chartContext: string;
    periods: string[];
  }[] = [];
  public currentDurationDate$: Observable<string>;
  debug = false;

  get hasPrevDuration(): boolean {
    const intervalPeriods = this.initialAvailablePeriods.find(
      (iap) => iap.interval === this.interval && iap.chartContext === this.chartContext,
    )?.periods;

    if (!intervalPeriods?.length) return false;

    const currentIndex = intervalPeriods.findIndex((ip) =>
      ip.includes(this.currentDurationDate),
    );

    return (
      currentIndex !== intervalPeriods?.length - 1 &&
      intervalPeriods?.some((period) => dayjs(period).isBefore(this.currentDurationDate))
    );
  }

  get hasNextDuration(): boolean {
    // const intervalPeriods = this.initialAvailablePeriods.find(
    //   (iap) => iap.interval === this.interval && iap.chartContext === this.chartContext,
    // )?.periods;
    // const currentIndex = _.findIndex(intervalPeriods, (ip) =>
    //   ip?.includes(this.currentDurationDate),
    // );
    // return (
    //   currentIndex !== 0 &&
    //   currentIndex !== -1 &&
    //   intervalPeriods?.some((period) => dayjs(period).isAfter(this.currentDurationDate))
    // );

    if (this.interval === 'M') {
      return dayjs()
        .set('date', dayjs().daysInMonth())
        .isAfter(dayjs(this.currentDurationDate), 'month');
    } else if (this.interval === 'W') {
      return dayjs().set('day', 6).isAfter(this.currentDurationDate, 'week');
    } else if (this.interval === 'Y') {
      return dayjs().set('month', 11).isAfter(this.currentDurationDate, 'year');
    }
  }

  timeLabel = 'Loading...';

  ngOnInit() {}

  ngOnChanges(changes?: SimpleChanges) {
    // This guard is necessary when first navigating to the page showing this component
    if (!this.availablePeriods) return;

    this.currentDurationDate$ = this.getSubscribable(this.chartContext);

    const rawIndex = this.availablePeriods?.indexOf(this.currentDurationDate);
    const currentIndex = ~rawIndex ? rawIndex : 0;
    if (changes.availablePeriods) {
      if (currentIndex !== null && currentIndex !== undefined) {
        const initialPeriod = this.initialAvailablePeriods.find(
          (iap) => iap.interval === this.interval,
        );
        if (!initialPeriod) {
          this.initialAvailablePeriods.push({
            interval: this.interval,
            chartContext: this.chartContext,
            periods: this.availablePeriods,
          });
        }
      }
    }
    this.timeLabel = this.getTimeLabel(this.availablePeriods[currentIndex], this.interval);
  }

  getTimeLabel(date: string, interval: 'M' | 'W' | 'Y') {
    switch (this.interval) {
      case 'M':
        {
          return dayjs(date).format('MMMM YYYY');
        }
        break;
      case 'W':
        {
          const offset = getNativeWeeksOffset(date);
          if (offset < 0) {
            // This happens when user switches from week to month; since
            // the month interval date is set to the last day of the month,
            // the week offset can be a negative value
            return 'Loading...';
          }
          if (offset === 0) return 'This week';
          if (offset === 1) return 'Last week';
          return `${offset} weeks ago`;
        }
        break;
      case 'Y':
        {
          return dayjs(date).format('YYYY');
        }
        break;
      default: {
        throw 'DateSelectToolbarComponent::getTimeLabel() unhandled interval: ' + interval;
      }
    }
    return date;
  }

  getSubscribable(context: string) {
    // Account view and cash screen charts need to be uniquely identifiable so that
    // the date select toolbar doesn't get confused with the selection of possible
    // dates. So, for multiple screens of the same type of chart, a ":" is used to
    // separate the page name itself with the more specific context (account ID, etc.)
    const colonIndex = context.indexOf(':');
    switch (context.slice(0, ~colonIndex ? colonIndex : context.length)) {
      case 'net-income':
        {
          return this.yodleeQuery.netIncomeCurrentDurationDate$;
        }
        break;
      case 'cash-screen':
        {
          return this.yodleeQuery.cashScreenCurrentDurationDate$;
        }
        break;
      case 'account-view':
        {
          return this.yodleeQuery.accountViewCurrentDurationDate$;
        }
        break;
      case 'expenses':
        {
          return this.yodleeQuery.expensesCurrentDurationDate$;
        }
        break;
      default: {
        console.error(
          'DateSelectToolbarComponent::getSubscribable unhandled context:',
          context,
        );
      }
    }
  }

  toggleNextDuration() {
    // The array availablePeriods is sorted latest to earliest, so we will choose the previous value in the array.
    this.timeLabel = 'Loading...';
    const intervalPeriods = this.initialAvailablePeriods.find(
      (iap) => iap.interval === this.interval,
    ).periods;
    const currentIndex = _.findIndex(intervalPeriods, (ip) =>
      ip.includes(this.currentDurationDate),
    );
    this.currentDurationDate = intervalPeriods[currentIndex - 1];
    if (this.currentDurationDate) {
      this.updateCurrentDurationDate();
      this.nextDurationClick.emit(this.currentDurationDate);
    }
  }

  togglePrevDuration() {
    // The array availablePeriods is sorted latest to earliest, so we will choose the next value in the array.
    this.timeLabel = 'Loading...';
    const intervalPeriods = this.initialAvailablePeriods.find(
      (iap) => iap.interval === this.interval,
    ).periods;
    const currentIndex = _.findIndex(intervalPeriods, (ip) =>
      ip.includes(this.currentDurationDate),
    );
    this.currentDurationDate = intervalPeriods[currentIndex + 1];

    if (this.currentDurationDate) {
      this.updateCurrentDurationDate();
      this.nextDurationClick.emit(this.currentDurationDate);
    }
  }

  intervalChanged(event) {
    this.timeLabel = 'Loading...';
    this.currentDurationDate = dayjs().format('YYYY-MM-DD');
    this.onIntervalChange.emit(this.interval);
  }

  updateCurrentDurationDate() {
    switch (this.chartContext) {
      case 'account-view':
        {
          this.yodleeStore.update({
            accountViewCurrentDurationDate: this.currentDurationDate,
          });
        }
        break;
      case 'cash-screen':
        {
          this.yodleeStore.update({ cashScreenCurrentDurationDate: this.currentDurationDate });
        }
        break;
      case 'expenses':
        {
          this.yodleeStore.update({ expensesCurrentDurationDate: this.currentDurationDate });
        }
        break;
      case 'net-income': {
        this.yodleeStore.update({ netIncomeCurrentDurationDate: this.currentDurationDate });
      }
    }
  }
}
