import { CardData, CardDataType } from '@1bill-app/services/home/home.type';

export default [
  {
    id: 1, 
    type: CardDataType.CREDIT_SCORE_APPLY,
    icon: 'speedometer-outline',
    title: 'Credit score',
    description: 'Update to get better deals',
    url: '/credit-score',
    btnLabel: 'Apply now',
  },
  {
    id: 2,
    type: CardDataType.ADD_EMAIL,
    icon: 'mail-outline',
    title: 'Send via email',
    description: 'Directly from supplier',
    url: '/email-bills',
    btnLabel: 'Add email',
  },
  {
    id: 3,
    type: CardDataType.LINK_ACCOUNT,
    icon: 'link-outline',
    title: 'Connect account',
    description: 'For seamless experience',
    url: '',
    btnLabel: 'Link account',
  },
  {
    id: 4,
    type: CardDataType.ADD_CREDIT_CARD,
    icon: 'card-outline',
    title: 'Add credit card',
    description: 'For auto payments',
    url: '/payment-info',
    btnLabel: 'Add card',
  },
  {
    id: 5,
    type: CardDataType.SETUP_PUSH_NOTIFICATION,
    icon: 'notifications-outline',
    title: 'Setup notifications',
    description: 'For payment reminders',
    url: '/app/settings/notifications',
    btnLabel: 'Setup',
  },
] as CardData[];
