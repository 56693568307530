import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePinScreenComponent } from './update-pin-screen.component';
import { CodeInputModule } from 'angular-code-input';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [UpdatePinScreenComponent],
  imports: [
    CommonModule,
    IonicModule,
    CodeInputModule,
  ]
})
export class UpdatePinScreenModule { }
