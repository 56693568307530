import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-linked-user-card',
  templateUrl: './linked-user-card.component.html',
  styleUrls: ['./linked-user-card.component.scss'],
})
export class LinkedUserCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
