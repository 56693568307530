import { PIN_ATTEMPT_LIMIT } from '@1bill-app/constants';
import { PINService } from '@1bill-app/services/auth/pin.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { KeyboardResize } from '@capacitor/keyboard';
import {
  AlertController,
  Animation,
  AnimationController,
  ModalController,
} from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { Keyboard } from '@capacitor/keyboard'


export enum CreatePINView {
  NEW_PIN,
  CONFIRM_PIN,
}

@Component({
  selector: 'app-create-pin-screen',
  templateUrl: './create-pin-screen.component.html',
  styleUrls: ['./create-pin-screen.component.scss'],
})
export class CreatePinScreenComponent implements OnInit {
  @ViewChild(CodeInputComponent) codeInput: CodeInputComponent;
  @ViewChild(CodeInputComponent, { read: ElementRef }) codeInputEl: ElementRef;
  public CreatePINView = CreatePINView;
  public view = CreatePINView.NEW_PIN;
  loading$ = this.pinService.loading$;
  get title() {
    return {
      [CreatePINView.NEW_PIN]: 'Create a PIN',
      [CreatePINView.CONFIRM_PIN]: 'Re-enter a PIN',
    }[this.view];
  }
  get subtitle() {
    return {
      [CreatePINView.NEW_PIN]: 'Use this PIN to secure the app',
      [CreatePINView.CONFIRM_PIN]: 'Confirm the PIN',
    }[this.view];
  }
  private pin: string;
  private confirmPin: string;
  incorrectPin;
  attemptCount: number = 0;

  constructor(
    private pinService: PINService,
    private modalCtrl: ModalController,
    private animationCtrl: AnimationController,
    private alertCtrl: AlertController,
  ) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ngOnInit() { }

  ionViewDidEnter() {
    this.view = CreatePINView.NEW_PIN;
    setTimeout(() => this.focusCodeInput());
  }

  ionViewWillEnter() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ionViewWillLeave() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }

  onClose() {
    this.pin = null;
    this.confirmPin = null;
    this.codeInput.reset(true);
    this.dismissLockScreen();
  }

  async onSetupLater() {
    this.pinService.clear().toPromise().then(() => {
      this.onClose();
    });
  }

  async onCodeCompleted(value: string) {
    switch (this.view) {
      case CreatePINView.NEW_PIN:
        this.pin = value;
        this.view = CreatePINView.CONFIRM_PIN;
        this.codeInput.reset(false);
        this.focusCodeInput();
        break;
      case CreatePINView.CONFIRM_PIN:
        this.confirmPin = value;
        if (this.pin === this.confirmPin) {
          this.incorrectPin = false;
          await this.pinService.store(this.pin).toPromise();
          this.modalCtrl.dismiss({ success: true });
        } else {
          this.incorrectPin = true;
          Haptics.impact({ style: ImpactStyle.Heavy });
          this.view = CreatePINView.NEW_PIN;
          this.confirmPin = null;
          this.codeInput.reset(false);
          this.focusCodeInput();
          this.triggerPinErrAnimation();
          this.addCSSVariable(
            this.codeInputEl.nativeElement,
            '--item-border-bottom',
            '2px solid var(--ion-color-danger',
          );
          this.attemptCount++;
          if (this.attemptCount >= PIN_ATTEMPT_LIMIT) {
            const alert = await this.alertCtrl.create({
              header: 'Too many attempts',
              message: 'You have entered the PIN incorrectly too many times.',
              buttons: ['ok'],
            });
            await alert.present();
            alert.onDidDismiss().then(() => {
              this.forgot();
            });
          }
        }
        break;
      default:
        break;
    }
  }

  forgot() {
    // this.authService.logout();
    this.dismissLockScreen();
  }

  async dismissLockScreen() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      this.pin = null;
      this.confirmPin = null;
      modal.dismiss();
    }
  }

  focusCodeInput() {
    this.codeInput && this.codeInput.focusOnField && this.codeInput.focusOnField(0);
  }
  triggerPinErrAnimation() {
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(this.codeInputEl.nativeElement)
      .easing('ease-out')
      .duration(1000)
      .keyframes([
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.2 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.3 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.4 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.5 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.6 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.7 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.8 },
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.9 },
      ]);
    animation.play();
  }
  addCSSVariable(el: HTMLElement, property: string, value: string) {
    el.style.setProperty(property, value);
  }
  removeCSSVariable(el: HTMLElement, property: string) {
    el.style.removeProperty(property);
  }
}
