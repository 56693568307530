export enum AccountStatusType {
  'ERROR' = 'ERROR',
  'NO_RECORD' = 'NO_RECORD',
  'DELETED' = 'DELETED',
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}
/**
 * Also defined in API at core/types/JobParserTypes.ts
 *
 * Please reflect any changes made here in that file.
 */
export enum JobState {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  MODIFIED = 'MODIFIED',
  ONPROGRESS = 'ONPROGRESS',
  DUPLICATE = 'DUPLICATE',
  INVALID = 'INVALID',
  BLACKLISTED_ADDRESS = 'BLACKLISTED_A',
  BLACKLISTED_BILLER = 'BLACKLISTED_B',
}
export interface Job {
  id: number;
  bpId: number;
  state: JobState;
  error: string;
  errorCode: string;
  created: string;
  updated: string;
  barcode: JobBarcode[];
}
export interface JobBarcode {
  barcodeValue: string;
  boundingPoly: any[];
  billInfo: {
    ref: string;
    totalAmount: number;
    billerCode: string;
    billerName: string;
    supplierLogoUrl: string;
    channel: string;
  };
}
export interface StatusJobData {
  items: Job[];
  count: number;
  totalCount: number;
}
export interface AccountStatus {
  billAccountId: number;
  billEmail: string;
  createdAt: string;
  email: string;
  isDeleted: number;
  modifiedAt: string;
  status: AccountStatusType;
}

export interface JobMeta {
  directDebit: boolean;
  alreadyPaid: boolean;
  cusSelected: boolean;
}
