import { _take, _uniqBy } from '@1bill-app/helpers/lodash.helper';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { isBillOverdue, isBillPaid } from '@1bill-app/services/bill/helper';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { Job } from '@1bill-app/services/status/types';

import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';
@Component({
  selector: 'app-bill-block-list',
  templateUrl: './bill-block-list.component.html',
  styleUrls: ['./bill-block-list.component.scss'],
})
export class BillBlockListComponent {
  @ViewChild('componentEl') componentEl: ElementRef;
  @Input() routePath: string;
  @Input() data: ViewBillType[] = [];
  @Input() recentAdded: ViewBillType[] = [];
  @Input() loading: boolean;
  @Input() title: string;
  @Input() source: string;
  @Input() limit: number;
  @Input() jobsOnProgress: Job[];
  @Input() isTechnicalIssue: boolean;
  @Input() billStatusFilter: 'all' | 'calendar' | 'paid' | 'unpaid' = 'unpaid';
  @Input() hideHeaders = false;
  @Input() isOffer = false;
  @Input() showBillStatus = true;

  /**
   * If false, then Calendar is currently being displayed.
   * Therefore, month/bill headings and "No Bills Found"
   * message will not be shown in the list
   */
  @Input() isNotCalendarView = true;

  prevCardsId: number[];
  public isBillPaid = isBillPaid;
  public isBillOverdue = isBillOverdue;

  constructor(
    private router: Router,
    private logger: NGXLogger,
    private billService: BillService,
  ) {}

  get bills() {
    let bills = this.data;
    // filter out recent added bills
    if (this.recentAddedBills?.length > 0 && Array.isArray(this.data)) {
      const ids = this.recentAddedBills.map((r) => r.billPaymentId);
      bills = this.data.filter((d) => ids.indexOf(d.billPaymentId) < 0);
    }
    return this.limit ? _take(bills, this.limit) : bills;
  }
  get recentAddedBills() {
    return _uniqBy(this.recentAdded, 'billPaymentId');
  }
  goToBillDet(billPaymentId?: number, jobId?: number) {
    if (billPaymentId) {
      this.router.navigate([this.routePath], {
        queryParams: { _source: this.source, billPaymentId: billPaymentId },
      });
    } else {
      this.router.navigate([this.routePath], {
        queryParams: { _source: this.source, jobId: jobId },
      });
    }
  }
  onPayNowClick(bill: ViewBillType) {
    this.logger.debug('Pay now button clicked for bill: ', bill);
    this.billService.presentPaymentCombinedModal(bill);
  }

  trackByFn(index: number, item: ViewBillType) {
    return item?.billPaymentId;
  }

  /**
   * @param bill
   * @returns true if bill is overdue (due date is past current date & bill is not paid)
   */
  isOverdue(bill: ViewBillType) {
    if (isBillOverdue(bill)) return true;
    return false;
  }

  /**
   * @param bill
   * @returns true if bill is due this month
   */
  isDueThisMonth(bill: ViewBillType) {
    let now = dayjs();
    let billDue = dayjs(bill.dueDate, 'YYYY-MM-DD');

    if (this.isOverdue(bill) == false && now.isSame(billDue, 'month')) return true;
    return false;
  }

  /**
   * @param bill
   * @returns true if bill is due after the end of the current month.
   */
  isComingUp(bill: ViewBillType) {
    if (this.isOverdue(bill) == false && this.isDueThisMonth(bill) == false) return true;
    return false;
  }

  /**
   * Determines whether or not to show month label for bills list ("paid" segment).
   * If heading for bill at index `i` is different than bill at index `i - 1`,
   * then returns true and the month label is displayed.
   * @param index
   * @returns true if showing heading
   */
  showListMonthLabelPaid(index: number) {
    if (index == 0) return true; // If first in list, heading logically must be shown

    //If heading for bill at index `i` is different than bill at index `i - 1`, show heading.
    if (this.getMonthTextPaid(index) != this.getMonthTextPaid(index - 1)) return true;

    return false;
  }

  /**
   * Determines whether or not to show month label for bills list ("all" segment).
   * If heading for bill at index `i` is different than bill at index `i - 1`,
   * then returns true and the month label is displayed.
   * @param index
   * @returns true if showing heading
   */
  showListMonthLabelAll(index: number) {
    if (index == 0) return true; // If first in list, heading logically must be shown

    //If heading for bill at index `i` is different than bill at index `i - 1`, show heading.
    if (this.getMonthTextAll(index) != this.getMonthTextAll(index - 1)) return true;

    return false;
  }

  /**
   * Determines whether or not to show "This Month" label for bills list ("unpaid" segment only).
   * Checks current bill index and previous bill index to see if the current bill is the first
   * bill that is due this month. If so, returns true and "This Month" label is displayed.
   * @param index
   * @returns true or false
   */
  showDueThisMonthLabel(index: number) {
    if (index == 0 && this.isDueThisMonth(this.bills[index])) return true;

    if (this.isOverdue(this.bills[index]) === false) {
      const thisBillDate = dayjs(this.bills[index]?.dueDate, 'YYYY-MM-DD');
      const prevBillDate = dayjs(this.bills[index - 1]?.dueDate, 'YYYY-MM-DD');

      if (
        this.isDueThisMonth(this.bills[index]) == true &&
        thisBillDate.isSame(prevBillDate, 'month') === false
      )
        return true;
    }

    return false;
  }

  /**
   * If due after this month (isComingUp) and if previous bill in bills list was
   * due this month or overdue, then this is the first bill in the "coming up" section.
   * Therefore, will return true and the "Coming Up" header will be displayed.
   * @param index
   * @returns true or false
   */
  showComingUpLabel(index: number) {
    if (index == 0 && this.isComingUp(this.bills[index])) return true;

    let thisBill = this.bills[index];
    let prevBill = this.bills[index - 1];
    if (
      this.isComingUp(thisBill) == true &&
      (this.isDueThisMonth(prevBill) === true || this.isOverdue(prevBill) === true)
    )
      return true;

    return false;
  }

  /**
   * Checks if bill's index = 0 and if it is overdue.
   * Bills are sorted by due date, so if there are any overdue bills, they
   * will logically be the first element(s) in the bills list.
   * @param index
   * @returns true if index = 0 and bill is overdue, else false */
  showOverdueLabel(index: number) {
    if (index == 0 && this.isOverdue(this.bills[index])) return true;
    return false;
  }

  /**
   * Text is taken from date paid.
   * @param index
   * @returns string - month label for bills list ("paid" segment).
   */
  getMonthTextPaid(index: number) {
    if (this.bills[index].datePaid)
      return dayjs(this.bills[index].datePaid).format('MMMM YYYY');

    //If no datePaid, show billEndDate
    if (this.bills[index].billEndDate)
      return dayjs(this.bills[index].billEndDate).format('MMMM YYYY');

    //If no billEndDate, show dateReceived
    if (this.bills[index].dateReceived)
      return dayjs(this.bills[index].dateReceived).format('MMMM YYYY');
  }

  /**
   * Text is taken from date due.
   * @param index
   * @returns string - month label for bills list ("all" segment).
   */
  getMonthTextAll(index: number) {
    if (this.bills[index].dueDate) return dayjs(this.bills[index].dueDate).format('MMMM YYYY');

    //If no dueDate, show billEndDate
    if (this.bills[index].billEndDate)
      return dayjs(this.bills[index].billEndDate).format('MMMM YYYY');

    //If no billEndDate, show dateReceived
    if (this.bills[index].dateReceived)
      return dayjs(this.bills[index].dateReceived).format('MMMM YYYY');
  }

  scrollIntoView() {
    setTimeout(
      () =>
        this.componentEl.nativeElement &&
        this.componentEl.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        }),
      0,
    );
  }
}
