import { ViewBillType } from '@1bill-app/services/bill/types';
import { NotificationItem } from '@1bill-app/services/notification/notification.type';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SubSink } from 'subsink';
import { UIService } from '@1bill-app/services/ui.service';
import { HomeQuery } from './../../services/home/home.query';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-notification-other',
  templateUrl: './notification-other.component.html',
  styleUrls: ['./notification-other.component.scss'],
})
export class NotificationOtherComponent {
  constructor(private uiService: UIService, public homeQuery: HomeQuery) {}

  @Output() dismissClicked: EventEmitter<string> = new EventEmitter();
  @Input() notificationItems: { notificationItem: NotificationItem; bill: ViewBillType }[];
  @Input() hasBillTransaction: boolean;
  @Input() isLoading: boolean;
  public dashboardPreferences$ = this.homeQuery.preferences$;

  subs = new SubSink();
  public appNeedsUpdate$ = this.uiService.newAppVersionAvailable().pipe(map(res=>res.newVersionAvailable));

  dismissNotification(id: string) {
    this.dismissClicked.emit(id);
  }

  trackByFn(item: { notificationItem: NotificationItem; bill: ViewBillType }) {
    return item?.notificationItem?.id;
  }
}
