import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { getFullMonthName } from '@1bill-app/helpers/date.helpers';
import { MapType } from '@angular/compiler';

@Component({
  selector: 'app-net-income-chart',
  templateUrl: './net-income-chart.component.html',
  styleUrls: ['./net-income-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetIncomeChartComponent implements AfterViewInit, OnChanges {
  constructor(
    private yodleeQuery: YodleeQuery,
    private logger: NGXLogger,
    private cdRef: ChangeDetectorRef,
  ) {
    this.balancePos = '#1da061';
  }

  //  @Input() availableDurations :string[];
  availableDurations: string[];
  @Input() currentDuration: string;
  @Input() set chartData(input) {
    this._chartData = input;
    this.initHicharts();
    setTimeout(() => (this.netIncomeChart as any)?.chart.reflow(), 500);
  }
  private _chartData: { durationDate: string; expenseTotal: number; incomeTotal: number }[];
  get chartData() {
    return this._chartData;
  }
  @ViewChild('netIncomeChart') netIncomeChart: ElementRef;
  subs = new SubSink();
  public loading$ = this.yodleeQuery.isLoading$;
  public currentDurationDate$ = this.yodleeQuery.netIncomeCurrentDurationDate$;
  public availableDurations$ = this.yodleeQuery.availableDurations$;
  public netIncomeDiff$ = this.yodleeQuery.netIncomediff$;
  public netIncomeDiffPerc$ = this.yodleeQuery.netIncomedifferencePerc$;
  accountDetails$ = this.yodleeQuery.accountDetails$;
  public currentNetIncomeTotal$ = this.yodleeQuery.currentNetIncomeTotal$;
  public prevDurationDate$ = this.yodleeQuery.netIncomePrevDurationDate$;

  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) chart.reflow();
      } catch (err) {
        console.warn('chartCallback', err);
      }
    }, 1000);
  };
  incomeValues: Map<string, number>;
  expenseValue: Map<string, number>;
  balancePos: string;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const { currentValue, previousValue } = changes.value;
      if (currentValue !== previousValue) {
        this.initHicharts();
        this.cdRef.detectChanges();
      }
    }
    if (this.chartData) {
    }
  }

  ngOnInit() {
    const chartElement = this.netIncomeChart as any;
    chartElement?.chart?.showLoading();
  }

  ngAfterViewInit() {
    this.initHicharts();
  }

  initHicharts() {
    const expenseAmounts = this.chartData?.map((data) => data?.expenseTotal);
    const incomeAmounts = this.chartData?.map((data) => data?.incomeTotal);
    const dates = this.chartData?.map((data) => data.durationDate);

    this.chartOptions = {
      chart: {
        type: 'column',
        dashStyle: 'dot',
        marginBottom: 25,
        className: 'net-income-chart',
        height: 200,
      },

      title: {
        text: '',
      },
      xAxis: {
        reversed: true,
        title: {
          text: 'Months',
        },
        categories: dates,
        labels: {
          enabled: true,
          formatter: function (label) {
            const date = dayjs(label?.value, 'YYYY-MM-DD');
            return date.isValid() ? date.format('MMM').toUpperCase() : label?.value;
          },
          x: -5,
        },
      },
      tooltip: {
        formatter: function () {
          const abs = Math.abs(this.y);
          const numberFormat =
            this.y < 1000
              ? Highcharts.numberFormat(abs, 2)
              : Highcharts.numberFormat(abs / 1000, 2) + 'k';

          return `Your total ${this.y < 0 ? 'expenses' : 'income'} for <b>${getFullMonthName(
            this.x,
            true,
          )}</b> is <b>$${numberFormat}</b>`;
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          allowDecimals: false,
          enabled: true,
          formatter: function () {
            const abs = Math.abs(this.value);
            return abs < 1000000 ? (abs < 1000 ? this.value : `${this.value / 1000}k`) : `${this.value / 1000000}M`;
          },
        } as any,
      },
      credits: {
        enabled: false,
      },

      plotOptions: {
        series: {
          stacking: true,
          lineWidth: 10,
        },
        column: {
          pointPadding: 0.5,
          borderWidth: 0,
          stacking: 'normal',
          pointWidth: 10,
          edgeWidth: 10,
        },
      },

      series: [
        {
          stack: 'data',
          name: '',
          id: 'data',
          data: incomeAmounts,
          color: '#1DA061',
          negativeColor: '#EB7A7A',
          borderRadius: 5,
          pointWidth: 20,
          showInLegend: false,
          dataLabels: {
            formatter: function () {
              if (this.y > 999 && this.y <= 999999) {
                return '$' + Highcharts.numberFormat(this.y / 1000, 0) + 'k';
              } else if (this.y > 999999) {
                return '$' + Highcharts.numberFormat(this.y / 1000000, 0) + 'M';
              } else {
                return '$' + Highcharts.numberFormat(this.y, 0);
              }
            },
            enabled: true,
            verticalAlign: 'top',
            color: '#1DA061',
            x: -10,
            y: -20,

            style: {
              textOutline: false,
            },
          },
        },

        {
          stack: 'data',
          name: '',
          linkedTo: 'data',
          data: expenseAmounts,
          color: '#1DA061',
          negativeColor: '#EB7A7A',
          borderRadius: 6,
          pointWidth: 20,
          dataLabels: {
            formatter: function () {
              if (this.y < -999) {
                if (this.y < -999999) {
                  // Show millions as M
                  return '-$' + Highcharts.numberFormat(Math.abs(this.y) / 1000000, 0) + 'M';
                } else {
                  return '-$' + Highcharts.numberFormat(Math.abs(this.y) / 1000, 0) + 'k';
                }
              }
              return '-$' + Highcharts.numberFormat(Math.abs(this.y), 0);
            },

            enabled: true,
            verticalAlign: 'bottom',
            color: '#EB7A7A',
            y: 10,
            x: -15,
            style: {
              textOutline: false,
            },

            inside: false,
            crop: false,
            overflow: 'none',
          },
        },
      ],
    } as any;
    setTimeout(() => (this.netIncomeChart as any)?.chart?.reflow(), 1500);
    setTimeout(() => (this.netIncomeChart as any)?.chart?.hideLoading(), 1500);
    this.cdRef.detectChanges();
  }
}
