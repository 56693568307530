import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HomeService } from '@1bill-app/services/home/home.service';

@Component({
  selector: 'app-yodlee-account-detail-list',
  templateUrl: './yodlee-account-detail-list.component.html',
  styleUrls: ['./yodlee-account-detail-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YodleeAccountDetailListComponent {
  @Input() accounts: YodleeAccount[];

  constructor(
    private logger: NGXLogger,
    private homeService: HomeService,
  ) {}

  trackByFn(account: YodleeAccount) {
    return account?.id;
  }

  async handleLinkBankAccount() {
    this.homeService.presentLinkBankAccountModal();
  }
}
