import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-prior-payment-info-modal',
  styleUrls: ['./prior-payment-info-modal.component.scss'],
  template: `
    <ion-card class="ion-no-margin">
      <div class="ion-padding-horizontal">
        <h1>Information</h1>
      </div>
      <ion-list class="ion-padding body">
        <p>
          Please consider adding identification documents and performing credit
          check before entering payment information.
        </p>
        <ion-item lines="none">
          <ion-button
            slot="end"
            color="primary"
            size="default"
            (click)="onClose()"
            >Close</ion-button
          >
        </ion-item>
      </ion-list>
    </ion-card>
  `,
})
export class PriorPaymentInfoModalComponent implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}
  onClose() {
    this.modalCtrl.dismiss();
  }
}
