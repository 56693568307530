import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { SelectablePaymentMethod } from '@1bill-app/services/payment/payment-card.service';
import _ from 'lodash';
import { PAYMENT_COMBINED_MODAL_ID } from '@1bill-app/constants';

@Component({
  selector: 'app-bill-checkout',
  templateUrl: './bill-checkout.component.html',
  styleUrls: ['./bill-checkout.component.scss'],
})
export class BillCheckoutComponent implements OnInit {
  constructor(private modalController: ModalController, private logger: NGXLogger) {}
  @Input() bill: ViewBillType;
  @Input() selectedMethod: SelectablePaymentMethod;
  @Input() completeAccountDetails: boolean;
  @Output() onGotoSelectPayment: EventEmitter<boolean> = new EventEmitter(false);

  ngOnInit() {}

  gotoSelectPayment(act: boolean) {
    this.onGotoSelectPayment.emit(act);
  }

  dismiss() {
    this.modalController.dismiss(null, 'cancel', PAYMENT_COMBINED_MODAL_ID);
  }

  dismissConfirm() {
    // Dismiss, confirming payment of one bill
    this.modalController.dismiss(
      {
        selectedPaymentMethod: this.selectedMethod,
        bill: this.bill,
      },
      'confirm',
      PAYMENT_COMBINED_MODAL_ID,
    );
  }
}
