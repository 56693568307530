import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-unlink-bill',
  templateUrl: './popover-unlink-bill.component.html',
  styleUrls: ['./popover-unlink-bill.component.scss'],
})
export class PopoverUnlinkBillComponent implements OnInit {
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}
  
  dismiss(confirmed?: boolean) {
    this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
  }
}
