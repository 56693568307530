import { ROUTE_NET_INCOME } from '@1bill-app/constants';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges, SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import { first, orderBy } from 'lodash';
import { NGXLogger } from 'ngx-logger';

HighchartsMore(Highcharts);
@Component({
  selector: 'app-net-wealth-card',
  templateUrl: './net-wealth-card.component.html',
  styleUrls: ['./net-wealth-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetWealthCardComponent implements AfterViewInit, OnChanges {
  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private yodleeService: YodleeService,
    private logger: NGXLogger,
  ) {}

  @Input() set chartData(input) {
    this._chartData = input;
    // Initialise and refresh chart after data change
    this.initHicharts();
    this.netWealthChart.chart.reflow();
  }
  get chartData() { return this._chartData };
  @Input() categorySummaries: any[];
  @ViewChild('timePeriodSelect') timePeriodSelect: HTMLIonSelectElement;
  @ViewChild('netWealthChart') netWealthChart: any;
  private _chartData: { amount: number, date: string }[];

  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};
  spendingChartLoading = false;

  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) chart?.reflow();
      } catch (err) {
        console.warn('chartCallback error:', err);
      }
    }, 500);
  };

  get lastDate() {
    const data = orderBy(this.chartData, [item => dayjs(item.date, 'YYYY-MM-DD')], ['desc']);
    const [currentMonth, lastMonth] = data;
    return lastMonth?.date;
  }

  get netAmount() {
    const lastDateData = first(orderBy(this.chartData, [item => dayjs(item.date, 'YYYY-MM-DD')], ['desc']));
    return lastDateData?.amount;
  }
  /**
  * Percentage of netCompareAmount relative to the total amount in the accounts.
  */
  get netComparePercentage() {
    const data = orderBy(this.chartData, [item => dayjs(item.date, 'YYYY-MM-DD')], ['desc']);
    const [currentMonth, lastMonth] = data;
    if (currentMonth && lastMonth) return this.netCompareAmount / lastMonth.amount;
    return null;
  }

  /**
   * Amount by which the current Yodlee account has relative to the previous selected time period.
   */
  get netCompareAmount() {
    const data = orderBy(this.chartData, [item => dayjs(item.date, 'YYYY-MM-DD')], ['desc']);
    const [currentMonth, lastMonth] = data;
    if (currentMonth && lastMonth) return currentMonth.amount - lastMonth.amount;
    return null;
  }

  ngAfterViewInit() {
    this.initHicharts();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const { currentValue, previousValue } = changes.value;
      if (currentValue !== previousValue) {
        this.initHicharts();
        this.cdRef.detectChanges();
      }
    }
  }

  refreshChart() {
    this.netWealthChart.chart.reflow();
  }

  initHicharts() {
    const chartDataAmount = this.chartData?.map(data => data?.amount);
    const chartDataLabels = this.chartData?.map(data => data?.date);

    this.chartOptions = {
      chart: {
        renderTo: 'chart',
        type: 'line',
        styledMode: true,
        className: 'spending-chart',
        height: 150,
        alignTicks: true,
        reflow: true,
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        backgroundColor: '#FCFFC5',
        borderColor: 'black',
        borderRadius: 10,
        borderWidth: 3,
        formatter: function () {
          const label = dayjs(this.x, 'YYYY-MM-DD');
          const date = label.isValid() ? label.format('MMM DD, YYYY') : this.x;
          return `<b>${date}</b>: $${this.y}`;
        },
      },

      xAxis: {
        categories: chartDataLabels,
        labels: {
          formatter: function (label) {
            const date = dayjs(label?.value, 'YYYY-MM-DD');
            return date.isValid() ? date.format('MMM YYYY') : label?.value;
          },
          style: {
            color: 'white',
          },
        },
      },

      yAxis: {
        title: {
          text: '',
          style: {
            color: '#FFFFFF',
          },
        },
      },

      scrollbar: {
        enabled: true,
      },

      plotOptions: {
        column: {
          borderRadius: 2,
          color: 'white'
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: 'Spending',
          data: chartDataAmount,
          pointPadding: 0,
          type: 'line',
          color: 'white',
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 200,
            },
          },
        ],
      },
    };

    this.cdRef.detectChanges();
  }

  getLastText() {
    if (!this.timePeriodSelect) return '';
    switch (this.timePeriodSelect.value) {
      case 'ALL_TIME':
        return 'the beginning';
      case 'YEARLY':
        return 'last year';
      case 'QUARTERLY':
        return 'the last quarter';
      case 'MONTHLY':
        return 'last month';
    }
  }

  routeToBreakdown() {
    this.router.navigateByUrl(ROUTE_NET_INCOME);
  }
}
