import { ROUTE_HOMEPAGE, USER_ACCESS_PERM_TYPES } from '@1bill-app/constants';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { UserService } from '@1bill-app/services/user/user.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-accessing-user-header',
  templateUrl: './accessing-user-header.component.html',
  styleUrls: ['./accessing-user-header.component.scss'],
})
export class AccessingUserHeaderComponent implements OnInit {
  subs = new SubSink();
  constructor(
    private userQuery: UserQuery,
    private userService: UserService,
    private router: Router,
    private platform: Platform,
  ) {}

  userAccessPermTypes = USER_ACCESS_PERM_TYPES;

  accessType: 'FULL' | 'VIEWER' | 'ADD_BILL';
  manageAs: string;

  manageAs$ = this.userQuery.manageAs$;
  accessType$ = this.userQuery.accessType$;

  isLoading$ = this.userQuery.isLoading$;

  ngOnInit() {
    this.subs.sink = this.manageAs$.subscribe((res) => {
      this.manageAs = res;
    });
    this.subs.sink = this.accessType$.subscribe((res) => {
      this.accessType = res;
    });

    this.userService.getAccessAs();
  }

  get isNativeIOS() {
    return Capacitor.isNativePlatform() && this.platform.is('ios');
  }

  getAccessType(accessLevel: string) {
    const lookup = {
      FULL: 0,
      ADD_BILL: 1,
      VIEWER: 2,
    };

    return this.userAccessPermTypes[lookup[accessLevel]];
  }

  async close() {
    await this.userService.resetAccessingAsUser(true);
    this.router.navigateByUrl(`${ROUTE_HOMEPAGE}`);
  }
}
