import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Auth } from 'aws-amplify';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class AppSecurityService {
  constructor(private alertController: AlertController, private logger: NGXLogger) {}

  async presentBlacklistedMobileAlert(forceSignOut = true) {
    const alertId = 'alert_mobile_blacklist';
    if (!document.getElementById(alertId)) {
      return await this.alertController
        .create({
          id: alertId,
          header: 'You entered a blacklisted mobile number',
          message:
            'Your account has been blacklisted as you have entered a mobile number that was involved in fraudulent activity on 1Bill.',
          buttons: ['OK'],
        })
        .then((alert) => {
          alert.present();
          if (forceSignOut) {
            this.logger.warn('Forcing sign out on user due to blacklisted mobile number.');
            Auth.signOut();
          }
          return alert;
        });
    }
  }

  async presentBlacklistedEmailAlert(forceSignOut = true) {
    const alertId = 'alert_email_blacklist';
    if (!document.getElementById(alertId)) {
      return await this.alertController
        .create({
          id: alertId,
          header: 'Email address was blacklisted',
          message:
            'This account was blocked as its email address matched our records of fraudulent activity on 1Bill.',
          buttons: ['OK'],
        })
        .then((alert) => {
          alert.present();
          if (forceSignOut) {
            this.logger.warn('Forcing sign out on user due to blacklisted email.');
            Auth.signOut();
          }
          return alert;
        });
    }
  }

  async presentBlacklistedAddressAlert(forceSignOut = true) {
    const alertId = 'alert_address_blacklist';
    if (!document.getElementById(alertId)) {
      return await this.alertController
        .create({
          id: alertId,
          header: 'A bill you uploaded contained blacklisted information',
          message:
            'This account was blocked as you have uploaded a bill with an address that has fraudulent activity history on 1Bill.',
          buttons: ['OK'],
        })
        .then((alert) => {
          alert.present();
          if (forceSignOut) {
            this.logger.warn('Forcing sign out on user due to blacklisted address.');
            Auth.signOut();
          }
          return alert;
        });
    }
  }

  async signOut() {
    return await Auth.signOut();
  }
}
