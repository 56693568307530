import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Sorts Suppliers for display in list
 * First sort by id (ascending order) then move suppliers
 * with "Other" as their name to the top of the list
 */
@Pipe({
  name: 'sortSuppliers',
})
export class SortSuppliers implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(suppliersList: Array<any>) {
    if (!suppliersList) return suppliersList;

    //Suppliers sorted by id
    const suppliersById = suppliersList.slice().sort((a, b) => (a.id < b.id ? -1 : 1));

    //Suppliers sorted to have "Other" at beginning of the list.
    const suppliersSorted = suppliersById.sort(function (a, b) {
      return ['OTHER', 'OTHER SUPPLIERS'].includes(a.name.toUpperCase())
        ? -1
        : ['OTHER', 'OTHER SUPPLIERS'].includes(b.name.toUpperCase())
        ? 1
        : 0;
      //return a.name.toUpperCase() == 'OTHER' ? -1 : b.name.toUpperCase() == 'OTHER' ? 1 : 0;
    });

    return suppliersSorted;
  }
}
