import { MissingInformationModal } from '@1bill-app/components/missing-information-modal/missing-information-modal.component';
import { SwitchCCIframeModalComponent } from '@1bill-app/components/modals/switch-cc-iframe-modal/switch-cc-iframe-modal.component';
import { YodleeFastLinkModal } from '@1bill-app/components/modals/yodlee-fast-link-modal/yodlee-fast-link-modal.component';
import { PopoverSimpleComponent } from '@1bill-app/components/popover/popover-simple/popover-simple.component';
import { EXPLANATION_NOTES_SIMPLE } from '@1bill-app/constants';
import { environment } from '@1bill-app/env';
import { ZenithPaymentMode, ZenithPaymentOptions } from '@1bill-app/interfaces/zenith-payment';
import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { applyTransaction } from '@datorama/akita';
import {
  AlertController,
  LoadingController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { SwitchBillDetails } from '../api/api-types';
import { ApiService } from '../api/api.service';
import { BillService } from '../bill/bill.service';
import { BillStore } from '../bill/bill.store';
import { formatQueryParams } from '../bill/helper';
import { BillCategoryKey } from '../bill/types';
import { showError } from '../custom-functions/custom-functions';
import { getAddressDetails } from '../google/google-address.service';
import { SettingsService } from '../settings/settings.service';
import { Settings } from '../settings/settings.store';
import { StatusService } from '../status/status.service';
import { StorageItemKeys, StorageService } from '../storage.service';
import { HomeData, HomeResponse, HomeStore } from './home.store';

declare let $: any;

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private homeStore: HomeStore,
    private billService: BillService,
    private statusService: StatusService,
    private logger: NGXLogger,
    private billStore: BillStore,
    private api: ApiService,
    private settingsService: SettingsService,
    private modalController: ModalController,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private loadingController: LoadingController,
    private storageService: StorageService,
  ) {}

  payment: any;
  svcApiUrlA = environment.svcApiUrlA;
  private _homeDataEvent = new Subject<{
    trigger: boolean;
    triggerSource: string;
    forceRefresh?: boolean;
  }>();

  homeDataEvent$ = this._homeDataEvent.asObservable();
  private _scrollToTop = new Subject<{ trigger: boolean; triggerSource: string }>();
  scrollToTop$ = this._scrollToTop.asObservable();

  private _homeRouteEvent = new Subject<{ trigger: boolean; message: string }>();
  homeRouteEvent$ = this._homeRouteEvent.asObservable();

  private _addBillEvent = new Subject();
  addBillEvent$ = this._addBillEvent.asObservable();
  fetchHome(forceRefresh: boolean = false) {
    return this.api.getData<HomeResponse>(this.svcApiUrlA + 'getHome', forceRefresh).pipe(
      tap(() => this.homeStore.setLoading(true)),
      map((response) => {
        this.logger.debug('HomeService::fetchHome() => fetched from network');
        this.homeStore.update({ ...response.data, success: response.success, loaded: true });
        return response;
      }),
      catchError((err) => {
        this.homeStore.setError(err);
        throw err;
      }),
      finalize(() => this.homeStore.setLoading(false)),
    );
  }

  addBillEvent() {
    this._addBillEvent.next();
  }

  getHome(fetch = false): Observable<HomeData> {
    if (this.homeStore.getValue().loaded && !fetch && !this.homeStore.getValue().refetch) {
      return of(this.homeStore.getValue());
    }
    this.homeStore.update({ refetch: false });
    return this.fetchHome(fetch).pipe(map((res) => res.data));
  }

  getAllData() {
    this.logger.debug('HomeService::getAllData() => call');
    return forkJoin({
      home: this.getHome(),
      bill: this.billService.getBill(),
      status: this.statusService.fetchStatus(),
      accountTerminationStatus: this.getAccountTerminationStatus(),
    });
  }

  fetchAllData(forceRefresh = false) {
    return forkJoin({
      home: this.fetchHome(forceRefresh),
      bill: this.billService.fetchBill(forceRefresh),
      status: this.statusService.fetchStatus(),
      accountTerminationStatus: this.getAccountTerminationStatus(),
      events: this.get1bEvents(),
      billPredictions: this.billService.billPredict(),
    });
  }
  /**
   * Trigger fetch home data
   * @param source The source where this function is called.
   * @param forceRefresh Whether to force refresh bill cache.
   */
  triggerFetchHomeData(source?: string, forceRefresh: boolean = false) {
    this._homeDataEvent.next({ trigger: true, triggerSource: source, forceRefresh });
    applyTransaction(() => {
      this.homeStore.update({ refetch: true, refetchSource: source, forceRefresh });
      this.billStore.update({ refetch: true, refetchSource: source, forceRefresh });
    });
  }

  scrollToTop(trigger: boolean, triggerSource?: string) {
    this._scrollToTop.next({ trigger, triggerSource });
  }

  setBillProgressInView(value: boolean) {
    this.homeStore.updateUI({ isBillProgressInView: value });
  }

  getAccountTerminationStatus() {
    return this.api.getAccountTerminationStatusFromDatabase();
  }

  async betterDealAddressAlert() {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message:
        '<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Missing full address!</h2><p class="alert-text">Please enter full address including street number and name.</p>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Got it',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  /**
   * Opens the ZenithPay secure iframe for adding a payment card.
   * @param replacingCardId The account payment ID of the card to be replaced.
   */
  async openZenithAddPaymentCard(replacingCardId?: number) {
    // based on the latest requriement, we do not need to verify the credit score
    // if(this.profileStrength<80){
    //   this.presentProfileCompletionModal();
    //   return;
    // }

    // Access this value after ZenithPay callback redirect
    if (replacingCardId) {
      this.storageService.setItem({
        key: StorageItemKeys.REPLACE_CARD_ID,
        value: replacingCardId,
      });
    }

    const accountSettings = await this.settingsService.fetch().toPromise();

    try {
      if (!accountSettings.email) {
        throw Error('Email address not found');
      }
      const onCarryingData = () => {
        // this.logger.log('fire sweet alert');
        Swal.fire({
          title: 'Initiating the payment plugin...',
          text: 'Please wait...',
          showConfirmButton: false,
        });

        Swal.close();
        // this.logger.log('loading zenith payment plugin');

        const zpPaymentOpts: ZenithPaymentOptions = {
          ...environment.zenith,
          title: 'Add Payment Card',
          mode: ZenithPaymentMode.TOKENISE,
          customerEmail: accountSettings.billEmail,
          customerReference: accountSettings.billEmail,
          additionalReference: accountSettings.billEmail,
          showFeeOnTokenising: true,
          hideHeader: true,
          hideMerchantLogo: false,
          hideTermsAndConditions: false,
          sendConfirmationEmailToMerchant: true,
        };
        this.payment = $.zpPayment(zpPaymentOpts);
        this.logger.log('open zenith payment plugin', zpPaymentOpts);
        this.payment.open();
      };

      if (!accountSettings.firstName || !accountSettings.lastName) {
        const missingInformationModal = await this.modalController.create({
          component: MissingInformationModal,
          componentProps: {
            firstName: accountSettings.firstName,
            lastName: accountSettings.lastName,
          },
        });
        await missingInformationModal.present();

        const dismissResult = await missingInformationModal.onWillDismiss();
        if (dismissResult.role === 'confirm') {
          const loading = await this.loadingController.create({
            message: 'Please wait...',
            mode: 'ios',
          });
          await loading.present();
          try {
            await this.settingsService
              .saveSettingDetails({
                option: 'NAME',
                settingsData: {
                  firstName: dismissResult.data?.firstName,
                  lastName: dismissResult.data?.lastName,
                },
              })
              .toPromise();
            onCarryingData();
          } catch (err) {
            this.errorAlert();
            throw Error(
              'Error saving first and last name from Add payment card option: ' + err,
            );
          } finally {
            await this.loadingController.dismiss();
          }
        }
      } else {
        onCarryingData();
      }
    } catch (err) {
      if (!environment.production) {
        console.error(err);
      }
      // send error log to server
      this.logger.error('onPaymentMethodClick', err);
      // display error toast
      showError('Error initialising payment. Please try again.', true);
    }
  }

  /**
   * Find better deals will show better deals modal
   * @param categoryKeySelected selected category key
   * @param settings settings data
   * @param placeId place id returned from google maps
   * @returns
   */
  findBetterDeals(
    categoryKeySelected: BillCategoryKey,
    settings: Settings,
    placeId: string = null,
  ) {
    return of(placeId).pipe(
      mergeMap((placeId) => from(getAddressDetails(placeId)).pipe(map((res) => res.result))),
      tap((res) => this.logger.log('getAddressDetails: ', res)),
      filter((googleAddress) => {
        if (googleAddress && googleAddress.streetNo) {
          return true;
        } else {
          this.betterDealAddressAlert();
          return false;
        }
      }),
      mergeMap((googleAddress) => {
        const billDetails: SwitchBillDetails = {
          solarPanels: false, // TODO: bill.hasSolar
          energy: categoryKeySelected === BillCategoryKey.ELECTRICITY, // whether the customer needs energy? bit confusing
          gas: categoryKeySelected === BillCategoryKey.GAS, // if gas bill
          internet: categoryKeySelected === BillCategoryKey.INTERNET, // if internet bill
          insurance: categoryKeySelected === BillCategoryKey.INSURANCE, // if insurance bill
          addressJson: googleAddress,
        };
        settings.identificationSelected = 'driver_licence'; // currently cnc only supports driver_licence
        return this.api.switchService(settings, billDetails);
      }),
      mergeMap((res) => {
        if (!res.userCanSwitch) {
          this.errorAlert(res.message || 'You are not eligible to switch bills');
          return of(false);
        }

        if (res.success && res.data.uniqueId) {
          const crmAccountId = res.data.uniqueId;
          this.logger.log('Switch svc success', res);
          this.presentFindDealsWindow(crmAccountId);
          return of(true);
          // return this.settingsService.fetch();
        } else {
          return of(false);
        }
      }),
      catchError((err) => {
        this.logger.log('Error in switch svc', err);
        this.errorAlert();
        return of(false);
      }),
    );
  }

  async errorAlert(customMessage?: string) {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: customMessage
        ? `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">${customMessage}</p>`
        : `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">Something went wrong! Please try again later.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Got it',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  /**
   * Present find better deals modal
   * @param crmAccountId crm account id
   * @returns
   */
  private async presentFindDealsWindow(crmAccountId: string) {
    const settings = await this.settingsService.fetch().toPromise();
    const redirectUrl = environment.findDealsRedirectUrl;
    const params = {
      billTypeId: `state:${btoa(
        JSON.stringify({ crmAcId: crmAccountId, email: settings.email }),
      )}`,
      crmAcId: crmAccountId,
      redirectUrl,
      electricityBillAvailable: false,
      gasBillAvailable: false,
      source: '1bill',
    };
    const querystring = formatQueryParams(params);
    const switchURL = environment.switchCCIframeUrl + `?${querystring}`;
    this.logger.log('iframe or native url', switchURL);
    // if ios native, use in app browser (for autofill and cookie issue), refresh page on close
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios') {
      return this.openWindow(switchURL);
    }
    const modal = await this.modalController.create({
      component: SwitchCCIframeModalComponent,
      cssClass: 'switch-cc-iframe-modal',
      componentProps: {
        title: 'Find better deals',
        switchURL,
      },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.logger.log('iframe modal dismissed');
      this.triggerFetchHomeData('BillPage::SwitchCCIframeModalComponent closed', true);
      // this.betterDealProcessing = false;
    }
  }

  /**
   * Opens the url.
   * In-app browser in app.
   * External tab in web.
   */
  private openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
      toolbarColor: 'ffffff',
    });

    Browser.addListener('browserFinished', () => {
      this.logger.log('native browser closed');
      // this.homeService.triggerFetchHomeData('BillPage::Switch open browser openWindow closed');
      // this.betterDealProcessing = false;
    });
  }

  triggerHomeRouteEvent(message: string) {
    this._homeRouteEvent.next({ trigger: true, message });
  }

  async presentLinkBankAccountModal() {
    const popover = await this.popoverController.create({
      component: PopoverSimpleComponent,
      cssClass: 'onebill-medium-popover',
      mode: 'ios',
      componentProps: {
        popoverTitle: EXPLANATION_NOTES_SIMPLE.CONFIRM_LINK_BANK_ACCOUNT_TITLE,
        descriptionArray: EXPLANATION_NOTES_SIMPLE.CONFIRM_LINK_BANK_ACCOUNT_DESC,
        buttonText: 'Proceed',
        hasBackButton: true,
      },
    });
    await popover.present();
    return popover.onWillDismiss().then(async (dismissResult) => {
      if (dismissResult.role === 'confirm') {
        const modal = await this.modalController.create({
          component: YodleeFastLinkModal,
          cssClass: 'yodlee-fast-link-modal',
        });
        await modal.present();
        return modal;
      }
    });
  }

  get1bEvents() {
    return this.api.get1bEvents().pipe(
      tap((eventsData) => {
        this.homeStore.update({ events: eventsData.events });
        this.billStore.update({ switchRewardPoints: eventsData.switchRewardPoints });
      }),
    );
  }
}
