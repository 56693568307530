import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode, persistState } from '@datorama/akita';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { debounceTime } from 'rxjs/operators';
import Amplify from '@aws-amplify/core';
import { STORAGE_APP_STATE_STORE } from './app/app.constants';

Amplify.configure(environment.amplify);
// if (!environment.production) {
//   Amplify.Logger.LOG_LEVEL = 'DEBUG';
// }
if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

akitaConfig({
  resettable: true,
});

const storage = persistState({
  key: STORAGE_APP_STATE_STORE,
  include: ['auth'],
  preStorageUpdate(storeName, state) {
    if (storeName === 'auth') {
      return {
        token: state.token,
        lastActivity: state.lastActivity,
      };
    }
    return state;
  },
  preStorageUpdateOperator: () => debounceTime(2000),
});
const providers = [{ provide: 'persistStorage', useValue: storage }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
