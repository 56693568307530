import { environment } from '@1bill-app/env';
import { CurrencyPipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService } from './services/in-memory-data/in-memory-data.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { CardIO } from '@ionic-native/card-io/ngx';
import { Device } from '@ionic-native/device/ngx';
import { DocumentScanner } from '@ionic-native/document-scanner/ngx';
import { Market } from '@ionic-native/market/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoggerModule } from 'ngx-logger';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreatePinScreenModule } from './components/create-pin-screen/create-pin-screen.module';
import { FeedbackFormComponentModule } from './components/feedback-form/header-logo.module';
import { HeaderLogoComponentModule } from './components/header-logo/header-logo.module';
import { LockScreenModule } from './components/lock-screen/lock-screen.module';
import { RemovePinScreenModule } from './components/remove-pin-screen/remove-pin-screen.module';
import { RewardBadgePopupModalModule } from './components/reward-badge-popup-modal/reward-badge-popup-modal.module';
import { UpdatePinScreenModule } from './components/update-pin-screen/update-pin-screen.module';
import { GlobalErrorHandler } from './services/global-error-handler';
import { interceptorProviders } from './services/interceptors/interceptors';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { CalendarModule } from 'ion2-calendar';
import { IonicSelectableModule } from 'ionic-selectable';
import { ActionDeniedModalComponentModule } from './components/action-denied-modal/action-denied-modal.module';
// HIDEREFERRALCODES
// import { ReferralPageModule } from './pages/referral/referral.module';
// import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    HttpClientModule,
    LoggerModule.forRoot(environment.loggerConfig),
    environment.production ? [] : AkitaNgDevtools.forRoot({ name: '1bill' }),
    AkitaNgRouterStoreModule,
    AppRoutingModule,

    //environment.production || !environment?.inMemoryWebApi
    //   ? []
    //   : HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
    //       passThruUnknownUrl: true,
    //     }),
    IonicStorageModule.forRoot({
      name: environment.localStorageDbName,
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    HeaderLogoComponentModule,
    FeedbackFormComponentModule,
    AngularSvgIconModule.forRoot(),
    RewardBadgePopupModalModule,
    LockScreenModule,
    CreatePinScreenModule,
    RemovePinScreenModule,
    UpdatePinScreenModule,
    CalendarModule,
    IonicSelectableModule,
    ActionDeniedModalComponentModule,
    // HIDEREFERRALCODES
    // ReferralPageModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...interceptorProviders,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    StatusBar,
    DocumentScanner,
    CardIO,
    Device,
    Market,
    NavParams,
    CurrencyPipe,
    Facebook,
    // HIDEREFERRALCODES
    // SocialSharing,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
