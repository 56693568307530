import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import {
  map,
  catchError,
  finalize,
  tap,
  filter,
  distinctUntilChanged,
  switchMap,
  delay,
} from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { PublishEventAction, WebsocketService } from '../websocket.service';
import { RewardStore } from './reward.store';
import { RewardActionCodeMapping } from './reward.type';

@Injectable({
  providedIn: 'root',
})
export class RewardService {
  popup$ = this.wsService.messages$.pipe(
    tap((data) => this.logger.log('popup$ first', data)),
    filter(
      (data) =>
        data.event === PublishEventAction.POPUP_REWARD &&
        data.type &&
        this.rewardStore.getValue().seenCodes.indexOf(data.type) === -1,
    ),
    tap((data) => this.logger.log('popup$ second', data)),
    distinctUntilChanged((prev, curr) => {
      this.logger.log('distinctUntilChanged', prev, curr);
      return (
        curr.event === PublishEventAction.POPUP_REWARD &&
        prev.event === curr.event &&
        prev.type === curr.type
      );
    }),
    tap((data) => this.logger.log('popup$ third', data)),
  );
  constructor(
    private rewardStore: RewardStore,
    private api: ApiService,
    private wsService: WebsocketService,
    private logger: NGXLogger,
  ) {}

  getRewards(badgeDisplayedCheck = false) {
    this.rewardStore.setLoading(true);
    return this.api.getRewards().pipe(
      map((response) => {
        if (response.success) {
          // let totalPoints = 0;
          // const filteredRewards = response.rewards.filter((reward) => reward.completed === 1);
          // filteredRewards.forEach((val) => (totalPoints += val.rewardPoints));
          this.logger.log('REWARDS LOGGER', response);
          this.rewardStore.update({
            ...response,
            ...{
              profileRewards: response?.rewards?.filter(
                (res) =>
                  res.isProfilePoint ||
                  res.actionCode == RewardActionCodeMapping.COMPLETE_PROFILE,
              ),
            },
          });
        }
        return response;
      }),
      tap((data) => {
        if (badgeDisplayedCheck) {
          data.rewards
            .filter((r) => r.completed && r.showBadge && !r.badgeDisplayed)
            .map((r) => {
              this.wsService.feedPopupMessage(r.actionCode);
            });
        }
      }),
      catchError((err) => {
        this.rewardStore.setError(err);
        throw err;
      }),
      finalize(() => this.rewardStore.setLoading(false)),
    );
  }

  getUserPoints() {
    return this.api.getUserPointsBalance().pipe(
      tap((response) => {
        if (response.success) {
          this.rewardStore.update({
            totalEarnedPoints: response.totalEarnedPoints,
            totalPointsBalance: response.totalPointsBalance,
            totalRedeemedPoints: response.totalRedeemedPoints,
          });
        }
      }),
      catchError((error) => {
        this.rewardStore.setError(error);
        throw error;
      }),
    );
  }

  markRewardDisplayedWithActionId(actionCode: RewardActionCodeMapping) {
    const reward = this.rewardStore
      .getValue()
      .rewards.find((r) => r.actionCode === actionCode);
    const seenCodes = [...this.rewardStore.getValue().seenCodes, actionCode];
    return this.api.markRewardDisplayed(reward.rewardId).pipe(
      map(() => {
        this.rewardStore.update({ seenCodes });
      }),
    );
  }

  markRewardCompletedWithActionId(rewardActionId: number) {
    this.rewardStore.setLoading(true);
    return this.api.markRewardCompleted(rewardActionId).pipe(
      switchMap((rewardres) => this.getRewards()),
      catchError((err) => {
        this.rewardStore.setError(err);
        throw err;
      }),
      finalize(() => this.rewardStore.setLoading(false)),
    );
  }

  /**
   * Runs the API to check if the user is eligible for receiving the
   * reward for having a user sign up with their referral code by
   * checking that both accounts have their mobile numbers verified.
   * Returns a promise of a boolean representing whether or not
   * the user was eligible and therefore rewarded with the points for
   * user referral.
   */
  // HIDEREFERRALCODES
  // getReferralCodeEligiblity() {
  //   return this.api.getReferralCodeEligibility();
  // }
}
