import { BillRating } from "@1bill-app/services/bill/types";

/**
 * Function to return lowercase trimmed string
 * @param char
 * @returns
 */
export function toLowerCaseTrimSafe(char: string) {
  try {
      return char ? char.toString().toLowerCase().trim() : null;
  } catch (err) {
      return char;
  }
}

export function isNumber(n: any) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export const hasTokenExpired = (issuedAt: string, expiresIn: number) => {
  if (issuedAt && expiresIn) {
    // Check if it has expired
    const expiredIn = new Date(issuedAt);
    expiredIn.setSeconds(expiredIn.getSeconds() + expiresIn);
    const now = new Date();
    const hasExpired = now > expiredIn;
    if (!hasExpired) return false;
  }
  return true;
};

export function getChartTitle() {
  const dailyAverage = [
    'Origin Energy',
    'AGL Energy',
    'Energy Australia',
    'Alinta Energy',
    'Simply Energy',
    'Red Energy',
    'Lumo Energy',
    'Diamond Energy',
    'GloBird',
  ];
  if (dailyAverage.includes(this.billDetails.provider)) return 'Daily average';
  return 'Monthly usage';
}

export function fromRatingNumber(rating: number): BillRating {
  if (rating >= 5) return BillRating.Excellent;
  else if (rating >= 4 && rating < 5) return BillRating.Good;
  else if (rating >= 3 && rating < 4) return BillRating.Ordinary;
  else if (rating < 3) return BillRating.Bad;
  this.logger.warn('fromRatingNumber(): No valid bill rating number given. Returning \'Good\' value as default.');
  return BillRating.Good;
}
