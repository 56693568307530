import { OpUnitType } from 'dayjs';

export enum ONEBILL_APP_ID {
  ios = 'id1495136517',
  android = 'ai.onebill.app',
}

export const DISPLAY_SIDE_MENU = false;

export const HOME_UPCOMING_BILL_LIST_COUNT = 5;
export const STATUS_POLLING_INTERVAL_MS = 5000;

export const AUTH_RESEND_MOBILE_VERIFICATION_INTERVAL = 60; // in seconds
// ROUTES
export const ROUTE_HOMEPAGE = '/app/home';
export const ROUTE_HISTORY_PAGE = '/app/history';
export const ROUTE_BILLS_PAGE = '/app/bills';
export const ROUTE_ADD_BILL_MANUALLY = '/app/add-bill-manually';
export const ROUTE_CREDIT_SCORE_PAGE = '/credit-score';
export const ROUTE_UPLOAD_FIRST_BILL_SELECT_PAGE = '/first-bill/select';
// export const ROUTE_SIGNIN_PAGE = '/auth/signin';
// export const ROUTE_SIGNIN_WITH_EMAIL_PAGE = '/auth/signin-with-email'; // old unused route
export const ROUTE_BILL_DETAILS = '/bill';
export const ROUTE_UPLOAD_YOUR_BILL = '/upload-your-bill';
export const ROUTE_EMAIL_BILLS = '/email-bills';
export const ROUTE_CONNECT_EMAIL = '/connect-email';
export const ROUTE_VERIFY_EMAIL = '/auth/verify-email';
export const ROUTE_SETTINGS_MAIN = '/app/settings';
export const ROUTE_SETTINGS_PAYMENT_INFO = '/payment-info';
export const ROUTE_SETTINGS_NOTIFICATION = '/app/settings/notifications';
export const ROUTE_SETTINGS_CONTACT_DETAILS = '/app/settings/contact-details';
export const ROUTE_SETTINGS_ID_DETAILS = '/app/settings/id-details';

// NEW ROUTES
export const ROUTE_LOGIN = '/auth/login';
export const ROUTE_BASE = '/auth/landing';
export const ROUTE_LANDING = '/auth/landing';
export const ROUTE_TERMS = '/auth/terms';
export const ROUTE_CREATE_ACCOUNT = '/auth/create-account';
export const ROUTE_UPDATE_NAME = '/auth/update-name';
export const ROUTE_ALLOW_NOTIFICATIONS = '/auth/allow-notifications';
export const ROUTE_MOBILE_NUMBER = '/auth/mobile-number';
export const ROUTE_MOBILE_VERIFICATION = '/auth/mobile-verification';
export const ROUTE_POST_MOBILE_VERIFICATION = '/auth/post-mobile-verification';
export const ROUTE_UPLOAD_FIRST_BILL = '/first-bill-upload';
export const ROUTE_SCREEN_LOCK = '/auth/lock';
export const ROUTE_EMAIL_SCANNER = '/email-scanner';
export const ROUTE_EMAIL_SCANNER_JOBS = '/email-scanner-jobs';
export const ROUTE_LAT_PAY_CALLBACK = '/lat-pay';
export const ROUTE_OFFERS = '/app/offers';
export const ROUTE_OFFERS_REDEEMED = '/app/offers/redeemed';
export const ROUTE_HELP = '/app/help';
export const ROUTE_REWARDS = '/app/offers';
export const ROUTE_USER_PROFILE = '/app/user-profile';
export const ROUTE_OFFER_CAT = '/app/offers/category-offers';
export const ROUTE_POINTS_INFO = '/points-info';
export const ROUTE_FIND_MORE_SAVINGS = '/find-more-savings';
export const ROUTE_NET_INCOME = '/net-income';
export const ROUTE_CASH_SCREEN = '/cash-screen';
export const ROUTE_ACCOUNT_VIEW = '/cash-screen/account-view';
export const ROUTE_PAYMENT_OPTIONS = '/payment-options';
export const ROUTE_LINK_BILLS_SCREEN = '/link-bill-screen';
export const ROUTE_UNSUBSCRIBE = '/unsubscribe';
export const ROUTE_NO_NETWORK = '/auth/no-network';
export const ROUTE_USER_MANAGEMENT = '/user-management';

export const ROUTE_GET_BETTER_DEALS = '/get-better-deals';
export const ROUTE_CONTACT_US = '/contact-us';
// ROUTE FRAGMENTS
export const ROUTE_FRAGMENT_UPCOMING_BILLS = 'dashboard-upcoming-bills-section';

// IMG or PDF constants
export const PDF_FILE = 'pdf';
export const IMG_FILE = 'img';
// STORAGE
export const STORAGE_APP_STATE_STORE = '1bill.store';
// SESSION
export const SESSION_TIMEOUT_VALUE: number = 30;
export const SESSION_TIMEOUT_UNIT: OpUnitType = 'minute';
// TEXT
export const TEXT_SESSION_EXPIRED = 'Your session has expired.';
export const TEXT_SESSION_EXPIRED_TOAST =
  'Your session has expired. You need to sign in again.';
export const LAT_PAY_CALLBACK_SESSION_EXPIRED_SOURCE = 'lat-pay-force-callback';
// COLORS
export const COLOR_RATING_VALUES = ['#E8502F', '#E8502F', '#FDC40C', '#52C681', '#52C681'];
// APP CONFIG
export const APP_CONFIG = {
  TOAST_ERROR_DURATION: 4000,
  TOAST_ERROR_CLOSE_TEXT: 'X',
  TOAST_SUCCESS_DURATION: 4000,
  TOAST_SUCCESS_CLOSE_TEXT: 'X',
};
// PIN
export const PIN_ATTEMPT_LIMIT = 3;

export const EMAIL_SCANNER_INITIAL_CONFIG = [
  // Enabling gmail option for now after security assessment
  {
    name: 'Gmail',
    key: 'gmail',
    active: false,
    isValid: null,
    route: 'email-scanner/gmail',
    icon: 'assets/icon/contact-details.svg',
  },
  {
    name: 'Yahoo',
    key: 'yahoo',
    active: false,
    isValid: null,
    route: 'email-scanner/yahoo',
    icon: 'assets/icon/contact-details.svg',
  },
  {
    name: 'Hotmail/Outlook',
    key: 'hotmail',
    active: false,
    isValid: null,
    route: 'email-scanner/hotmail',
    icon: 'assets/icon/contact-details.svg',
  },
];

export const SEND_VIA_EMAIL_TITLE = 'Get bills sent from your provider';
export const EXPLANATION_NOTES_SIMPLE = {
  UPDATE_BALANCE_TITLE: 'Update balance',

  BILL_UPLOAD_SUCCESS_ICON: '../assets/icon/alert/successTwo.svg',
  BILL_UPLOAD_SUCCESS_TITLE: 'Bill has been successfully uploaded',
  BILL_UPLOAD_SUCCESS_DESC:
    'We have successfully uploaded your bill! We are now scanning and processing the information in your bill.',
  BILL_UPLOAD_SUCCESS_DESC_TWO:
    'We will alert you when your bill has scanned. P.S you can keep using the app while we scan!',

  CREDIT_SCORE_TITLE: 'Credit Score',
  CREDIT_SCORE_DESC:
    'The Credit Score is a tool used by the lenders associated with 1bill to assess customers for the funding of bills. The 1bill Credit Score has 3 tiers:<br> 0 - 299   Bad;<br> 300 - 599   Normal; <br> 600 - 1000   Good ',
  YOUR_PROFILE_TITLE: 'Your Profile',
  YOUR_PROFILE_DESC:
    'Your Profile compiles information that helps identify you and enables the speedy processing and payment of your bills.',
  POINTS_TITLE: 'What are 1bill Points?',
  POINTS_DESC:
    "1bill Points are 1bill's loyalty & reward program for 1bill users. Many of the actions a 1bill user performs will earn them 1bill points. Most of these actions you would do in the everyday payment & management of your bills. Only with 1bill, you do those same actions and earn points which you can use to purchase a large selection of products. To see the products you can buy with your 1bill points, and the process of redeeming your selections, go to the Offers page.",
  EMAIL_INFO_TITLE: 'How to scan email account for bills',
  EMAIL_INFO_DESC: 'Insert instructions on how to scan email for bills...',
  UPLOAD_INFO_TITLE: 'How to upload or take a photo of your bill',
  UPLOAD_INFO_DESC:
    'Place your bill on a dark surface and hold the camera over the bill ensuring the entire bill is in view. Take a photo of ALL the pages of the bill.',
  MANUAL_PAYMENT_TITLE: 'Manual Payment Required',
  MANUAL_PAYMENT_DESC_CARDS:
    'This bill needs to be paid manually by you.\nTo enable automatic payments, please edit your Payment Instructions settings.',
  MANUAL_PAYMENT_DESC_NO_CARDS:
    'This bill needs to be paid manually by you.\nTo enable automatic payments, you must first add a payment card.',
  MANUAL_PAYMENT_LINK_CARDS: ROUTE_SETTINGS_PAYMENT_INFO,
  MANUAL_PAYMENT_LINK_NO_CARDS: ROUTE_PAYMENT_OPTIONS,
  FEEDBACK_TITLE: 'What is device info?',
  FEEDBACK_DESC:
    'The information from the device you are using is utilised so 1bill can address technical issues and improve customer services.',
  FILTER_DESC: 'Show all bills from one address, or all bills of a certain type.',
  EDIT_TAG_TITLE: 'Tags',
  EDIT_TAG_DESC:
    ' You can Edit the tag linked to this bill (eg. "Investment property"; "Mum\'s house"). This will then become an option to Filter your bills. Default is the property address.',
  FILTER_BILL_TITLE: 'Filter',
  FILTER_BILL_DESC:
    'Use the Filter to display all bills from the one address, all bills of a certain type, or bills with a user created Tag.',
  EMAIL_SCANNER_TITLE: 'How does the email scanner work?',
  EMAIL_SCANNER_DESC: [
    `The Email Scanner works by scanning your email account daily for any bills. The initial scan searches for bills received in the previous 12 months.`,
    `Once set up, bills emailed to you will be automatically uploaded to your 1bill account, saving you the time of looking for, and uploading them, manually.`,
    `The scanner only looks for PDFs of bills. No other emails are scanned.
    Gmail uses OAuth protocol for secure authentication and authorisation to access emails. For other mail providers, your login credentials are encrypted and secure.`,
  ],
  YD_BANK_AC_TITLE: 'Why link a bank account?',
  YD_BANK_AC_DESC: [
    `Linking your bank account to your 1bill account gives you a view of your personal finances and upcoming bills in the one place.
    You can then see your bank account balance at the time bill payment is required, and reminders can be sent if your balance is lower than the next bill amount.`,
    `1bill takes security seriously and all your information is kept safe using best-practice security standards and procedures.
    Your bank account is connected to the 1bill App via a secure application programming interface (API).`,
    `This process is handled by Envestnet Yodlee, a global leader in banking technology.
    Your login credentials are sent to Yodlee using enforced end-to-end encryption. We do not store or record your login details.`,
    `This is for the purpose of scanning your bank account for payments and your financial summary only.
    Payment of bills by 1bill from your bank account is not available at this time, but is in the pipeline.`,
    `For 1bill Payment options, please go to: <br /><a href="payment-info">1bill payment info</a>`,
  ],
  CONFIRM_LINK_BANK_ACCOUNT_TITLE: 'Linking your bank account',
  CONFIRM_LINK_BANK_ACCOUNT_DESC: [
    'Note: This is to scan transactions to help in the management & payment of bills, and provide a financial summary.',
    'Payment of bills directly from your bank account is not available at this time.',
  ],
  NOTIFY_DEVICE_TITLE: 'Notifications on your device',
  NOTIFY_DEVICE_DESC: [
    'If using 1bill on a mobile device, enabling this option will allow you to receive push notifications from the 1bill app.',
    'Push notifications allow you to easily intake, manage and organise the notifications you receive from 1bill on your mobile device.',
  ],
  BUNDLED_BILL_TITLE: 'Paying bills in a bundle',
  BUNDLED_BILL_DESC: [
    'Using bundled bill payments allows you to pay off several of your bills, from any provider, all at once.',
    `Please note that bills from some billers may not be payable via 1bill for a variety of reasons.`,
  ],
};

export const SQLITE_DB_NAME = '1bill_db';
export const BILL_UPLOAD_NOTE = 'Residential bills only accepted at this time.';
export const SQLITE_TABLE_APP_LOGS = 'app_logs_v2';
export const SQLITE_TABLE_PUSH_LOGS = 'push_logs_v2';

export const GOOGLE_OAUTH_GMAIL_INSTRUCTIONS: { title: string; desc: string }[] = [
  {
    title: 'Step 1: Select your Google account ',
    desc: `After you click the button Sign in with Google, you will be presented a screen with the list of Google accounts.
    If not currently logged in with Google, you will be asked to login.`,
  },
  {
    title: 'Step 2: Allow 1bill permission for Gmail',
    desc: `You might be presented with the screen about app verification. If so, click "Advanced" and then click "Go to 1bill.com".`,
  },
  {
    title: `Step 3: Confirm authorisation`,
    desc: `You will need to confirm 1bill final authorisation to access your Gmail.`,
  },
  {
    title: `Step 4: Success`,
    desc: `Finally, you will be presented with a success screen for Gmail authorisation. Please click "Done" if you are in mobile
    and "Take me there" button if you are in web version of 1bill app.`,
  },
];

export const EMAIL_SCANNER_MAIN_INFO = `1bill allows you to link your email address to your 1bill account to upload bills directly to your 1bill account. 
You will need to login to your email through 1bill and authorise 1bill to access your emails. 
Your password is encrypted and cannot be read by 1bill.
1bill will only scan for emails that contain bills and will not read other emails.`;

export const TEXTAREA_MAX_LENGTH = 200;
export const PAY_BILL_POINTS_REWARD = 100;
export const BILL_FEE = 0.015;

export const YODLEE_ACCOUNT_NAME_LIMIT = 100;
export const YODLEE_ACCOUNT_NICKNAME_LIMIT = 50;

// Style colours
export const CSS_COLOR_PRIMARY = '#1da061';
export const CSS_COLOR_LIGHTERPRIMARY = '#11dc93';

export const HELP_TOPICS = [
  {
    name: 'Bills',
    iconPath: '../assets/icon/v3/new-design-icons/bills.svg',
    bgColour: '#e0e6f0',
    keywords: ['bill'],
  },
  {
    name: 'Payment',
    iconPath: '../assets/icon/v3/new-design-icons/creditCard.svg',
    bgColour: '#e0e6f0',
    keywords: ['bill_payment'],
  },
  {
    name: 'Rewards',
    iconPath: '../assets/icon/v3/new-design-icons/rewards.svg',
    bgColour: '#e0e6f0',
    keywords: ['1bill_points', 'rewards'],
  },
  {
    name: 'Profile',
    iconPath: '../assets/icon/v3/new-design-icons/profile.svg',
    bgColour: '#e0e6f0',
    keywords: ['account', 'profile'],
  },
];

export const MOBILE_VIEW_THRESHOLD_PX = 992;

/**
 * An object of key: value pairs where the keys are the amount of 1bill points for promotional codes,
 * and the values are the corresponding dollar amounts of those promotional codes.
 */
export const PROMO_CODE_DOLLAR_AMOUNT_MAP = {
  1000: 10.0,
  2000: 20.0,
};

/**
 * An object of key: value pairs where the keys are the amount of 1bill points for promotional codes,
 * and the values are the corresponding dollar amounts of those promotional codes.
 */
export enum BillCalendarEventColours {
  upcomingUnpaid = '#3880ff', // blue
  processingPayment = '#ffc409', // yellow
  paid = '#34D688', // green
  overdue = '#FF8080', // red,
}

/**
 * The different types of User Permission Types for Multi-User Permission Matrix
 */
export const USER_ACCESS_PERM_TYPES: {
  name: 'FULL' | 'VIEWER' | 'ADD_BILL';
  title: 'Full' | 'Viewer' | 'Add Bill';
  description: string;
  colour: string;
}[] = [
  {
    name: 'FULL',
    title: 'Full',
    description: `User can perform all functions including bill payment, uploading of bills, adding of users and switching services`,
    colour: '#11DC93',
  },
  {
    name: 'ADD_BILL',
    title: 'Add Bill',
    description: `User can add bills though cannot pay or edit payment information`,
    colour: '#EF8738',
  },
  {
    name: 'VIEWER',
    title: 'Viewer',
    description: `User can view all bills, cannot add new ones or authorise payments`,
    colour: '#D8545C',
  },
];

export const SALARY_INTERVAL_OPTIONS: {
  value: 'week' | 'fortnight' | 'month' | 'year';
  name: string;
  default?: boolean;
}[] = [
  {
    value: 'week',
    name: 'Weekly',
    default: true,
  },
  {
    value: 'fortnight',
    name: 'Fortnightly',
  },
  {
    value: 'month',
    name: 'Monthly',
  },
  { value: 'year', name: 'Yearly' },
];

export const PAYMENT_COMBINED_MODAL_ID = 'paymentCombinedModal';
export const REFERRAL_CODE_LOCAL_STORAGE_KEY = 'onebill_referralCode';
export const REFERRAL_CODE_QUERY_PARAM_KEY = 'ref';
