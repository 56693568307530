import { appProductTypeDefaultImg, appProductTypes } from '@1bill-app/services/bill/types';
import { YodleeTransaction } from '@1bill-app/services/yodlee/yodlee.types';

export const getTransactionBillerLogo = (transaction: YodleeTransaction) => {
  if (transaction?.billerLogo) {
    return transaction.billerLogo;
  } else {
    return (
      appProductTypes.find((p) => p.key === transaction?.billType)?.imgUrl ||
      appProductTypeDefaultImg
    );
  }
};

export const getTransactionBillerName = (transaction: YodleeTransaction) => {
  if (transaction?.billerName) return transaction.billerName;
  return transaction?.description?.simple;
};

export const getTransactionStatus = (transaction: YodleeTransaction) => {
  switch (transaction?.status) {
    case 'PENDING':
      return 'Pending';
    case 'POSTED':
      return 'Paid';
    default:
      return 'Pending';
  }
};
