import { Component, OnInit } from '@angular/core';
import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { ChangeDetectionStrategy, Input } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { HomeService } from '@1bill-app/services/home/home.service';

@Component({
  selector: 'app-connected-account-list',
  templateUrl: './connected-account-list.component.html',
  styleUrls: ['./connected-account-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedAccountListComponent{
  @Input() accounts: YodleeAccount[];

  constructor(
    private logger: NGXLogger,
    private homeService: HomeService,
  ) {}

  trackByFn(account: YodleeAccount) {
    return account?.id;
  }

  async handleLinkBankAccount() {
    this.homeService.presentLinkBankAccountModal();
  }
}
