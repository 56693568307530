import { SpendingCategory } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-spending-item',
  templateUrl: './spending-item.component.html',
  styleUrls: ['./spending-item.component.scss'],
})
export class SpendingItemComponent implements OnInit {
  @Input() data: SpendingCategory;
  @Input() color: string;

  constructor() {}

  get isNegative(): boolean {
    return this.data?.type === 'EXPENSE';
  }

  ngOnInit() {}

}
