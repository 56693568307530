import { Injectable, EventEmitter } from '@angular/core';
import { UserStore } from './user.store';
import { UserResponse, ViewMultiUserAccessType } from './user.types';
import { environment } from '@1bill-app/env';
import { catchError, filter, map, tap, finalize } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AlertController, ToastController } from '@ionic/angular';
import { applyTransaction } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { LoadingService } from '../loading/loading.service';
import { NotificationService } from '../notification/notification.service';
import { BillService } from '../bill/bill.service';
import { APP_CONFIG } from '@1bill-app/constants';
import { SettingsService } from '../settings/settings.service';
import { forkJoin } from 'rxjs';
import { HomeService } from '../home/home.service';
import { StatusService } from '../status/status.service';
import { StorageItemKeys, StorageService } from '../storage.service';
import { Auth } from 'aws-amplify';
import { getReferralCodeFromLocalStorage } from '@1bill-app/helpers/storage.helper';
import { AppSecurityService } from '../app-security.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private userStore: UserStore,
    private api: ApiService,
    private alertController: AlertController,
    private logger: NGXLogger,
    private _loadingSvc: LoadingService,
    private notificationService: NotificationService,
    private billService: BillService,
    private settingsService: SettingsService,
    private statusService: StatusService,
    private homeService: HomeService,
    private storageService: StorageService,
    private appSecurityService: AppSecurityService,
    private toastController: ToastController,
  ) {}
  svcApiUrlA = environment.svcApiUrlA;
  svcApiUrlC = environment.svcApiUrlC;

  /**
   * Emitted values should match the termination status, i.e. if termination requested, then 1, if cancel requested, then 0.
   */
  accountTerminationStatusChange: EventEmitter<1 | 0> = new EventEmitter();

  setPinEnabled(value: boolean) {
    return this.userStore.update(() => ({
      pinEnabled: value,
    }));
  }

  getUser() {
    return this.api.getUser().pipe(
      tap((response: UserResponse) => {
        if (response.success && response.data) {
          this.userStore.update({
            user: response.data,
            // salaryPerInterval: response.data.salaryPerInterval,
            // salaryInterval: response.data.salaryInterval,
          });
        }
      }),
    );
  }

  checkIfMobileNumberIsUnique(data: { mobileNumber: string }) {
    return this.api.checkIfMobileNumberIsUnique(data);
  }

  async triggerUserLogin() {
    this.userStore.setLoading(true);
    return this.api
      .triggerUserLogin()
      .toPromise()
      .then(async (loginCount) => {
        this.userStore.update({ loginCount: loginCount });
        // HIDEREFERRALCODES
        // if (loginCount < 2) {
        //   const referralCodeStorage = getReferralCodeFromLocalStorage();
        //   if (referralCodeStorage) {
        //     this.submitReferralCode(
        //       await this.getUserEmail(),
        //       referralCodeStorage,
        //     ).subscribe();
        //   }
        // }
      })
      .finally(() => {
        this.userStore.setLoading(false);
      });
  }

  getUserLoginCount() {
    return this.api.getUserLoginCount().pipe(
      tap((loginCount) => {
        this.userStore.update({ loginCount: loginCount });
      }),
    );
  }

  getUserRegistrationComplete() {
    return this.api.getUserRegistrationComplete().then((result) => {
      if (result.userBlacklisted) {
        this.appSecurityService.presentBlacklistedEmailAlert();
      }
      return result;
    });
  }

  cancelAccountTermination() {
    return this.api.cancelAccountTermination().pipe(
      tap(() => {
        this.accountTerminationStatusChange.emit(0);
      }),
    );
  }

  requestAccountTermination(terminationReason?: string) {
    return this.api.requestAccountTermination(terminationReason).pipe(
      tap(() => {
        this.accountTerminationStatusChange.emit(1);
      }),
    );
  }

  accountTerminationStatus() {
    return this.api.accountTerminationStatus();
  }

  getAccountTerminationStatusFromDatabase() {
    return this.api.getAccountTerminationStatusFromDatabase();
  }

  async presentAccountCancelTerminationAlert() {
    return new Promise<boolean>((resolve, reject) => {
      this.alertController
        .create({
          mode: 'ios',
          cssClass: 'bill-alert',
          header: 'Cancel account termination?',
          message: 'Please confirm if you want to cancel your 1bill account termination.',
          buttons: [
            {
              text: 'Cancel',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                resolve(false);
              },
            },
            {
              text: 'Yes, cancel it.',
              handler: () => {
                resolve(true);
              },
            },
          ],
        })
        .then((alert) => {
          alert.present();
        });
    });
  }

  updateSalary(salaryAmount: number, interval: 'week' | 'fortnight' | 'month' | 'year') {
    return this.api.updateUserSalary(salaryAmount, interval).pipe(
      tap((updateResult) => {
        this.userStore.update({ salaryPerInterval: salaryAmount, salaryInterval: interval });
      }),
    );
  }

  deleteSalary() {
    return this.api.deleteUserSalary().pipe(
      tap((deleteResult) => {
        this.userStore.update({ salaryPerInterval: null, salaryInterval: null });
      }),
    );
  }

  /**
   * Handles Approval/Denial of invitations/requests and also when user
   * revokes another user's access to their account
   */
  updateMUAReqAndRevokedStatus(
    actionPerformed:
      | 'APPROVED_REQUEST'
      | 'ACCEPTED_INVITATION'
      | 'DENIED_REQUEST'
      | 'REJECTED_INVITATION'
      | 'REVOKED_ACCESS'
      | 'REVOKED_INVITATION'
      | 'REVOKED_REQUEST',
    mua: ViewMultiUserAccessType,
  ) {
    return this.api.updateMUAReqAndRevokedStatus(actionPerformed, mua);
  }

  sendUserAccessRequest(reqBody: {
    receiverEmail: string;
    accessLevel: 'FULL' | 'VIEWER' | 'ADD_BILL';
    requestType: 'INVITATION' | 'REQUEST';
  }) {
    return this.api.sendUserAccessRequest(reqBody);
  }

  /**
   * Called when user wants to access 1Bill as another user
   * @param mua
   * @param loading
   */
  async activateAccessingAsUser(mua: ViewMultiUserAccessType, loading = true) {
    await this.updateManageAsUserAndAccessType(
      mua.accessedUserEmail,
      mua.accessLevel,
      loading,
      mua,
    );
  }

  /**
   * Called when the user clicks the "Close" button in the
   * `accessing-user-header` while accessing 1Bill as another user.
   * @param loading
   */
  async resetAccessingAsUser(loading = true) {
    await this.updateManageAsUserAndAccessType(null, null, loading);
  }

  /**
   * Sets `manageAs` and `accessType` in UserStore to provided values.
   * @param manageAsUserEmail
   * @param accessType
   * @param loading
   */
  async updateManageAsUserAndAccessType(
    manageAsUserEmail: string,
    accessType: 'FULL' | 'VIEWER' | 'ADD_BILL',
    loading = true,
    mua?: ViewMultiUserAccessType,
  ) {
    if (loading) {
      this.userStore.setLoading(true);
      await this._loadingSvc.presentLoading();
    }
    try {
      // If trying to access a user, check if valid first
      if (manageAsUserEmail != null && accessType != null) {
        const valid = await this.attemptAccessUser(mua).toPromise();
        this.logger.log('Is MUA valid?:', valid);

        // If not valid, throw error
        if (valid == false) {
          this.presentToast('danger', "You don't have permission to access this user!");
          throw new Error('Your permissions to access this user are invalid');
        }
      }

      this.logger.log('updating user store');
      await this.userStore.update({
        manageAs: manageAsUserEmail,
        accessType: accessType,
      });

      // If user pressed "Close", clear local storage.
      // Else, save to local storage.
      if (manageAsUserEmail == null && accessType == null) {
        // Clear local Storage
        this.logger.log('Cleared local storage for Access As');
        this.storageService.removeItem(StorageItemKeys.MANAGE_AS);
        this.storageService.removeItem(StorageItemKeys.ACCESS_TYPE);
      } else {
        // Save to local Storage
        this.logger.log('Saved to local storage for Access As');
        this.storageService.setItem({
          key: StorageItemKeys.MANAGE_AS,
          value: manageAsUserEmail,
        });
        this.storageService.setItem({
          key: StorageItemKeys.ACCESS_TYPE,
          value: accessType,
        });
      }

      this.logger.log(
        'updated user store:',
        this.userStore.getValue().manageAs,
        this.userStore.getValue().accessType,
      );

      await this.getInitDisplayData();

      if (loading) {
        this.userStore.setLoading(false);
        await this._loadingSvc.dismissLoading();
      }
    } catch (err) {
      applyTransaction(() => {
        this.userStore.setError(true);
        if (loading) this.userStore.setLoading(false);
      });
      throw err;
    }
  }

  async getInitDisplayData() {
    const res = await forkJoin({
      home: this.homeService.fetchHome(true),
      bill: this.billService.fetchBill(true),
      status: this.statusService.fetchStatus(),
      accountTerminationStatus: this.homeService.getAccountTerminationStatus(),
      events: this.homeService.get1bEvents(),
      billPredictions: this.billService.billPredict(),
      settings: this.settingsService.fetch(),
      notifications: this.notificationService.getNotifications(false),
      multiUserAccesses: this.getMultiUserAccesses().toPromise(),
    }).toPromise();

    this.logger.log(res);
    return res;
  }

  attemptAccessUser(mua: ViewMultiUserAccessType) {
    return this.api.attemptAccessUser({ mua }).pipe(
      map((response) => {
        if (response.success) {
          return response.isValid;
        } else {
          return false;
        }
      }),
    );
  }

  async getAccessAs() {
    const manageAs = await this.storageService.getItem(StorageItemKeys.MANAGE_AS);
    const accessType = await this.storageService.getItem(StorageItemKeys.ACCESS_TYPE);

    if (manageAs && accessType) {
      this.userStore.setLoading(true);
      await this.userStore.update({
        manageAs: manageAs,
        accessType: accessType as 'FULL' | 'ADD_BILL' | 'VIEWER',
      });
      await this.getInitDisplayData();
      this.userStore.setLoading(false);
    }
    this.logger.log('Access As:', manageAs, accessType);
  }

  getMultiUserAccesses(loading = true) {
    if (loading) this.userStore.setLoading(true);

    return this.api.getMultiUserAccesses().pipe(
      map((response) => {
        if (response.success) {
          this.userStore.update({
            multiUserAccesses: response.data,
          });
          return response.data;
        } else {
          return [];
        }
      }),
      tap(() => {
        if (loading) this.userStore.setLoading(false);
      }),
      catchError((err) => {
        applyTransaction(() => {
          this.userStore.setError(true);
          if (loading) this.userStore.setLoading(false);
        });
        throw err;
      }),
    );
  }

  async presentToast(color: 'success' | 'danger' | 'warning', message: string) {
    const toast = await this.toastController.create({
      message,
      duration: APP_CONFIG.TOAST_SUCCESS_DURATION,
      color,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    toast.present();
    if (color === 'success') this.getMultiUserAccesses(true);
  }

  // HIDEREFERRALCODES
  // submitReferralCode(email: string, referralCode: string) {
  //   return this.api.submitReferralCode(email, referralCode);
  // }

  // getSubmittedReferralCode() {
  //   return this.api.getSubmittedReferralCode();
  // }

  /**
   * Gets any referral code submitted by the user, by link or already submitted to API, to check
   * if the user should be able to input another one on the UpdateNamePage.
   */
  // HIDEREFERRALCODES
  // async getInputReferralCode() {
  //   const localStorageCode = getReferralCodeFromLocalStorage();
  //   return await this.getSubmittedReferralCode()
  //     .toPromise()
  //     .then((submittedCode) => {
  //       return submittedCode?.length
  //         ? { code: submittedCode, fromLocalStorage: false }
  //         : localStorageCode?.length
  //         ? { code: localStorageCode, fromLocalStorage: false }
  //         : null;
  //     });
  // }

  public async getUserEmail() {
    const currentUserInfo = await Auth.currentUserInfo();
    return currentUserInfo.attributes.email as string;
  }
}
