import { BillsFilterState } from '@1bill-app/services/bill/bill.store';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bill-search-bar-desktop',
  templateUrl: './bill-search-bar-desktop.component.html',
  styleUrls: ['./bill-search-bar-desktop.component.scss'],
})
export class BillSearchBarDesktopComponent {
  @Output() searchChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() tagChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() categoryChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() popOverFilterClicked: EventEmitter<any> = new EventEmitter<any>();
  @Input() billTags: string[];
  @Input() billCategories: any[];
  @Input() filter: BillsFilterState;

  showFilters = false;
  constructor() {}
  onSearchClick(value: any) {
    this.searchChange.emit(value);
  }
  presentInfoPopoverFilter() {
    this.popOverFilterClicked.emit();
  }

  onCatBtnClick(value: any) {
    this.categoryChange.emit(value);
  }
  onPropertyTagClick(value: any) {
    this.tagChange.emit(value);
  }
  searchSubmit(){}
}
