import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { NotificationState } from './notification.type';

function createInitialState(): NotificationState {
  return {
    notifications: null,
    hasBillTransaction: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notification', resettable: true })
export class NotificationStore extends Store<NotificationState> {
  constructor() {
    super(createInitialState());
  }
}
