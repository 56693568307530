import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-lat-pay-iframe-modal',
  templateUrl: './lat-pay-iframe-modal.component.html',
  styleUrls: ['./lat-pay-iframe-modal.component.scss'],
})
export class LatPayIframeModalComponent implements OnInit {
  iframeUrl: SafeUrl;
  @Input() url: any;
  @Input() title = 'Pay your bill';

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    this.logger.log('iframe url', this.iframeUrl);
  }

  async closeModal() {
    await this.modalCtrl.dismiss(true);
  }
  async finished() {
    await this.modalCtrl.dismiss(true);
  }

}
