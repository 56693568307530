import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { YodleeState } from './yodlee.types';

function createInitialState(): YodleeState {
  return {
    accounts: null,
    summaries: null,
    wealthCategories: null,
    currentAccountId: null,
    accountBalances: null,
    netIncomeCategories: null,
    netWealthChartData: null,
    categorySummaries: null,
    transactionCategories: null,
    netWealthDetailedChartData: null,
    availableDurations: null,
    currentDuration: 'M',
    currentCategories: null,
    cashScreenChartData: null,
    accountViewChartData: null,
    netIncomeDiff: null,
    netIncomeDiffPerc: null,
    currentNetIncomeTotal: null,
    transactions: null,
    netIncomeCurrentDurationDate: null,
    netIncomePrevDurationDate: null,
    expensesCurrentDurationDate: null,
    cashScreenCurrentDurationDate: null,
    accountViewCurrentDurationDate: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'yodlee', resettable: true })
export class YodleeStore extends Store<YodleeState> {
  constructor() {
    super(createInitialState());
  }
}
