import { LAT_PAY_CALLBACK_SESSION_EXPIRED_SOURCE, ROUTE_BILLS_PAGE, ROUTE_BILL_DETAILS, ROUTE_HOMEPAGE } from '@1bill-app/constants';
import { AuthService } from '@1bill-app/services/auth/state/auth.service';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';

interface LatPayCallbackQueryParam {
  token: string;
  reference: string;
  message: string;
  result: string;
  signature: string;
}

@Component({
  selector: 'app-lat-payment-callback',
  templateUrl: './lat-payment-callback.component.html',
  styleUrls: ['./lat-payment-callback.component.scss'],
})
export class LatPaymentCallbackComponent implements OnInit {
  // failed http://localhost:8100/lat-pay-failed?token=cba1268d-44f9-492a-ba19-7677e2d16055&reference=94&message=The%20payment%20was%20approved.&result=FAILED&signature=97ec3cc2f0cd786cd0d84cb329e81450c9ff7ef7542b8aea39f5e965c395a1e8
  // success:  http://localhost:8100/lat-pay-successful?token=cba1268d-44f9-492a-ba19-7677e2d16055&reference=94&message=The%20payment%20was%20approved.&result=COMPLETED&signature=87042fc23b30dfe816cbfbd5b8ab059b32a8fdb904abd56ab2ebb32bdcc7f817
  constructor(
    private route: ActivatedRoute,
    private billService: BillService,
    private router: Router,
    private alertController: AlertController,
    private authService: AuthService,
  ) {}
  
  _source = ROUTE_HOMEPAGE;
  param: LatPayCallbackQueryParam;
  loading: boolean;

  ngOnInit() {
    this.loading = true;
    this.route.queryParams.forEach((param: LatPayCallbackQueryParam) => {
      console.log({ param });
      const { token, reference, message, result, signature } = param;
      this.param = param;
      this.processCallback(param);
    });
  }

  processCallback(param: LatPayCallbackQueryParam) {
    throw Error('LatitudePay is no longer available.');
    // const timedOut = this.authService.isSessionTimeout();
    // if (timedOut) {
    //   this.authService.resetLastActivity();
    // }
    // this.billService.updateLatPaySaleCallback(param).subscribe(
    //   () => {
    //     this.loading = false;
    //     this.showAlert(param);
    //   },
    //   (error) => {
    //     throw error;
    //   },
    //   () => {
    //     if (timedOut) {
    //       this.authService.logout(LAT_PAY_CALLBACK_SESSION_EXPIRED_SOURCE);
    //     }
    //   },
    // );
  }

  async showAlert(param: LatPayCallbackQueryParam) {
    const billPaymentId = param.reference;
    // If bundled bill payment was cancelled, this will be a list of bill IDs separated by '-',
    // so this route-to-bill functionality should only apply if there is a single bill ID.

    const bundledFail = billPaymentId.includes('-');
    const buttons = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary',
        handler: () => {
          // this.logger.log('Confirm Cancel: blah');
        },
      },
      {
        text: bundledFail ? 'OK' : 'Go to bill',
        handler: () => {
          if (!bundledFail) {
            this.gotoBill(param.reference);
          }
        },
      },
    ];

    const alert = await this.alertController.create({
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'bill-alert',
      header: `Payment status: ${param.result}`,
      message: param.message,
      buttons: buttons,
    });

    await alert.present();

    await alert.onDidDismiss().then(() => {
      if (bundledFail) {
        this.router.navigateByUrl(ROUTE_HOMEPAGE);
      }
    });
  }

  gotoBill(billPaymentId) {
    this.router.navigate([ROUTE_BILL_DETAILS], {
      queryParams: {
        _source: ROUTE_BILLS_PAGE,
        billPaymentId,
        refetchBills: true,
      },
      replaceUrl: true,
    });
  }
}
