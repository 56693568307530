import { PopoverFailedComponent } from '@1bill-app/components/popover-failed/popover-failed.component';
import {
  ONEBILL_APP_ID,
  ROUTE_BILL_DETAILS,
  ROUTE_SETTINGS_PAYMENT_INFO,
  ROUTE_USER_MANAGEMENT,
  ROUTE_USER_PROFILE,
} from '@1bill-app/constants';
import { fromRatingNumber } from '@1bill-app/helpers/general.helper';
import { BetterDealQuery } from '@1bill-app/services/better-deal/better-deal.query';
import { BetterDealService } from '@1bill-app/services/better-deal/better-deal.service';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { isBillOverdue } from '@1bill-app/services/bill/helper';
import {
  appProductTypeDefaultImg,
  appProductTypes,
  BillCategoryKey,
  BillRating,
  ViewBillType,
} from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { NotificationItem } from '@1bill-app/services/notification/notification.type';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Market } from '@ionic-native/market/ngx';
import { AlertController, Platform, PopoverController } from '@ionic/angular';
import dayjs from 'dayjs';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent {
  constructor(
    private logger: NGXLogger,
    private billQuery: BillQuery,
    private billService: BillService,
    private router: Router,
    private categorySvc: CategoryService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private userQuery: UserQuery,
    private market: Market,
    private platform: Platform,
    private betterDealService: BetterDealService,
    private betterDealQuery: BetterDealQuery,
  ) {
    this.percentage = 50;
  }

  @Input() isLoading: boolean = false;
  @Input('data') notificationItem: NotificationItem;
  @Input() bill: ViewBillType;
  @Input() billPaymentId: number;
  @Input() billRating: number;
  @Output() dismissClicked: EventEmitter<string> = new EventEmitter();
  betterDealProcessing$ = this.betterDealQuery.select('processing');
  percentage: number;
  public nativePlatform: 'ios' | 'android' = this.platform.is('ios') ? 'ios' : 'android';
  public nativeMarket = this.nativePlatform == 'ios' ? 'App Store' : 'Play Store';
  public overdueBills$ = this.billQuery.overdueBills$;
  ROUTE_USER_PROFILE = ROUTE_USER_PROFILE;

  getSupplierLogo(nSupplierLogo, nCategoryKey) {
    if (nSupplierLogo) {
      return nSupplierLogo;
    } else {
      return (
        appProductTypes.find((i) => i.key == nCategoryKey)?.imgUrl || appProductTypeDefaultImg
      );
    }
  }

  get isAccessingAsOtherUser() {
    return this.userQuery.isAccessingAsOtherUser;
  }

  redirect(pageName: string) {
    this.router.navigate([`${pageName}`]);
  }

  getBillDueDate(notificationDueDate?: string) {
    const dueDate = this.bill?.dueDate || notificationDueDate;
    if (!dueDate) return null;

    const now = dayjs().startOf('day');
    const dueDateDayjs = dayjs(dueDate);
    const numberOfDays = Math.abs(dueDateDayjs.diff(now, 'day'));

    if (numberOfDays === 0) return 'today';
    if (now.isBefore(dueDateDayjs)) {
      // Bill is upcoming
      return numberOfDays === 1 ? 'tomorrow' : `in ${numberOfDays} days`;
    } else if (dueDateDayjs.isBefore(now)) {
      // Bill is overdue
      if (numberOfDays === 1) return 'yesterday';
      return numberOfDays + ' days ago';
    }
  }

  onPayNowClick(paymentId: number) {
    //let bill = this.getBill(paymentId);

    // Trigger dismiss notification
    //this.dismissClicked.emit(this.notificationItem?.id);
    if (this.bill) this.billService.presentPaymentCombinedModal(this.bill);
  }

  viewBill(billPaymentId: number) {
    this.router.navigate([ROUTE_BILL_DETAILS], {
      queryParams: {
        billPaymentId,
        _source: ROUTE_USER_PROFILE,
      },
    });
  }

  navToUserManagementPage() {
    this.router.navigate([ROUTE_USER_MANAGEMENT]);
  }

  onViewLinkedPaymentClick() {
    //this.dismissClicked.emit(this.notificationItem.id);
    this.router.navigate([ROUTE_SETTINGS_PAYMENT_INFO]);
  }

  getRatingText(): string {
    let rating = fromRatingNumber(this.billRating);
    if (rating == BillRating.Bad) {
      return "It looks like you're paying too much for your";
    } else if (rating == BillRating.Ordinary) {
      return 'It looks like you can pay less for your';
    } else if (rating == BillRating.Good) {
      return 'It looks like you could get a better deal for your';
    } else if (rating == BillRating.Excellent) {
      return 'It looks like you have a great deal for your';
    }
  }

  dismissNotification() {
    this.logger.debug(
      'dismissNotification() clicked notificationItemid: ',
      this.notificationItem?.id,
    );
    this.dismissClicked.emit(this.notificationItem?.id);
  }

  getDetails(categoryKey: BillCategoryKey) {
    return this.categorySvc.getCategoryDetails(categoryKey);
  }

  getCardIconUrl() {
    if (this.bill.supplierLogoUrl) {
      return this.bill.supplierLogoUrl;
    } else {
      return (
        appProductTypes.find((p) => p.key === this.bill.categoryKey)?.imgUrl ||
        appProductTypeDefaultImg
      );
    }
  }

  getDueText(bill) {
    if (isBillOverdue(bill)) return 'Overdue';
    return 'Due date';
  }

  canSwitch(categoryKey) {
    return appProductTypes.find((val) => val.key === categoryKey)?.canSwitch;
  }

  async errorAlert(customMessage?: string) {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: customMessage
        ? `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">${customMessage}</p>`
        : `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">Something went wrong! Please try again later.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Got it',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async presentPopover(ev: any) {
    // FIXME: Button click event inside ion-card click event is not clickable in this case

    const popover = await this.popoverController.create({
      component: PopoverFailedComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
    });
    return await popover.present();
  }

  async presentTncPopUp() {
    if (this.bill.billPaymentId) {
      this.betterDealService.presentTncPopUp(this.bill);
    }
  }

  async openAppInMarket() {
    const marketAppId =
      this.nativePlatform === 'ios' ? ONEBILL_APP_ID.ios : ONEBILL_APP_ID.android;
    await this.market.open(marketAppId);
  }
}
