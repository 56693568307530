import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switch-and-save-consent',
  templateUrl: './switch-and-save-consent.component.html',
  styleUrls: ['./switch-and-save-consent.component.scss'],
})
export class SwitchAndSaveConsentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
