import { CSS_COLOR_LIGHTERPRIMARY } from '@1bill-app/constants';
import { YodleePossibleTransaction } from '../yodlee/yodlee.types';

export enum BillCategoryKey {
  ALL = 'ALL',
  INTERNET = 'I', // Inactive/Deleted (Replaced by Telecom)
  WATER = 'W',
  GAS = 'G',
  ELECTRICITY = 'E',
  COUNCIL_RATES = 'C',
  INSURANCE = 'S',
  REGO = 'R',
  TELECOM = 'T',
  LPG_CYLINDER = 'L',
  HOT_WATER = 'H',
  STRATA = 'A',
  FINES = 'F',
  TOLL_NOTICE = 'N',
  OTHER = 'O',
}

export const appProductTypeDefaultImg = 'assets/bills/icons/small-icons/other-icon-small.svg';
export const appProductTypeDefaultImgUrl =
  'assets/icon/small-icons/white-bg/other-icon-small.svg';

export interface AppProductType {
  title: string;
  key: BillCategoryKey;
  color: string;
  icon: string;
  imgUrl: string;
  imgUrlWhite: string;
  canSwitch: boolean;
  inAddBill: boolean;
}

export const appProductTypes: AppProductType[] = [
  {
    title: 'Electricity',
    key: BillCategoryKey.ELECTRICITY,
    color: 'light-green',
    icon: 'assets/bills/icons/small-icons/electricity-icon-small.svg',
    imgUrl: 'assets/icon/shadowboxer/types/LightbulbFilament.svg',
    imgUrlWhite: 'assets/bills/icons/white/electricity.svg',
    canSwitch: true,
    inAddBill: true,
  },

  {
    title: 'Water',
    key: BillCategoryKey.WATER,
    color: 'light-blue',
    icon: 'assets/bills/icons/small-icons/water-icon-small.svg',
    imgUrl: 'assets/icon/shadowboxer/types/Drop.svg',
    imgUrlWhite: 'assets/bills/icons/white/water.svg',
    canSwitch: false,
    inAddBill: true,
  },

  {
    title: 'Gas',
    key: BillCategoryKey.GAS,
    color: 'light-purple',
    icon: 'assets/bills/icons/small-icons/gas-icon-small.svg',
    imgUrl: 'assets/icon/shadowboxer/types/Fire.svg',
    imgUrlWhite: 'assets/bills/icons/white/gas.svg',
    canSwitch: true,
    inAddBill: true,
  },

  /**
   * Inactive.
   */
  {
    title: 'Broadband',
    color: 'light-brown',
    key: BillCategoryKey.INTERNET,
    icon: 'assets/bills/icons/small-icons/internet-icon-small.svg',
    imgUrl: 'assets/icon/shadowboxer/types/ChalkboardSimple.svg',
    imgUrlWhite: 'assets/bills/icons/white/broadband.svg',
    canSwitch: false,
    inAddBill: true,
  },

  {
    title: 'Council Rates',
    color: 'light-yellow',
    key: BillCategoryKey.COUNCIL_RATES,
    icon: 'assets/bills/icons/small-icons/council-icon-small.svg',
    imgUrl: 'assets/icons/shadowboxer/types/Bank.svg',
    imgUrlWhite: 'assets/bills/icons/white/council.svg',
    canSwitch: false,
    inAddBill: true,
  },

  {
    title: 'Insurance',
    color: 'light-yellow',
    key: BillCategoryKey.INSURANCE,
    icon: appProductTypeDefaultImg,
    imgUrl: null,
    imgUrlWhite: null,
    canSwitch: false,
    inAddBill: false,
  },

  {
    title: 'Vehicle Registration',
    color: 'light-brown',
    key: BillCategoryKey.REGO,
    icon: 'assets/bills/icons/small-icons/car-rego-small.svg',
    imgUrl: 'assets/icon/shadowboxer/types/Car.svg',
    imgUrlWhite: 'assets/bills/icons/white/car.png',
    canSwitch: false,
    inAddBill: true,
  },

  {
    title: 'Telecom',
    color: 'light-yellow',
    key: BillCategoryKey.TELECOM,
    icon: appProductTypeDefaultImg,
    imgUrl: null,
    imgUrlWhite: null,
    canSwitch: false,
    inAddBill: false,
  },

  {
    title: 'Bottled Gas',
    color: 'light-yellow',
    key: BillCategoryKey.LPG_CYLINDER,
    icon: appProductTypeDefaultImg,
    imgUrl: null,
    imgUrlWhite: null,
    canSwitch: false,
    inAddBill: false,
  },

  {
    title: 'Hot Water',
    color: 'light-yellow',
    key: BillCategoryKey.HOT_WATER,
    icon: appProductTypeDefaultImg,
    imgUrl: null,
    imgUrlWhite: null,
    canSwitch: false,
    inAddBill: false,
  },

  {
    title: 'Strata',
    key: BillCategoryKey.STRATA,
    color: 'light-gray',
    icon: appProductTypeDefaultImg,
    imgUrl: appProductTypeDefaultImgUrl,
    imgUrlWhite: appProductTypeDefaultImgUrl,
    canSwitch: false,
    inAddBill: false,
  },
  {
    title: 'Fines',
    key: BillCategoryKey.FINES,
    color: 'light-gray',
    icon: appProductTypeDefaultImg,
    imgUrl: appProductTypeDefaultImgUrl,
    imgUrlWhite: appProductTypeDefaultImgUrl,
    canSwitch: false,
    inAddBill: false,
  },
  {
    title: 'Toll Notice',
    key: BillCategoryKey.TOLL_NOTICE,
    color: 'light-gray',
    icon: appProductTypeDefaultImg,
    imgUrl: appProductTypeDefaultImgUrl,
    imgUrlWhite: appProductTypeDefaultImgUrl,
    canSwitch: false,
    inAddBill: false,
  },
  {
    title: 'Other',
    key: BillCategoryKey.OTHER,
    color: 'light-gray',
    icon: appProductTypeDefaultImg,
    imgUrl: appProductTypeDefaultImgUrl,
    imgUrlWhite: appProductTypeDefaultImgUrl,
    canSwitch: true,
    inAddBill: true,
  },
];

export interface UserBillPayment {
  billPaymentId: number;
  accountBillTypeId: number;
  srId: number;
  billUrl: string;
  dateReceived: string;
  createdAt: string;
  dueDate: string;
  billDays: number;
  billStartDate: string;
  billEndDate: string;
  amount: number;
  minimumAmount: number;
  payMinimumOnly: number;
  paymentMethod: string;
  datePaid: string;
  amountPaid: number;
  billerCode: string;
  billerReference: string;
  howPaid: PaidPaymentMethod;
  howToPay: string;
  address1: string;
  address2: string;
  updatedAt: string;
  ratioPreviousAmount: number;
  supplierName: string;
  switchCustomerId: string;
  switchStatus: 'ONGOING' | 'COMPLETED' | 'FAILED';
  switchRetailerName: string;
  switchRetailerLogo: string;
  switchPlanName: string;
  switchAddress: string;
  peakUsage: string;
  offPeakUsage: string;
  controlledLoad: string;
  controlledLoad1: string;
  controlledLoad2: string;
  shoulder1: string;
  payNowPaymentInitiated: 0 | 1;
  payNowPaymentApproved: 0 | 1;
  paymentPending: 0 | 1 | 2 | 3;
  paymentReady: 0 | 1 | null;
  meterNumber: string;
  dpi: string;
  isDirectDebitSetup: 1 | 0;
  possibleTransaction?: YodleePossibleTransaction;
  barChartData?: BarChartData[];
  linkedPaymentMethodId?: number;
  accountOrCardNo: string;
}

export interface OneBillAccountBillType {
  id: number;
  payNowLatestBillPaymentId?: number;
  billTypeId: number;
  billTypeKey: string;
  name: string;
  supplierLogoUrl: string;
  billSupplierId: string;
  supplierName: string;
  description: string;
  isCompetitive: string;
  billCompareFrequency: string;
  tileColourHex: string;
  icon: string;
  billReferenceNumber: string;
  howToPay: string;
  rank: number;
  numberSuppliersCompared: number;
  rank1SupplierId: string;
  currentCostEstPa: number;
  potentialSavingsPa: number;
  costAfterSwitchEstPa: number;
  compareDate: string;
  fullAddress?: string;
  billPayments?: Partial<UserBillPayment>[];
  dueDays?: number;
  dueAmount?: number;
  rating: number;
  billTag: string;
}

export interface BillCategoryType {
  id: number;
  key: string;
  name: string;
}

export enum BillRating {
  Bad = 1,
  Ordinary = 2,
  Good = 3,
  Excellent = 4,
  Perfect = 5,
}

export type ViewBillType = {
  billPaymentId: number;
  jobId?: number;
  accountBillTypeId: number;
  category: string;
  categoryKey: BillCategoryKey;
  rating: number;
  rank: number;
  provider: string;
  amount: number;
  amountPaid: number;
  dueDate: string;
  savingPerYear: number;
  fullAddress?: string;
  address1: string; // this is supply address
  address2: string; // this is supply address
  billDays: number; // no of days returned from parser
  billStartDate: string;
  billEndDate: string;
  billerCode: string;
  billerReference: string;
  datePaid: string;
  supplierLogoUrl: string;
  owingText: string;
  billTag: string;
  howToPay: string;
  howPaid: PaidPaymentMethod;
  switchCustomerId: string;
  switchStatus: 'ONGOING' | 'COMPLETED' | 'FAILED';
  switchRetailerName: string;
  switchRetailerLogo: string;
  switchPlanName: string;
  switchAddress: string;
  dateReceived: string;
  createdAt: string;
  peakUsage: string;
  offPeakUsage: string;
  controlledLoad: string;
  controlledLoad1: string;
  controlledLoad2: string;
  shoulder1: string;
  payNowPaymentInitiated: 0 | 1;
  payNowPaymentApproved: 0 | 1;
  /**
   * 0 = Paid
   *
   * 1 = Pending
   *
   * 3 = Refunded
   *
   * null/undefined = Unpaid
   */
  paymentPending: 0 | 1 | 2 | 3;
  paymentReady: 0 | 1 | null;
  meterNumber: string;
  dpi: string;
  isDirectDebitSetup: 1 | 0;
  possibleTransaction?: YodleePossibleTransaction;
  barChartData?: BarChartData[];
  linkedPaymentMethodId: number;
  accountOrCardNo: string;
};

export interface SelectableBill extends ViewBillType {
  selected?: boolean;
  showMore?: boolean;
  displayTag: string;
}

export interface BarChartData {
  bbox: number[];
  class: string;
  accuracy: number;
  date?: string;
  text?: string;
  usage?: number;
}

export enum GetBillOption {
  ALL_BILLS = 'ALL_BILLS',
  UNPAID_BILLS = 'UNPAID_BILLS',
  UNPAID_PLUS_FAILED_BILLS = 'UNPAID_PLUS_FAILED_BILLS',
  PAID_BILLS = 'PAID_BILLS',
}

export enum GetBillDuration {
  /**
   * Any duration.
   */
  ALL = 'ALL',
  /**
   * Within 7 days in the future.
   */
  WEEK = 'WEEK',
  /**
   * Within 30 days in the future.
   */
  MONTH = 'MONTH',
  /**
   * Within the current month.
   */
  THIS_MONTH = 'THIS_MONTH',
}

export enum GetBillGroup {
  ALL = 'ALL',
  RECENT_BILLS = 'RECENT_BILLS',
  UPCOMING_BILLS = 'UPCOMING_BILLS',
  OVERDUE_BILLS = 'OVERDUE_BILLS',
}

export enum PaidPaymentMethod {
  /** Manually mark as paid */
  MANUALLY_MARKED_PAID = 'M',
  /** Automatically paid by system */
  AUTOMATICALLY_PAID = 'A',
  /** Paid using pay now */
  PAID_USING_PAY_NOW = 'P',
  /** Paid using funds */
  PAID_USING_FUND = 'F',
  /** Paid using Latitude Pay */
  PAID_USING_LATITUDE_PAY = 'L',
  /** Paid using direct debit with supplier */
  DIRECT_DEBIT_WITH_SUPPLIER = 'D',
}

export enum PaymentType {
  Manual = 'M',
  Funded = 'F',
  Automatic = 'A',
}

export interface GetFailedBill {
  jobId: number;
  productTypeP: string;
  productType: string;
  billerCode: string;
  billerReference: string;
  dueDate: string;
  amount: number;
  supplierName: string;
  supplierLogoUrl: string;
  billDays: number;
  billStartDate: string;
  billEndDate: string;
  address1: string;
  address2: string;
  dateReceived: string;
  createdAt: string;
  updatedAt: string;
}

export interface ParsedBillData {
  provider: string;
  category: string;
  categoryKey: BillCategoryKey;
  dueDate: string;
  amount: number;
  supplierLogoUrl: string;
  billStartDate: string;
  billEndDate: string;
}

export interface UserBillPrediction {
  jobId: number;
  billPaymentId: number;
  dueDate: string;
  billStartDate: string;
  billEndDate: string;
  statementPeriodStartDate: string;
  statementPeriodEndDate: string;
  amount: number;
  accountBillTypeId: number;
  supplierName: string;
  supplierLogoUrl: string;
  categoryKey: string;
  category: string;
  predictedDueDate: string;
  predictedStartDate: string;
  predictedEndDate: string;
}

export enum SwitchErrorCode {
  MobileUnverified = 'MobileUnverified',
  MultipleMobile = 'MultipleMobile',
  SwitchLimitReachedForProduct = 'SwitchLimitReachedForProduct',
}

export interface BillRatingConfigItem {
  rating: BillRating;
  colour: string;
  /**
   * How the rating is displayed to the users in the app; should be unique
   */
  name: string;
  /**
   * If true, will not be shown on RateCompareComponent
   */
  hideFromRateCompare?: boolean;
}

// Colours for pointer triangles defined in SCSS as ::after CSS rules cannot be controlled via template.
export const BILL_RATINGS_CONFIG: BillRatingConfigItem[] = [
  {
    rating: BillRating.Bad,
    colour: '#d8545c',
    name: 'Bad',
  },
  {
    rating: BillRating.Ordinary,
    colour: '#ef8738',
    name: 'Okay',
  },
  {
    rating: BillRating.Good,
    colour: '#09b778',
    name: 'Good',
  },
  {
    rating: BillRating.Excellent,
    colour: CSS_COLOR_LIGHTERPRIMARY,
    name: 'Excellent',
    hideFromRateCompare: true,
  },
];
