import { NgxLoggerLevel, NGXLoggerMonitor, NGXLogInterface } from 'ngx-logger';
import { SQLiteService } from '../sqlite/sqlite.service';
import { serializeError } from 'serialize-error'
import Auth from '@aws-amplify/auth';
import { SQLITE_TABLE_APP_LOGS } from '@1bill-app/constants';

export class AppSQLiteLoggerMonitor implements NGXLoggerMonitor {
  constructor(private sqlite: SQLiteService) { }
  onLog(log: NGXLogInterface) {
    if (log.level >= NgxLoggerLevel.INFO) {
      const statement = `
        INSERT INTO ${SQLITE_TABLE_APP_LOGS}(level, timestamp, fileName, lineNumber, message, additional, user)
          VALUES(?,?,?,?,?,?,?)
      `;
      let message: string;
      if (typeof log.message === 'string') {
        message = log.message
      } else if ((log.message as any) instanceof Error) {
        message = JSON.stringify(serializeError(log.message));
      } else {
        message = JSON.stringify(log.message);
      };

      this.getUserEmail().then(email => {
        const values = [log.level, new Date(log.timestamp).getTime(), log.fileName, Number(log.lineNumber), message, JSON.stringify(log.additional), email];
        this.sqlite.run({ statement, values });
      })
    }
  }

  async getUserEmail() {
    let email: string;
    try {
      const user = await Auth.currentAuthenticatedUser();
      email = user?.attributes?.email || null;
    } catch (e) { }
    return email;
  }
}
