import { UserService } from '@1bill-app/services/user/user.service';
import { UserAccountTerminationStatus } from '@1bill-app/services/user/user.types';
import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-account-termination',
  templateUrl: './account-termination.component.html',
  styleUrls: ['./account-termination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountTerminationComponent {
  constructor(private userService: UserService, private cdRef: ChangeDetectorRef) {}

  @Input() data: UserAccountTerminationStatus;
  countdownDays: number;
  cancelled = false;
  loading = false;

  ngOnInit() {
    this.userService.accountTerminationStatusChange.subscribe(
      (newTerminationStatus: 0 | 1) => {
        this.cancelled = newTerminationStatus === 0;
      },
    );
    this.countdownDays = dayjs(this.data?.terminationDate).diff(dayjs(), 'days');
  }

  onClickCancelTermination() {
    this.userService.presentAccountCancelTerminationAlert().then((selection) => {
      if (selection) {
        this.confirmCancelTermination();
      }
    });
  }

  confirmCancelTermination() {
    this.loading = true;
    this.cdRef.detectChanges();
    this.userService.cancelAccountTermination().subscribe(
      (result) => {
        this.cancelled = true;
        this.loading = false;
        this.cdRef.detectChanges();
      },
      (error) => {
        this.loading = false;
        this.cdRef.detectChanges();
      },
    );
  }
}
