import {
  ROUTE_FIND_MORE_SAVINGS,
  ROUTE_BILL_DETAILS,
  ROUTE_USER_PROFILE,
  ROUTE_HOMEPAGE,
} from '@1bill-app/constants';
import { BetterDealService } from '@1bill-app/services/better-deal/better-deal.service';
import { BillRating, ViewBillType, BILL_RATINGS_CONFIG } from '@1bill-app/services/bill/types';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

interface BillRatingConfig {
  rating: BillRating;
  colour: string;
  name: string;
}

@Component({
  selector: 'app-rate-compare',
  templateUrl: './rate-compare.component.html',
  styleUrls: ['./rate-compare.component.scss'],
})
export class RateCompareComponent {
  @Input() bill: ViewBillType;
  @Input() darkButton = false;
  /**
   * On the notification component; this implies `bill` does not have all the required properties and they must be acquired from the BillQuery
   */
  @Input() isNotification = false;
  @Input() route = false;

  activeRating: BillRatingConfig;
  routeSavings = ROUTE_FIND_MORE_SAVINGS;
  readonly RATINGS = BILL_RATINGS_CONFIG.filter(
    (configItem) => !configItem.hideFromRateCompare,
  );

  get savingText() {
    switch (this.getMappedRating(this.bill.rating)) {
      case BillRating.Bad:
        return `You could be saving $${this.bill.savingPerYear.toFixed(2)} per year, a saving of more than 10% compared to your current plan.`;
      case BillRating.Ordinary:
        return `You could be saving $${this.bill.savingPerYear.toFixed(2)} per year, a saving of ${
          this.bill.rating === BillRating.Excellent ? 'up to' : 'more than'
        } 5% compared to your current plan.`;
      default:
        return 'You are on a good deal with your current provider. We will continually monitor your bills and let you know if a better deal becomes available.';
    }
  }

  get userCanSwitch() {
    return this.userQuery.userHasFullAccess;
  }

  constructor(
    private betterDealService: BetterDealService,
    private router: Router,
    private userQuery: UserQuery,
  ) {}

  ngOnInit() {
    this.activeRating = this.RATINGS.find(
      (rating) => rating.rating === this.getMappedRating(this.bill.rating),
    );
  }

  routeToBill() {
    if (!this.route) return;
    this.router.navigate([ROUTE_BILL_DETAILS], {
      queryParams: {
        billPaymentId: this.bill.billPaymentId,
        _source: this.isNotification ? ROUTE_USER_PROFILE : ROUTE_HOMEPAGE,
      },
    });
  }

  /**
   * Converts rating (raw from API) to one of three values displayed on the newly designed component.
   * @param billRating Rating of bill from API
   * @returns One of three new ratings
   */
  getMappedRating(billRating: BillRating | number) {
    switch (billRating) {
      case BillRating.Good:
      case BillRating.Excellent:
        return BillRating.Ordinary;
      case BillRating.Perfect:
        return BillRating.Good;
      default:
        return billRating < BillRating.Perfect ? billRating : BillRating.Good;
    }
  }

  presentTncPopUp() {
    this.betterDealService.presentTncPopUp(this.bill);
  }

  /** For use in template */
  toLower(str: string) {
    return str.toLowerCase();
  }
}
