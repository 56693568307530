import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockScreenComponent } from './lock-screen.component';
import { IonicModule } from '@ionic/angular';
import { CodeInputModule } from 'angular-code-input';

@NgModule({
  declarations: [LockScreenComponent],
  imports: [
    CommonModule,
    IonicModule,
    CodeInputModule,
  ]
})
export class LockScreenModule { }
