import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ADD_BILL_KEYS, ADD_BILL_MENU } from 'src/app/app.menu';

@Component({
  selector: 'app-add-bill-menu',
  templateUrl: './add-bill-menu.component.html',
  styleUrls: ['./add-bill-menu.component.scss'],
})
export class AddBillMenuComponent implements OnInit {
  @Output() clickedMenu = new EventEmitter<ADD_BILL_KEYS>();
  addBillMenu = ADD_BILL_MENU;
  constructor() { }

  ngOnInit() {}

  menuClicked(menuKey){
    this.clickedMenu.emit(menuKey);
  }

}
