import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AccountStatus, JobMeta, StatusJobData } from './types';

export interface StatusData {
  job: StatusJobData;
  latestJobId: number;
  jobMeta: JobMeta;
  user: Partial<AccountStatus>;
}
export interface StatusResponse {
  data: StatusData;
  success: boolean;
}
export interface StatusUIState {
  isPolling?: boolean;
  isPollingComplete?: boolean;
  isJobOnProgress?: boolean;
}
export interface StatusState extends StatusData {
  ui: StatusUIState;
  addedJobIds: number[];
  latestJobId: number;
  success: boolean;
}

function createInitialState(): StatusState {
  return {
    ui: {
      isPolling: null,
      isPollingComplete: null,
      isJobOnProgress: null,
    },
    job: {
      items: [],
      count: null,
      totalCount: null,
    },
    user: { status: null },
    addedJobIds: [],
    latestJobId: null,
    jobMeta: { directDebit: null, alreadyPaid: null, cusSelected: null, },
    success: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'status', resettable: true })
export class StatusStore extends Store<StatusState> {
  constructor() {
    super(createInitialState());
  }
  updateUI(uiState: Partial<StatusUIState>) {
    const prevUIState = this.getValue().ui;
    this.update({ ui: { ...prevUIState, ...uiState } });
  }
}
