import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeStore } from '@1bill-app/services/yodlee/yodlee.store';
import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import {
  AlertController,
  LoadingController,
} from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { HomeService } from 'src/app/services/home/home.service';

@Component({
  selector: 'app-yodlee-account-list',
  templateUrl: './yodlee-account-list.component.html',
  styleUrls: ['./yodlee-account-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YodleeAccountListComponent {
  subs = new SubSink();
  @Input() accounts: YodleeAccount[];
  @Input() showBalance: boolean = false;

  get displayAccounts() {
    return this.accounts?.filter((a) => a?.showOnDashboard);
  }

  constructor(
    private logger: NGXLogger,
    private loadingController: LoadingController,
    private yodleeService: YodleeService,
    private yodleeStore: YodleeStore,
    private changeDetectorRef: ChangeDetectorRef,
    private alertController: AlertController,
    private homeService: HomeService,
  ) { }

  trackByFn(account: YodleeAccount) {
    return account?.id;
  }

  async hideAccount(account: YodleeAccount) {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    const newValue = !account?.showOnDashboard;
    try {
      await this.yodleeService
        .updateAccount(account?.id, newValue).toPromise();
      this.yodleeStore.update({
        accounts: this.yodleeStore
          .getValue()
          ?.accounts?.filter((x) => x?.id !== account?.id),
      });
      this.changeDetectorRef.detectChanges();
      await this.yodleeService.getAccounts().toPromise();
    } catch (error) {
      this.logger.error('Error show/hide account: ', error);
      this.errorAlert();
    } finally {
      await loading.dismiss();
    }
  }

  async errorAlert() {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message:
        '<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">Something went wrong! Please try again later.</p>',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        }, {
          text: 'Got it',
        },
      ],
    });

    await alert.present();
  }

  async handleHideAccount(account: YodleeAccount) {
    const title = 'Are you sure to hide this account from dashboard?';
    const message = `You can always unhide your account on <a href="/accounts">bank accounts</a> page`;
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">${title}</h2><p class="alert-text">${message}</p>`,
      buttons: [
        {
          text: 'Yes',
          handler: () => this.hideAccount(account),
        },
        { text: 'Cancel' },
      ],
    });

    await alert.present();
  }

  handleLinkBankAccount() {
    this.homeService.presentLinkBankAccountModal();
  }
}
