import { UploadPhotoEventStatus } from '@1bill-app/interfaces/photo-bill';
import { Injectable } from '@angular/core';
import { from, of } from 'rxjs';
import { catchError, concatMap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ImageEnhancerService {
  constructor(private api: ApiService) {}
  // enhanceUrlImage(url: string, opts?: { base64: boolean }) {
  //   const body = {
  //     url
  //   };
  //   const processEnhancedImage = async (imageUrl: string) => {
  //     const downloadUrl = await this.api.getDownloadUrl({ url: imageUrl }).toPromise();
  //     const res = await fetch(downloadUrl.url);
  //     const blob = await res.blob();
  //     const dataUrl =  URL.createObjectURL(blob);
  //     return { status: UploadPhotoEventStatus.ENHANCE_SUCCESS, url: imageUrl, dataUrl };
  //   };
  //   return this.api.enhanceImage(body).pipe(
  //     concatMap(res => {
  //       if (res && res.url && res.url !== 'null') {
  //         if (opts && opts.base64) {
  //           return from(processEnhancedImage(res.url));
  //         } else {
  //           return of({ status: UploadPhotoEventStatus.ENHANCE_SUCCESS, url: res.url });
  //         }
  //       } else {
  //         return of({ status: UploadPhotoEventStatus.ENHANCE_FAILED, data: res, url });
  //       }
  //     }),
  //     catchError((err) => {
  //       console.error(err);
  //       return of({
  //         status: UploadPhotoEventStatus.ENHANCE_FAILED,
  //         url,
  //         error: err.message || err,
  //       });
  //     }),
  //   );
  // }
  /**
   * Enhance image by passing s3Key only
   * @param param0 
   * @param opts 
   * @returns 
   */
  enhanceS3KeyImage({ s3Key, card = false }, opts?: { displayEnhancedImage: boolean }) {

    const body = {
      s3Key,
      s3KeyOnlyMode: true,
      card
    };

    if(!card){
      delete body.card;
    }
    const processEnhancedImage = async (imageS3Key: string) => {
      const downloadUrl = await this.api.getDownloadUrl({ s3Key: imageS3Key, s3KeyOnlyMode:true }).toPromise();
      const res = await fetch(downloadUrl.url); // all good for s3-key
      const blob = await res.blob();
      const dataUrl = URL.createObjectURL(blob);
      return { status: UploadPhotoEventStatus.ENHANCE_SUCCESS, s3Key: imageS3Key, dataUrl };
    };
    return this.api.enhanceImage(body).pipe(
      concatMap((res) => {
        if (res && res.s3Key && res.s3Key !== 'null') {
          if (opts && opts.displayEnhancedImage) {
            return from(processEnhancedImage(res.s3Key));
          } else {
            return of({ status: UploadPhotoEventStatus.ENHANCE_SUCCESS, s3Key: res.s3Key });
          }
        } else {
          return of({ status: UploadPhotoEventStatus.ENHANCE_FAILED, data: res, s3Key });
        }
      }),
      catchError((err) => {
        console.error(err);
        return of({
          status: UploadPhotoEventStatus.ENHANCE_FAILED,
          s3Key,
          error: err.message || err,
        });
      }),
    );
  }
}
