import { Browser } from '@capacitor/browser';
import { Auth } from 'aws-amplify';

// import { openSignInWindow } from './open-popup';
import { logger } from '../services/tsLogger';

export const urlOpener = async (url: string, redirectUrl: string) => {
  logger.log('urlOpener', { url, redirectUrl });
  // On Expo, use WebBrowser.openAuthSessionAsync to open the Hosted UI pages.
  // const { type, url: newUrl } = await WebBrowser.openAuthSessionAsync(url, redirectUrl);
  const isLogout = url && url.includes('/logout?');
  Browser.open({
    presentationStyle: !isLogout ? 'popover' : 'fullscreen',
    url,
    windowName: '_self',
  });
  // if (Capacitor.getPlatform() === 'web') {
  //   openSignInWindow(url, '_blank', 500, 500);
  // } else {
  //   Browser.open({
  //     presentationStyle: 'popover',
  //     url,
  //     windowName: '_self',
  //   });
  // }
};
/**
 * @deprecated We're now checking if mobile verified from DB.
 */
export async function phoneNumberIsVerified(): Promise<boolean> {
  const user = await Auth.currentAuthenticatedUser();
  return !!user.attributes?.phone_number_verified;
}
