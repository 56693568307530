export enum CardDataType {
  CREDIT_SCORE_APPLY = 'CREDIT_SCORE_APPLY',
  ADD_EMAIL = 'ADD_EMAIL',
  LINK_ACCOUNT = 'LINK_ACCOUNT',
  ADD_CREDIT_CARD = 'ADD_CREDIT_CARD',
  SETUP_PUSH_NOTIFICATION = 'SETUP_PUSH_NOTIFICATION',
}

export interface CardData {
  id: number;
  type: CardDataType;
  icon: string;
  title: string;
  description: string;
  url: string;
  btnLabel: string;
}

/**
 * Also defined in API at core/types/OneBillTypes.ts
 */
 export interface OnebillEvent {
  key: string;
  eventType: 'switch_promo' | 'other'
  header: string;
  description: string;
  cssClass: string;
  appAction: string;
  buttonText1: string;
  buttonText2: string;
}
