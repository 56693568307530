import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubSink } from 'subsink';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { PaymentType } from '@1bill-app/services/bill/types';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-payment-select-modal',
  styleUrls: ['./payment-select-modal.component.scss'],
  template: `
    <ion-card class="ion-no-margin">
      <ion-toolbar>
        <ion-title><h2>Payment Type</h2></ion-title>
        <ion-buttons slot="end">
          <div (click)="onClickClose()" class="one-button one-btn-close">×</div>
        </ion-buttons>
      </ion-toolbar>
      <ion-card-content>
        <div *ngIf="loading" class="ion-text-center">
          <ion-spinner name="crescent" style="height: 50px; width: 50px;"></ion-spinner>
        </div>

        <ng-container *ngIf="!loading">
          <ion-list>
            <ion-radio-group
              [(ngModel)]="paymentType"
              (ionChange)="selectScheduledPayments($event)"
            >
              <ion-item lines="none">
                <ion-label class="ion-text-wrap">I will pay my own bills</ion-label>
                <ion-radio mode="md" slot="start" value="M"></ion-radio>
              </ion-item>

              <ion-item lines="none">
                <ion-label class="ion-text-wrap"
                  >1bill will pay my bills with my money</ion-label
                >
                <ion-radio mode="md" slot="start" value="A"></ion-radio>
              </ion-item>

              <!-- <ion-item lines="none">
                <ion-label class="ion-text-wrap">1bill will fund my bill payments</ion-label>
                <ion-radio [disabled]="true" mode="md" slot="start" value="F"></ion-radio>
              </ion-item> -->
            </ion-radio-group>
          </ion-list>
          <ion-item lines="none" style="font-size: 12px;">
            <ion-icon name="information-circle-outline" slot="start"></ion-icon>
            <ion-label class="ion-text-wrap" style="font-size: 12px;"
              >Payment settings can be individually set and changed against each bill at any
              time</ion-label
            >
          </ion-item>
          <!-- save button -->
          <ion-button
            expand="block"
            color="primary"
            class="ion-padding-horizontal ion-margin-vertical"
            (click)="onSave()"
            [disabled]="loading || saving"
          >
            <p *ngIf="saving; else saveText"><ion-spinner></ion-spinner></p>
            <ng-template #saveText>Save</ng-template>
          </ion-button>
        </ng-container>
      </ion-card-content>
    </ion-card>
  `,
})
export class PaymentSelectModalComponent implements OnInit, OnDestroy {
  saving: boolean;
  loading: boolean;
  subs = new SubSink();
  paymentType: PaymentType;

  constructor(
    private modalCtrl: ModalController,
    private _serviceBill: BillService,
    private logger: NGXLogger,
  ) {}

  ngOnInit() {
    // this.subs.sink = this._serviceBill.stateChanged.subscribe((state) => {
    //   if (state) {
    //     this.paymentType = state.serviceBill.servicePaymentType;
    //     this.loading = state.serviceBill.loading;
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ionViewWillEnter() {
    this.loading = true;
    // this.subs.sink = this._serviceBill
    //   .getServicePaymentType()
    //   .pipe(map(() => (this.loading = false)))
    //   .subscribe();
  }

  onClickClose() {
    this.modalCtrl.dismiss();
  }

  selectScheduledPayments(event: any) {}

  onSave() {
    this.saving = true;
    // this.subs.sink = this._serviceBill
    //   .updateServicePaymentType(this.paymentType)
    //   .pipe(
    //     map(() => this.logger.log('success')),
    //     catchError((err) => {
    //       console.error(err);
    //       throw err;
    //     }),
    //     finalize(() => {
    //       this.saving = false;
    //       this.modalCtrl.dismiss({ updated: true });
    //     })
    //   )
    //   .subscribe();
  }
}
