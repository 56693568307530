import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { StorageItemKeys, StorageService } from '../storage.service';
import { UserService } from './user.service';
import { UserState } from './user.types';

function createInitialState(): UserState {
  return {
    user: null,
    pinEnabled: null,
    multiUserAccesses: null,
    manageAs: null,
    accessType: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
