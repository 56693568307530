import { Component, Input, OnInit,EventEmitter,Output } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { input } from 'aws-amplify';

@Component({
  selector: 'app-redeem-card-popover',
  templateUrl: './redeem-card-popover.component.html',
  styleUrls: ['./redeem-card-popover.component.scss'],
})
export class RedeemCardPopoverComponent implements OnInit {  

  @Input() pointsAmount: number;
  @Input() dollarAmount: number;
  @Input() points: number; 
  @Output() confirmRedeem: EventEmitter<number> = new EventEmitter();
  constructor(private popoverController: PopoverController) {}

  ngOnInit() {}

  openLink() {
    this.popoverController.dismiss();
    }
  
    dismiss(confirmed?: boolean) {
      this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
    }

}
