import { ToastController, ModalController } from '@ionic/angular';
import { environment } from '@1bill-app/env';
import {
  PaymentCard,
  PaymentCardService,
} from '@1bill-app/services/payment/payment-card.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { SubSink } from 'subsink';
import { showError } from 'src/app/services/custom-functions/custom-functions';
import { HomeService } from '@1bill-app/services/home/home.service';
import { ViewBillType, BillCategoryKey } from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { BillService } from '@1bill-app/services/bill/bill.service';

@Component({
  selector: 'app-swap-bill-modal',
  templateUrl: './swap-bill-modal.component.html',
  styleUrls: ['./swap-bill-modal.component.scss'],
})
export class SwapBillModalComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private query: SettingsQuery,
    private logger: NGXLogger,
    private loadingController: LoadingController,
    private zone: NgZone,
    private paymentCardService: PaymentCardService,
    private alertCtr: AlertController,
    private categoryService: CategoryService,
    private billService: BillService,
    private homeService: HomeService,
    private toastController: ToastController,
  ) {}

  @Input() bill: ViewBillType;
  @Input() selectedPaymentMethodId: number;

  subs = new SubSink();
  accountCards: PaymentCard[];
  defaultAccountCardId: number;
  loading: HTMLIonLoadingElement;
  saving: boolean;
  editing = false;
  tagName: string;
  regex: RegExp = /^[-_&() a-zA-Z0-9.$!#@]+$/;
  errorMessage: string;
  disabledBTN = false;

  ngOnInit() {
    this.query.select(['accountCards', 'defaultAccountCardId']).subscribe({
      next: (accountSettings) => {
        this.accountCards = accountSettings.accountCards;
        this.defaultAccountCardId = accountSettings.defaultAccountCardId;
      },
      error: (err) => {
        this.logger.error('unable to retrieve user setting from data store', err);
      },
    });
    this.tagName = this.bill.billTag ?? this.bill.fullAddress;
  }

  scrollTrue() {
    if (this.accountCards.length >= 3) return true;

    return false;
  }

  numCards() {
    return this.accountCards.length;
  }

  dismiss(updated?: boolean) {
    this.modalController.dismiss(null, updated ? 'confirm' : 'cancel');
  }

  clickSelect(index: number) {
    this.selectedPaymentMethodId = this.accountCards[index].accountPaymentId;
  }

  trackByFn(index: number, item: PaymentCard) {
    return item.accountPaymentId;
  }

  getCategoryIconSource(categoryKey: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(categoryKey)?.icon;
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }

  getTypeIconSource(categoryKey: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(categoryKey)?.imgUrl;
  }

  getCardBackground(i: any) {
    if (this.accountCards[i].cardType === 'MasterCard') {
      return './assets/cards/MasterCard.png';
    } else if (this.accountCards[i].cardType === 'Visa') {
      return './assets/cards/Visa.png';
    } else if (this.accountCards[i].cardType === 'American Express') {
      return './assets/cards/American Express.png';
    }
  }

  validateTagName() {
    const valid = !!this.tagName.match(this.regex);
    this.errorMessage = valid ? null : 'Invalid tag name';
    return !!this.tagName.match(this.regex);
  }

  toggleEdit() {
    if (this.editing) {
      // Save tag
      this.billService
        .updateTag({
          accountBillTypeId: this.bill.accountBillTypeId,
          tag: this.tagName,
        })
        .subscribe(() => {
          this.homeService.triggerFetchHomeData('SwapBillModalComponent::Edit tag', true);
        });
    }

    this.editing = !this.editing;
  }

  onPaymentMethodClick() {
    this.homeService.openZenithAddPaymentCard();
  }

  swapBill() {
    const selectedCard = this.accountCards.find(
      (accountCard) => accountCard.accountPaymentId === this.selectedPaymentMethodId,
    );
    const cardName = selectedCard.nickname ?? selectedCard.cardType;
    if (!selectedCard) {
      this.toastController
        .create({
          message: 'Sorry, your bill could not be linked to this card.',
          duration: 3000,
          color: 'danger',
        })
        .then((toast) => {
          toast.present();
        });
      return;
    }
    this.loadingController
      .create({
        mode: 'md',
        message: `Swapping your bill to ${cardName}...`,
      })
      .then((loader) => {
        loader.present();
        this.paymentCardService
          .linkBillsToPaymentMethod([this.bill.billPaymentId], this.selectedPaymentMethodId)
          .subscribe({
            next: () => {
              this.toastController
                .create({
                  message: `Your bill link was successfully swapped to ${cardName}.`,
                  duration: 3000,
                  color: 'success',
                })
                .then((toast) => {
                  toast.present();
                  this.dismiss(true);
                });
            },
            error: (err) => {
              this.toastController.create({
                message: `Sorry, an error occurred (${err?.message ?? JSON.stringify(err)}).`,
                duration: 3000,
              });
            },
            complete: () => {
              loader.dismiss();
            },
          });
      });
  }

  async setToDefault(card: PaymentCard) {
    const alert = await this.alertCtr.create({
      // cssClass: 'my-custom-class',
      header: 'Set this card as default card for payment?',
      // subHeader: 'Set this card to default?',
      message: card.cardType + ' ' + card.cardNumber,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          // cssClass: 'secondary',
          handler: (blah) => {
            this.logger.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Set to default',
          cssClass: 'alert-accept',
          handler: (blah) => {
            this.logger.log('Confirm set to default: blah');
            this.onSetDefault(card);
          },
        },
      ],
    });

    await alert.present();
  }

  async onSetDefault(card: PaymentCard) {
    const loading = await this.loadingController.create({
      message: 'Updating default card...',
    });
    await loading.present();
    try {
      this.saving = true;
      await this.paymentCardService.updatePaymentCard(card).toPromise();
      await loading.dismiss();
    } catch (err) {
      if (!environment.production) {
        console.error(err);
      }
      // send error log to server
      this.logger.error('onSetDefault', err);
      await loading.dismiss();
      // display error toast
      showError('Error occurred while saving settings data!!', true);
    }
    this.saving = false;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
