import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Input,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import Highcharts from 'highcharts';
import { NGXLogger } from 'ngx-logger';
import dayjs from 'dayjs';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { ExpensesScreenResponse } from '@1bill-app/services/yodlee/yodlee.types';
import { getFullMonthName } from '@1bill-app/helpers/date.helpers';
import { NumberFormatPipe } from '@1bill-app/pipes/number-format.pipe';

let categoryName: string;

@Component({
  selector: 'app-expenses-chart',
  templateUrl: './expenses-chart.component.html',
  styleUrls: ['./expenses-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpensesChartComponent implements OnInit {
  constructor(
    private logger: NGXLogger,
    private yodleeQuery: YodleeQuery,
  ) {}

  @Input() chartData: Partial<ExpensesScreenResponse>;
  @Input() spendingAmount: number;
  @Input() spentString: string;
  @Input() intervalString: string;
  @ViewChild('expenseChart') expenseChart: ElementRef;

  public loading$ = this.yodleeQuery.selectLoading();

  get absSpendingRatio() {
    return Math.abs(this.chartData?.spendingDifferenceRatio ?? 0);
  }

  get absSpendingDifference() {
    return Math.abs(this.chartData?.spendingDifference ?? 0);
  }

  highcharts = Highcharts;
  chartOptions: Highcharts.ChartOptions = {
    chart: {
      type: 'column',
      dashStyle: 'dot',
      height: 250,
      marginBottom: 35,
      className: 'expenses-chart',
      id: '',
      panning: true,
      pinchType: 'x',
      zoomType: '',
      resetZoomButton: {
        theme: {
          style: {
            display: 'none',
          },
        },
      },
    },
    title: {
      text: '',
    },

    tooltip: {
      followTouchMove: false,
      formatter: function () {
        const numberFormatPipe = new NumberFormatPipe();
        const date = dayjs(this.x, 'YYYY-MM-DD');
        const monthCheck = date.isValid() ? date.format('MMM').toUpperCase() : this.x;
        const periodLabel = getFullMonthName(monthCheck);
        const afterCategoryName = `${
          monthCheck === periodLabel ? '' : ' in '
        } <b>${periodLabel}</b>`;

        return `You have spent <b>$${numberFormatPipe.transform(
          this.y,
          3, 2, 2,
        )}</b> on ${categoryName}${afterCategoryName}`;
      },
    },

    noData: {
      style: {
        fontWeight: 'bold',
        fontSize: '15px',
        color: '#303030',
      },
    },

    xAxis: {
      reversed: true,
      panning: true,
      min: 0,
      max: 3.75,
      tickPositioner: function () {
        const ticks = this.series[0].xData;
        let result = [];

        for (let index = 0; index < ticks.length; index++) {
          result.push(ticks[index]);
        }
        return result;
      },
      showEmpty: false,
      labels: {
        enabled: true,
        autoRotation: 0,
        formatter: function (label) {
          const date = dayjs(label?.value, 'YYYY-MM-DD');
          return date.isValid() ? date.format('MMM').toUpperCase() : label?.value;
        },
      },
    },
    yAxis: {
      panning: false,
      title: {
        text: null,
      },
      labels: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },

    navigator: { enabled: false },
    scrollbar: { enabled: false },

    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
        stacking: 'normal',
        dataLabels: {
          crop: false,
          overflow: 'allow',
          enabled: true,
          color: 'white',
          verticalAlign: 'bottom',
          x: 0,
          y: -20,
          formatter: function () {
            const numberFormatPipe = new NumberFormatPipe();
            return `$${numberFormatPipe.transform(this.y, 3, 1, 2, true)}`;
          },
          style: {
            textOutline: false,
          },
        },
      },
    },

    series: [
      {
        marker: { enabled: true },
        name: '',
        id: 'data',
        data: [],
        color: '#51BD89',
        negativeColor: '#eb7a7a',
        borderRadius: 5,
        pointWidth: 60,
        showInLegend: false,
        minPointLength: 24,
        dataLabels: {
          formatter: function () {
            if (this.y > 1000) {
              return '$' + Highcharts.numberFormat(this.y / 1000, 2) + 'K';
            } else {
              return '$' + this.y;
            }
          },
          enabled: true,
          crop: false,
          overflow: 'justify',
          color: 'white',
          verticalAlign: 'bottom',

          x: 0,
          y: -6,
          style: {
            textOutline: false,
          },
        },
      },
    ],
  } as any;

  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        // console.log('chart callback', chart);
        // if (chart) chart.reflow();
      } catch (err) {
        console.warn('chartCallback', err);
      }
    }, 1000);
  };

  ngOnInit() {
    const chartElement = this.expenseChart as any;
    chartElement?.chart?.showLoading();

    this.yodleeQuery.isLoading$.subscribe((result) => {
      if (result) {
        (this.expenseChart as any)?.chart?.showLoading();
      } else {
        (this.expenseChart as any)?.chart?.hideLoading();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    const chart = Highcharts.charts.find(
      (chart) => chart?.options?.chart?.className === 'expenses-chart',
    );
    this.intervalString = changes.intervalString?.currentValue;
    this.spentString = changes.spentString?.currentValue;

    chart?.showLoading();

    if (chart?.yAxis?.length) {
      chart?.yAxis[0]?.setExtremes(null, null);
    }
    if (chart?.xAxis?.length) {
      (chart.xAxis[0] as any).labelFormatter = () => '';
      chart?.xAxis[0]?.setExtremes(null, null);
    }

    if (!chart) {
      setTimeout(() => (this.expenseChart as any)?.chart?.reflow(), 1500);
      setTimeout(() => (this.expenseChart as any)?.chart?.hideLoading(), 1500);
      return;
    }

    if (changes.chartData) {
      chart?.hideLoading();
      categoryName = this.chartData?.categoryName;
      chart.series[0].setData(
        this.chartData.chartData.map((cData) => Math.abs(cData?.amount ?? 0)),
      );

      chart.xAxis[0].setCategories(
        this.chartData.chartData.map((cData) => cData?.label || 'NO DATA'),
      );
      chart.series[0].update({ type: 'column', color: this.chartData.itemColor });

      // Set extremes to keep column heights constant
      chart.yAxis[0].setExtremes(
        Math.min(this.chartData.chartMin, 0),
        Math.max(this.chartData.chartMax, 0),
      );

      chart.redraw();
      chart.reflow();

      // chart.series[0].update({ type: 'column', color: this.color, colors: [this.color], edgeColor: this.color, borderColor: this.color, negativeColor: this.color });
    }
  }
}
