import { Component, Input, OnInit } from '@angular/core';

enum Step {
  PLAN,
  ACCEPTED,
  CONTRACT,
  WAIT,
  CONNECT,
}

@Component({
  selector: 'app-switch-status',
  templateUrl: './switch-status.component.html',
  styleUrls: ['./switch-status.component.scss'],
})
export class SwitchStatusComponent implements OnInit {
  @Input() data: {
    switchStatus: 'ONGOING' | 'ACCEPTED' | 'WAIT' | 'COMPLETED' | 'FAILED';
    switchRetailerName: string;
    switchRetailerLogo: string;
    switchPlanName: string;
    switchAddress: string;
  }
  @Input() billCategoryLogo: string;
  @Input() showHeading: boolean = true;
  @Input() accordionState: 'expand' | 'collapse' = 'expand';
  // fixme: fix the switch steps
  //  set switch steps dynamically
  switchSteps = [
    {
      step: Step.PLAN,
      title: 'Select the new plan',
      completed: true,
    },
    {
      step: Step.ACCEPTED,
      title: 'Signup process completed',
      completed: false,
    },
    {
      step: Step.WAIT,
      title: 'Cooling off period ended (1bill points awarded)',
      completed: false,
    },
    {
      step: Step.CONNECT,
      title: 'Switch complete',
      completed: false,
    }
  ];

  constructor() {}

  ngOnInit() {
    // Update switch steps from defaults
    this.switchSteps.forEach((switchStep) => {
      switch (switchStep.step) {
        case Step.PLAN: {
          switchStep.completed = this.data.switchStatus === 'ONGOING' || this.data.switchStatus === 'ACCEPTED' || this.data.switchStatus === 'WAIT' || this.data.switchStatus === 'COMPLETED';
        } break;
        case Step.ACCEPTED: {
          switchStep.completed = this.data.switchStatus === 'ACCEPTED' || this.data.switchStatus === 'WAIT' ||  this.data.switchStatus === 'COMPLETED';
        } break;
        case Step.WAIT: {
          switchStep.completed = this.data.switchStatus === 'WAIT' || this.data.switchStatus === 'COMPLETED';
        } break;
        case Step.CONNECT: {
          switchStep.completed = this.data.switchStatus === 'COMPLETED';
        } break;
      } 
    })
  }

  toggleAccordionState() {
    this.accordionState = this.accordionState === 'expand' ? 'collapse' : 'expand';
  }

  getAccumulatedPoint(type: 'value' | 'ratio' = 'value') {
    const value = this.switchSteps.reduce((a, b) => {
      if (b.completed) {
        a += 1;
      }
      return a;
    }, 0);
    if (type == 'ratio') {
      return value / this.switchSteps.length
    }
    if (type == 'value') {
      return value
    }
  }

  renderPointItemClass(data) {
    const classNames = [];
    if (this.accordionState === 'expand') {
      classNames.push('expand');
    } else {
      classNames.push('collapse');
    }
    if (data.completed) {
      classNames.push('complete');
    } else {
      classNames.push('incomplete');
    }
    return classNames.join(' ');
  }

}
