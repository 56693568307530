import { generateArray } from '@1bill-app/helpers/array.helper';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { BillCategoryKey } from '@1bill-app/services/bill/types';
import { HomeService } from '@1bill-app/services/home/home.service';
import { SettingsService } from '@1bill-app/services/settings/settings.service';
import { Settings, SettingsStore } from '@1bill-app/services/settings/settings.store';
import { YodleeAccount } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, LoadingController } from '@ionic/angular';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { ROUTE_HOMEPAGE, ROUTE_FRAGMENT_UPCOMING_BILLS } from '@1bill-app/constants';

export enum DisplayMode {
  BALANCE,
  COMPARISON,
}

@Component({
  selector: 'app-account-summary',
  templateUrl: './account-summary.component.html',
  styleUrls: ['./account-summary.component.scss'],
})
export class AccountSummaryComponent implements OnInit {
  @Input() accounts: YodleeAccount[];
  @Input('loading') isLoading: boolean;
  address: string;
  placeId: string;
  settings: Settings;
  mode = DisplayMode.BALANCE;
  modes = DisplayMode;
  loading: HTMLIonLoadingElement;
  generateArray = generateArray;
  public numOfBillTypes$ = this.billQuery.billCategories$.pipe(
    map((cs) => (cs as any[])?.filter((c) => c?.id >= 1)),
    map((cs) => cs?.length),
  );
  public anyBills$ = this.numOfBillTypes$.pipe(map((num) => num > 0 ?? false));
  public aggregated$ = this.billQuery.aggregated$;
  get bankAccounts() {
    return this.accounts?.filter((a) => a?.type === 'bank');
  }

  get creditCards() {
    return this.accounts?.filter((a) => a?.type === 'creditCard');
  }

  get totalBankAccounts() {
    return _.sum(this.bankAccounts?.map((a) => a?.balance?.amount)) ?? 0;
  }

  get totalCreditCards() {
    return _.sum(this.creditCards?.map((a) => a?.balance?.amount)) ?? 0;
  }

  get totalBillAmountsOwed$() {
    return this.aggregated$.pipe(map((a) => a?.totalDueAmount + a?.totalOverdueAmount));
  }

  constructor(
    private router: Router,
    private actionSheetController: ActionSheetController,
    private billQuery: BillQuery,
    private logger: NGXLogger,
    private settingsService: SettingsService,
    private settingsStore: SettingsStore,
    private loadingCtrl: LoadingController,
    private homeService: HomeService,
  ) { }

  async ngOnInit() {
    this.settings = await this.settingsService.fetch().toPromise();
  }

  handleFindBetterDeals() {
    const userPlaceId = this.settingsStore.getValue().addressJson?.placeId;
    if (userPlaceId) {
      this.selectAddressFromProfile(userPlaceId);
    } else {
      this.mode = DisplayMode.COMPARISON;
    }
  }

  async handleLinkBankAccount() {
    // this.router.navigateByUrl('/accounts');
    this.homeService.presentLinkBankAccountModal();
  }

  async handleAddCard() {
    this.homeService.openZenithAddPaymentCard();
  }

  async onAddressSelected(event) {
    this.logger.debug('setAddress', event);
    this.placeId = event?.data?.placeId;
    if (event?.success) await this.findBetterDeals();
    this.mode = DisplayMode.BALANCE;
  }

  async selectAddressFromProfile(placeId: string) {
    this.placeId = placeId;
    await this.findBetterDeals();
  }

  async findBetterDeals() {
    await this.displayLoading();
    this.homeService
      .findBetterDeals(BillCategoryKey.ELECTRICITY, this.settings, this.placeId).toPromise()
      .catch(err => {
          this.logger.error('findBetterDeals error', err);
      }).finally(() => {
        this.hideLoading();
      });

  }

  async displayLoading() {
    if (!this.loading?.present) {
      this.loading = await this.loadingCtrl.create({ mode: 'ios', message: 'Loading...' });
    }
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading?.dismiss) {
      await this.loading.dismiss();
    }
  }

  async handleAddBill() {
    const actionSheetConfig: any = {
      cssClass: 'bill-action-sheet',
      buttons: [
        {
          text: `Upload or take photo`,
          cssClass: 'delete-button',
          handler: () => this.router.navigate(['/upload-your-bill/img']),
        },
        {
          text: `Upload file`,
          cssClass: 'delete-button mt-10',
          handler: () => this.router.navigate(['/upload-your-bill/pdf']),
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
        },
      ],
      mode: 'ios',
    };
    const actionSheet = await this.actionSheetController.create(actionSheetConfig);
    await actionSheet.present();
  }

  handleGoToBills() {
    // Code to scroll to home page bills section with 'Month' tab selected.
    // const uniqueValue = String(new Date().valueOf());
    // const anchorLink = document.createElement('a');
    // anchorLink.setAttribute('href', `${ROUTE_HOMEPAGE}#${ROUTE_FRAGMENT_UPCOMING_BILLS}`);
    // anchorLink.setAttribute('id', uniqueValue);
    // document.body.appendChild(anchorLink);
    // document.getElementById(uniqueValue).click();
    // document.body.removeChild(anchorLink);
    // this.homeService.triggerHomeRouteEvent('monthTab');
    // // this.router.navigate(['/app/home'], { fragment: 'dashboard-upcoming-bills-section' });

    // Should navigate to /app/bills with the 'Unpaid' tab selected.
    // This tab is selected by default.
    this.router.navigate(['/app/bills'], { fragment: 'unpaid' });
  }
}
