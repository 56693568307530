import { Injectable, ErrorHandler } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { NGXLogger } from 'ngx-logger';
import { AppSQLiteLoggerMonitor } from './logging/app-sqlite-logger-monitor';
import { SQLiteService } from './sqlite/sqlite.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private logger: NGXLogger, private sqlite: SQLiteService) {
    super();
  }

  async handleError(error) {
    this.logger.error('GlobalErrorHandler',error);
    if (Capacitor.isNativePlatform()) {
      this.logger.registerMonitor(new AppSQLiteLoggerMonitor(this.sqlite))
    }
    super.handleError(error);
  }
}
