import { ROUTE_EMAIL_SCANNER_JOBS } from '@1bill-app/constants';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emails-scanned',
  templateUrl: './emails-scanned.component.html',
  styleUrls: ['./emails-scanned.component.scss'],
})
export class EmailsScannedComponent implements OnInit {
  //scannedJobs$ = this.query.emailScannedJobs$;
  emailScannedBillsToVerify$ = this.billQuery.emailScannedBillsToVerify$;
  routeEmailScannerJobs = ROUTE_EMAIL_SCANNER_JOBS;
  constructor(public billQuery: BillQuery, ) {}

  ngOnInit() {}
}
