import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AccessingUserHeaderComponent } from '../accessing-user-header/accessing-user-header.component';
import { HeaderLogoComponent } from './header-logo.component';

@NgModule({
  declarations: [HeaderLogoComponent, AccessingUserHeaderComponent],
  imports: [CommonModule, IonicModule, RouterModule],
  exports: [HeaderLogoComponent],
})
export class HeaderLogoComponentModule {}
