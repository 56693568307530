import { Auth, Hub, Logger } from 'aws-amplify';
import { browserOrNode } from '@aws-amplify/core/lib/JS';
// import { AwsCognitoOAuthOpts } from '@aws-amplify/auth/lib/types';
import { parse } from 'url';
import {
  CognitoUser,
  CognitoUserSession,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoAccessToken,
} from 'amazon-cognito-identity-js';
import { urlSafeDecode } from '@aws-amplify/core';
import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
const logger = new Logger('CustomOAuth');
@Injectable({ providedIn: 'root' })
export class CustomOAuth {
  constructor(private logger: NGXLogger) {}
  /**
   * Used to complete the OAuth flow with or without the Cognito Hosted UI
   * @param {String} URL - optional parameter for customers to pass in the response URL
   * @see https://github.com/aws-amplify/amplify-js/blob/61f7478609fce7dd2f25c562aeb887d3f3db4a67/packages/auth/src/Auth.ts
   */
  async _handleAuthResponse(URL?: string) {
    const AMPLIFY_SYMBOL = (typeof Symbol !== 'undefined' && typeof Symbol.for === 'function'
      ? Symbol.for('amplify_default')
      : '@@amplify_default') as Symbol;
    const dispatchAuthEvent = (event: string, data: any, message: string) => {
      Hub.dispatch('auth', { event, data, message }, 'Auth', AMPLIFY_SYMBOL);
    };
    const authInstance = Auth as any;

    if (authInstance.oAuthFlowInProgress) {
      logger.debug(`Skipping URL ${URL} current flow in progress`);
      return;
    }

    try {
      authInstance.oAuthFlowInProgress = true;
      if (!authInstance._config.userPoolId) {
        throw new Error(`OAuth responses require a User Pool defined in config`);
      }

      dispatchAuthEvent(
        'parsingCallbackUrl',
        { url: URL },
        `The callback url is being parsed`,
      );

      const currentUrl = URL || (browserOrNode().isBrowser ? window.location.href : '');

      const hasCodeOrError = !!(parse(currentUrl).query || '')
        .split('&')
        .map((entry) => entry.split('='))
        .find(([k]) => k === 'code' || k === 'error');

      const hasTokenOrError = !!(parse(currentUrl).hash || '#')
        .substr(1)
        .split('&')
        .map((entry) => entry.split('='))
        .find(([k]) => k === 'access_token' || k === 'error');

      if (hasCodeOrError || hasTokenOrError) {
        authInstance._storage.setItem('amplify-redirected-from-hosted-ui', 'true');
        try {
          const {
            accessToken,
            idToken,
            refreshToken,
            state,
          } = await authInstance._oAuthHandler.handleAuthResponse(currentUrl);
          const session = new CognitoUserSession({
            IdToken: new CognitoIdToken({ IdToken: idToken }),
            RefreshToken: new CognitoRefreshToken({
              RefreshToken: refreshToken,
            }),
            AccessToken: new CognitoAccessToken({
              AccessToken: accessToken,
            }),
          });

          let credentials;
          // Get AWS Credentials & store if Identity Pool is defined
          if (authInstance._config.identityPoolId) {
            credentials = await Auth.Credentials.set(session, 'session');
            logger.debug('AWS credentials', credentials);
          }

          /* 
          Prior to the request we do sign the custom state along with the state we set. This check will verify
          if there is a dash indicated when setting custom state from the request. If a dash is contained
          then there is custom state present on the state string.
          */
          const isCustomStateIncluded = /-/.test(state);

          /*
          The following is to create a user for the Cognito Identity SDK to store the tokens
          When we remove this SDK later that logic will have to be centralized in our new version
          */
          //#region
          const currentUser: CognitoUser = authInstance.createCognitoUser(
            session.getIdToken().decodePayload()['cognito:username'],
          );

          // This calls cacheTokens() in Cognito SDK
          currentUser.setSignInUserSession(session);

          if (window && typeof window.history !== 'undefined') {
            // window.history.replaceState(
            //   {},
            //   null,
            //   (authInstance._config.oauth as AwsCognitoOAuthOpts).redirectSignIn,
            // );
          }

          dispatchAuthEvent(
            'signIn',
            currentUser,
            `A user ${currentUser.getUsername()} has been signed in`,
          );
          dispatchAuthEvent(
            'cognitoHostedUI',
            currentUser,
            `A user ${currentUser.getUsername()} has been signed in via Cognito Hosted UI`,
          );

          if (isCustomStateIncluded) {
            const customState = state.split('-').splice(1).join('-');

            dispatchAuthEvent(
              'customOAuthState',
              urlSafeDecode(customState),
              `State for user ${currentUser.getUsername()}`,
            );
          }
          //#endregion

          return credentials;
        } catch (err) {
          logger.debug('Error in cognito hosted auth response', err);
          dispatchAuthEvent('signIn_failure', err, `The OAuth response flow failed`);
          dispatchAuthEvent(
            'cognitoHostedUI_failure',
            err,
            `A failure occurred when returning to the Cognito Hosted UI`,
          );
          dispatchAuthEvent(
            'customState_failure',
            err,
            `A failure occurred when returning state`,
          );
        }
      }
    } finally {
      authInstance.oAuthFlowInProgress = false;
    }
  }
}
