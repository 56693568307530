import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AFEvent, AppsFlyer } from 'appsflyer-capacitor-plugin';

export enum UserRegistrationMethods {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  EMAIL = 'email',
  APPLE = 'apple',
}
export enum UploadedBillMimeTypes {
  PDF = 'pdf',
  IMG = 'image',
}

@Injectable({
  providedIn: 'root',
})
export class AppsflyerService {
  constructor() {}

  userRegistration(registrationMethod: UserRegistrationMethods) {
    const data: AFEvent = {
      eventName: 'User Registration',
      eventValue: { af_registration_method: registrationMethod },
    };
    return this.logEvent(data);
  }

  billUpload(contentType: UploadedBillMimeTypes) {
    const data: AFEvent = {
      eventName: 'Bill upload',
      eventValue: { af_content_type: contentType },
    };
    return this.logEvent(data);
  }
  startedSwitch() {
    const data: AFEvent = {
      eventName: 'Started Switch',
      eventValue: {},
    };
    return this.logEvent(data);
  }
  completedSwitch() {
    const data: AFEvent = {
      eventName: 'Completed Switch',
      eventValue: {},
    };
    return this.logEvent(data);
  }

  private logEvent(data: AFEvent) {
    if (!Capacitor.isNativePlatform()) {
      return;
    }

    return AppsFlyer.logEvent(data)
      .then((r) => {
        // alert('AppsFlyerLogEvent ~~>' + JSON.stringify(r));
        console.log('AppsFlyerLogEvent ~~>', r);
      })
      .catch((err) => {
        console.error('AppsFlyerErrEvent ~~>', err);
        // alert('AppsFlyerErrEvent ~~>' + JSON.stringify(err));
      });
  }
}
