import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeAccount, YodleeWealthInfoGroup } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { sum } from 'lodash';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink/dist/subsink';

@Component({
  selector: 'app-wealth-list',
  templateUrl: './wealth-list.component.html',
  styleUrls: ['./wealth-list.component.scss'],
})
export class WealthListComponent implements OnInit {
  @Input() type: 'asset' | 'liability' = 'asset';
  subs = new SubSink();

  expanded: boolean = false;

  constructor(
    private yodleeService: YodleeService,
    private yodleeQuery: YodleeQuery,
    private route: ActivatedRoute,
  ) {}

  public accountWealthInfoGroups$ = this.yodleeQuery.wealthCategories$;

  assetWealthInfo: YodleeWealthInfoGroup[];
  liabilityWealthInfo: YodleeWealthInfoGroup[];

  allAccounts: YodleeAccount[];
  assetAccounts: YodleeAccount[];
  liabilityAccounts: YodleeAccount[];

  public allAccounts$ = this.yodleeQuery.accounts$;

  public assetAccounts$ = this.yodleeQuery.accounts$.subscribe((accounts) => {
    this.assetAccounts = accounts?.filter((account) => account.wealthType === 'asset');
    return this.assetAccounts;
  });

  public liabilityAccounts$ = this.yodleeQuery.accounts$.subscribe((accounts) => {
    this.liabilityAccounts = accounts?.filter((account) => account.wealthType === 'liability');
    return this.liabilityAccounts;
  });

  ngOnInit() {
    this.subs.sink = this.yodleeService.getAccounts().subscribe();
    this.subs.sink = this.yodleeService.getWealthInformation().subscribe();
    this.yodleeQuery.wealthCategories$
      .pipe(filter((wealthInfoGroup) => !!wealthInfoGroup))
      .subscribe((wealthInfoGroups) => {
        this.assetWealthInfo = wealthInfoGroups['asset'];
        this.liabilityWealthInfo = wealthInfoGroups['liability'];
        //this.logger.log('Wealth Info Groups:', wealthInfoGroups);
      });
  }

  getWealthCategories() {
    if (this.type == 'asset') return this.assetWealthInfo;
    return this.liabilityWealthInfo;
    //return this.accountWealthInfoGroups[this.type];
  }

  get wealthTitle() {
    if (this.type === 'asset') return 'Assets';
    return 'Liabilities';
  }

  get totalWealth() {
    return sum(
      (this.type === 'asset' ? this.assetWealthInfo : this.liabilityWealthInfo)?.map(
        (wealthCategory) => wealthCategory.totalAmount,
      ),
    );
  }

  trackByFn(data: { type: string }) {
    return data?.type;
  }

  expandWealth() {
    this.expanded = !this.expanded;
  }
}
