import { _take, _uniqBy } from '@1bill-app/helpers/lodash.helper';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { isBillPaid } from '@1bill-app/services/bill/helper';
import { ViewBillType } from '@1bill-app/services/bill/types';
import { Job } from '@1bill-app/services/status/types';
import {
  animate, animateChild, query,
  stagger, style, transition, trigger
} from '@angular/animations';
import {
  ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';

/**
 * Cards to be displayed based upon array of cards passed.
 */
@Component({
  selector: 'app-bill-card-list',
  templateUrl: './bill-card-list.component.html',
  styleUrls: ['./bill-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        // each time the binding value changes
        query(':leave', [stagger(100, [animateChild()])], {
          optional: true,
        }),
        query(
          ':enter',
          [
            style({ opacity: 0, transform: 'translateY(-8px)' }),
            stagger(100, [animate('0.2s', style({ opacity: 1, transform: 'none' }))]),
          ],
          { optional: true },
        ),
      ]),
    ]),
  ],
})
export class BillCardListComponent {
  @ViewChild('componentEl') componentEl: ElementRef;
  @Input() routePath: string;
  @Input() data: ViewBillType[] = [];
  @Input() recentAdded: ViewBillType[] = [];
  @Input() loading: boolean;
  @Input() title: string;
  @Input() source: string;
  @Input() limit: number;
  @Input() jobsOnProgress: Job[];
  @Input() isTechnicalIssue: boolean;
  prevCardsId: number[];
  public isBillPaid = isBillPaid;
  
  constructor(
    private router: Router,
    private logger: NGXLogger,
    private billService: BillService
  ) {}

  get bills() {
    let bills = this.data;
    // filter out recent added bills
    if (this.recentAddedBills?.length > 0 && Array.isArray(this.data)) {
      const ids = this.recentAddedBills.map((r) => r.billPaymentId);
      bills = this.data.filter((d) => ids.indexOf(d.billPaymentId) < 0);
    }
    return this.limit ? _take(bills, this.limit) : bills;
  }
  get recentAddedBills() {
    return _uniqBy(this.recentAdded, 'billPaymentId');
  }
  goToBillDet(billPaymentId?: number, jobId?: number) {
    if (billPaymentId) {
      this.router.navigate([this.routePath], {
        queryParams: { _source: this.source, billPaymentId: billPaymentId },
      });
    } else {
      this.router.navigate([this.routePath], {
        queryParams: { _source: this.source, jobId: jobId },
      });
    }
  }
  onPayNowClick(bill: ViewBillType) {
    this.logger.debug('Pay now button clicked for bill: ', bill);
    this.billService.presentPaymentCombinedModal(bill);
  }
  trackByFn(index: number, item: ViewBillType) {
    return item?.billPaymentId;
  }
  scrollIntoView() {
    setTimeout(
      () =>
        this.componentEl.nativeElement &&
        this.componentEl.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        }),
      0,
    );
  }
}
