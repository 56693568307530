import { isArrayExists } from '@1bill-app/helpers/array.helper';
import {
  formatDateLong2,
  getDayCount,
  isDateTimeWithin24Hrs,
  isDateValid,
  isDateWithinAMonth,
  isDateWithinAWeek,
  isDateWithinCurrentMonth,
} from '@1bill-app/helpers/date.helpers';
import { isNumber } from '@1bill-app/helpers/general.helper';
import { _orderBy, _pick, _take } from '@1bill-app/helpers/lodash.helper';
import { EventEmitter, Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { orderBy, sum } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BillState, BillStore } from './bill.store';
import { isBillOverdue, isBillPaid, canSwitch } from './helper';
import {
  appProductTypes,
  BillCategoryKey,
  BillCategoryType,
  GetBillDuration,
  GetBillGroup,
  GetBillOption,
  GetFailedBill,
  OneBillAccountBillType,
  UserBillPayment,
  ViewBillType,
} from './types';

@Injectable({
  providedIn: 'root',
})
export class BillQuery extends Query<BillState> {
  constructor(protected store: BillStore, private logger: NGXLogger) {
    super(store);
  }

  public isLoading$ = this.selectLoading();
  public error$ = this.selectError();
  public isFirstBill$ = this.select('isFirstBill');
  public emailScannedBillsToVerify$ = this.select('emailScannedBillsToVerify');
  public bills$ = this.getBillsForView();
  public upcomingBills$ = this.getBillsForView(GetBillOption.UNPAID_BILLS);
  public homeUpcomingBills$ = this.getHomeUpcomingBills();
  public failedBills$ = this.getFailedBills(2);
  public billDetails$ = this.getBillDetails();
  public failedBillDetails$ = this.getFailedBillDetails();
  public billsPageUpcomingBills$ = this.getBillsPageUpcomingBills();
  public historyBills$ = this.getHistoryBills();
  public recentlyUploadedBills$ = this.getRecentlyUploadedBills();
  public overdueBills$ = this.getHomeOverdueBills();
  public savingsBills$ = this.getSavingsBills();
  public billsAggregatedInfo$ = this.getBillsAggregatedInfo();
  public switchRewardPoints$ = this.select('switchRewardPoints');
  public showAppMenu: EventEmitter<any> = new EventEmitter();
  public paidBillCount$ = this.getBillsForView(GetBillOption.PAID_BILLS).pipe(
    
    map((bills) => bills.length),
  );
  public accountBillTypesWithBillPayment$ = this.getAccountBillTypesWithBillPayment();

  public billCategories$ = this.select('billCategories').pipe(
    map((categories) => {
      if (!categories) return categories;

      return [
        {
          id: '-1',
          key: 'ALL',
          name: 'All',
        },

        ...categories,
      ];
    }),
  );
  public billsThisMonth$ = this.getHomeUpcomingBills(
    GetBillOption.UNPAID_BILLS,
    GetBillDuration.MONTH,
    /* Previously THIS_MONTH for 'This month' header on design */
    GetBillGroup.ALL,
  );
  public aggregated$ = this.select('aggregated');
  public paymentOptionsBills$ = this.select('paymentOptionsBills');
  public predictions$ = this.select('billPredictions');

  get combinedDueAmount$() {
    return this.select((data) => {
      const totalDueAmount = isNumber(data.aggregated?.totalDueAmount)
        ? parseFloat(data.aggregated?.totalDueAmount + '')
        : 0;
      const totalOverdueAmount = isNumber(data.aggregated?.totalOverdueAmount)
        ? parseFloat(data.aggregated?.totalOverdueAmount + '')
        : 0;
      return totalDueAmount + totalOverdueAmount;
    });
  }

  get calculatedDueAmount$() {
    return this.billsPageUpcomingBills$.pipe(
      map((bill) => {
        return bill.reduce(
          (sum, item) => {
            if (item.amount > 0) {
              const hasPaid = item.amountPaid > 0;

              if (hasPaid && item.amountPaid <= item.amount) {
                sum += item.amount - item.amountPaid;
              }
            }

            sum += item.amount > 0 ? item.amount : 0;
            return sum;
          },

          0,
        );
      }),
    );
  }

  get totalBillSaving$() {
    return this.select('aggregated').pipe(map((aggregated) => aggregated?.totalBillSaving));
  }

  /**
   * Get list of all suppliers
   */
  getSuppliers() {
    return this.select('suppliers').pipe((res) => {
      if (!res) return null;
      return res;
    });
  }

  /**
   * Get bill tags
   * @param limit
   */
  getBillTags(limit?: number) {
    return this.select('billTags').pipe(
      map((res) => {
        if (!res) return null;
        if (limit) return _take(res, limit);
        return res;
      }),
    );
  }

  /**
   * Get bill tags
   * @param limit
   */
  getBillTagsForPage(billOption: GetBillOption) {
    return this.getBillsForView(billOption)
      .pipe(
        map((bills) => {
          const billTags = this.getValue().billTags;
          if (!billTags?.length) return [];

          return billTags.filter((tag) => {
            return bills.filter((b) => {
              return (
                tag === b.billTag ||
                (b.fullAddress && isArrayExists(tag.split(' '), b.fullAddress.split(' ')))
              );
            }).length;
          });
        }),
      )
      .pipe(map((x) => Array.from(new Set(x))));
  }

  /**
   * Return account bill types with the respective bill payments data
   */
  private getAccountBillTypesWithBillPayment() {
    return this.select('accountBillTypes').pipe(
      map((bt) => {
        if (!Array.isArray(bt)) return [];

        return bt.map((bt) => {
          const billPayments =
            bt.billPayments.length > 0
              ? bt.billPayments.map((bp) => ({
                  billPaymentId: bp.billPaymentId,
                }))
              : [];
          bt.billPayments = billPayments;
          return bt;
        });
      }),
    );
  }

  /**
   * Get unpaid bills for home page
   */
  getHomeUpcomingBills(
    billOption = GetBillOption.UNPAID_BILLS,
    duration: GetBillDuration = GetBillDuration.ALL,
    group: GetBillGroup = GetBillGroup.ALL,
  ) {
    return this.getBillsForView(billOption).pipe(
      map((bills) => this.billsFilterByCategory(bills, 'homePageFilter')),
      map((bills) => this.billsFilterGroup(bills, group)),
      map((bills) => this.billsFilterByDuration(bills, duration)),
      map((bills) => this.billsFilterLimit(bills, 'homePageFilter')),
    );
  }

  getBillsAggregatedInfo() {
    return this.getBillsForView(GetBillOption.UNPAID_PLUS_FAILED_BILLS).pipe(
      map((bills) => {
        const fix = (value: number, spaces = 2) => Number(value.toFixed(spaces));
        const billAmount = (bill) =>
          fix(bill.amount - (isNaN(bill.amountPaid) ? 0 : bill.amountPaid));

        const upcomingBills = bills.filter((bill) => bill.dueDate && !isBillOverdue(bill));
        const recentBills = bills.filter(
          (bill) =>
            bill.createdAt &&
            isDateTimeWithin24Hrs(bill.createdAt) &&
            bill.dueDate &&
            !isBillOverdue(bill),
        );
        const recentAllDueAmount = sum(recentBills.map(billAmount)) ?? 0;
        const recentMonthlyDueAmount =
          sum(
            recentBills.filter((bill) => isDateWithinAMonth(bill.dueDate)).map(billAmount),
          ) ?? 0;
        const recentWeeklyDueAmount =
          sum(recentBills.filter((bill) => isDateWithinAWeek(bill.dueDate)).map(billAmount)) ??
          0;
        const upcomingAllDueAmount = sum(upcomingBills.map(billAmount)) ?? 0;
        const upcomingMonthlyDueAmount =
          sum(
            upcomingBills.filter((bill) => isDateWithinAMonth(bill.dueDate)).map(billAmount),
          ) ?? 0;
        const upcomingWeeklyDueAmount =
          sum(
            upcomingBills.filter((bill) => isDateWithinAWeek(bill.dueDate)).map(billAmount),
          ) ?? 0;

        return {
          upcomingBillsCount: upcomingBills?.length ?? 0,
          recentBillsCount: recentBills?.length ?? 0,
          recentAllDueAmount,
          recentMonthlyDueAmount,
          recentWeeklyDueAmount,
          upcomingAllDueAmount,
          upcomingMonthlyDueAmount,
          upcomingWeeklyDueAmount,
        };
      }),
    );
  }

  /**
   * Get overdue bills without filter
   * @returns
   */
  getHomeOverdueBills() {
    return this.getBillsForView(GetBillOption.UNPAID_BILLS).pipe(
      map((bills) => this.billsFilterGroup(bills, GetBillGroup.OVERDUE_BILLS)),
      map((bills) => this.billsFilterLimit(bills, 'homePageFilter')),
    );
  }

  /**
   * Get bills that can switch to different providers.
   * @returns
   */
  getSavingsBills() {
    return this.getBillsForView(GetBillOption.ALL_BILLS).pipe(
      map((bills) =>
        bills?.filter(
          (bill) =>
            bill?.billPaymentId &&
            this.canSwitch(bill.categoryKey) &&
            bill?.rating &&
            bill?.savingPerYear,
        ),
      ),
      map((bills) => orderBy(bills, ['rating'], ['asc'])),
    );
  }

  /**
   * Get unpaid bills for home page, no filter
   */
  getHomeUpcomingBillsNoFilter(billOption = GetBillOption.UNPAID_BILLS) {
    return this.getBillsForView(billOption);
  }

  /**
   * Get bill details for single bill details and history page bill details (using current bill id)
   * Normally used for getting successful bill.
   */
  private getBillDetails() {
    return this.getBillsForView().pipe(
      map((bills) => {
        const billPaymentId = this.getValue().currentBillId;
        return bills.filter((b) => b.billPaymentId == billPaymentId);
      }),
    );
  }

  /**
   * Get bill details for single bill details and history page bill details (using job id provided)
   * This is typically used for failed bills.
   */
  private getFailedBillDetails() {
    return this.getBillsForView().pipe(
      map((bills) => {
        const jobId = this.getValue()?.currentJobId;
        return bills.filter((b) => b.jobId == jobId);
      }),
    );
  }

  /**
   * Get unpaid bills for bills page
   */
  getBillsPageUpcomingBills(billOption = GetBillOption.UNPAID_BILLS) {
    return this.getBillsForView(billOption).pipe(
      map((bills) => this.billsFilterLimit(bills, 'billsPageFilter')),
      map((bills) => this.billsFilterByCategory(bills, 'billsPageFilter')),
      map((bills) => this.billsFilterByProperty(bills, 'billsPageFilter')),
      map((bills) => this.billsFilterByQuery(bills, 'billsPageFilter')),
      map((bills) => this.billsOrder(bills, billOption)),
    );
  }

  /**
   * Get recently uploaded bills (24 hrs)
   */
  getRecentlyUploadedBills(billOption = GetBillOption.ALL_BILLS) {
    return this.getBillsForView(billOption).pipe(
      map((bills) => this.billsFilterByUploadTime(bills, '24hr')),
    );
  }

  /**
   * Checks if user has already uploaded bills based upon bill payments.
   */
  hasUserUploadedBills() {
    return this.select('billPayments').pipe(
      tap((bp) => {
        this.logger.log('hasUserUploadedBills>billPayments', bp);
      }),
      map((data) => {
        if (data && data.length) {
          return true;
        }

        return false;
      }),
    );
  }

  /**
   * Get formatted bills for view
   * @param billOption
   * @param snapshot
   */
  public getBillsForView(): Observable<ViewBillType[]>;
  public getBillsForView(billOption?: GetBillOption): Observable<ViewBillType[]>;
  public getBillsForView(billOption?: GetBillOption, snapshot?: 'true' | true): ViewBillType[];

  public getBillsForView(billOption?: GetBillOption, snapshot?: 'true' | true) {
    if (billOption && !!snapshot) {
      return this.applyBillFilterSort(billOption, this.getValue());
    } else {
      return this.select([
        'billPayments',
        'accountBillTypes',
        'failedBills',
        'billCategories',
        'ui',
      ]).pipe(
        map((data) => {
          return this.applyBillFilterSort(billOption, data);
        }),
      );
    }
  }

  /**
   * Get bills for history page
   */
  private getHistoryBills() {
    return this.getBillsForView(GetBillOption.PAID_BILLS).pipe(
      map((bills) => this.billsFilterLimit(bills, 'historyPageFilter')),
      map((bills) => this.billsFilterByCategory(bills, 'historyPageFilter')),
      map((bills) => this.billsFilterByProperty(bills, 'historyPageFilter')),
      map((bills) => this.billsFilterByQuery(bills, 'historyPageFilter')),
    );
  }

  /**
   * Returns bills with limit(stored in state) for selected page filter
   * @param bills
   * @param uiPage
   */
  private billsOrder(bills: ViewBillType[], billOption: GetBillOption) {
    if (billOption === GetBillOption.PAID_BILLS) {
      // Newest at top by Date Paid, if null => Date Received (for direct debit bills)
      return _orderBy(bills, (bill) => bill.datePaid ?? bill.dateReceived, 'desc');
    }

    if (billOption === GetBillOption.UNPAID_PLUS_FAILED_BILLS) {
      // Oldest at top by Due Date
      return _orderBy(bills, 'dueDate', 'asc');
    }

    return bills;
  }

  /**
   * Returns bills with limit(stored in state) for selected page filter
   * @param bills
   * @param uiPage
   */
  private billsFilterLimit(
    bills: ViewBillType[],
    uiPage: 'homePageFilter' | 'billsPageFilter' | 'historyPageFilter',
  ) {
    const limit = this.getValue().ui?.[uiPage]?.limit;

    if (limit && limit !== -1) {
      return _take(bills, limit);
    }

    return bills;
  }

  private billsFilterGroup(bills: ViewBillType[], group: GetBillGroup) {
    if (group === GetBillGroup.ALL) return bills;
    else if (group === GetBillGroup.UPCOMING_BILLS)
      return bills.filter((bill) => !isBillOverdue(bill));
    else if (group === GetBillGroup.RECENT_BILLS)
      return bills.filter(
        (bill) =>
          bill.createdAt && isDateTimeWithin24Hrs(bill.createdAt) && !isBillOverdue(bill),
      );
    else if (group === GetBillGroup.OVERDUE_BILLS)
      return bills.filter((bill) => isBillOverdue(bill));
    return bills;
  }

  /**
   * Returns bills uploaded within given time frame
   * @param bills
   * @param timePeriod
   */
  private billsFilterByUploadTime(bills: ViewBillType[], timePeriod: '24hr') {
    if (timePeriod === '24hr') {
      return bills.filter((b) => b.createdAt && isDateTimeWithin24Hrs(b.createdAt));
    }

    return bills;
  }

  /**
   * Returns bills filtered by categories(stored in state) for selected page filter
   * @param bills
   * @param uiPage
   */
  private billsFilterByCategory(
    bills: ViewBillType[],
    uiPage: 'homePageFilter' | 'billsPageFilter' | 'historyPageFilter',
  ) {
    const { categoryKeys, categoryKey } = this.getValue().ui?.[uiPage];

    if (categoryKeys?.length && categoryKeys.length > 0) {
      return bills.filter((b) => {
        return b.categoryKey && categoryKeys.indexOf(b.categoryKey) >= 0;
      });
    }

    if (categoryKey && categoryKey !== BillCategoryKey.ALL) {
      return bills.filter((b) => b.categoryKey === categoryKey);
    }

    return bills;
  }

  /**
   * Filters bills based upon duration.
   * @param bills
   * @param filter
   * @returns
   */
  private billsFilterByDuration(
    bills: ViewBillType[],
    filter: GetBillDuration = GetBillDuration.WEEK,
  ) {
    if (filter === GetBillDuration.ALL) return bills;

    if (bills?.length) {
      return bills.filter((b) => {
        switch (filter) {
          case GetBillDuration.MONTH:
            return isDateWithinAMonth(b.dueDate);
          case GetBillDuration.WEEK:
            return isDateWithinAWeek(b.dueDate);
          case GetBillDuration.THIS_MONTH:
            return isDateWithinCurrentMonth(b.dueDate);
          default:
            return isDateWithinAWeek(b.dueDate);
        }
      });
    }

    return bills;
  }

  /**
   * Returns bills filtered by properties(stored in state) for selected page filter
   * @param bills
   * @param uiPage
   */
  private billsFilterByProperty(
    bills: ViewBillType[],
    uiPage: 'homePageFilter' | 'billsPageFilter' | 'historyPageFilter',
  ) {
    const propertyTags = this.getValue().ui?.[uiPage]?.propertyTags;

    if (propertyTags?.length && propertyTags.length > 0) {
      return bills.filter((b) => {
        return (
          propertyTags.indexOf(b.billTag) >= 0 ||
          (b.fullAddress &&
            propertyTags.some((tag) =>
              isArrayExists(tag.split(' '), b.fullAddress.split(' ')),
            ))
        );
      });
    }

    return bills;
  }

  /**
   * Returns bills filtered by query(stored in state) for selected page filter
   * @param bills
   * @param uiPage
   */

  private billsFilterByQuery(
    bills: ViewBillType[],
    uiPage: 'homePageFilter' | 'billsPageFilter' | 'historyPageFilter',
  ) {
    const query = this.getValue().ui?.[uiPage]?.query;
    if (!query || typeof query !== 'string') return bills;

    return bills.filter((b) => {
      const fieldsToQuery = [
        b.fullAddress,
        b.provider,
        b.category,
        b.billTag,
        formatDateLong2(b.dueDate),
      ];
      const queries = query.split(' ');

      return queries.every((q) => {
        return fieldsToQuery
          .filter((f) => !!f)
          .map((f) => f.toLowerCase())
          .join(' ')
          .includes(q.toLowerCase());
      });
    });
  }

  /**
   * Get transformed failed bills
   * @param limit
   */
  getFailedBills(limit?: number) {
    return this.select(['failedBills', 'billCategories']).pipe(
      map(({ failedBills, billCategories }) => {
        if (!Array.isArray(failedBills)) return [];
        const data = this.transformFailedBills(failedBills, billCategories);
        if (limit) return _take(data, limit);
        return data;
      }),
    );
  }

  /**
   * Apply filtering & sorting to bills
   * @param billOption
   * @param data
   */
  private applyBillFilterSort(
    billOption: GetBillOption,
    data: Pick<
      BillState,
      'accountBillTypes' | 'billPayments' | 'billCategories' | 'failedBills'
    >,
  ) {
    let bills = [];
    if (!Array.isArray(data.billPayments)) return [];

    // get transformed bills
    bills = this.transformBillsToView(data.billPayments, data.accountBillTypes);
    // merge bills with transformed failed bills
    bills = [...bills, ...this.transformFailedBills(data.failedBills, data.billCategories)];

    // Apply filtering for UNPAID_BILLS or UNPAID_PLUS_FAILED_BILLS
    if (
      (billOption && billOption === GetBillOption.UNPAID_BILLS) ||
      billOption === GetBillOption.UNPAID_PLUS_FAILED_BILLS
    ) {
      bills = bills.filter((bill) => !isBillPaid(bill));

      if (billOption === GetBillOption.UNPAID_BILLS) {
        // Assuming failed bills cannot have accountBillTypeId set, and that all succeeded bills will have accountBillTypeId set
        // Could filter by billPaymentId instead?
        bills = bills.filter((bill) => bill.accountBillTypeId);
      }
    }

    if (billOption && billOption === GetBillOption.PAID_BILLS) {
      bills = bills.filter((bill) => isBillPaid(bill));

      /**
       * For "All bills", sort by datePaid
       * Most recently paid at the top (desc order)
       * If no datePaid, sort by billEndDate
       */
      bills = _orderBy(
        bills,
        [(item) => (item.datePaid ? item.datePaid : item.billEndDate ? item.billEndDate : '')],
        ['desc'],
      );
      return bills;
    }

    // sorting | ordering'
    /**
     * For "All bills", sort by dueDate
     * Newest bills at the top (desc order)
     * If no dueDate, sort by billEndDate
     * If no billEndDate, sort by dateReceived
     */
    bills = _orderBy(
      bills,
      [
        (item) =>
          item.dueDate
            ? item.dueDate
            : item.billEndDate
            ? item.billEndDate
            : item.dateReceived
            ? item.dateReceived
            : '',
      ],
      ['desc'],
    );
    return bills;
  }

  /**
   * Transform raw bills into ViewBillType
   * @param billPayments
   * @param accountBillTypes
   */
  private transformBillsToView(
    billPayments: UserBillPayment[],
    accountBillTypes: OneBillAccountBillType[],
  ): ViewBillType[] {
    return billPayments.map((bp) => {
      const bt = _pick(
        accountBillTypes.find((bt) => bt.id === bp.accountBillTypeId),
        [
          'id',
          'billTypeKey',
          'name',
          'rank',
          'numberSuppliersCompared',
          'potentialSavingsPa',
          'supplierName',
          'rating',
          'fullAddress',
          'address1',
          'address2',
          'billDays',
          'billStartDate',
          'billEndDate',
          'billerCode',
          'billerReference',
          'datePaid',
          'supplierLogoUrl',
          'billTag',
          'howToPay',
          'howPaid',
          'switchCustomerId',
          'switchStatus',
          'switchRetailerName',
          'switchRetailerLogo',
          'switchPlanName',
          'switchAddress',
          'peakUsage',
          'offPeakUsage',
          'controlledLoad',
          'controlledLoad1',
          'controlledLoad2',
          'shoulder1',
          'payNowPaymentInitiated',
          'payNowPaymentApproved',
          'paymentReady',
          'paymentPending',
          'meterNumber',
          'dpi',
          'isDirectDebitSetup',
          'createdAt',
          'linkedPaymentMethodId',
        ],
      );

      return {
        billPaymentId: bp?.billPaymentId,
        accountBillTypeId: bt.id,
        category: bt.name,
        categoryKey: bt.billTypeKey as BillCategoryKey,
        rating: bt.rating,
        rank: bt.rank,
        provider: bt.supplierName,
        amount: bp.amount,
        amountPaid: bp.amountPaid,
        dueDate: bp.dueDate,
        savingPerYear: bt.potentialSavingsPa,
        fullAddress: bt.fullAddress,
        address1: bp.address1,
        address2: bp.address2,
        billDays: bp.billDays,
        billStartDate: bp.billStartDate,
        billEndDate: bp.billEndDate,
        billerCode: bp.billerCode,
        billerReference: bp.billerReference,
        datePaid: bp.datePaid,
        supplierLogoUrl: bt.supplierLogoUrl,
        owingText: this.getOwingText(bp),
        billTag: bt.billTag,
        howToPay: bt.howToPay,
        howPaid: bp.howPaid,
        dateReceived: bp.dateReceived,
        createdAt: bp.createdAt,
        switchCustomerId: bp.switchCustomerId,
        switchStatus: bp.switchStatus,
        switchRetailerName: bp.switchRetailerName,
        switchRetailerLogo: bp.switchRetailerLogo,
        switchPlanName: bp.switchPlanName,
        switchAddress: bp.switchAddress,
        peakUsage: bp.peakUsage,
        offPeakUsage: bp.offPeakUsage,
        controlledLoad: bp.controlledLoad,
        controlledLoad1: bp.controlledLoad1,
        controlledLoad2: bp.controlledLoad2,
        shoulder1: bp.shoulder1,
        payNowPaymentInitiated: bp.payNowPaymentInitiated,
        payNowPaymentApproved: bp.payNowPaymentApproved,
        paymentPending: bp.paymentPending,
        paymentReady: bp.paymentReady,
        meterNumber: bp.meterNumber,
        dpi: bp.dpi,
        isDirectDebitSetup: bp.isDirectDebitSetup,
        possibleTransaction: bp?.possibleTransaction,
        barChartData: bp?.barChartData,
        linkedPaymentMethodId: bp?.linkedPaymentMethodId,
        accountOrCardNo: bp?.accountOrCardNo,
      } as ViewBillType;
    });
  }

  /**
   * Transform raw failed bills into ViewBillType
   * @param failedBills
   * @param billCategories
   */
  private transformFailedBills(
    failedBills: GetFailedBill[],
    billCategories: BillCategoryType[],
  ): ViewBillType[] {
    if (!Array.isArray(failedBills)) return [];

    // get category info method
    const getCategoryInfo = (bill: GetFailedBill) => {
      const key = bill.productType || bill.productTypeP;

      if (!key)
        return {
          name: null,
          key: null,
        };

      return Array.isArray(billCategories)
        ? billCategories.find((c) => c.key === key)
        : {
            name: null,
            key,
          };
    };

    return failedBills
      .filter((b) => b.productType || b.productTypeP)
      .map((bill) => {
        return {
          jobId: bill.jobId,
          category: getCategoryInfo(bill)?.name,
          categoryKey: getCategoryInfo(bill)?.key as BillCategoryKey,
          provider: bill.supplierName,
          amount: bill.amount,
          dueDate: bill.dueDate,
          fullAddress: [bill.address1, bill.address2].filter((a) => a).join(' '),
          address1: bill.address1,
          address2: bill.address2,
          billDays: bill.billDays,
          billStartDate: bill.billStartDate,
          billEndDate: bill.billEndDate,
          billerCode: bill.billerCode,
          billerReference: bill.billerReference,
          supplierLogoUrl: bill.supplierLogoUrl,
          dateReceived: bill.dateReceived,
          createdAt: bill.createdAt,
          // auto fill missing field with null values
          billPaymentId: null,
          rating: null,
          rank: null,
          accountBillTypeId: null,
          amountPaid: null,
          savingPerYear: null,
          datePaid: null,
          owingText: null,
          billTag: null,
          howToPay: null,
          howPaid: null,
          switchCustomerId: null,
          switchStatus: null,
          switchRetailerName: null,
          switchRetailerLogo: null,
          switchPlanName: null,
          switchAddress: null,
          peakUsage: null,
          offPeakUsage: null,
          controlledLoad: null,
          controlledLoad1: null,
          controlledLoad2: null,
          shoulder1: null,
          payNowPaymentInitiated: null,
          payNowPaymentApproved: null,
          paymentPending: null,
          paymentReady: null,
          meterNumber: null,
          dpi: null,
          isDirectDebitSetup: null,
          linkedPaymentMethodId: null,
          accountOrCardNo: null,
        } as ViewBillType;
      });
  }

  /**
   * Get bill owing text
   * @param bill
   */
  private getOwingText(bill: UserBillPayment) {
    if (bill.amount <= 0) {
      return 'All paid up!';
    }

    const now = new Date().toISOString();
    const dueDays = isDateValid(bill.dueDate) ? getDayCount(now, bill.dueDate) : null;
    const daysText =
      dueDays >= -1 && dueDays <= 1 ? Math.abs(dueDays) + ' day' : Math.abs(dueDays) + ' days';
    return bill.amount ? (dueDays < 0 ? daysText + ' overdue' : 'Due in ' + daysText) : '';
  }

  /**
   * Determine whether or not a bill can be switched to different providers.
   * @param categoryKey
   * @returns
   */
  private canSwitch(categoryKey: string) {
    return appProductTypes.find((val) => val.key === categoryKey)?.canSwitch;
  }
}
