import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover-info-icon',
  templateUrl: './popover-info-icon.component.html',
  styleUrls: ['./popover-info-icon.component.scss'],
})
export class PopoverInfoIconComponent implements OnInit {
  @Input() hasBackButton: boolean;
  @Input() popoverTitle: string;
  @Input() description: string;
  @Input() descriptionTwo: string;
  @Input() descriptionArray: string[];
  @Input() link: string;
  @Input() iconSource: string;
  @Input() linkText: string;
  @Input() buttonText: string;
  constructor(private navCtrl: NavController, private popoverController: PopoverController) {}

  ngOnInit() {}

  openLink() {
    this.popoverController.dismiss();
    this.navCtrl.navigateForward(this.link);
  }

  dismiss(confirmed?: boolean) {
    this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
  }
}