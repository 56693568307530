import { PipesModule } from '@1bill-app/pipes/pipes.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HighchartsChartModule } from 'highcharts-angular';
import { InputMaskModule } from '../directives/input-mask.module';
import { LatPaymentCallbackComponent } from '../pages/bill/components/lat-payment-callback/lat-payment-callback.component';
import { paymentCardFilter } from '../pages/settings/payment-information/payment-card.filter';
import { AccountSummaryComponent } from './account-summary/account-summary.component';
import { AccountTerminationComponent } from './account-termination/account-termination.component';
import { AddBillMenuComponent } from './add-bill-menu/add-bill-menu.component';
import { AddSalaryModalComponent } from './add-salary-modal/add-salary-modal.component';
import { BalanceActivityItemComponent } from './balance-activity-item/balance-activity-item.component';
import { BillCardListComponent } from './bill-card-list/bill-card-list.component';
import { BillCardSavingListComponent } from './bill-card-saving-list/bill-card-saving-list.component';
import { BillCardSavingSingleComponent } from './bill-card-saving-single/bill-card-saving-single.component';
import { BillCardSingleComponent } from './bill-card-single/bill-card-single.component';
import { BillPredictionsComponent } from './bill-predictions/bill-predictions.component';
import { BillRatingComponent } from './bill-rating/bill-rating.component';
import { BillsChart } from './bills-chart/bills-chart.component';
import { BillSearchBarDesktopComponent } from './bill-search-bar-desktop/bill-search-bar-desktop.component';
import { BillUsageChartComponent } from './bill-usage-chart/bill-usage-chart.component';
import { CashChartComponent } from './cash-chart/cash-chart.component';
import { CategoriesComponent } from './categories/categories.component';
import { ConfirmTerminateAccountModalComponent } from './confirm-terminate-account-modal/confirm-terminate-account-modal.component';
import { ConnectedAccountItemComponent } from './connected-account-item/connected-account-item.component';
import { ConnectedAccountListComponent } from './connected-account-list/connected-account-list.component';
import { CreditScoreMeterComponent } from './credit-score-meter/credit-score-meter.component';
import { DashboardCreditScoreMeterComponent } from './dashboard-credit-score-meter/dashboard-credit-score-meter.component';
import { DateInputFieldComponent } from './date-input-field/date-input-field.component';
import { DateSelectToolbarComponent } from './date-select-toolbar/date-select-toolbar.component';
import { EmailsScannedComponent } from './emails-scanned/emails-scanned.component';
import { ExpensesChartComponent } from './expenses-chart/expenses-chart.component';
import { FindBetterDealsComponent } from './find-better-deals/find-better-deals.component';
//import { FirstTimeUserAnimationPopoverComponent } from './popover/first-time-user-animation-popover/first-time-user-animation-popover.component';
import { GoogleAddressSearchComponent } from './google-address-search/google-address-search.component';
import { HeaderLogoComponentModule } from './header-logo/header-logo.module';
import { LinkedBillItemComponent } from './linked-bill-item/linked-bill-item.component';
import { LinkedBillsListComponent } from './linked-bills-list/linked-bills-list.component';
import { MissingInformationModal } from './missing-information-modal/missing-information-modal.component';
import { LatPayIframeModalComponent } from './modals/lat-pay-iframe-modal/lat-pay-iframe-modal.component';
import { SelectableBillsListComponent } from './selectable-bills-list/selectable-bills-list.component';
import { SwitchCCIframeModalComponent } from './modals/switch-cc-iframe-modal/switch-cc-iframe-modal.component';
import { UploadFileModalComponent } from './modals/upload-file-modal/upload-file-modal.component';
import { YodleeFastLinkModal } from './modals/yodlee-fast-link-modal/yodlee-fast-link-modal.component';
import { NetIncomeChartComponent } from './net-income-chart/net-income-chart.component';
import { NetIncomeItemComponent } from './net-income-item/net-income-item.component';
import { NetWealthCardComponent } from './net-wealth-card/net-wealth-card.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NotificationOtherComponent } from './notification-other/notification-other.component';
import { NotificationOverdueComponent } from './notification-overdue/notification-overdue.component';
import { OfferCardComponent } from './offer-card/offer-card.component';
import { OfferCarouselComponent } from './offer-carousel/offer-carousel.component';
import { BillBundleCheckoutComponent } from './payment-combined-modal/bill-bundle-checkout/bill-bundle-checkout.component';
import { BillBundleSelectComponent } from './payment-combined-modal/bill-bundle-select/bill-bundle-select.component';
import { BillCheckoutCardComponent } from './payment-combined-modal/bill-checkout-card/bill-checkout-card.component';
import { BillCheckoutComponent } from './payment-combined-modal/bill-checkout/bill-checkout.component';
import { CheckoutFooterComponent } from './payment-combined-modal/checkout-footer/checkout-footer.component';
import { PayBillComponent } from './payment-combined-modal/pay-bill/pay-bill.component';
import { PaymentCombinedModalComponent } from './payment-combined-modal/payment-combined-modal.component';
import { SelectPaymentMethodComponent } from './payment-combined-modal/select-payment-method/select-payment-method.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';
import { PaymentOptionsSliderComponent } from './payment-options-slider/payment-options-slider.component';
import { PaymentSelectModalComponent } from './payment-select-modal/payment-select-modal.component';
import { PopoverAchievementsComponent } from './popover-achievements/popover-achievements.component';
import { ManagePaymentMethodPopoverComponent } from './popover/manage-payment-method-popover/manage-payment-method-popover.component';
import { PopoverFailedComponent } from './popover-failed/popover-failed.component';
import { PopoverInfoIconComponent } from './popover/popover-info-icon/popover-info-icon.component';
import { PopoverJobInfoUpdateComponent } from './popover/popover-job-info-update/popover-job-info-update.component';
import { PopoverOnebillPointsComponent } from './popover/popover-onebill-points/popover-onebill-points.component';
import { PopoverPayingRateInfoComponent } from './popover/popover-paying-rate-info/popover-paying-rate-info.component';
import { PopoverSimpleComponent } from './popover/popover-simple/popover-simple.component';
import { SwapBillModalComponent } from './popover/swap-bill-modal/swap-bill-modal.component';
import { PopoverUnlinkBillComponent } from './popover/popover-unlink-bill/popover-unlink-bill.component';
import { PopoverUpdateBalanceComponent } from './popover/popover-update-balance/popover-update-balance.component';
import { ProfileHeaderComponent } from './user-profile/profile-header/profile-header.component';
import { PriorPaymentInfoModalComponent } from './prior-payment-info-modal/prior-payment-info-modal.component';
import { ProfileTodoItemComponent } from './profile-todo-item/profile-todo-item.component';
import { RateCompareComponent } from './rate-compare/rate-compare.component';
import { RecentTransactionItemComponent } from './recent-transaction-item/recent-transaction-item.component';
import { RequireNewPasswordModalComponent } from './require-new-password-modal/require-new-password-modal.component';
import { SelectableBillComponent } from './selectable-bill/selectable-bill.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SpendingItemComponent } from './spending-item/spending-item.component';
import { SwitchStatusComponent } from './switch-status/switch-status.component';
import { TagsComponent } from './tags/tags.component';
import { TrackSpendingComponent } from './track-spending/track-spending.component';
import { AppSettingsComponent } from './user-profile/app-settings/app-settings.component';
import { UserDetailsComponent } from './user-profile/user-details/user-details.component';
import { UserNotificationsComponent } from './user-profile/user-notifications/user-notifications.component';
import { WealthChartComponent } from './wealth-chart/wealth-chart.component';
import { WealthListComponent } from './wealth-list/wealth-list.component';
import { WealthSingleComponent } from './wealth-single/wealth-single.component';
import { WealthSummaryCardComponent } from './wealth-summary-card/wealth-summary-card.component';
import { YodleeAccountDetailListComponent } from './yodlee-account-detail-list/yodlee-account-detail-list.component';
import { YodleeAccountListComponent } from './yodlee-account-list/yodlee-account-list.component';
import { YodleeAccountSingleComponent } from './yodlee-account-single/yodlee-account-single.component';
import { YodleeTransactionListComponent } from './yodlee-transaction-list/yodlee-transaction-list.component';
import { YodleeTransactionSingleComponent } from './yodlee-transaction-single/yodlee-transaction-single.component';
import { BillBlockItemComponent } from './bill-block-item/bill-block-item.component';
import { BillBlockListComponent } from './bill-block-list/bill-block-list.component';
import { BillsCalendarComponent } from './bills-calendar/bills-calendar.component';
import { AssetLiabilityItemComponent } from './asset-liability-item/asset-liability-item.component';
import { BillSavingsComponent } from './bill-block-item/bill-savings/bill-savings.component';
import { FirstTimeUserAnimationPopoverComponent } from './popover/first-time-user-animation-popover/first-time-user-animation-popover.component';
import { AddBillPopoverComponent } from './popover/add-bill-popover/add-bill-popover.component';
import { IonicSelectableModule } from 'ionic-selectable';
import { BillsSalaryChartComponent } from './bills-salary-chart/bills-salary-chart.component';
import { PromoCodeCardComponent } from '../pages/offers/promo-code-card/promo-code-card.component';
import { NgCalendarModule } from 'ionic2-calendar';
import { UserManagementModalComponent } from '../modals/user-management-modal/user-management-modal.component';
import { LinkedUserCardComponent } from './linked-user-card/linked-user-card.component';
import { RedeemCardPopoverComponent } from './popover/redeem-card-popover/redeem-card-popover.component';
import { SwitchAndSaveConsentComponent } from './popover/switch-and-save-consent/switch-and-save-consent.component';
import { RedeemSuccessfulPopoverComponent } from './popover/redeem-successful-popover/redeem-successful-popover.component';
import { ModalCompareValidationErrorComponent } from './modal-compare-validation-error/modal-compare-validation-error.component';
// import { ReferralLinkShareComponent } from './referral-link-share/referral-link-share.component';

const COMPONENTS = [
  AccountSummaryComponent,
  AccountTerminationComponent,
  AddBillMenuComponent,
  AddBillPopoverComponent,
  AddSalaryModalComponent,
  AppSettingsComponent,
  AssetLiabilityItemComponent,
  BalanceActivityItemComponent,
  BillBlockItemComponent,
  BillBlockListComponent,
  BillBundleCheckoutComponent,
  BillBundleSelectComponent,
  BillCardListComponent,
  BillCardSavingListComponent,
  BillCardSavingSingleComponent,
  BillCardSingleComponent,
  BillCheckoutCardComponent,
  BillCheckoutComponent,
  BillPredictionsComponent,
  BillRatingComponent,
  BillsCalendarComponent,
  BillsChart,
  BillSavingsComponent,
  BillSearchBarDesktopComponent,
  BillsSalaryChartComponent,
  BillUsageChartComponent,
  CashChartComponent,
  CategoriesComponent,
  CheckoutFooterComponent,
  ConfirmTerminateAccountModalComponent,
  ConnectedAccountItemComponent,
  ConnectedAccountListComponent,
  CreditScoreMeterComponent,
  DashboardCreditScoreMeterComponent,
  DateInputFieldComponent,
  DateSelectToolbarComponent,
  EmailsScannedComponent,
  ExpensesChartComponent,
  FindBetterDealsComponent,
  FirstTimeUserAnimationPopoverComponent,
  GoogleAddressSearchComponent,
  LatPayIframeModalComponent,
  LatPaymentCallbackComponent,
  LinkedBillItemComponent,
  LinkedBillsListComponent,
  ManagePaymentMethodPopoverComponent,
  MissingInformationModal,
  NetIncomeChartComponent,
  NetIncomeItemComponent,
  NetWealthCardComponent,
  NotificationOtherComponent,
  NotificationOverdueComponent,
  NotificationItemComponent,
  OfferCardComponent,
  OfferCarouselComponent,
  PayBillComponent,
  paymentCardFilter,
  PaymentCombinedModalComponent,
  PaymentMethodsComponent,
  PaymentOptionsSliderComponent,
  PaymentSelectModalComponent,
  PopoverAchievementsComponent,
  PromoCodeCardComponent,
  PopoverFailedComponent,
  PopoverJobInfoUpdateComponent,
  PopoverOnebillPointsComponent,
  PopoverPayingRateInfoComponent,
  PopoverInfoIconComponent,
  PopoverSimpleComponent,
  PopoverUnlinkBillComponent,
  PopoverUpdateBalanceComponent,
  PriorPaymentInfoModalComponent,
  ProfileTodoItemComponent,
  ProfileHeaderComponent,
  RateCompareComponent,
  RecentTransactionItemComponent,
  RedeemCardPopoverComponent,
  RedeemSuccessfulPopoverComponent,
  // HIDEREFERRALCODES
  // ReferralLinkShareComponent,
  RequireNewPasswordModalComponent,
  SelectableBillComponent,
  SelectableBillsListComponent,
  SelectPaymentMethodComponent,
  SideMenuComponent,
  SwitchAndSaveConsentComponent,
  SpendingItemComponent,
  SwapBillModalComponent,
  SwitchCCIframeModalComponent,
  SwitchStatusComponent,
  TagsComponent,
  TrackSpendingComponent,
  UploadFileModalComponent,
  UserNotificationsComponent,
  UserDetailsComponent,
  WealthChartComponent,
  WealthListComponent,
  WealthSingleComponent,
  WealthSummaryCardComponent,
  YodleeAccountDetailListComponent,
  YodleeAccountListComponent,
  YodleeAccountSingleComponent,
  YodleeFastLinkModal,
  YodleeTransactionListComponent,
  YodleeTransactionSingleComponent,
  ProfileTodoItemComponent,
  BillCardSavingListComponent,
  BillCardSavingSingleComponent,
  PaymentMethodsComponent,
  FindBetterDealsComponent,
  PaymentMethodsComponent,
  WealthChartComponent,
  WealthListComponent,
  WealthSingleComponent,
  WealthSummaryCardComponent,
  NotificationOverdueComponent,
  NotificationOtherComponent,
  NotificationItemComponent,
  NetIncomeItemComponent,
  NetWealthCardComponent,
  SpendingItemComponent,
  RecentTransactionItemComponent,
  DateSelectToolbarComponent,
  NetIncomeChartComponent,
  ExpensesChartComponent,
  CashChartComponent,
  ConnectedAccountItemComponent,
  BalanceActivityItemComponent,
  ConnectedAccountListComponent,
  PopoverUpdateBalanceComponent,
  PaymentOptionsSliderComponent,
  LinkedBillsListComponent,
  LinkedBillItemComponent,
  TrackSpendingComponent,
  PopoverUnlinkBillComponent,
  SwapBillModalComponent,
  SelectableBillsListComponent,
  BillBlockItemComponent,
  BillBlockListComponent,
  BillsCalendarComponent,
  AssetLiabilityItemComponent,
  FirstTimeUserAnimationPopoverComponent,
  UserManagementModalComponent,
  LinkedUserCardComponent,
  ModalCompareValidationErrorComponent,
];

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    InputMaskModule,
    PipesModule,
    AngularSvgIconModule,
    HighchartsChartModule,
    HeaderLogoComponentModule,
    NgCalendarModule,
    IonicSelectableModule,
  ],
  declarations: [...COMPONENTS],
  entryComponents: [PopoverUpdateBalanceComponent],
  exports: [...COMPONENTS, HeaderLogoComponentModule],
})
export class SharedComponentsModule {}
