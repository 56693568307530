import { PIN_ATTEMPT_LIMIT } from '@1bill-app/constants';
import { PINService } from '@1bill-app/services/auth/pin.service';
import { AuthService } from '@1bill-app/services/auth/state/auth.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { KeyboardResize, Keyboard} from '@capacitor/keyboard';
import { Haptics, HapticsImpactStyle } from '@capacitor/haptics';
import {
  AlertController,
  Animation,
  AnimationController,
  ModalController,
} from '@ionic/angular';
import { CodeInputComponent } from 'angular-code-input';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export enum RemovePINView {
  CONFIRM_PIN,
}

@Component({
  selector: 'app-remove-pin-screen',
  templateUrl: './remove-pin-screen.component.html',
  styleUrls: ['./remove-pin-screen.component.scss'],
})
export class RemovePinScreenComponent implements OnInit {
  @ViewChild(CodeInputComponent) codeInput: CodeInputComponent;
  @ViewChild(CodeInputComponent, { read: ElementRef }) codeInputEl: ElementRef;
  public RemovePINView = RemovePINView;
  public view = RemovePINView.CONFIRM_PIN;
  loading$ = this.pinService.loading$;
  get title() {
    return {
      // [RemovePINView.NEW_PIN]: 'Create a PIN',
      [RemovePINView.CONFIRM_PIN]: 'Enter your PIN',
    }[this.view];
  }
  get subtitle() {
    return {
      // [RemovePINView.NEW_PIN]: 'Use this PIN to secure the app',
      [RemovePINView.CONFIRM_PIN]: 'Enter your PIN to remove PIN / Biometric Auth',
    }[this.view];
  }
  // private pin: string;
  private confirmPin: string;
  incorrectPin: boolean;
  attemptCount: number = 0;

  constructor(
    private pinService: PINService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private animationCtrl: AnimationController,
    private authService: AuthService,
  ) {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ngOnInit() {}

  ionViewDidEnter() {
    this.view = RemovePINView.CONFIRM_PIN;
    setTimeout(() => this.focusCodeInput());
  }

  ionViewWillEnter() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None });
    }
  }

  ionViewWillLeave() {
    if (Capacitor.isNativePlatform()) {
      Keyboard.setResizeMode({ mode: KeyboardResize.Native });
    }
  }

  onClose() {
    this.confirmPin = null;
    this.attemptCount = 0;
    this.incorrectPin = false;
    this.dismissLockScreen();
  }

  async onForgotPin() {
    const alert = await this.alertCtrl.create({
      header: 'Forgot your PIN?',
      message: 'Reset pin by loggin in again.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'Okay',
          role: 'okay',
          handler: () => {
            console.log('Confirm Okay');
          },
        },
      ],
    });
    await alert.present();
    alert.onDidDismiss().then(({ role }) => {
      if (role === 'okay') {
        this.pinService
          .clear()
          .pipe(switchMap(() => this.authService.logout('remove-pin-screen.comp')))
          .subscribe();
      }
      this.dismissLockScreen();
    });
  }

  async onCodeCompleted(value: string) {
    this.confirmPin = value;
    if (await this.pinService.check(this.confirmPin).toPromise()) {
      await this.pinService.clear().toPromise();
      this.incorrectPin = false;
      this.modalCtrl.dismiss({ success: true });
    } else {
      this.incorrectPin = true;
      // setTimeout(() => {
      this.codeInput.reset(false);
      this.confirmPin = null;
      // this.focusCodeInput();
      // });
      Haptics.impact({ style: HapticsImpactStyle.Heavy });
      this.codeInput.reset(false);
      this.focusCodeInput();
      this.triggerPinErrAnimation();
      this.addCSSVariable(
        this.codeInputEl.nativeElement,
        '--item-border-bottom',
        '2px solid var(--ion-color-danger',
      );
      this.attemptCount++;
      if (this.attemptCount >= PIN_ATTEMPT_LIMIT) {
        const alert = await this.alertCtrl.create({
          header: 'Too many attempts',
          message: 'You have entered the PIN incorrectly too many times.',
          buttons: ['ok'],
        });
        await alert.present();
        alert.onDidDismiss().then(() => {
          this.dismissLockScreen();
        });
      }
    }
    this.codeInput.reset(true);
    this.focusCodeInput();
  }

  // forgot() {
  //   // this.authService.logout();
  //   this.dismissLockScreen();
  // }

  async dismissLockScreen() {
    const modal = await this.modalCtrl.getTop();
    if (modal) {
      modal.dismiss();
    }
  }

  focusCodeInput() {
    this.codeInput && this.codeInput.focusOnField && this.codeInput.focusOnField(0);
  }

  triggerPinErrAnimation() {
    const animation: Animation = this.animationCtrl
      .create()
      .addElement(this.codeInputEl.nativeElement)
      .easing('ease-out')
      .duration(1000)
      .keyframes([
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.1 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.2 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.3 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.4 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.5 },
        { transform: 'translate3d(4px, 0, 0)', offset: 0.6 },
        { transform: 'translate3d(-4px, 0, 0)', offset: 0.7 },
        { transform: 'translate3d(2px, 0, 0)', offset: 0.8 },
        { transform: 'translate3d(-1px, 0, 0)', offset: 0.9 },
      ]);
    animation.play();
  }
  addCSSVariable(el: HTMLElement, property: string, value: string) {
    el.style.setProperty(property, value);
  }
  removeCSSVariable(el: HTMLElement, property: string) {
    el.style.removeProperty(property);
  }
}
