import { _intersection, _isEmpty } from './lodash.helper';

/**
 * Check if array exists in another array
 * @example
 * isArrayExists([1], [1,2]) // => true
 * isArrayExists([5], [1,2]) // => false
 * @param arr
 * @param haystack
 */
export function isArrayExists<T>(arr: T[], haystack: T[]) {
  return !_isEmpty(_intersection(arr, haystack));
}
/**
 * Add/remove item in source array based on input array items
 * @example
 * toggleArrayItems([1], [1,2,3]) // => [2,3]
 * toggleArrayItems([2,5], [1,2,3]) // => [1,3,5]
 * @param inputArr
 * @param sourceArr
 */
export function toggleArrayItems<T>(inputArr: T[], sourceArr: T[]) {
  let items = sourceArr;
  const commonItem = _intersection(inputArr, sourceArr);
  if (!_isEmpty(commonItem)) {
    items = [...items.filter((pt) => inputArr.indexOf(pt) < 0)];
  }

  return [...items, ...inputArr.filter((pt) => commonItem.indexOf(pt) < 0)] as T[];
}
/**
 * Generates array for looping stars based upon length provided.
 * @param length
 */
export function generateArray(length: number): number[] {
  if (!length) return [];
  return Array(length).fill(length);
}
