import { Component, OnInit } from '@angular/core';
import { BillRating } from '@1bill-app/services/bill/types';

@Component({
  selector: 'app-popover-paying-rate-info',
  templateUrl: './popover-paying-rate-info.component.html',
  styleUrls: ['./popover-paying-rate-info.component.scss'],
})
export class PopoverPayingRateInfoComponent implements OnInit {
  // ratings = [BillRating.Bad, BillRating.Ordinary, BillRating.Good, BillRating.Excellent];
  ratings = [1,3,4,5];
  constructor() { }

  ngOnInit() {}

}
