import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ViewBillType } from 'src/app/services/bill/types';
import { PopoverSimpleComponent } from 'src/app/components/popover/popover-simple/popover-simple.component';
import { EXPLANATION_NOTES_SIMPLE } from 'src/app/app.constants';

@Component({
  selector: 'app-pay-bill',
  templateUrl: './pay-bill.component.html',
  styleUrls: ['./pay-bill.component.scss'],
})
export class PayBillComponent {
  constructor(private popoverController: PopoverController) {}
  @Input() bill: ViewBillType;
  @Input() ready: boolean;
  @Output() actionEvent: EventEmitter<number> = new EventEmitter();

  showHelpPopover() {
    this.popoverController
      .create({
        component: PopoverSimpleComponent,
        cssClass: 'onebill-popover-simple',
        componentProps: {
          popoverTitle: EXPLANATION_NOTES_SIMPLE.BUNDLED_BILL_TITLE,
          descriptionArray: EXPLANATION_NOTES_SIMPLE.BUNDLED_BILL_DESC,
          hasBackButton: true,
        },
      })
      .then((helpPopover) => {
        helpPopover.present();
      });
  }

  onPayButtonClicked() {
    this.actionEvent.emit(1);
  }

  onBundlePayButtonClicked() {
    this.actionEvent.emit(2);
  }
}
