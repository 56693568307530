import { environment } from '@1bill-app/env';
import { hasTokenExpired } from '@1bill-app/helpers/general.helper';
import { jsonParseSafe } from '@1bill-app/helpers/object.helper';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import _, { last } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { from, Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { AuthStore } from '../auth/state/auth.store';
import { YodleeQuery } from './yodlee.query';
import { YodleeStore } from './yodlee.store';
import {
  AccountHistoricalBalance,
  CashChartResponse,
  CreateManualYodleeAccount,
  EditManualYodleeAccount,
  TransactionCategory,
  YodleeAccessToken,
  YodleeAccount,
  YodleeWealthInfoGroup,
} from './yodlee.types';

@Injectable({
  providedIn: 'root',
})
export class YodleeService {
  constructor(
    private yodleeStore: YodleeStore,
    private yodleeQuery: YodleeQuery,
    private api: ApiService,
    private logger: NGXLogger,
    private authStore: AuthStore,
  ) {}

  getAccessToken() {
    return from(Storage.get({ key: environment.yodleeTokenStorageKey })).pipe(
      map((result: any) => jsonParseSafe<YodleeAccessToken>(result?.value)),
      mergeMap((result) => {
        const hasExpired = hasTokenExpired(result?.issuedAt, result?.expiresIn);
        if (!hasExpired) return of(result);
        return this.api.getYodleeAccessToken();
      }),
      filter((result) => result.success),
      mergeMap((result) => {
        return from(
          Storage.set({
            key: environment.yodleeTokenStorageKey,
            value: JSON.stringify(result),
          }),
        ).pipe(map(() => result));
      }),
      tap((result) => this.authStore.update({ yodleeToken: result })),
      catchError((err) => {
        this.logger.error('Api Error getYodleeAccessToken', err);
        return of({ token: null });
      }),
    );
  }

  /**
   * Get list of Yodlee accounts from backend
   */
  getAccounts(): Observable<YodleeAccount[]> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) => this.api.getYodleeAccountInformation(yodleeToken?.token)),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({
            accounts: response?.accounts,
            summaries: response?.summaries,
          });
          // this.getMultipleAccountHistoricalBalances(response.accounts?.map((account) => account.id)).toPromise();
          return response?.accounts;
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  getWealthInformation(): Observable<{
    asset: YodleeWealthInfoGroup[];
    liability: YodleeWealthInfoGroup[];
  }> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) => this.api.getYodleeWealthInformation(yodleeToken?.token)),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({
            wealthCategories: response?.data,
          });
          // this.getMultipleAccountHistoricalBalances(response.accounts?.map((account) => account.id)).toPromise();
          return response?.data;
        } else {
          return { asset: [], liability: [] };
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  /**
   * Get the net wealth dashboard data (chart and list)
   */
  getDashboardWealthData(): Observable<YodleeAccount[]> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) => this.api.getDashboardWealthData(yodleeToken?.token)),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({
            netWealthChartData: response.wealthChartData,
            categorySummaries: response.categorySummaries,
          });
          return response;
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  getCashScreenWealthData(
    toDate = '',
    interval: 'M' | 'W' | 'Y' = 'M',
    accountType = '',
  ): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) =>
        this.api.getCashScreenChartData(yodleeToken?.token, {
          toDate,
          interval,
          accountType,
        }),
      ),
      map((response) => {
        this.logger.log('getCashScreenWealthData() response:', response);
        if (response.success) {
          this.yodleeStore.update({
            cashScreenChartData: response.chartData,
            cashScreenCurrentDurationDate: response.chartData.reqPeriod,
          });
        } else {
          this.yodleeStore.setError('False success value from API');
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  getAccountViewData(
    toDate = '',
    interval: 'M' | 'W' | 'Y' = 'M',
    accountId = '',
    accountType = '',
  ): Observable<{
    cashChartData: CashChartResponse;
    accountBalances: AccountHistoricalBalance[];
  }> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) =>
        this.api.getAccountViewData(yodleeToken?.token, {
          toDate,
          interval,
          accountId,
          accountType,
        }),
      ),
      map((response) => {
        this.logger.log('getAccountViewData() response:', response);
        if (response.success) {
          // If accountId is provided (i.e. data for only one account), we need to keep it separate
          // from the chart on the dashboard that has data for all accounts.
          this.yodleeStore.update({
            accountViewChartData: response.cashChartData,
            accountViewCurrentDurationDate: response.cashChartData.reqPeriod,
            accountBalances: response.accountHistoricalData.historicalBalances,
          });
          return {
            cashChartData: response.cashChartData,
            accountBalances: response.accountHistoricalData.historicalBalances,
          };
        } else {
          throw 'getAccountViewData() false success value from API';
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  getNetWealthData(): Observable<YodleeAccount[]> {
    const { netIncomeCurrentDurationDate, currentDuration } = this.yodleeStore.getValue();
    const data: any = {};
    if (netIncomeCurrentDurationDate) data.currentDurationDate = netIncomeCurrentDurationDate;
    if (currentDuration) data.currentDuration = currentDuration;
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) => this.api.getNetWealthData(yodleeToken?.token, data)),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({
            netWealthDetailedChartData: response.chartData,
            availableDurations: response.availableDurations,
            currentCategories: response.categorySummaries,
            netIncomeDiff: response.netIncomeDiff,
            netIncomeDiffPerc: response.netIncomeDiffPerc,
            currentNetIncomeTotal: response.currentNetIncomeTotal,
            netIncomeCurrentDurationDate: response.currentDurationDate,
            netIncomePrevDurationDate: response.prevDurationDate,
          });
          if (!this.yodleeQuery.getValue()?.netIncomeCurrentDurationDate)
            this.yodleeStore.update({
              netIncomeCurrentDurationDate: last(
                response.chartData.availableDurations,
              ) as string,
            });
          return response;
        } else {
          return [];
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  getExpensesScreenData(
    highLevelCategoryId: number | string,
    toDate = '',
    interval: 'M' | 'W' | 'Y' = 'M',
  ) {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      switchMap((yodleeToken) =>
        this.api.getExpensesScreenData(yodleeToken?.token, {
          highLevelCategoryId: String(highLevelCategoryId),
          toDate,
          interval,
        }),
      ),
      map((response) => {
        this.logger.log('getExpensesScreenData() response:', response);
        this.yodleeStore.update({
          expensesCurrentDurationDate: response.expensesScreenData.reqPeriod,
        });
        if (response.success) {
          return response;
        }
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  updateAccount(accId: number, show: boolean): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) =>
        this.api.updateYodleeAccountInformation(accId, show, yodleeToken?.token),
      ),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  // getAccountHistoricalBalances(accountId: string): Observable<any> {
  //   this.yodleeStore.setLoading(true);
  //   return this.getAccessToken().pipe(
  //     filter((yodleeToken) => yodleeToken?.token),
  //     mergeMap((yodleeToken) =>
  //       this.api.getAccountHistoricalBalances(accountId, yodleeToken?.token),
  //     ),
  //     map((result) => {
  //       if (result.success) {
  //         return result.accountHistoricalData;
  //       }
  //       throw 'getAccountHistoricalBalances() success state is false. Check backend logs.';
  //     }),
  //     catchError((err) => {
  //       this.yodleeStore.setError(err);
  //       throw err;
  //     }),
  //     finalize(() => this.yodleeStore.setLoading(false)),
  //   );
  // }

  getMultipleAccountHistoricalBalances(): Observable<any> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token),
      mergeMap((yodleeToken) =>
        this.api.getMultipleAccountHistoricalBalances(yodleeToken?.token),
      ),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({
            accountBalances: response.result,
          });
          return response.result.filter((accountHistoryItem) => accountHistoryItem ?? false);
        }
        return [];
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  getYodleeTransactionCategories(): Observable<TransactionCategory[]> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token),
      mergeMap((yodleeToken) => this.api.getTransactionCategoryList(yodleeToken?.token)),
      map((response) => {
        if (response.success) {
          this.yodleeStore.update({ transactionCategories: response.transactionCategories });
          return response.transactionCategories;
        } else {
          console.error('Error:', response);
        }
      }),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => {
        this.yodleeStore.setLoading(false);
      }),
    );
  }

  createManualAccount(data: CreateManualYodleeAccount): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) => this.api.createManualYodleeAccount(data, yodleeToken?.token)),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  editManualAccount(data: EditManualYodleeAccount, yodleeAccountId: number): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) =>
        this.api.editManualYodleeAccount(data, yodleeAccountId, yodleeToken?.token),
      ),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }
  updateTransaction(
    transactionId: number,
    data: { ignore?: boolean; added?: boolean },
    accountType: string,
  ): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) =>
        this.api.updateYodleeTransactionInformation(
          transactionId,
          data,
          accountType,
          yodleeToken?.token,
        ),
      ),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  unlinkAccount(accId: number): Observable<void> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) => this.api.unlinkYodleeAccount(accId, yodleeToken?.token)),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  verifyAccounts(providerAccountId: number): Observable<any> {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) =>
        this.api.verifyYodleeAccountsBalance(providerAccountId, yodleeToken?.token),
      ),
      catchError((err) => {
        this.yodleeStore.setError(err);
        throw err;
      }),
      finalize(() => this.yodleeStore.setLoading(false)),
    );
  }

  private hasYodleeTokenExpired(yodleeToken: YodleeAccessToken) {
    const issuedAt: string = yodleeToken?.issuedAt;
    const expiresIn = yodleeToken?.expiresIn;
    const accessToken = yodleeToken?.token;
    if (issuedAt && expiresIn && accessToken) {
      // Check if it has expired
      const expiredIn = new Date(issuedAt);
      expiredIn.setSeconds(expiredIn.getSeconds() + expiresIn);
      this.logger.log('Token expired at: ', expiredIn);
      const now = new Date();
      const hasExpired = now > expiredIn;
      this.logger.log('Has expired: ', hasExpired);
      if (!hasExpired) return false;
    }
    return true;
  }

  getTransactions() {
    this.yodleeStore.setLoading(true);
    return this.getAccessToken().pipe(
      filter((yodleeToken) => yodleeToken?.token && yodleeToken?.token !== null),
      mergeMap((yodleeToken) => {
        return this.api.getYodleeTransactions(yodleeToken?.token).pipe(
          map((response) => {
            if (response.success) {
              this.yodleeStore.update({ transactions: response.data });
            }
          }),
          catchError((err) => {
            this.yodleeStore.setError(err);
            throw err;
          }),
          finalize(() => this.yodleeStore.setLoading(false)),
        );
      }),
    );
  }
}
