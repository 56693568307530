import {
  getTransactionBillerLogo,
  getTransactionBillerName,
} from '@1bill-app/helpers/yodlee.helper';
import { NotificationService } from '@1bill-app/services/notification/notification.service';
import { YodleeService } from '@1bill-app/services/yodlee/yodlee.service';
import { YodleeTransaction } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController, LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-yodlee-transaction-single',
  templateUrl: './yodlee-transaction-single.component.html',
  styleUrls: ['./yodlee-transaction-single.component.scss'],
})
export class YodleeTransactionSingleComponent implements OnInit {
  subs = new SubSink();
  @Input() transaction: YodleeTransaction;
  getTransactionBillerLogo = getTransactionBillerLogo;
  getTransactionBillerName = getTransactionBillerName;

  constructor(
    private router: Router,
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private loadingController: LoadingController,
    private yodleeService: YodleeService,
    private ngZone: NgZone,
    private notificationService: NotificationService,
  ) {}

  ngOnInit() {}

  async handleIgnoreTransaction() {
    const title = 'Are you sure you want to ignore this transaction?';
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">${title}</h2>`,
      buttons: [
        {
          text: 'Yes',
          handler: () => this.ignoreTransaction(),
        },
        { text: 'Cancel' },
      ],
    });

    await alert.present();
  }

  async ignoreTransaction() {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: 2000,
    });
    await loading.present();
    this.subs.sink = this.yodleeService
      .updateTransaction(this.transaction?.id, { ignore: true }, this.transaction?.CONTAINER)
      .pipe(finalize(() => loading.dismiss()))
      .subscribe(() =>
        this.ngZone.run(() => {
          this.subs.sink = this.notificationService.getNotifications().subscribe();
          this.subs.sink = this.yodleeService.getTransactions().subscribe();
        }),
      );
  }

  async handleAddBill() {
    const actionSheetConfig: any = {
      cssClass: 'bill-action-sheet',
      buttons: [
        {
          text: `Upload or take photo`,
          cssClass: 'delete-button',
          handler: () => this.router.navigate(['/upload-your-bill/img']),
        },
        {
          text: `Upload file`,
          cssClass: 'delete-button mt-10',
          handler: () => this.router.navigate(['/upload-your-bill/pdf']),
        },
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'cancel-button',
        },
      ],
      mode: 'ios',
    };
    const actionSheet = await this.actionSheetController.create(actionSheetConfig);
    await actionSheet.present();
  }
}
