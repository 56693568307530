import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectableBill, ViewBillType } from '@1bill-app/services/bill/types';
import { BillService } from '@1bill-app/services/bill/bill.service';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { LoadingController } from '@ionic/angular';
import { PaymentCardService } from '@1bill-app/services/payment/payment-card.service';
import { Router } from '@angular/router';
import { ROUTE_PAYMENT_OPTIONS } from '@1bill-app/constants';

interface SelectableFilter {
  selected: boolean;
  name: string;
}

@Component({
  selector: 'app-selectable-bills-list',
  templateUrl: './selectable-bills-list.component.html',
  styleUrls: ['./selectable-bills-list.component.scss'],
})
export class SelectableBillsListComponent {
  constructor(
    private logger: NGXLogger,
    private categoryService: CategoryService,
    private loadingController: LoadingController,
    private paymentCardService: PaymentCardService,
    private billService: BillService,
    private router: Router,
  ) {}

  @Input('bills') allBills: ViewBillType[] = [];
  @Input() selectedBills: SelectableBill[] = [];
  @Input() initialBill: ViewBillType;
  @Input() context: 'bill-bundle-select' | 'linked-bills-list';
  @Input() accountPaymentId: number;
  @Output() onCheckoutBundleAction: EventEmitter<boolean> = new EventEmitter(false);
  @Output() onSelectBundleAction: EventEmitter<ViewBillType[]> = new EventEmitter(null);

  selectableProperties: SelectableFilter[] = [];
  selectableTypes: SelectableFilter[] = [];
  selectableBills: SelectableBill[] = [];
  showFilterSection = false;
  showSelectedBills = false;
  searchTerm: string;
  noBillsMessage: string;

  private readonly billBundleSelectMapHandler = (bill: ViewBillType) => ({
    ...bill,
    selected: this.selectedBills
      .map((selectedBill) => selectedBill.accountBillTypeId)
      .includes(bill.accountBillTypeId),
    displayTag: bill.billTag || bill.address2 || bill.fullAddress,
  });

  private readonly billBundleSelectSortHandler = (a: SelectableBill) => {
    // Sort by initialBill (always first), and then other selected bills
    return a.selected
      ? a.accountBillTypeId === this.initialBill.accountBillTypeId
        ? -1
        : 1
      : 1;
  };

  private readonly linkedBillsListMapHandler = (bill) => ({
    ...bill,
    // selected: bill.linkedPaymentMethodId === this.accountPaymentId, // This will select all bills that have been linked already, if only they appeared in the list in the first place.
    displayTag: bill.billTag || bill.address2 || bill.fullAddress,
  });

  private readonly linkedBillsListSortHandler = (a: SelectableBill) => {
    return a.linkedPaymentMethodId === this.accountPaymentId ? -1 : 1;
  };

  ngOnInit() {
    this.noBillsMessage =
      this.context === 'bill-bundle-select'
        ? 'No bills found.'
        : 'You do not have any bills that can be linked. To link a bill it must be unpaid and not linked to a payment method already. ' +
          "To change the payment method a bill is linked to, go to the previous page and select 'Swap bill' on the bill you wish to swap.";
    this.selectableBills = this.allBills
      .map(
        this.context === 'bill-bundle-select'
          ? this.billBundleSelectMapHandler
          : this.linkedBillsListMapHandler,
      )
      .sort(
        this.context === 'bill-bundle-select'
          ? this.billBundleSelectSortHandler
          : this.linkedBillsListSortHandler,
      );
    this.selectableProperties = _.uniq(
      this.selectableBills.map((billType) => ({
        selected: false,
        name: billType.displayTag,
      })),
    );

    // Only show categories that the user has bills of
    const categories = _.uniq(this.selectableBills?.map((bill) => bill.category));
    this.selectableTypes = categories?.map((category) => ({
      selected: false,
      name: category,
    }));

    this.selectedBills = this.selectableBills?.filter(
      (selectableBill) => selectableBill.selected,
    );
  }

  trackByFn(item: SelectableBill) {
    return item.billPaymentId;
  }

  updateSearchFilter(event: any) {
    this.searchTerm = event.detail.value;
    this.applyFilters();
  }

  proceedAction() {
    switch (this.context) {
      case 'bill-bundle-select':
        {
          this.onCheckoutBundleAction.emit(true);
        }
        break;
      case 'linked-bills-list':
        {
          this.linkAllSelectedBills();
        }
        break;
      default: {
        console.error(
          'SelectableBillsListComponent::proceedAction() unhandled context:',
          this.context,
        );
      }
    }
  }

  async linkAllSelectedBills() {
    this.loadingController
      .create({
        mode: 'md',
        message: 'Linking your bills...',
      })
      .then((loader) => {
        loader.present();

        this.paymentCardService
          .linkBillsToPaymentMethod(
            this.selectedBills.map((sb) => sb.billPaymentId),
            this.accountPaymentId,
          )
          .toPromise()
          .then(() => {
            this.logger.log('Successfully linked bill(s).');
            // Refetch bill so data isn't failing to update due to cache
            this.billService.fetchBill(true).subscribe();
            this.router.navigate([ROUTE_PAYMENT_OPTIONS]);
          })
          .catch((err) => {
            this.logger.error('Failed to link payment method ID:', err);
          })
          .finally(() => {
            loader.dismiss();
          });
      });
  }

  toggleShowFilterSection() {
    if (this.allBills?.length) {
      this.showFilterSection = !this.showFilterSection;
    }
  }

  togglePropertyFilter(property: SelectableFilter) {
    property.selected = !property.selected;
    this.applyFilters();
  }

  toggleTypeFilter(type: SelectableFilter) {
    type.selected = !type.selected;
    this.applyFilters();
  }

  canProceed() {
    switch (this.context) {
      case 'bill-bundle-select': {
        return this.selectedBills?.length > 1;
      }
      case 'linked-bills-list': {
        return this.selectedBills?.length > 0;
      }
      default: {
        console.error(
          'SelectableBillsListComponent::canProceed() Unhandled context:',
          this.context,
        );
      }
    }
  }

  /**
   * Fired when the user makes a change to the filters. The list of bills (selectableBills)
   * will be shrunk or expanded according to the filters selected.
   * If multiple filters are applied, the list will only contain bills that satisfy all the filters.
   *
   * Filter types:
   *
   * type - the type of bill (gas, electricity, etc.)
   *
   * property name - the bill's location descriptor (tag, or bill address if tag N/A)
   *
   * search term - the bill's descriptor or provider name needs includes this.
   */
  private applyFilters() {
    // More efficiency possible?
    let selections = this.selectedBills;
    const selectedIds = selections.map((selection) => selection.accountBillTypeId);
    let filteredBills = this.allBills.map((bill) => ({
      ...bill,
      displayTag: bill.billTag || bill.address2 || bill.fullAddress,
      selected: selectedIds.includes(bill.accountBillTypeId),
    }));

    // Filter by bill types
    const selectedTypes = this.selectableTypes
      .filter((selectableType) => selectableType.selected)
      .map((selectableType) => selectableType.name);
    if (selectedTypes.length) {
      filteredBills = filteredBills.filter((bill) => selectedTypes.includes(bill.category));
    }

    // Filter by bill properties/tags
    const selectedPropertyNames = this.selectableProperties
      .filter((selectableProperty) => selectableProperty.selected)
      .map((selectableProperty) => selectableProperty.name);
    if (selectedPropertyNames.length) {
      filteredBills = filteredBills.filter((bill) =>
        selectedPropertyNames.includes(bill.displayTag),
      );
    }

    // Filter by search term if present
    if (this.searchTerm?.length) {
      filteredBills = filteredBills.filter(
        (bill) =>
          bill.displayTag.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
          bill.provider.toLowerCase().includes(this.searchTerm.toLowerCase()),
      );
    }

    this.selectableBills = filteredBills.map((bill) => {
      const matchingSelection = selections.find(
        (selection) => selection.accountBillTypeId === bill.accountBillTypeId,
      );
      return {
        ...bill,
        selected: this.selectedBills
          .map((bill) => bill.accountBillTypeId)
          .includes(bill.accountBillTypeId),
        showMore: matchingSelection?.showMore ?? false,
        displayTag: bill.displayTag,
      };
    });
    if (this.selectableBills.length === 0 && this.allBills.length > 0) {
      this.noBillsMessage = 'No bills found. Try removing some search filters.';
    }
  }

  toggleExpandSelectedBills() {
    this.showSelectedBills = !this.showSelectedBills;
  }

  toggleSelectBill(billPaymentId: number) {
    this.selectedBills = this.selectableBills.filter(
      (selectableBill) => selectableBill.selected,
    );
    if (!this.selectedBills.length) {
      this.showSelectedBills = false;
    }
    this.onSelectBundleAction.emit(this.selectedBills);
  }

  /**
   * Fired when a user selects the 'Deselect' button under show all bills
   * @param bill The bill being deselected
   */
  deselectBill(bill: ViewBillType) {
    this.selectedBills = this.selectedBills.filter(
      (selectedBill) => selectedBill.accountBillTypeId !== bill.accountBillTypeId,
    );
    this.selectableBills.find(
      (selectableBill) => selectableBill.accountBillTypeId === bill.accountBillTypeId,
    ).selected = false;
    if (!this.selectedBills.length) {
      this.showSelectedBills = false;
    }
    this.onSelectBundleAction.emit(this.selectedBills);
    this.logger.log('Deselecting this bill:', bill);
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }
}
