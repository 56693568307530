import { versions } from './versions';

export function getVer(stage: string): {
    version: string,
    revision: string,
    branch: string,
    raw: string,
} {
    return {
        ...versions,
        raw: ('v' + versions.version + '-' + versions.revision + (stage !== 'prod' ? '-' + stage : '')),
    };
}
