import { Injectable } from '@angular/core';
import { applyTransaction } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { catchError, filter, map, tap, finalize } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { PublishEventAction, WebsocketService } from '../websocket.service';
import { NotificationStore } from './notification.store';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private api: ApiService,
    private notificationStore: NotificationStore,
    private wsService: WebsocketService,
    private logger: NGXLogger,
  ) {}

  refreshNotificationEvent() {
    return this.wsService.messages$.pipe(
      tap((data) => this.logger.log('clearCacheIfAdminRequests', data)),
      filter((data) => data.event === PublishEventAction.REFRESH_NOTIFICATION),
    );
  }

  getNotifications(loading = true) {
    if (loading) this.notificationStore.setLoading(true);
    return this.api.getNotifications().pipe(
      map((response) => {
        if (response.success) {
          this.notificationStore.update({
            notifications: response.data,
            hasBillTransaction: response.hasBillTransaction,
          });
          return response.data;
        } else {
          return [];
        }
      }),
      tap(() => {
        if (loading) this.notificationStore.setLoading(false);
      }),
      catchError((err) => {
        applyTransaction(() => {
          this.notificationStore.setError(err);
          if (loading) this.notificationStore.setLoading(false);
        });
        throw err;
      }),
    );
  }

  dismissNotification(notificationItemId: string, loading = true) {
    this.notificationStore.setLoading(loading);
    return this.api.dismissNotification(notificationItemId).pipe(
      tap(() => {
        this.notificationStore.setLoading(false);
      }),
      catchError((err) => {
        applyTransaction(() => {
          this.notificationStore.setLoading(false);
          this.notificationStore.setError(err);
        });
        throw err;
      }),
    );
  }

  async dismissAllNotifications() {
    this.notificationStore.setLoading(true);
    return this.api.dismissAllNotifications().toPromise()
      .catch((error) => {
        this.logger.error('Error dismissing multiple notifications:', error);
        this.notificationStore.setError(error);
        throw error;
      })
      .finally(() => {
        this.notificationStore.setLoading(false);
      });
  }

  markNotificationsRead() {
    this.notificationStore.setLoading(true);
    return this.api.markNotificationsRead().pipe(
      tap(() => {
        this.notificationStore.setLoading(false);
      }),
      catchError((err) => {
        applyTransaction(() => {
          this.notificationStore.setLoading(false);
          this.notificationStore.setError(err);
        });
        throw err;
      }),
    );
  }

  unsubscribeUserEmail(userToken: string) {
    this.notificationStore.setLoading(true);
    return this.api.unsubscribeUserEmail(userToken).pipe(
      catchError((err) => {
        applyTransaction(() => {
          this.notificationStore.setError(err);
        });
        throw err;
      }),
      finalize(() => {
        this.notificationStore.setLoading(false);
      }),
    );
  }
}
