import {
  ROUTE_CONNECT_EMAIL,
  ROUTE_EMAIL_SCANNER,
  ROUTE_MOBILE_NUMBER,
  ROUTE_POINTS_INFO,
  ROUTE_SETTINGS_NOTIFICATION,
  ROUTE_SETTINGS_PAYMENT_INFO,
  ROUTE_UPLOAD_FIRST_BILL,
} from '@1bill-app/constants';
import { HomeService } from '@1bill-app/services/home/home.service';
import { Reward, RewardActionCodeMapping } from '@1bill-app/services/reward/reward.type';
import { SpendingCategory } from '@1bill-app/services/yodlee/yodlee.types';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-net-income-item',
  templateUrl: './net-income-item.component.html',
  styleUrls: ['./net-income-item.component.scss'],
})
export class NetIncomeItemComponent implements OnInit {
  @Input() accordionState: 'expand' | 'collapse';
  @Input() category: SpendingCategory;

  constructor(
    private navCtrl: NavController,
    private homeService: HomeService,
    private popoverController: PopoverController,
    private router: Router,
  ) {}

  ngOnInit() {}

  navigate() {
    if (this.category.type !== 'EXPENSE') return;
    this.router.navigate(['net-income/expenses', this.category.id]);
  }

  routePointsInfo() {
    this.navCtrl.navigateForward([ROUTE_POINTS_INFO]);
  }

  onItemClick(data: Reward) {
    if (data.completed) return;
    switch (data.actionCode) {
      case RewardActionCodeMapping.ADD_FIRST_BILL:
        this.navCtrl.navigateForward([ROUTE_UPLOAD_FIRST_BILL]);
        break;
      case RewardActionCodeMapping.ADD_PAYMENT_DETAILS:
        this.navCtrl.navigateForward([ROUTE_SETTINGS_PAYMENT_INFO]);
        break;
      case RewardActionCodeMapping.SET_NOTIFICATION:
        this.navCtrl.navigateForward([ROUTE_SETTINGS_NOTIFICATION]);
        break;
      case RewardActionCodeMapping.VERIFY_MOBILE_NUMBER:
        this.navCtrl.navigateForward([ROUTE_MOBILE_NUMBER]);
        break;
      case RewardActionCodeMapping.FIRST_CONNECT_EMAIL_WITH_SUPPLIER:
        this.navCtrl.navigateForward([ROUTE_CONNECT_EMAIL]);
        break;
      case RewardActionCodeMapping.EMAIL_SCANNER:
        this.navCtrl.navigateForward([ROUTE_EMAIL_SCANNER]);
        break;
      case RewardActionCodeMapping.LINK_BANK_ACCOUNT:
        this.linkBankAccounts();
        break;
    }
  }

  linkBankAccounts() {
    this.homeService.presentLinkBankAccountModal();
  }
}
