import { YodleeAccessToken } from '@1bill-app/services/yodlee/yodlee.types';
import { Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider as FederatedProvider } from '@aws-amplify/auth/lib/types';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthCredentials {
  email?: string;
  password?: string;
}
export interface AuthUIState {
  socialLoading: boolean;
}
export interface AuthState {
  ui: AuthUIState;
  token: string;
  yodleeToken: YodleeAccessToken;
  loggedIn: boolean;
  firstName: string;
  credentials?: AuthCredentials;
  userConfirmed?: boolean;
  mobileConfirmed?: boolean;
  unconfirmedMobile?: string;
  username?: string;
  email?: string;
  codeDeliveryDetails?: any;
  userAttributes?: any;
  authState?: string;
  authProvider?: FederatedProvider;
  lastActivity?: string;
  hasName?: boolean;
}

function createInitialState(): AuthState {
  return {
    ui: {
      socialLoading: null,
    },
    token: null,
    yodleeToken: null,
    loggedIn: null,
    firstName: null,
    credentials: {
      email: null,
      password: null,
    },
    userConfirmed: false,
    authState: null,
    authProvider: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
  updateUI(uiState: Partial<AuthUIState>) {
    const prevUIState = this.getValue().ui;
    this.update({ ui: { ...prevUIState, ...uiState } });
  }
}
