import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { RewardService } from './reward.service';
import { RewardStore } from './reward.store';
import { RewardActionCodeMapping, RewardState } from './reward.type';

@Injectable({ providedIn: 'root' })
export class RewardQuery extends Query<RewardState> {
  isLoading$ = this.selectLoading();
  rewards$ = this.select((state) => state.rewards);
  profileReward$ = this.select((state) => state.profileRewards);
  totalPointsBalance$ = this.select((state) => state.totalPointsBalance);
  connectedEmail$ = this.select((state) =>
    state.profileRewards.some(
      (reward) => reward.actionCode === RewardActionCodeMapping.EMAIL_SCANNER,
    ),
  );

  constructor(
    protected store: RewardStore,
    private service: RewardService,
    private logger: NGXLogger,
  ) {
    super(store);
  }

  getRewardsCache() {
    if (this.store.getValue().rewards?.length > 0) {
      return of(this.store.getValue());
    } else {
      return this.service.getRewards();
    }
  }

  getProfileRewardPoint(profileRewardCode: RewardActionCodeMapping) {
    return this.profileReward$.pipe(
      map((res) => res.find((val) => val?.actionCode == profileRewardCode)),
      map((res) => res?.rewardPoints),
      tap((val) => this.logger.log('from', profileRewardCode, 'val', val)),
    );
  }
}
