import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AccountPreference } from '../settings/settings.store';
import { OnebillEvent } from './home.type';

export interface HomeUIState {
  isBillProgressInView: boolean;
}

export interface HomeData {
  ui: HomeUIState;
  creditCheck?: {
    score: number;
    scoreDate: string;
  };
  profile: { firstName: string; lastName: string; profileStrength: number };
  preferences: AccountPreference[];
  bestDeals: any[];
  featureFlags: string[];
  switchData: SwitchDataHome[];
}

export interface SwitchDataHome {
  switchCustomerId: string;
  accountBillTypeId: number;
  switchStatus: 'ONGOING' | 'COMPLETED' | 'FAILED';
  switchRetailerName: string;
  switchRetailerLogo: string;
  switchPlanName: string;
  switchAddress: string;
  billCategoryKey: string;
}

export interface HomeResponse {
  data: HomeData;
  success: boolean;
}

export interface HomeState extends HomeData {
  refetch: boolean;
  refetchSource?: string;
  success: boolean;
  loaded: boolean;
  showBalance: boolean;
  forceRefresh: boolean;
  replacingCardId?: number;
  events: OnebillEvent[];
}

function createInitialState(): HomeState {
  return {
    ui: { isBillProgressInView: null },
    creditCheck: null,
    profile: null,
    bestDeals: [],
    featureFlags: [],
    refetch: null,
    refetchSource: null,
    success: null,
    loaded: null,
    switchData: [],
    showBalance: false,
    preferences: null,
    forceRefresh: null,
    events: [],
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'home', resettable: true })
export class HomeStore extends Store<HomeState> {
  constructor() {
    super(createInitialState());
  }
  updateUI(uiState: Partial<HomeUIState>) {
    const prevUIState = this.getValue().ui;
    this.update({ ui: { ...prevUIState, ...uiState } });
  }
}
