import { RewardQuery } from '@1bill-app/services/reward/reward.query';
import { RewardService } from '@1bill-app/services/reward/reward.service';
import { RewardActionCodeMapping } from '@1bill-app/services/reward/reward.type';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import ConfettiGenerator from 'confetti-js';

@Component({
  selector: 'app-reward-badge-popup-modal',
  templateUrl: './reward-badge-popup-modal.component.html',
  styleUrls: ['./reward-badge-popup-modal.component.scss'],
})
export class RewardBadgePopupModalComponent implements OnInit, AfterViewInit {
  @Input() actionCode: RewardActionCodeMapping;
  reward$ = this.rewardQuery.rewards$.pipe(
    map((rewards) => rewards.find((r) => r.actionCode === this.actionCode)),
  );

  constructor(
    private rewardQuery: RewardQuery,
    private rewardService: RewardService,
    private modalCtrl: ModalController,
  ) {}

  ngOnInit() {
    this.explodeConfetti();
  }

  getReward() {
    return this.rewardQuery.rewards$;
  }

  ngAfterViewInit() {
    // setTimeout(() => {
    //   this.rewardService.markRewardDisplayedWithActionId(this.rewardActionId).subscribe()
    // }, 500);
  }

  onClose() {
    this.rewardService.markRewardDisplayedWithActionId(this.actionCode).subscribe();
    this.modalCtrl.dismiss();
  }

  explodeConfetti() {
    const confettiSettings = {
      target: 'confetti-canvas',
      max: 300,
      respawn: false,
      size: 1.2,
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }
}
