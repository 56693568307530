import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { ViewBillType } from '../bill/types';
import {
  ActiveMeters,
  BetterDealProduct,
  BetterDealProductDetailsPayload,
  BetterDealProductPayload,
} from './better-deal.type';

export interface BetterDealState {
  bill: ViewBillType;
  streetTypes: string[];
  concessionTypes: string[];
  titleTypes: string[];
  lifeSupportEquipmentTypes?: { id: string; name: string }[];
  productPayload: BetterDealProductPayload;
  products: BetterDealProduct[];
  productDetailsPayload: BetterDealProductDetailsPayload;
  product: BetterDealProduct;
  accountType: string;
  isSolar: boolean;
  isResidential: boolean;
  isLifeSupport: boolean;
  address?: {
    postcode: string;
    suburb: string;
    state: string;
    streetNo?: string;
    streetName?: string;
    streetType?: string;
    subpremise?: string;
  };
  fullAddressText: string;
  postcode: string;
  suburb: string;
  state: string;
  streetNo?: string;
  streetName?: string;
  streetType?: string;
  subpremise?: string;
  activeMeters?: ActiveMeters[];
  electricityDistributorId?: number;
  gasDistributorId?: number;
  fuelType?: string;
  isConnection?: boolean;
  nmi?: string;
  mirn?: string;
  checksum?: string;
  firstName: string;
  email: string;
  customerId: string;
  saleId: string;
  processing: boolean;
}

function createInitialState(): BetterDealState {
  return {
    bill: null,
    streetTypes: [],
    concessionTypes: [],
    titleTypes: [],
    lifeSupportEquipmentTypes: [],
    productPayload: null,
    products: null,
    productDetailsPayload: null,
    product: null,
    isSolar: null,
    isResidential: null,
    isLifeSupport: null,
    isConnection: false, // FIX IT MOVE IN
    address: null,
    fullAddressText: null,
    streetNo: '',
    streetName: '',
    streetType: '',
    subpremise: '',
    postcode: '',
    suburb: '',
    state: '',
    activeMeters: null,
    accountType: '',
    electricityDistributorId: null,
    gasDistributorId: null,
    nmi: null,
    mirn: null,
    checksum: null,
    firstName: '',
    email: '',
    customerId: null,
    saleId: null,
    processing: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'better-deal', resettable: true })
export class BetterDealStore extends Store<BetterDealState> {
  constructor() {
    super(createInitialState());
  }
}
