import { APP_CONFIG, USER_ACCESS_PERM_TYPES } from '@1bill-app/constants';
import { UserService } from '@1bill-app/services/user/user.service';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { EMAIL_REGEX } from '../../pages/auth/auth-constants';
import { getValidationMsgs, ValidationMsgIF } from '@1bill-app/validators/messages';

import { ModalController, ToastController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { UserQuery } from '@1bill-app/services/user/user.query';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';
import { toLowerCaseTrimSafe } from '@1bill-app/helpers/general.helper';

@Component({
  selector: 'app-user-management-modal',
  templateUrl: './user-management-modal.component.html',
  styleUrls: ['./user-management-modal.component.scss'],
})
export class UserManagementModalComponent implements OnInit {
  @Input() accessTypeIndex: number;

  email: string;

  accountsCanAccessMe: {
    email: string;
    requestStatus: 'APPROVED' | 'SENT' | 'REQUESTED' | 'DENIED';
  }[];
  userAccessPermTypes = USER_ACCESS_PERM_TYPES;
  userAccessPermType: any;

  AccountsCanAccessMeMUA$ = this.userQuery.AccountsCanAccessMeMUA$;

  validationMsgs: ValidationMsgIF;
  serverValidationError: string = null;

  loading = false;

  form: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private userService: UserService,
    private userQuery: UserQuery,
    private settingsQuery: SettingsQuery,
    private toastController: ToastController,
  ) {
    this.form = new FormGroup({
      email: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.pattern(EMAIL_REGEX),
        ]),
        [this.ValidateMUAEmail()],
      ),
      // phoneNumber: new FormControl(''),
    });

    this.validationMsgs = getValidationMsgs();

    /**
     * After user edits the email field, reset serverValidationError
     * to null so that it's no longer displayed.
     */
    this.emailCtrl.valueChanges.subscribe((val) => {
      this.serverValidationError = null;
    });
  }

  get emailCtrl() {
    return this.form.get('email');
  }

  get emailClass() {
    return (this.emailCtrl.invalid && this.emailCtrl.touched) || this.serverValidationError
      ? 'error'
      : '';
  }

  checkIfEmailUnique(control: AbstractControl): Observable<{
    alreadyHasAccess: boolean;
    alreadySentReq: boolean;
    alreadySentInv: boolean;
  }> {
    if (!this.accountsCanAccessMe) {
      return of(null);
    }

    const sendingToSelf = toLowerCaseTrimSafe(control.value) === toLowerCaseTrimSafe(this.email) ? true : false;

    const alreadyHasAccess = this.accountsCanAccessMe.some(
      (acc) =>
        JSON.stringify({
          email: control.value,
          requestStatus: 'APPROVED',
        }) === JSON.stringify(acc),
    );
    const alreadySentReq = this.accountsCanAccessMe.some(
      (acc) =>
        JSON.stringify({
          email: control.value,
          requestStatus: 'SENT',
        }) === JSON.stringify(acc),
    );
    const alreadySentInv = this.accountsCanAccessMe.some(
      (acc) =>
        JSON.stringify({
          email: control.value,
          requestStatus: 'REQUESTED',
        }) === JSON.stringify(acc),
    );

    if (sendingToSelf || alreadyHasAccess || alreadySentReq || alreadySentInv) {
      const errors = {
        sendingToSelf: sendingToSelf,
        alreadyHasAccess: alreadyHasAccess,
        alreadySentReq: alreadySentReq,
        alreadySentInv: alreadySentInv,
      };

      return of(errors);
    }

    return of(null);
  }

  /**
   * Validator to check if user already has access to the 1bill account associated with the inputted email.
   */
  ValidateMUAEmail(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.checkIfEmailUnique(control).pipe(
        map((result) => {
          // if res is true, email found, return true
          return result ? result : null;
          // return null when no validation error
        }),
      );
    };
  }

  async ngOnInit() {
    this.userAccessPermType = this.userAccessPermTypes[this.accessTypeIndex];

    this.settingsQuery.select().subscribe({
      next: (settingsData) => {
        this.email = settingsData.email;
      },
    });

    this.AccountsCanAccessMeMUA$.subscribe((muaList) => {
      this.accountsCanAccessMe = muaList.map((mua) => {
        return { email: mua.accessorUserEmail, requestStatus: mua.requestStatus };
      });
    });
    this.logger.log('ACCOUNTS CAN ACCESS', this.accountsCanAccessMe);
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  async sendAccessReq() {
    this.loading = true;
    const reqBody = {
      receiverEmail: this.form.get('email')?.value,
      accessLevel: <'FULL' | 'VIEWER' | 'ADD_BILL'>this.userAccessPermType?.name,
      requestType: <'INVITATION' | 'REQUEST'>'INVITATION',
    };

    this.logger.log(reqBody);
    this.userService.sendUserAccessRequest(reqBody).subscribe({
      next: (res) => {
        this.logger.log('RESULT:', res);
        if (res.success == true) {
          this.userService.getMultiUserAccesses().subscribe((res) => {
            this.logger.log('MultiUserAccesses:', res);
          });

          this.loading = false;
          this.modalCtrl.dismiss();
          this.presentToast(
            'success',
            `${reqBody.requestType == 'INVITATION' ? 'Invitation' : 'Request'} sent!`,
          );
        }
      },
      error: async (err) => {
        this.logger.log('ERROR:', err);

        this.userService.getMultiUserAccesses().subscribe((res) => {
          this.logger.log('MultiUserAccesses:', res);
        });

        this.loading = false;
        //await this.modalCtrl.dismiss();
        this.serverValidationError = err.error.message;
        //this.presentToast('danger', err.error.message);
      },
    });
  }

  async presentToast(color: 'success' | 'danger' | 'warning', message: string) {
    const toast = await this.toastController.create({
      message,
      duration: APP_CONFIG.TOAST_SUCCESS_DURATION,
      color,
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
        },
      ],
    });
    toast.present();
    if (color === 'success') this.userService.getMultiUserAccesses(false);
  }
}
