import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { SharedComponentsModule } from '../shared-components.module';
import { FeedbackFormComponent } from './feedback-form.component';

@NgModule({
  declarations: [FeedbackFormComponent],
  imports: [CommonModule, IonicModule, RouterModule, FormsModule, SharedComponentsModule],
  exports: [FeedbackFormComponent],
})
export class FeedbackFormComponentModule {}
