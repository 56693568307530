import { Component, Input, OnInit } from '@angular/core';
import { Platform, PopoverController } from '@ionic/angular';
import { MOBILE_VIEW_THRESHOLD_PX, SALARY_INTERVAL_OPTIONS } from '@1bill-app/constants';

enum SalaryValidation {
  INVALID = 'You must enter a positive number as your salary.',
  TOO_HIGH = 'Maximum accepted salary is $9,999,999.',
}

@Component({
  selector: 'app-add-salary-modal',
  templateUrl: './add-salary-modal.component.html',
  styleUrls: ['./add-salary-modal.component.scss'],
})
export class AddSalaryModalComponent implements OnInit {
  @Input() salary: number;
  @Input() interval: 'week' | 'fortnight' | 'month' | 'year';
  displayValidationMessage = false;
  validationMessage: SalaryValidation;
  initialSalary: number;

  get isDesktopView() {
    return this.platform.width() > MOBILE_VIEW_THRESHOLD_PX;
  }

  private readonly MAX_SALARY = 9999999;
  readonly SALARY_INTERVAL_OPTIONS = SALARY_INTERVAL_OPTIONS;

  constructor(private popoverController: PopoverController, private platform: Platform) {}

  ngOnInit() {
    this.initialSalary = this.salary;
    if (!this.interval) {
      this.interval = (this.SALARY_INTERVAL_OPTIONS.find((option) => option.default) ?? this.SALARY_INTERVAL_OPTIONS[0]).value;
    }
  }

  onInput() {
    // Clear validation message if user makes input
    this.displayValidationMessage = false;
  }

  submitSalary() {
    const numericInput = Number(this.salary);
    if (isNaN(numericInput) || numericInput <= 0) {
      this.displayValidationMessage = true;
      this.validationMessage = SalaryValidation.INVALID;
    } else if (numericInput > this.MAX_SALARY) {
      this.displayValidationMessage = true;
      this.validationMessage = SalaryValidation.TOO_HIGH;
    } else if (numericInput === this.initialSalary) {
      this.popoverController.dismiss();
    } else {
      this.popoverController.dismiss({ salary: Number(this.salary), interval: this.interval });
    }
  }

  deleteSalary() {
    this.popoverController.dismiss({ deleteSalary: true });
  }
}
