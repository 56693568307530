import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable, of, from } from 'rxjs';
import { catchError, filter, finalize, map, mergeMap, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { BillCategoryKey, SwitchErrorCode, ViewBillType } from '../bill/types';
import {
  getAddressDetails,
  GoogleAddressDetails,
  GoogleAddressService,
} from '../google/google-address.service';
import { BetterDealStore } from './better-deal.store';
import { BetterDealProductDetails, CreateCustomerAndSalePayload } from './better-deal.type';
import { BillAddressSearchModalComponent } from 'src/app/pages/bill/components/bill-address-search-modal/bill-address-search-modal.component';
import { SwitchBillDetails } from '../api/api-types';
import { getDayCount } from '@1bill-app/helpers/date.helpers';
import { formatQueryParams } from '../bill/helper';
import { SwitchCCIframeModalComponent } from '@1bill-app/components/modals/switch-cc-iframe-modal/switch-cc-iframe-modal.component';
import {
  ROUTE_CONTACT_US,
  ROUTE_GET_BETTER_DEALS,
  ROUTE_SETTINGS_CONTACT_DETAILS,
  ROUTE_SETTINGS_ID_DETAILS,
} from '@1bill-app/constants';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { PopoverBetterDealTncComponent } from '@1bill-app/components/popover/popover-better-deal-tnc/popover-better-deal-tnc.component';
import { Router } from '@angular/router';
import { environment } from '@1bill-app/env';
import { AppsflyerService } from '../analytics/appsflyer.service';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { SettingsService } from '../settings/settings.service';
import { HomeService } from '../home/home.service';
import { BillService } from '../bill/bill.service';
import { UIService } from '../ui.service';

@Injectable({
  providedIn: 'root',
})
export class BetterDealService {
  constructor(
    private api: ApiService,
    private betterDealStore: BetterDealStore,
    private logger: NGXLogger,
    private modalController: ModalController,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private router: Router,
    private appsflyerService: AppsflyerService,
    private settingsService: SettingsService,
    private homeService: HomeService,
    private googleAddressService: GoogleAddressService,
    private billService: BillService,
    private uiService: UIService,
  ) {}

  getStreetTypes() {
    return this.api.getReferenceData('streettype').pipe(
      map((res) => {
        this.betterDealStore.update({ streetTypes: res.map((x) => x.name) });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in streettypes', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
    );
  }

  getConcessionTypes() {
    return this.api.getReferenceData('concession').pipe(
      map((res) => {
        this.betterDealStore.update({ concessionTypes: res.map((x) => x.name) });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in concessionTypes', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
    );
  }

  getTitleTypes() {
    return this.api.getReferenceData('title').pipe(
      map((res) => {
        this.betterDealStore.update({ titleTypes: res.map((x) => x.name) });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in titleTypes', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
    );
  }

  getLifeSupportEquipmentTypes() {
    return this.api.getReferenceData('lifesupportequipment').pipe(
      map((res) => {
        this.betterDealStore.update({ lifeSupportEquipmentTypes: res });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in lifesupportequipment', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
    );
  }

  saveFiltersAndAddress(data) {
    this.betterDealStore.update({ productPayload: data });
    this.betterDealStore.update({
      subpremise: data?.subpremise,
      streetNo: data?.streetNo,
      streetName: data?.streetName,
      streetType: data?.streetType,
      suburb: data.suburb,
      postcode: data.postcode,
      state: data.state,
      address: {
        subpremise: data?.subpremise,
        streetNo: data?.streetNo,
        streetName: data?.streetName,
        streetType: data?.streetType,
        suburb: data.suburb,
        postcode: data.postcode,
        state: data.state,
      },
      electricityDistributorId: data.electricityDistributorId,
      activeMeters: data?.activeMeters,
      accountType: data.accountType,
      isSolar: data.solarPanels,
      isLifeSupport: data.lifeSupport,
      fuelType: data.fuelType,
      nmi: data?.nmi,
      mirn: data?.mirn,
    });
  }

  // saveProduct(product: any){
  //   this.betterDealStore.update({product});
  // }
  // saveUserFromConsent(data: any){
  //   this.betterDealStore.update({
  //     firstName: data.firstName,
  //     email: data.email
  //   })
  // }

  getActiveMeters(nmi: string, checksum: string) {
    this.betterDealStore.setLoading(true);
    return this.api.getActiveMeters(nmi, checksum).pipe(
      map((res) => {
        this.betterDealStore.update({
          activeMeters: res,
          nmi: nmi,
          checksum: checksum,
        });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in activemeters', err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getMsatsDiscoveryResults(address: GoogleAddressDetails) {
    this.betterDealStore.setLoading(true);
    return this.api.getMsatsDiscovery(address).pipe(
      map((res) => {
        //this.betterDealStore.update({msatsDiscoveryResult: res.results});
        return res.results;
      }),
      catchError((err) => {
        this.betterDealStore.setError(err);
        this.logger.error('Error in msats discovery', err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getBetterDealProducts() {
    this.betterDealStore.setLoading(true);
    let productPayload = this.betterDealStore.getValue().productPayload;
    return this.api.getBetterDealProducts(productPayload).pipe(
      map((res) => {
        this.betterDealStore.update({ products: res });
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in fetching products', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getProductDetails(productId: number): Observable<BetterDealProductDetails> {
    this.betterDealStore.setLoading(true);
    let productPayload = this.betterDealStore.getValue().productPayload;

    return this.api.getBetterDealProductDetails(productId, productPayload).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        this.betterDealStore.setError(error);
        throw error;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  createCustomerAndSale(payload: CreateCustomerAndSalePayload) {
    return this.api.createCncCustomerAndSale(payload).pipe(
      map((res) => {
        if (res.customerId && res.saleId) {
          this.betterDealStore.update({
            customerId: res.customerId,
            saleId: res.saleId,
          });
          return res;
        } else if (res.customerId) {
          //sale may not created
          //Try again with customerId
          this.betterDealStore.update({
            customerId: res.customerId,
          });
          throw { error: 'cant create sales id..' };
        }
      }),
      //retryWhen(errors => errors.pipe(delay(2000), take(1))),
      catchError((err) => {
        this.logger.error('Error in create customer', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getBusinessRules(productId: number) {
    this.betterDealStore.setLoading(true);
    let data: any = {
      postcode: this.betterDealStore.getValue().postcode,
      suburb: this.betterDealStore.getValue().suburb,
      state: this.betterDealStore.getValue().state,
      productId: productId,
      fuelType: this.betterDealStore.getValue().fuelType,
      // gasDistributorId: this.betterDealStore.getValue().gasDistributorId ?? 0,
      // electricityDistributorId: this.betterDealStore.getValue().electricityDistributorId ?? 0,
    };
    if (data.fuelType == 'Dual') {
      data = {
        ...data,
        gasDistributorId: this.betterDealStore.getValue().gasDistributorId ?? 0,
        electricityDistributorId:
          this.betterDealStore.getValue().electricityDistributorId ?? 0,
      };
    } else if (data.fuelType == 'Electricity') {
      data = {
        ...data,
        electricityDistributorId:
          this.betterDealStore.getValue().electricityDistributorId ?? 0,
      };
    } else if (data.fuelType == 'Gas') {
      data = {
        ...data,
        gasDistributorId: this.betterDealStore.getValue().gasDistributorId ?? 0,
      };
    }

    return this.api.getBusinessRules(data).pipe(
      map((res) => {
        //this.betterDealStore.update({products : res});
        return res;
      }),
      catchError((err) => {
        this.logger.error('Error in createaddressJson', err);
        this.betterDealStore.setError(err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getElectricityDistributorId(nmi: string, state: string) {
    const nmiTwo = nmi.substring(0, 2);

    switch (state) {
      case 'VIC':
        switch (nmiTwo) {
          case '61':
            return 1;
          case '62':
            return 3;
          case '64':
            return 5;
          case '60':
            return 2;
          case '63':
            return 4;
        }
        break;
      case 'QLD':
        switch (nmiTwo) {
          case '31':
          case 'QB':
            return 6;
        }
        break;
      case 'NSW':
        switch (nmiTwo) {
          case '41':
            return 9;
          case '43':
            return 8;
          case '42':
          case '40':
          case '44':
          case '45':
            return 7;
        }
        break;
      case 'SA':
        if (nmiTwo == '20') return 10;
        break;
      case 'ACT':
        if (nmiTwo == '70') return 12;
        break;
    }
  }

  getGasDistributorId(postcode: string, suburb: string) {
    return this.api.getFuelTypeDistributor(postcode, suburb).pipe(
      map((res) => {
        this.betterDealStore.update({
          gasDistributorId: res.distributors.find((x) => x.type == 'Gas').id,
        });
        return res.distributors.find((x) => x.type == 'Gas').id;
      }),
      catchError((err) => {
        this.betterDealStore.setError(err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  getElectricityDistributorIdFromApi(postcode: string, suburb: string) {
    return this.api.getFuelTypeDistributor(postcode, suburb).pipe(
      map((res) => {
        this.betterDealStore.update({
          electricityDistributorId: res.distributors.find((x) => x.type == 'Electricity').id,
        });
        return res.distributors.find((x) => x.type == 'Electricity').id;
      }),
      catchError((err) => {
        this.betterDealStore.setError(err);
        throw err;
      }),
      finalize(() => this.betterDealStore.setLoading(false)),
    );
  }

  async presentCannotSwitchModal(switchErrorCode: SwitchErrorCode, message: string) {
    let text1 = 'You are unable to switch at this time';
    let text2 = message;
    let buttonText = '';
    let route = '';
    const routeSource = this.router.url;

    if (switchErrorCode === SwitchErrorCode.MobileUnverified) {
      buttonText = 'Verify mobile number';
      route = ROUTE_SETTINGS_CONTACT_DETAILS;
    }
    if (switchErrorCode === SwitchErrorCode.MultipleMobile) {
      buttonText = 'Please contact our support team.';
      route = ROUTE_CONTACT_US;
    }

    const modal = await this.uiService.presentActionDeniedModal(
      text1,
      text2,
      buttonText,
      route,
      routeSource,
    );

    return modal;
  }

  async presentInsufficientDataModal(missingExtra) {
    let text1 = 'Please complete your profile details in settings';
    let text2 = 'in order to get a better deal!';
    let buttonText = 'Complete profile details';
    let route = ROUTE_SETTINGS_CONTACT_DETAILS;
    const routeSource = this.router.url;

    if (missingExtra === 'driver_license') {
      text1 = 'Please add your driver license in settings';
      text2 = 'in order to get a better deal!';
      buttonText = 'Add driver license';
      route = ROUTE_SETTINGS_ID_DETAILS;
    }
    const modal = await this.uiService.presentActionDeniedModal(
      text1,
      text2,
      buttonText,
      route,
      routeSource,
    );
    return modal;
  }

  async presentAddressInputModal(bill: ViewBillType) {
    const modal = await this.modalController.create({
      component: BillAddressSearchModalComponent,
      cssClass: 'my-custom-class',
      componentProps: {
        address: `${bill.address1} ${bill.address2}`,
      },
    });
    await modal.present();

    const { data } = await modal.onWillDismiss();
    if (data && data.success) {
      const placeId = data.data.placeId;
      this.getBetterDeal(bill, placeId);
    }
  }

  async errorAlert(customMessage?: string) {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message: customMessage
        ? `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">${customMessage}</p>`
        : `<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Oops!</h2><p class="alert-text">Something went wrong! Please try again later.</p>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Got it',
          handler: () => {},
        },
      ],
    });

    await alert.present();
  }

  async presentSwitchWindow(
    crmAccountId: string,
    categoryKey: string,
    accountBillTypeId: number,
    billPaymentId: number,
    billData: ViewBillType,
  ) {
    // appsflyer
    this.appsflyerService.startedSwitch();
    const redirectUrl = environment.switchRedirectUrl + `/${billPaymentId}`;
    const billUsage: {
      billAmt: number;
      billDays: number;
      peakUsage: string;
      hasOffPeak: string;
      hasControlledLoad1: string;
      hasControlledLoad2: string;
      hasShoulder: string;
      nmi?: string;
      mirn?: string;
    } = {
      billAmt: billData?.amount,
      billDays:
        billData?.billDays ?? getDayCount(billData?.billStartDate, billData?.billEndDate) + 1,
      peakUsage: billData?.peakUsage ?? '0',
      hasOffPeak: billData?.offPeakUsage ?? '0',
      hasControlledLoad1: billData?.controlledLoad1 ?? '0',
      hasControlledLoad2: billData?.controlledLoad2 ?? '0',
      hasShoulder: billData?.shoulder1 ?? '0',
      nmi:
        billData?.categoryKey == BillCategoryKey.ELECTRICITY && billData?.meterNumber
          ? billData?.meterNumber
          : '0',
      mirn:
        billData?.categoryKey == BillCategoryKey.GAS && billData?.dpi ? billData?.dpi : '0',
    };

    // delete unwanted fields
    // billData.categoryKey == BillCategoryKey.ELECTRICITY ? delete billUsage.mirn : '';
    // billData.categoryKey == BillCategoryKey.GAS ? delete billUsage.nmi : '';

    const params = {
      crmAcId: crmAccountId,
      billTypeId: accountBillTypeId,
      redirectUrl,
      source: '1bill',
      ...billUsage,
    };

    const querystring = formatQueryParams(params);
    const switchURL = environment.switchCCIframeUrl + `?${querystring}`;
    // const switchURL = `https://d1sundi548slwp.cloudfront.net/index.html`;

    this.logger.log('iframe or native url', switchURL);

    // if ios native, use in app browser (for autofill and cookie issue), refresh page on close
    if (Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios') {
      return this.openWindow(switchURL);
    }

    const modal = await this.modalController.create({
      component: SwitchCCIframeModalComponent,
      cssClass: 'switch-cc-iframe-modal',
      componentProps: {
        // accountBillTypeId,
        // crmAccountId,
        // categoryKey,
        // billPaymentId,
        // billData,
        switchURL,
      },
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.logger.log('iframe modal dismissed');
      this.homeService.triggerFetchHomeData(
        'BillPage::SwitchCCIframeModalComponent closed',
        true,
      );
      this.betterDealStore.update({ processing: false });
    }
  }

  /**
   * Opens the url.
   * In-app browser in app.
   * External tab in web.
   */
  private openWindow(url: string) {
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
      toolbarColor: 'ffffff',
    });

    Browser.addListener('browserFinished', () => {
      this.logger.log('native browser closed');
      this.homeService.triggerFetchHomeData(
        'BillPage::Switch open browser openWindow closed',
        true,
      );
      this.betterDealStore.update({ processing: false });
      // this.latPayProcessing = false;
    });
  }

  private async getBetterDeal(bill: ViewBillType, placeId: string = null) {
    this.logger.debug('getBetterDeal => ', bill);

    const settings = await this.settingsService.fetch().toPromise();
    const { isMissing, missingExtra, missingCount } =
      this.settingsService.identifyMissingPersonalDetails(settings);

    if (isMissing) {
      // await this.presentInsufficientDataToast(missingExtra);
      await this.presentInsufficientDataModal(missingCount > 1 ? null : missingExtra);
      return;
    }

    this.betterDealStore.update({ processing: true });

    let crmAccountId = bill.switchCustomerId;
    this.logger.log('crmAccountId', crmAccountId);

    if (!crmAccountId) {
      from(Promise.resolve(placeId))
        .pipe(
          mergeMap((placeId) => {
            if (placeId) {
              return of([{ placeId }]);
            } else {
              return from(
                this.googleAddressService.getPlacePredictions(
                  `${bill?.address1} ${bill?.address2}`,
                ),
              ).pipe(map((res) => res));
            }
          }),
          filter((res) => {
            if (placeId || (res.length && !!res[0].placeId)) {
              return true;
            } else {
              // this.presentToast('warning', 'Address mapping failed. Please contact 1bill support.');
              this.betterDealStore.update({ processing: false });
              this.presentAddressInputModal(bill);
              return false;
            }
          }),
          map((res) => {
            if (placeId) {
              return placeId;
            } else {
              return res[0].placeId;
            }
          }),
          mergeMap((placeId) =>
            from(getAddressDetails(placeId)).pipe(map((res) => res.result)),
          ),
          tap((res) => this.logger.log(res)),
          filter((res) => {
            if (res && res.streetNo) {
              return true;
            } else {
              this.betterDealStore.update({ processing: false });
              this.presentAddressInputModal(bill);
              return false;
            }
          }),
          mergeMap((googleAddress) => {
            const billDetails: SwitchBillDetails = {
              accountBillTypeId: bill.accountBillTypeId,
              solarPanels: false, // TODO: bill.hasSolar
              energy: bill.categoryKey === BillCategoryKey.ELECTRICITY, // whether the customer needs energy? bit confusing
              gas: bill.categoryKey === BillCategoryKey.GAS, // if gas bill
              internet: bill.categoryKey === BillCategoryKey.INTERNET, // if internet bill
              insurance: bill.categoryKey === BillCategoryKey.INSURANCE, // if insurance bill
              elecRetailer:
                bill.categoryKey === BillCategoryKey.ELECTRICITY ? bill.provider : null, // for electricty only
              gasRetailer: bill.categoryKey === BillCategoryKey.GAS ? bill.provider : null, // for gas only
              addressJson: googleAddress,
            };
            settings.identificationSelected = 'driver_licence'; // currently cnc only supports driver_licence
            return this.api.switchService(settings, billDetails);
          }),
          mergeMap((res) => {
            if (!res.userCanSwitch) {
              this.errorAlert(res.message || 'You are not eligible to switch bills');
              return of(false);
            }
            if (res.success && res.data.uniqueId) {
              crmAccountId = res.data.uniqueId;
              this.logger.log('Switch svc success', res);
              this.presentSwitchWindow(
                crmAccountId,
                bill.categoryKey,
                bill.accountBillTypeId,
                bill.billPaymentId,
                bill,
              );
              return of(true);
              // return this._settings.fetch();
            } else {
              return of(false);
            }
          }),
          tap(() => this.betterDealStore.update({ processing: false })),
          // mergeMap(() => this.userService.getUser()),
          mergeMap(() => this.billService.getBill(true)),
          catchError((err) => {
            this.logger.error('Error in switch svc', err);
            this.errorAlert();
            this.betterDealStore.update({ processing: false });

            return of(false);
          }),
        )
        .subscribe();
    } else {
      this.presentSwitchWindow(
        crmAccountId,
        bill.categoryKey,
        bill.accountBillTypeId,
        bill.billPaymentId,
        bill,
      );
    }
  }

  async presentTncPopUp(bill: ViewBillType) {
    const popover = await this.popoverController.create({
      component: PopoverBetterDealTncComponent,
      cssClass: 'find-better-deals-popover',
      mode: 'ios',
      componentProps: {
        categoryKey: bill.categoryKey,
      },
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();

    if (role === 'dismiss') {
      if (
        bill?.categoryKey == BillCategoryKey.ELECTRICITY ||
        bill?.categoryKey == BillCategoryKey.GAS
      ) {
        const settings = await this.settingsService.fetch().toPromise();
        const { isMissing, missingExtra, missingCount } =
          this.settingsService.identifyMissingPersonalDetails(settings);

        if (isMissing) {
          await this.presentInsufficientDataModal(missingCount > 1 ? null : missingExtra);
          return;
        }

        // check if user can switch
        const switchCheck = await this.api
          .checkIfUserCanSwitch({
            switchAddress: bill.switchAddress,
            product: bill.categoryKey,
            billTypeId: bill.accountBillTypeId,
          })
          .toPromise();

        if (!switchCheck.userCanSwitch) {
          await this.presentCannotSwitchModal(switchCheck.code, switchCheck.message);
          return;
        }

        // fullAddress will not be set, but address1 and address2 set, from certain contexts
        if (!bill.fullAddress) {
          bill = { ...bill, fullAddress: `${bill.address1} ${bill.address2}` };
        }

        this.betterDealStore.update({
          bill: bill,
        });

        this.router.navigate([ROUTE_GET_BETTER_DEALS]);
      } else {
        this.getBetterDeal(bill, null);
      }
    }
  }
}
