import { Component, Input } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { Browser } from '@capacitor/browser';
import { Clipboard } from '@capacitor/clipboard';

@Component({
  selector: 'app-redeem-successful-popover',
  templateUrl: './redeem-successful-popover.component.html',
  styleUrls: ['./redeem-successful-popover.component.scss'],
})
export class RedeemSuccessfulPopoverComponent {
  @Input() giftCardCode: string;

  constructor(
    private popoverController: PopoverController,
    private toastController: ToastController,
  ) {}

  routeToShop() {
    this.openWindow('https://card.gift/cards/all');
  }

  /**
   * Copied from SelectPaymentMethodComponent
   * @param url The URL to open in a new tab on desktop, or in an in-app browser on mobile app
   */
  private openWindow(url: string) {
    this.dismiss(true);
    Browser.open({
      presentationStyle: 'fullscreen',
      url,
      windowName: '_blank',
      toolbarColor: 'ffffff',
    });
  }

  async copyToClipboard(value: string) {
    await Clipboard.write({ string: value });
    this.toastController
      .create({
        message: 'Copied your promo code to clipboard',
        duration: 3000,
      })
      .then((toast) => {
        toast.present();
      });
  }

  dismiss(confirmed?: boolean) {
    this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
  }
}
