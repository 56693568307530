import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';
import { ComponentRef } from '@ionic/core';
import { Auth } from 'aws-amplify';
import { UserService } from '../user/user.service';
import { UserQuery } from '../user/user.query';
import { ApiService } from '../api/api.service';
import { from } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { UserStore } from '../user/user.store';
import { applyTransaction } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class PINService {
  isOpen = false;
  static pinKey = '1bill_auth_pin';
  pinEnabled$ = this.userQuery.pinEnabled$;
  loading$ = this.userQuery.selectLoading();
  constructor(
    private logger: NGXLogger,
    private modalCtrl: ModalController,
    private userSvc: UserService,
    private userQuery: UserQuery,
    private userStore: UserStore,
    private api: ApiService,
  ) {}
  /**
   * Stores pin after being created.
   * @param pin
   * @returns
   */
  store(pin: string) {
    this.userStore.setLoading(true);
    return this.api.pinStore({ pin }).pipe(
      filter(({ success }) => {
        if (!success) {
          this.userStore.setLoading(false);
        }
        return success;
      }),
      switchMap(() => from(Auth.currentAuthenticatedUser())),
      switchMap((user) => from(Auth.updateUserAttributes(user, { 'custom:pin_code': '1' }))),
      tap(() => {
        this.userSvc.setPinEnabled(true);
        this.userStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.userStore.setError(error);
          this.userStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  check(pin: string) {
    this.userStore.setLoading(true);
    return this.api.pinCheck({ pin }).pipe(
      map(({ success }) => success),
      tap(() => {
        this.userStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.userStore.setError(error);
          this.userStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  // async retrieve(): Promise<string> {
  //   if (!Capacitor.isNativePlatform()) return;
  //   let value: string;
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     value =
  //       user.attributes['custom:pin_code'] && user.attributes['custom:pin_code'] !== '-'
  //         ? atob(user.attributes['custom:pin_code'])
  //         : null;
  //     this.userSvc.setPinEnabled(value ? true : false);
  //   } catch (err) {
  //     this.logger.warn(err);
  //   }
  //   return value;
  // }

  clear() {
    this.userStore.setLoading(true);
    return this.api.pinClear().pipe(
      filter(({ success }) => {
        if (!success) {
          this.userStore.setLoading(false);
        } else {
          this.userSvc.setPinEnabled(false);
        }
        return success;
      }),
      switchMap(() => from(Auth.currentAuthenticatedUser())),
      switchMap((user) => from(Auth.updateUserAttributes(user, { 'custom:pin_code': '-' }))),
      tap(() => {
        this.userStore.setLoading(false);
      }),
      catchError((error) => {
        applyTransaction(() => {
          this.userStore.setError(error);
          this.userStore.setLoading(false);
        });
        throw error;
      }),
    );
  }

  async openCreatePIN(component: ComponentRef) {
    if (this.isOpen || !Capacitor.isNativePlatform()) return;
    this.isOpen = true;
    // const value = await this.retrieve();
    // if (!value) {
    // }

    const modal = await this.modalCtrl.create({ component });
    await modal.present();
    modal.onDidDismiss().then((result) => {
      this.isOpen = false;
    });
  }
  async openRemovePIN(component: ComponentRef) {
    if (this.isOpen || !Capacitor.isNativePlatform()) return;
    this.isOpen = true;
    // const value = await this.retrieve();
    // if (!value) {
    // }

    const modal = await this.modalCtrl.create({ component });
    await modal.present();
    modal.onDidDismiss().then((result) => {
      this.isOpen = false;
    });
  }

  async openUpdatePIN(component: ComponentRef) {
    if (this.isOpen || !Capacitor.isNativePlatform()) return;
    this.isOpen = true;
    // const value = await this.retrieve();
    // if (!value) {
    // }

    const modal = await this.modalCtrl.create({ component });
    await modal.present();
    modal.onDidDismiss().then((result) => {
      this.isOpen = false;
    });
  }

  async hasPin(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) return false;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const pinEnabled =
        user.attributes['custom:pin_code'] && user.attributes['custom:pin_code'] === '1'
          ? true
          : false;
      this.userSvc.setPinEnabled(pinEnabled);
      return pinEnabled;
    } catch (err) {
      this.logger.warn(err);
      this.userSvc.setPinEnabled(false);
      return false;
    }
    // return !!(await this.retrieve());
  }
  async openCreateNewPin(): Promise<boolean> {
    if (!Capacitor.isNativePlatform()) return false;
    try {
      const user = await Auth.currentAuthenticatedUser();
      const open = user.attributes['custom:pin_code'] ? false : true;
      console.log({ open });
      return open;
    } catch (err) {
      this.logger.warn(err);
      return false;
    }
  }
}
