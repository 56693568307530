import { environment } from '@1bill-app/env';
import { ROUTE_BILLS_PAGE, ROUTE_CREDIT_SCORE_PAGE, ROUTE_HELP, ROUTE_REWARDS } from './app.constants';

export const generalMenu = [
  {
    title: 'Home',
    url: '/app/home',
    iconSrc: 'assets/icon/shadowboxer/House.svg',
    type: 'internal-url',
    displaySideMenu: true,
  },
  {
    title: 'Profile',
    icon: 'open-outline',
    type: 'menu',
    open: false,
    iconSrc: 'assets/icon/open_in_new.svg',
    subMenus: [
      {
        title: 'Your achievements',
        url: '/achievements',
        icon: 'medal-outline',
        iconSrc: 'assets/icon/achievements/achievement-menu.svg',
        type: 'internal-url',
        subMenu: true,
      },
      {
        title: 'Credit Score',
        url: ROUTE_CREDIT_SCORE_PAGE,
        icon: 'pulse-outline',
        iconSrc: 'assets/icon/credit-score.svg',
        type: 'internal-url',
        subMenu: true,
      },
    ],
  },
  {
    title: 'Payment Instructions',
    icon: 'open-outline',
    type: 'menu',
    open: false,
    iconSrc: 'assets/icon/open_in_new.svg',
    subMenus: [
      {
        title: 'Payments',
        url: 'payment-info',
        type: 'internal-url',
        iconSrc: 'assets/icon/payments.svg',
        subMenu: true,
      },
      {
        title: 'Bank Accounts',
        url: '/accounts',
        icon: 'bills-outline',
        iconSrc: 'assets/icon/bank-account.svg',
        type: 'internal-url',
        subMenu: true,
        // displaySideMenu: true,
      },
    ],
  },
  {
    title: 'Email Scanner',
    url: '/email-scanner',
    icon: 'mail-outline',
    iconSrc: 'assets/icon/email-scanner.svg',
    type: 'internal-url',
  },
  {
    title: 'Support',
    icon: 'open-outline',
    type: 'menu',
    open: false,
    iconSrc: 'assets/icon/open_in_new.svg',
    subMenus: [
      {
        title: 'FAQ',
        url: 'faq',
        icon: 'help-circle-outline',
        iconSrc: 'assets/icon/FAQ.svg',
        type: 'internal-url',
        subMenu: true,
      },
      {
        title: 'Contact Us',
        url: '/contact-us',
        iconSrc: 'assets/icon/contact-us.svg',
        icon: 'send-outline',
        type: 'internal-url',
        subMenu: true,
      },
      {
        title: 'Terms and Conditions',
        url: environment.appTerms,
        icon: 'open-outline',
        type: 'external-url',
        iconSrc: '',
        subMenu: true,
      },

      {
        title: 'Privacy Policy',
        url: environment.appPrivacyPolicy,
        icon: 'open-outline',
        iconSrc: '',
        type: 'external-url',
        subMenu: true,
      },
    ],
  },
  // {
  //   title: 'History',
  //   url: '/app/history',
  //   icon: 'timer-outline',
  //   type: 'internal-url',
  // },
  {
    title: 'Bills',
    url: ROUTE_BILLS_PAGE,
    iconSrc: 'assets/icon/shadowboxer/Receipt.svg',
    type: 'internal-url',
    displaySideMenu: true,
    showInMenu: false,
  },
  {
    title: 'Rewards',
    url: ROUTE_REWARDS,
    iconSrc: 'assets/icon/shadowboxer/CrownSimple.svg',
    type: 'internal-url',
    displaySideMenu: true,
  },
  {
    title: 'Help',
    url: ROUTE_HELP,
    iconSrc: 'assets/icon/shadowboxer/Question.svg',
    type: 'internal-url',
    displaySideMenu: true,
  },

  // Removed per new menu arrangement
  // {
  //   title: 'Bank Accounts',
  //   url: '/accounts',
  //   icon: 'bills-outline',
  //   iconSrc: 'assets/icon/bank-accounts.svg',
  //   type: 'internal-url',
  //   // displaySideMenu: true,
  // },
  // removed until ready
  // {
  //   title: 'Funding',
  //   url: '/app/funding',
  //   icon: 'wallet-outline',
  //   iconSrc: 'assets/icon/money-wallet.svg',
  //   type: 'internal-url',
  // },
  // {
  //   title: '1bill Points ',
  //   url: '/points-info',
  //   icon: 'ticket-outline',
  //   type: 'internal-url',
  // },
  // {
  //   title: 'Bank Account(s) Scanner',
  //   url: '/accounts',
  //   iconSrc: 'assets/icon/bank-accounts.svg',
  //   type: 'internal-url',
  // },
  {
    title: 'Settings',
    url: '/app/settings',
    icon: 'settings-outline',
    iconSrc: 'assets/icon/settings.svg',
    type: 'internal-url',
    displaySideMenu: false,
  },
  {
    title: 'Logout',
    url: '/app/logout',
    iconSrc: 'assets/icon/logout.svg',
    icon: 'log-out',
    type: 'internal-url',
  },
  // {
  //   title: 'Legal',
  //   icon: 'open-outline',
  //   type: 'menu',
  //   open: false,
  //   iconSrc: 'assets/icon/open_in_new.svg',
  // },
];

export const dummyMenu = [
  {
    title: 'Home',
    url: '/dashboard',
    iconSrc: 'assets/icon/shadowboxer/House.svg',
    type: 'internal-url',
    displaySideMenu: true,
  },
  {
    title: 'Bills',
    url: '/bills-tab',
    iconSrc: 'assets/icon/shadowboxer/Receipt.svg',
    type: 'internal-url',
    displaySideMenu: true,
  },
 { 
  title: 'Rewards',
  url: '/rewards',
  iconSrc: 'assets/icon/shadowboxer/CrownSimple.svg',
  type: 'internal-url',
  displaySideMenu: true,
},
{
title: 'Help',
url: ROUTE_HELP,
iconSrc: 'assets/icon/shadowboxer/Question.svg',
type: 'internal-url',
displaySideMenu: true,
},

];


export enum ADD_BILL_KEYS {
  PHOTO_BILL = 'PHOTO_BILL',
  PDF_BILL = 'PDF_BILL',
  EMAIL_BILL = 'EMAIL_BILL',
  CONNECT_AC = 'CONNECT_AC',
}

export const ADD_BILL_MENU = [
  {
    title: 'Upload or take photo',
    icon: 'scan-outline',
    key: ADD_BILL_KEYS.PHOTO_BILL,
  },
  {
    title: 'Upload file',
    icon: 'cloud-upload-outline',
    key: ADD_BILL_KEYS.PDF_BILL,
  },
  {
    title: 'Send via email',
    icon: 'mail-outline',
    key: ADD_BILL_KEYS.EMAIL_BILL,
  },
  {
    title: 'Connect email with provider',
    icon: 'link-outline',
    key: ADD_BILL_KEYS.CONNECT_AC,
  },
];
// export enum SOCIAL_LOGIN_KEYS {
//   GOOGLE = 'GOOGLE',
//   FACEBOOK = 'FACEBOOK',
//   APPLE = 'APPLE',
// }

export const SOCIAL_LOGIN_OPTS = [
  {
    title: 'Sign in with Google',
    icon: 'assets/icon/social/google.svg',
    key: 'GOOGLE',
  },
  {
    title: 'Sign in with Facebook',
    icon: 'assets/icon/social/facebook.svg',
    key: 'FACEBOOK',
  },
  {
    title: 'Sign in with Apple',
    icon: 'assets/icon/social/apple.svg',
    key: 'APPLE',
  },
];

export const SOCIAL_LOGIN_OPTS2 = {
  google: {
    title: 'Sign in with Google',
    icon: 'assets/icon/social/google.svg',
  },
  facebook: {
    title: 'Sign in with Facebook',
    icon: 'assets/icon/social/facebook.svg',
  },
  apple: {
    title: 'Sign in with Apple',
    icon: 'assets/icon/social/apple.svg',
  },
};
