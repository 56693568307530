import { Component } from '@angular/core';
import { stepNumber } from '@1bill-app/interfaces/photo-bill';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-first-time-user-animation-popover',
  templateUrl: './first-time-user-animation-popover.component.html',
  styleUrls: ['./first-time-user-animation-popover.component.scss'],
})
export class FirstTimeUserAnimationPopoverComponent {
  constructor(private popoverController: PopoverController) {
    this.stepNum = stepNumber;
  }

  stepOne: string =
    ' Place your bill on a flat dark-colored surface in an area with plenty of lighting. ';
  stepTwo: string = 'Take a clear photo of your bill using your mobile device';
  stepThree: string = 'Check your photo and ensure it is in focus and the text is clear';
  imgURL: string = 'assets/icon/v3/camera-focus.png';

  stepNum: typeof stepNumber;
  //starts at step 1 instruction
  switchValue: string = stepNumber.STEP_ONE;
  errorMessage: string = 'ERROR';

  changeStep2() {
    this.switchValue = stepNumber.STEP_TWO;
    setTimeout(() => {
      this.stepTwo = 'Ensure all the four edges of the bill are inside the photo';
    }, 3000);
    setTimeout(() => {
      this.stepTwo = 'If possible, turn on your camera flash for better results';
    }, 6500);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus-green.png';
    }, 3000);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus.png';
    }, 3500);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus-green.png';
    }, 4000);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus.png';
    }, 4500);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus-green.png';
    }, 5000);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus.png';
    }, 5500);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus-green.png';
    }, 6000);

    setTimeout(() => {
      this.imgURL = 'assets/icon/v3/camera-focus.png';
    }, 6500);
  }

  changeStep3() {
    this.switchValue = stepNumber.STEP_THREE;

    setTimeout(() => {
      this.stepThree = 'Otherwise retake the photo';
    }, 3000);
  }

  dismiss(confirmed = false) {
    this.popoverController.dismiss(null, confirmed ? 'confirm' : 'cancel');
  }
}
