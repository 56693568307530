import { environment } from '@1bill-app/env';
import { objectKeysToSnakeCase } from '@1bill-app/helpers/object.helper';
import { CreditCheckResp, DoCreditCheck } from '@1bill-app/interfaces/one-bill-service-b';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { NGXLogger } from 'ngx-logger';
import { stringify } from 'querystring';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppsflyerService } from '../analytics/appsflyer.service';
import {
  ActiveMeters,
  BetterDealProduct,
  BetterDealProductDetails,
  BetterDealProductPayload,
  CncBusinessRule,
  CreateCustomerAndSalePayload,
  FuelTypeDistributor,
  MsatsDiscoveryResponse,
} from '../better-deal/better-deal.type';
import { BillCategoryKey, SwitchErrorCode } from '../bill/types';
import { CachingService } from '../caching/caching.service';
import { formatMobileNumber } from '../custom-functions/custom-functions';
import { UserJobInput } from '../email-scanner-jobs/email-scanner-jobs.service';
import { EmailScannerJob } from '../email-scanner-jobs/email-scanner-jobs.store';
import { EmailScannerBase } from '../email-scanner/email-scanner.store';
import { FaqResponse } from '../faq/faq.type';
import { GoogleAddressDetails } from '../google/google-address.service';
import { OnebillEvent } from '../home/home.type';
import { InterceptorSkipHeader } from '../interceptors/enum';
import { DummyOffer, OfferResponse, RedeemedOffer } from '../offer/offer.type';
import { PaymentCard } from '../payment/payment-card.service';
import { RewardResponse } from '../reward/reward.type';
import { Settings, SettingsOptions } from '../settings/settings.store';
import { StatusResponse } from '../status/status.store';
import {
  ViewMultiUserAccessType,
  UserAccountTerminationStatus,
  UserAccountData,
} from '../user/user.types';
import {
  CashChartResponse,
  ExpensesScreenResponse,
  CreateManualYodleeAccount,
  EditManualYodleeAccount,
  YodleeAccessToken,
  AccountHistoryItem,
  YodleeWealthInfoGroup,
} from '../yodlee/yodlee.types';
import { CreaterCustomerData, SwitchBillDetails } from './api-types';
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  svcApiUrlA: string;
  svcApiUrlB: string;
  svcApiUrlC: string;
  notifyApiUrl: string;
  imageApiUrl: string;
  svcEmlUrl: string;
  payApiUrl: string;
  creditHistory$;
  cncApiUrl: string;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private cachingService: CachingService,
    private appsflyerSvc: AppsflyerService,
  ) {
    this.svcApiUrlA = environment.svcApiUrlA;
    this.svcApiUrlB = environment.svcApiUrlB;
    this.svcApiUrlC = environment.svcApiUrlC;
    this.notifyApiUrl = environment.notifyApiUrl;
    this.imageApiUrl = environment.imageApiUrl;
    this.payApiUrl = environment.payApiUrl;
    this.svcEmlUrl = environment.svcEmlUrl;
    this.cncApiUrl = environment.cncApiUrl;
  }

  getMsatsDiscovery(addressJson: GoogleAddressDetails) {
    return this.http
      .post<{ results: MsatsDiscoveryResponse[] }>(
        this.cncApiUrl + 'msats/discovery',
        addressJson,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getActiveMeters(nmi: string, checksum: string) {
    return this.http
      .get<ActiveMeters[]>(this.cncApiUrl + 'metadata/activeMeters', {
        params: {
          nmi,
          checksum,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getBetterDealProducts(data: BetterDealProductPayload) {
    return this.http.post<BetterDealProduct[]>(this.cncApiUrl + 'products', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getBetterDealProductDetails(id: number, payload: BetterDealProductPayload) {
    return this.http
      .post<BetterDealProductDetails>(this.cncApiUrl + 'products/' + id, payload)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getReferenceData(type: string) {
    //Return a list of reference data based on a given type
    //Valid value: concession, streettype, title or lifesupportequipment
    return this.http
      .get<{ id: string; name: string }[]>(this.cncApiUrl + 'metadata/referenceData', {
        params: {
          type,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getBusinessRules(data: any) {
    //Return a list of business rules for better deal sign up page
    //e.g. Some retailers may ask for additional conditions including solar, concession, connection...

    return this.http
      .get<CncBusinessRule[]>(this.cncApiUrl + 'metadata/rules', { params: { ...data } })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  createCncCustomerAndSale(payload: CreateCustomerAndSalePayload) {
    //Create customer and sale
    return this.http
      .post<{ customerId: string; saleId: string }>(this.cncApiUrl + 'journey/signup', payload)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getFuelTypeDistributor(postcode: string, suburb: string) {
    return this.http
      .post<{ distributors: FuelTypeDistributor[] }>(this.cncApiUrl + 'metadata/fuelType', {
        postcode,
        suburb,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getSettings() {
    return this.http.get<Settings>(this.svcApiUrlA + 'accountSettings').pipe(
      map((res) => {
        res ? (res.mobileNumber = res?.mobileNumber || '') : '';
        // to resolve: patchValue null in form causes optional field
        // to make invalid if not ""
        return res;
      }),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  putSettings(data: SettingsOptions) {
    return this.http
      .put<{ success: boolean; message: string }>(this.svcApiUrlA + 'accountSettings', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  emailScannerJobs() {
    return this.http
      .get<{
        success: boolean;
        data: EmailScannerJob[];
      }>(this.svcApiUrlC + 'emailScannerJobs/list')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateEmailScannerJobs(jobs: { jobId: number; approve: 1 | 0 }[]) {
    return this.http
      .post<{ success: boolean }>(this.svcApiUrlC + 'emailScannerJobs/update', jobs)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateJobMetasWithUserInput(jobs: UserJobInput[]) {
    return this.http.patch(this.svcApiUrlA + 'jobs/update', jobs).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  pinCheck(data: { pin: string }) {
    return this.http.post<{ success: boolean }>(this.svcApiUrlC + 'pin/check', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  pinStore(data: { pin: string }) {
    return this.http.post<{ success: boolean }>(this.svcApiUrlC + 'pin/store', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  pinClear(data?: { pin: null }) {
    return this.http.post<{ success: boolean }>(this.svcApiUrlC + 'pin/clear', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  appVersion() {
    const insertHeaders = new HttpHeaders().set(
      InterceptorSkipHeader.Authorization,
      InterceptorSkipHeader.Authorization,
    );
    return this.http
      .get<{
        success: boolean;
        version: {
          ios: string;
          android: string;
          web: string;
          forceUpdateApp?: boolean;
        };
      }>(this.svcApiUrlC + 'appVersion', { headers: insertHeaders })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  initEmailScannerConfig(data: { username: string; password: string; emailProvider: string }) {
    return this.http
      .post<{ success: boolean; message: string }>(this.svcApiUrlA + 'emailScanner/init', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  listEmailScannerConfig() {
    return this.http
      .get<{
        success: boolean;
        data: Pick<
          EmailScannerBase,
          'username' | 'emailProvider' | 'extCredId' | 'isValid' | 'oauthEnabled'
        >[];
        message: string;
      }>(this.svcApiUrlA + 'emailScanner/list')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateEmailScannerConfig(data: EmailScannerBase) {
    return this.http
      .post<{ success: boolean; message: string }>(
        this.svcApiUrlA + 'emailScanner/update',
        data,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  removeEmailScannerConfig(data: { extCredId: string }) {
    return this.http
      .post<{ success: boolean; message: string }>(
        this.svcApiUrlA + 'emailScanner/delete',
        data,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getNewTokenUrlGmail() {
    return this.http
      .get<{ success: boolean; url: string }>(this.svcEmlUrl + 'oauth/google/oauth2signin')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  creditCheck(data: DoCreditCheck) {
    this.logger.debug('api: ', data);

    if (data && data.mobileNumber) {
      data.mobileNumber = formatMobileNumber(data.mobileNumber);
    }
    return this.http.post<CreditCheckResp>(this.svcApiUrlB + 'creditCheck', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getUploadUrl(fileName: string, mime: string, type: string) {
    return this.http
      .post<{ url: string; s3Url: string; s3Key: string }>(this.svcApiUrlA + 'uploadUrl', {
        fileName,
        mime,
        type,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  //s3-key only mode
  getUploadUrlByProduct(fileName: string, mime: string, type: string, product: string) {
    return this.http
      .post<{ url: string; s3Url: string; s3Key: string }>(
        this.svcApiUrlA + 'uploadUrlByProduct',
        {
          fileName,
          mime,
          type,
          product,
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  //s3-key only mode
  getDownloadUrl(opts: { s3Key?: string; mime?: string; s3KeyOnlyMode: boolean }) {
    const { s3Key, s3KeyOnlyMode } = opts;
    if (!s3Key) {
      throw Error('s3Key should be provided');
    }
    return this.http
      .post<{ url: string; key: string }>(this.svcApiUrlA + 'downloadUrl', {
        s3Key,
        s3KeyOnlyMode,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Sends image for enhancement.
   * @param data
   */
  enhanceImage(data) {
    const headers = new HttpHeaders().set(InterceptorSkipHeader.Authorization, '');
    return this.http
      .post<{ err: string; s3Key: string }>(this.imageApiUrl + 'api/imageOpt', data, {
        headers,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Adds bill to the queue in AWS SQS.
   * @param body
   */
  addBillToQueue(body) {
    return this.http
      .post<{ data: { jobId: string }; message: string }>(this.svcApiUrlA + 'doc/queue', body)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Process manually entered bills on `add-bill-manually` page
   * @param body
   */
  processManualBill(body) {
    return this.http
      .post<{ data: { jobId: string; parseJobResults: any }; message: string }>(
        this.svcApiUrlA + 'processManuallyEnteredBill',
        body,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  addPaymentCard(body: any) {
    return this.http.post<any>(this.payApiUrl + 'addPayCard', body).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  replacePaymentCard(newPaymentCard: PaymentCard, oldAccountPaymentId: number) {
    return this.http
      .put<any>(this.payApiUrl + 'replacePayCard', { newPaymentCard, oldAccountPaymentId })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getPaymentCard() {
    return this.http
      .get<{ card: PaymentCard; success: boolean }>(this.payApiUrl + 'getPayCard')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateBillTag(data: { accountBillTypeId: any; tag: string }) {
    return this.http.post<any>(this.svcApiUrlA + 'bill/tag', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  paymentMarkPaid(data: { billPaymentId: number; date?: string }) {
    return this.http
      .post<{ success: boolean }>(this.svcApiUrlA + 'details/markPaid', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  paymentMarkUnpaid(data: { billPaymentId: number; date?: string }) {
    return this.http
      .post<{ success: boolean }>(this.svcApiUrlA + 'details/markUnpaid', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateDirectDebit(data: { billPaymentId: number; directDebitFlag: 1 | 0 }) {
    return this.http
      .post<{ billPaymentId: number; directDebitFlag: 1 | 0 }>(
        this.svcApiUrlA + 'details/updateDirectDebit',
        data,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getPaymentFileBlobUri(paymentId: number, type: string) {
    return this.http
      .get(this.svcApiUrlA + 'doc/file/' + paymentId, {
        params: {
          type,
          response: 'json',
          buffer: 'false', // for api legacy support
        },
      })
      .pipe(
        map((res: any) => {
          if (res.buffer == false) {
            return res;
          }
          const fileBody = res.Body;
          const blob = new Blob([new Uint8Array(fileBody.data, 0, res.ContentLength)], {
            type: res.ContentType,
          });
          return URL.createObjectURL(blob);
        }),
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  setDefaultCard(card: PaymentCard) {
    return this.http
      .post(this.payApiUrl + 'setDefaultPayCard', {
        accountPaymentId: card.accountPaymentId,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  setCardNickname(accountPaymentId: number, newNickname: string) {
    return this.http
      .patch(this.payApiUrl + 'cardNickname', {
        accountPaymentId,
        newNickname,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  removeCard(card: PaymentCard) {
    return this.http
      .post(this.payApiUrl + 'removePayCard', {
        accountPaymentId: card.accountPaymentId,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getStatus() {
    return this.http.get<StatusResponse>(this.svcApiUrlA + 'getStatus').pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  registerDevice(data) {
    const body = objectKeysToSnakeCase(data);
    return this.http.post<any>(this.notifyApiUrl + 'registerDevice', body).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  /**
   * Creates Customer for CC CRM
   * @param cusData
   */
  switchService(userInfo: CreaterCustomerData, billInfo: SwitchBillDetails) {
    return this.http
      .post<{
        success: boolean;
        userCanSwitch: boolean;
        message: string;
        data: {
          uniqueId: string;
          Message: string;
        };
        code?: SwitchErrorCode;
      }>(this.svcApiUrlA + 'switchService', { userInfo, billInfo })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  checkIfUserCanSwitch(data: {
    switchAddress: string;
    product: BillCategoryKey;
    billTypeId: number;
  }) {
    return this.http
      .post<{
        success: boolean;
        userCanSwitch: boolean;
        message: string;
        code?: SwitchErrorCode;
      }>(this.svcApiUrlA + 'checkIfUserCanSwitch', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Deletes bill
   */
  deleteBill(billPaymentId) {
    return this.http
      .delete<{ success: boolean }>(this.svcApiUrlB + `deleteBill/${billPaymentId}`)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Deletes job
   */
  deleteFailedJob(jobId) {
    return this.http
      .delete<{ success: boolean; isLocked: boolean }>(
        this.svcApiUrlB + `deleteFailedJob/${jobId}`,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Get user rewards
   */
  getRewards() {
    return this.http.get<RewardResponse>(this.svcApiUrlB + 'getRewards').pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  /**
   * Get user points balance - same as getRewards() API, but only fetches the points so it's not as resource expensive
   * for operations that only require fetching updated points.
   */
  getUserPointsBalance() {
    return this.http.get<Partial<RewardResponse>>(this.svcApiUrlB + 'getRewards/points').pipe(
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  /**
   * Mark reward as displayed
   */
  markRewardDisplayed(rewardId: number) {
    return this.http
      .get<{ success: boolean }>(this.svcApiUrlB + 'markRewardDisplayed/' + rewardId)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Mark reward as completed
   */
  markRewardCompleted(rewardId: number) {
    return this.http
      .get<{ success: boolean }>(this.svcApiUrlB + 'markRewardCompleted/' + rewardId)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /**
   * Pays the bill right now. - billPaymentIds
   * Backwards compatibility API previously: payBillNow
   */
  payBillNow(data: { billPaymentIds: number[]; accountPaymentId: number }) {
    return this.http
      .post<{
        billPaymentInfo?: {
          success: boolean;
          status: string;
          billPaymentId?: number;
          code?: string;
          error?: string;
          firstMonthPaymentLimit?: string;
        }[];
        success: boolean;
      }>(this.payApiUrl + 'payBundleNow', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  latCreateOnlineBundlePurchase(data: { billPaymentIds: number[]; isSourceNative: boolean }) {
    return this.http
      .post<{
        success: boolean;
        data: {
          paymentUrl: string;
        };
      }>(this.payApiUrl + 'lat/sale/onlineBundle', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateLatPaySaleCallback(data: {
    token: string;
    reference: string;
    message: string;
    result: string;
    signature: string;
  }) {
    return this.http
      .post<{
        success: boolean;
      }>(this.payApiUrl + 'lat/sale/online/callbackBundle', { ...data, ...{ source: 'app' } })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  checkIfMobileNumberIsUnique(data: { mobileNumber: string }) {
    return this.http
      .post<{
        success: boolean;
        mobileIsUnique: boolean;
        mobileIsBlacklisted: boolean;
      }>(this.svcApiUrlA + 'checkIfMobileNumberIsUnique', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }
  verifyMobileNumber(data: { mobileNumber: string; mobileVerified: boolean }) {
    return this.http
      .post<{
        success: boolean;
      }>(this.svcApiUrlA + 'verifyMobileNumber', data)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  switchProgressCallBack(data: SwitchProcessCallbackResponse) {
    // appsflyer
    this.appsflyerSvc.completedSwitch();
    return this.http
      .post<{ success: boolean; message: string }>(
        this.svcApiUrlB + 'switchProgressCallBack',
        { ...data, ...{ source: 'app' } },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  /** Native app switch API call */
  switchProgressCallBackApp(data: any) {
    // appsflyer
    this.appsflyerSvc.completedSwitch();
    return this.http
      .post<{ success: boolean; message: string }>(
        this.svcApiUrlB + 'switchProgressCallBackApp',
        { ...data, source: 'app' },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  faq() {
    return this.http.get<FaqResponse>(this.svcApiUrlB + 'faq').pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getOffers() {
    return this.http.get<OfferResponse>(this.svcApiUrlB + 'getOffers').pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getOffersV2(forceRefresh = false) {
    return this.getData<OfferResponse>(this.svcApiUrlB + 'getOffersV2', forceRefresh).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getDummyOffers() {
    const insertHeaders = new HttpHeaders().set(
      InterceptorSkipHeader.Authorization,
      InterceptorSkipHeader.Authorization,
    );
    return this.http
      .get<DummyOffer[]>(this.svcApiUrlB + 'dummyOffers', {
        headers: insertHeaders,
      })
      .pipe(
        catchError((error) => {
          this.handleError(error);
        }),
      );
  }

  getRedeemedOffers(redeemId?: number): Observable<RedeemedOffer | RedeemedOffer[]> {
    let url = this.svcApiUrlB + 'getRedeemedOffers';
    if (redeemId) url = url + '/' + redeemId;
    return this.http.get<any>(url).pipe(
      map((result) => result?.data),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  pointsLog() {
    return this.http
      .get<{ success: boolean; data: any[] }>(this.svcApiUrlB + 'pointsLog')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  // loginOffer(): Observable<MBALoginResult> {
  //   return this.http
  //     .post<any>(this.svcApiUrlB + 'loginOffer', {})
  //     .pipe(map((result) => result?.data));
  // }

  redeemOffer(offerId: number) {
    return this.http
      .post<{ success: boolean; data: any; code?: string; message: string }>(
        this.svcApiUrlB + 'redeemOffer',
        {
          offerId: offerId,
          newVersion: true,
        },
      )
      .pipe(
        // map((result) => result?.data),
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getYodleeAccessToken() {
    return this.http.post<YodleeAccessToken>(this.svcApiUrlB + 'yodleeAccessToken', {});
  }

  getYodleeAccountInformation(accessToken: string) {
    return this.http
      .get<any>(this.svcApiUrlB + 'getYodleeAccountInformation', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getDashboardWealthData(accessToken: string) {
    return this.http
      .get<any>(this.svcApiUrlB + 'getWealthDashboard', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getCashScreenChartData(
    accessToken: string,
    params: {
      toDate: string;
      interval: 'M' | 'W' | 'Y';
      accountType: string;
    },
  ) {
    return this.http
      .get<{ success: boolean; chartData: CashChartResponse }>(
        this.svcApiUrlB + 'cashScreenData',
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
          params,
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getAccountViewData(
    accessToken: string,
    params: {
      toDate: string;
      interval: 'M' | 'W' | 'Y';
      accountId: string;
      accountType: string;
    },
  ) {
    return this.http
      .get<{
        success: boolean;
        cashChartData: CashChartResponse;
        accountHistoricalData: AccountHistoryItem;
      }>(this.svcApiUrlB + 'accountViewData', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
        params,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getExpensesScreenData(
    accessToken: string,
    params: {
      highLevelCategoryId: string;
      toDate: string;
      interval: 'M' | 'W' | 'Y';
    },
  ) {
    return this.http
      .get<{ success: boolean; expensesScreenData: ExpensesScreenResponse }>(
        this.svcApiUrlB + 'expensesScreen',
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
          params,
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getNetWealthData(
    accessToken: string,
    params: {
      currentDurationDate?: string;
      interval?: 'M' | 'W';
    },
  ) {
    return this.http
      .get<any>(this.svcApiUrlB + 'getNetWealthData', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
        params,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getYodleeTransactions(accessToken: string) {
    return this.http
      .get<{ success: boolean; data: any[] }>(this.svcApiUrlB + 'getYodleeBillTransactions', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getYodleeWealthInformation(accessToken: string) {
    return this.http
      .get<{
        success: boolean;
        data: { asset: YodleeWealthInfoGroup[]; liability: YodleeWealthInfoGroup[] };
      }>(this.svcApiUrlB + 'yodleeWealthCategoryInformation', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateYodleeAccountInformation(accId: number, show: boolean, accessToken: string) {
    return this.http
      .post<any>(
        this.svcApiUrlB + 'updateYodleeAccount',
        {
          accountId: accId,
          showOnDashboard: show,
        },
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getAccountHistoricalBalances(accountId: string, accessToken: string) {
    return this.http
      .get<any>(this.svcApiUrlB + `getAccountHistoricalBalances?accountId=${accountId}`, {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getMultipleAccountHistoricalBalances(accessToken: string) {
    return this.http
      .get<any>(this.svcApiUrlB + `getAccountsHistoricalBalances`, {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getTransactionCategoryList(accessToken: string) {
    return this.http
      .get<any>(this.svcApiUrlB + 'yodleeTransactionCategories', {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  createManualYodleeAccount(data: CreateManualYodleeAccount, accessToken: string) {
    return this.http
      .post<any>(this.svcApiUrlB + 'createManualYodleeAccount', data, {
        headers: {
          'Yodlee-Access-Token': accessToken,
        },
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  editManualYodleeAccount(
    data: EditManualYodleeAccount,
    yodleeAccountId: number,
    accessToken: string,
  ) {
    return this.http
      .put<any>(
        this.svcApiUrlB + 'editManualYodleeAccount',
        { ...data, yodleeAccountId },
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  updateYodleeTransactionInformation(
    transactionId: number,
    data: { ignore?: boolean; added?: boolean },
    accountType: string,
    accessToken: string,
  ) {
    return this.http
      .post<any>(
        this.svcApiUrlB + 'updateYodleeTransaction',
        {
          transactionId: transactionId,
          accountType: accountType,
          ...data,
        },
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  verifyYodleeAccountsBalance(providerAccountId: number, accessToken: string) {
    return this.http
      .post<any>(
        this.svcApiUrlB + 'verifyYodleeAccountsBalance',
        {
          providerAccountId,
        },
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  unlinkYodleeAccount(accId: number, accessToken: string) {
    return this.http
      .post<any>(
        this.svcApiUrlB + 'unlinkYodleeAccount',
        {
          accountId: accId,
        },
        {
          headers: {
            'Yodlee-Access-Token': accessToken,
          },
        },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  parseCard(body) {
    return this.http.post<string>(this.svcApiUrlA + 'parseCard', body).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  accountTerminationStatus() {
    return this.http
      .get<{ success: boolean; data: any }>(this.svcApiUrlC + 'accountTermination/status')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  requestAccountTermination(terminationReason?: string) {
    return this.http
      .post<{
        success: boolean;
        data: {
          active: boolean;
          accountId: number;
          data: {
            message: string;
            terminationReason?: string;
          };
          createdAt: string;
          deleteDate: string;
        };
        message: string;
      }>(this.svcApiUrlC + 'accountTermination', { source: 'app', terminationReason })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getNotifications() {
    return this.http
      .get<{
        success: boolean;
        data: any[];
        hasBillTransaction: boolean;
      }>(this.notifyApiUrl + 'getNotifications')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  dismissNotification(id: string) {
    return this.http.post<any>(this.notifyApiUrl + 'dismissNotification', { id }).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  dismissAllNotifications() {
    return this.http.delete(this.notifyApiUrl + 'dismissAllNotifications').pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  markNotificationsRead() {
    return this.http.post<any>(this.notifyApiUrl + 'markNotificationsRead', {}).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  cancelAccountTermination() {
    return this.http
      .post<{ success: boolean; message: string }>(
        this.svcApiUrlC + 'accountTermination/cancel',
        { source: 'app' },
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  linkBills(billPaymentIds: number[], accountPaymentId: number) {
    return this.http
      .patch<void>(`${this.payApiUrl}linkMethod`, {
        billPaymentIds,
        accountPaymentId,
      })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  unlinkBill(billPaymentId: number) {
    return this.http
      .delete<void>(`${this.payApiUrl}linkMethod?billPaymentId=${billPaymentId}`)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  verifyBillsStatus(items: any) {
    return this.http.post<void>(`${this.svcApiUrlC}verifyBillsStatus`, {
      items,
    });
  }

  unsubscribeUserEmail(emailToken: string) {
    return this.http
      .patch<any>(`${this.notifyApiUrl}emailSubscription`, { token: emailToken })
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getUser() {
    return this.http
      .get<{ success: boolean; data: UserAccountData }>(this.svcApiUrlA + 'user')
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getUserRegistrationComplete() {
    return this.http
      .get<{ userCreated: boolean; userBlacklisted: boolean }>(this.svcApiUrlA + 'userRegistrationComplete')
      .toPromise();
  }

  triggerUserLogin() {
    return this.http.patch<number>(this.svcApiUrlA + 'login', { type: 'trigger' }).pipe(
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  getUserLoginCount() {
    return this.http.get<number>(`${this.svcApiUrlA}login`).pipe(
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  getAccountTerminationStatusFromDatabase() {
    return this.http.get<any>(`${this.svcApiUrlC}accountTerminationStatusDb`).pipe(
      map((result) => result.data as UserAccountTerminationStatus),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  get1bEvents() {
    return this.http.get<any>(`${this.svcApiUrlC}events`).pipe(
      map(
        (result) =>
          result as { success: boolean; events: OnebillEvent[]; switchRewardPoints: number },
      ),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getBillPrediction() {
    return this.http.get<any>(`${this.svcApiUrlC}billPredict`).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  updateUserSalary(salaryAmount: number, interval: 'week' | 'fortnight' | 'month' | 'year') {
    return this.http
      .put<any>(`${this.svcApiUrlB}userSalary`, {
        salaryAmount,
        interval,
      })
      .pipe(
        catchError((error) => {
          this.handleError(error);
        }),
      );
  }

  deleteUserSalary() {
    return this.http.delete<any>(`${this.svcApiUrlB}userSalary`).pipe(
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  updateMUAReqAndRevokedStatus(
    actionPerformed:
      | 'APPROVED_REQUEST'
      | 'ACCEPTED_INVITATION'
      | 'DENIED_REQUEST'
      | 'REJECTED_INVITATION'
      | 'REVOKED_ACCESS'
      | 'REVOKED_INVITATION'
      | 'REVOKED_REQUEST',
    mua: ViewMultiUserAccessType,
  ) {
    return this.http
      .put<any>(`${this.svcApiUrlC}updateMUAReqAndRevokedStatus`, {
        actionPerformed,
        mua,
      })
      .pipe(
        catchError((error) => {
          this.handleError(error);
        }),
      );
  }

  sendUserAccessRequest(reqBody: {
    receiverEmail: string;
    accessLevel: 'FULL' | 'VIEWER' | 'ADD_BILL';
    requestType: 'INVITATION' | 'REQUEST';
  }) {
    return this.http
      .post<{ success: boolean }>(this.svcApiUrlC + 'sendUserAccessRequest', reqBody)
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  attemptAccessUser(data: { mua: ViewMultiUserAccessType }) {
    return this.http
      .post<{ success: boolean; isValid: boolean }>(
        this.svcApiUrlC + 'attemptAccessAsUser',
        data,
      )
      .pipe(
        catchError((err) => {
          this.handleError(err);
        }),
      );
  }

  getMultiUserAccesses() {
    return this.http.get<any>(`${this.svcApiUrlC}getMultiUserAccesses`).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  getAvailablePromoCodes() {
    return this.http.get<any>(`${this.svcApiUrlB}promoCodes/available`).pipe(
      map((response) => {
        if (response.success) {
          return response.availablePromoCodes;
        }
        this.logger.error('Error in getAvailablePromoCodes(): returned success = false');
        return [];
      }),
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  getRedeemedPromoCodes() {
    return this.http.get<any>(`${this.svcApiUrlB}promoCodes/redeemed`).pipe(
      map((response) => {
        if (response.success) {
          return response.redeemedPromoCodes;
        }
        throw Error('Could not get redeemed promo codes - API success is false');
      }),
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  redeemPromoCode(pointAmount: number) {
    return this.http.post<any>(`${this.svcApiUrlB}promoCodes/redeem`, { pointAmount }).pipe(
      map((response) => {
        if (response.success) {
          return response.redeemCode;
        }
        throw Error('Could not redeem code - API success is false');
      }),
      catchError((error) => {
        this.handleError(error);
      }),
    );
  }

  // HIDEREFERRALCODES - next 4 methods.
  // getReferralCode(): Observable<string> {
  //   return this.http
  //     .get<{ success: boolean; code: string }>(`${this.svcApiUrlC}referralCodes`)
  //     .pipe(
  //       map((response) => {
  //         if (response.success) {
  //           return response.code;
  //         }
  //         throw Error('Could not get referral code - API success is false');
  //       }),
  //       catchError((error) => {
  //         this.handleError(error);
  //       }),
  //     );
  // }

  // submitReferralCode(email: string, referralCode: string) {
  //   const insertHeaders = new HttpHeaders().set(InterceptorSkipHeader.Authorization, '');
  //   return this.http
  //     .post<{ success: boolean }>(
  //       `${this.svcApiUrlC}referralCodes`,
  //       { email, referralCode },
  //       { headers: insertHeaders },
  //     )
  //     .pipe(
  //       map((response) => {
  //         if (!response.success) {
  //           throw Error('Could not submit referral code - API success is false');
  //         }
  //       }),
  //       catchError((error) => {
  //         this.handleError(error);
  //       }),
  //     );
  // }

  // getSubmittedReferralCode() {
  //   return this.http
  //     .get<{ success: boolean; code: string }>(`${this.svcApiUrlC}referralCodes/submitted`)
  //     .pipe(
  //       map((response) => {
  //         if (!response.success) {
  //           throw Error('Could not get referral code - API success is false');
  //         }
  //         return response.code;
  //       }),
  //     );
  // }

  // getReferralCodeEligibility() {
  //   return this.http
  //     .get<{ success: boolean; verified: boolean }>(
  //       `${this.svcApiUrlC}referralCodes/eligibility`,
  //     )
  //     .pipe(
  //       map((response) => {
  //         if (!response.success) {
  //           throw Error(
  //             'Could not check for referral code eligibility - API success is false',
  //           );
  //         }
  //         return response.verified;
  //       }),
  //       catchError((error) => {
  //         this.handleError(error);
  //       }),
  //     );
  // }

  private callAndCache<T>(url: string): Observable<any> {
    return this.http.get<T>(url).pipe(
      switchMap((res) => {
        // Store our new data
        return from(this.cachingService.setCacheRequest(url, res)).pipe(map(() => res));
      }),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  /**
   * Get request with caching
   * @param url The url of the request
   * @param forceRefresh Whether or not to force refresh the API result
   * @returns
   */
  getData<T>(url: string, forceRefresh = false): Observable<any> {
    return from(Network.getStatus()).pipe(
      switchMap((networkStatus) => {
        // Handle offline case
        if (!networkStatus.connected) return from(this.cachingService.getCachedRequest(url));

        // Handle connected case
        if (forceRefresh) {
          // Make a new API call
          return this.callAndCache<T>(url);
        } else {
          // Check if we have cached data
          const storedValue = from(this.cachingService.getCachedRequest(url));
          return storedValue.pipe(
            switchMap((result) => {
              if (!result) {
                // Perform a new request since we have no data
                return this.callAndCache<T>(url);
              } else {
                // Return cached data
                return of(result);
              }
            }),
          );
        }
      }),
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  //get billpaymentId for usageAndMeter API
  UsageAndMeter(data: { billPaymentId: any; peakUsage: any; meterNumber: any }) {
    this.logger.log(
      'ID: ',
      data.billPaymentId,
      'Usage: ',
      data.peakUsage,
      'Meter Number: ',
      data.meterNumber,
    );
    return this.http.post<any>(this.svcApiUrlA + 'usageAndMeter', data).pipe(
      catchError((err) => {
        this.handleError(err);
      }),
    );
  }

  private handleError(err: any): never {
    this.logger.error(`API ERROR: `, err);
    throw err;
  }
}

export enum BillPaymentFailedErrorCodes {
  AC_DETAILS_MISSING = 'AC_DETAILS_MISSING',
  CARD_DETAILS_MISSING = 'CARD_DETAILS_MISSING',
  BILL_DETAILS_MISSING = 'BILL_DETAILS_MISSING',
  BILL_ID_MISSING = 'BILL_ID_MISSING',
  BILL_ALREADY_PAID = 'BILL_ALREADY_PAID',
  /** Indicates exceeding the payment limit for an account's first month. */
  FIRST_MONTH_PAYMENT_LIMIT_EXCEEDED = 'FIRST_MONTH_PAYMENT_LIMIT_EXCEEDED',
}
export interface SwitchProcessCallbackResponse {
  cncRefId: string; // Switch id to track switch progress
  billTypeId: number; // Unique bill is passed before
  callbackStatus: 'success' | 'successful' | 'failed';
  retailerName: string; // name of the retailer selected by the customer
  retailerLogo: string; // retailer's logo url
  planName: string; // name of the retailer plan selected by the customer
  address: string; // the supply address of the customer
}
