import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

/** 
 * Do not use this component on its own. It should only be used in one source, UIService,
 * so that code there can be used to determine whether there is already an instance of
 * this modal running to prevent duplicates.
*/
@Component({
  selector: 'app-action-denied-modal',
  templateUrl: './action-denied-modal.component.html',
  styleUrls: ['./action-denied-modal.component.scss'],
})
export class ActionDeniedModalComponent implements OnInit {
  @Input() text1: string;
  @Input() text2: string;
  @Input() buttonText: string;
  @Input() route: string;

  constructor(private modalController: ModalController, private router: Router) {}

  ngOnInit() {}

  close() {
    this.modalController.dismiss(null, 'close');
  }

  buttonClick() {
    if (this.route) {
      this.modalController.dismiss();
      this.router.navigate([this.route]);
    }
  }
}
