import { fromRatingNumber } from '@1bill-app/helpers/general.helper';
import { BillRating } from '@1bill-app/services/bill/types';
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-bill-rating',
  templateUrl: './bill-rating.component.html',
  styleUrls: ['./bill-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BillRatingComponent),
      multi: true,
    },
  ],
})
export class BillRatingComponent {
  constructor(private logger: NGXLogger) {}

  @Input() size: 'sm' | 'md' | 'lg' = 'lg';
  @Output() change: EventEmitter<number> = new EventEmitter<number>();
   
  _rating: number;
  billRatings = BillRating;

  get rating(): number {
    return this._rating;
  }

  set rating(ratingNumber: number) {
    const billRating = fromRatingNumber(ratingNumber);
    if (billRating !== this._rating) {
      this._rating = billRating;
      this.onChange(ratingNumber);
    }
  }

  onChange = (ratingNumber: number) => {};
  onTouched = () => {};

  writeValue(value: number): void {
    this._rating = fromRatingNumber(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  toggleRating(rating: BillRating) {
    this.rating = rating;
    this.change.emit(rating);
  }
}
