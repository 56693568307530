import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import _ from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { map } from 'rxjs/operators';
import { DASHBOARD_PREFERENCES } from 'src/app/data/dashboard-preference.data';
import cardList from '../../data/cards.data';
import { AccountPreference } from '../settings/settings.store';
import { HomeState, HomeStore } from './home.store';
import { CardDataType } from './home.type';

@Injectable({ providedIn: 'root' })
export class HomeQuery extends Query<HomeState> {
  constructor(protected store: HomeStore, private logger: NGXLogger) {
    super(store);
  }
  
  isLoading$ = this.selectLoading();
  featureFlags$ = this.select('featureFlags');
  creditCheckScore$ = this.select('creditCheck');
  isBillProgressInView$ = this.select((state) => state.ui?.isBillProgressInView);
  showBalance$ = this.select((state) => state.showBalance);
  events$ = this.select('events');
  preferences$ = this.select('preferences').pipe(
    map((prefs) => {
      if (!prefs) return _.cloneDeep(DASHBOARD_PREFERENCES);
      return this.checkAndAddNewItems(prefs);
    }),
  );

  checkAndAddNewItems(prefs: AccountPreference[]) {
    const everyExists = DASHBOARD_PREFERENCES?.every((dp) =>
      prefs?.find((p) => dp.itemName === p.itemName),
    );
    if (!everyExists) {
      const addedPrefs = DASHBOARD_PREFERENCES.filter(
        (dp) => !prefs?.find((p) => p.itemName === dp.itemName),
      );
      prefs.unshift(..._.cloneDeep(addedPrefs));
      prefs = prefs.map((p, i) => ({ ...p, order: i + 1 }));
    }
    return prefs;
  }
  getCardList() {
    return this.select('featureFlags').pipe(
      map((flags) => {
        let list = cardList;
        if (flags?.length) {
          // FEATURE_FLAG: DISABLE_CREDIT_SCORE
          if (flags.indexOf('DISABLE_CREDIT_SCORE') >= 0) {
            list = list.filter((card) => card.type !== CardDataType.CREDIT_SCORE_APPLY);
          }
          // FEATURE_FLAG: DISABLE_LINK_ACCOUNT
          if (flags.indexOf('DISABLE_LINK_ACCOUNT') >= 0) {
            list = list.filter((card) => card.type !== CardDataType.LINK_ACCOUNT);
          }
        }
        return list;
      }),
    );
  }
}
