import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
@Injectable({
  providedIn: 'root',
})
export class NumberFormatPipe implements PipeTransform {
  /**
   * Transforms numbers into a more readable or compact format.
   * @param number The number to be transformed and commafied.
   * @param minTransformDigits The minimum number of digits under which `number` will be transformed i.e. 3000 => 3k
   * @param decimalDigitsTransformed How many decimal digits will be displayed if transformed
   * @param decimalDigitsBelowMin How many decimal digits will be displayed if not transformed
   * @param absolute If `true`, returns the absolute value of the number input
   * @returns The number, with commas separating each three units, and transformed to appropriate symbols if conditions met
   */
  transform(
    number: number | string,
    minTransformDigits = 0,
    decimalDigitsTransformed = 2,
    decimalDigitsBelowMin = 2,
    absolute = false,
  ): string {
    if (number == 0) return '0';
    if (typeof number === 'string') {
      number = Number(number);
    }
    if (absolute) {
      number = Math.abs(number);
    }
    if (!number || isNaN(number)) return null;
    if (minTransformDigits && Math.abs(number) < Math.pow(10, minTransformDigits)) {
      return this.addNumberFormatCommas(number.toFixed(decimalDigitsBelowMin ?? 2));
    }
    const rounder = Math.pow(10, 1);
    let key = '';

    const powers = [
      { key: 'b', value: Math.pow(10, 9) },
      { key: 'm', value: Math.pow(10, 6) },
      { key: 'k', value: 1000 },
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = number / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (Math.abs(reduced) >= 1) {
        number = reduced;
        key = powers[i].key;
        break;
      }
    }

    return this.addNumberFormatCommas(number.toFixed(decimalDigitsTransformed ?? 2) + key);
  }

  /**
   * Adds comma values to numerical strings for improved readability.
   * @param value The number to add commas to
   * @returns A string of the same number with commas separating each three placement units
   */
  addNumberFormatCommas(value: number | string) {
    if (value === undefined || value === null)
      return 'Undefined or null value passed to NumberFormatPipe::addNumberFormatCommas().';
    const strValue = String(value);

    /**
     * Only applies to numbers formatted correctly, e.g. 100000 or 4934.64
     */
    const replaceableMatchRegex = /\d+/;
    let useValue = strValue.match(replaceableMatchRegex)[0];

    // The starting point: end of string if no decimal point; just before the decimal point if there is decimal point
    let currentTripleIndex = useValue.includes('.')
      ? useValue.indexOf('.') - 3
      : useValue.length - 3;

    // Count left three spaces each time and add a comma to separate each three digits for readability
    for (; currentTripleIndex > 0; currentTripleIndex -= 3) {
      useValue = `${useValue.substring(0, currentTripleIndex)},${useValue.substring(
        currentTripleIndex,
      )}`;
    }
    return strValue.replace(replaceableMatchRegex, useValue);
  }
}
