import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UploadState, UploadStore } from './upload.store';

@Injectable({ providedIn: 'root' })
export class UploadQuery extends Query<UploadState> {
  isLoading$ = this.selectLoading();

  constructor(protected store: UploadStore) {
    super(store);
  }
  get isUploading() {
    return this.getValue().isUploading;
  }
  get hasUploaded() {
    return this.getValue().hasUploaded;
  }
  get hasUploadErr() {
    return this.getValue().hasUploadErr;
  }
}
