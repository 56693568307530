import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewBillType, BillCategoryKey } from '@1bill-app/services/bill/types';
import { ActionSheetController, LoadingController, ModalController } from '@ionic/angular';
import { SwapBillModalComponent } from '../popover/swap-bill-modal/swap-bill-modal.component';
import { CategoryService } from '@1bill-app/services/category/category.service';
import { PaymentCardService } from '@1bill-app/services/payment/payment-card.service';

@Component({
  selector: 'app-linked-bill-item',
  templateUrl: './linked-bill-item.component.html',
  styleUrls: ['./linked-bill-item.component.scss'],
})
export class LinkedBillItemComponent implements OnInit {
  constructor(
    private modalController: ModalController,
    private actionSheetController: ActionSheetController,
    private loadingController: LoadingController,
    private paymentCardService: PaymentCardService,
    private categoryService: CategoryService,
  ) {}

  @Input() bill: ViewBillType;
  @Input('selectedId') selectedPaymentMethodId: number;
  @Output() swapped: EventEmitter<number> = new EventEmitter(false);
  @Output() unlinked: EventEmitter<number> = new EventEmitter(false);

  ngOnInit() {
    if (!this.bill) {
      console.error('LinkedBillItemComponent: No bill provided through Input() decorator.');
    }
  }

  getCategoryIconSource(categoryKey: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(categoryKey)?.icon;
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }

  async presentSwapModal(event) {
    const popover = await this.modalController.create({
      component: SwapBillModalComponent,
      componentProps: {
        bill: this.bill,
        selectedPaymentMethodId: this.selectedPaymentMethodId,
      },
      cssClass: 'swap-bill-modal',
    });
    await popover.present();
    popover.onWillDismiss().then((data) => {
      if (data.role === 'confirm') {
        this.swapped.emit(this.bill.billPaymentId);
      }
    })
  }

  async presentUnlinkPopover(event) {
    const actionSheet = await this.actionSheetController.create({
      buttons: [
        {
          text: 'Unlink bill',
          cssClass: ['action-sheet-button', 'action-sheet-confirm-button'],
          handler: () => {
            this.loadingController
              .create({ mode: 'md', message: 'Unlinking bill...' })
              .then((loader) => {
                loader.present();
                console.debug('Unlinking...');
                this.paymentCardService
                  .unlinkBillFromPaymentMethod(this.bill.billPaymentId)
                  .toPromise()
                  .then(() => {
                    console.debug('Unlink successful.');
                    // Successfully unlinked
                    this.unlinked.emit(this.bill.billPaymentId);
                  })
                  .catch((err) => {
                    console.error('Error with unlink bill API:', err);
                  })
                  .finally(() => {
                    loader.dismiss();
                  });
              });
          },
        },
        { text: 'Cancel', cssClass: 'action-sheet-button' },
      ],
      // component: PopoverUnlinkBillComponent,
    });
    await actionSheet.present();
  }
}
