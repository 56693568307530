import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { CognitoUser } from '@aws-amplify/auth';
import { AuthStore } from '@1bill-app/services/auth/state/auth.store';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { ROUTE_LOGIN } from '@1bill-app/constants';
import { AuthQuery } from '@1bill-app/services/auth/state/auth.query';

@Component({
  selector: 'app-require-new-password-modal',
  styleUrls: ['./require-new-password-modal.component.scss'],
  template: `
    <div class="static-btn">
      <ion-icon (click)="dismissModal()" name="close-outline"></ion-icon>
    </div>
    <div class="content">
      <div class="ion-padding-horizontal ion-text-center">
        <h1>You are required to update your password</h1>
      </div>
      <div class="validation-errors">
        <div class="error-message" *ngIf="serverValidationError">
          {{ serverValidationError }}
        </div>
      </div>
      <ion-input
        class="round"
        [ngClass]="pwdClass"
        [type]="passwordType || 'password'"
        #password
        data-cy="input-password"
        placeholder="Enter your new password"
        enterkeyhint="done"
        (change)="setPassword(password.value)"
        (keyup)="setPassword(password.value)"
        (keyup.enter)="onUpdate()"
      >
      </ion-input>
      <ion-button
        mode="ios"
        data-cy="btn-email-signup"
        class="btn-email ion-margin-top"
        color="primary"
        expand="block"
        data-cy="btn-signin"
        (click)="onUpdate()"
        [disabled]="isLoading$ | async"
      >
        <ng-container *ngIf="!(isLoading$ | async)">
          <ion-label>Update</ion-label>
        </ng-container>
        <ion-spinner *ngIf="isLoading$ | async" name="crescent"></ion-spinner>
      </ion-button>
    </div>
  `,
})
export class RequireNewPasswordModalComponent implements OnInit {
  @Input() email: string;
  @Input() user: CognitoUser;
  isLoading$ = this.authQuery.isLoading$;
  password: string;
  serverValidationError: string;
  public passwordType: string;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private authStore: AuthStore,
    private authQuery: AuthQuery,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {}
  setPassword(password: any) {
    this.password = password;
  }

  togglePasswordType() {
    this.passwordType = this.passwordType || 'password';
    this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    this.cdRef.detectChanges();
  }

  get pwdClass() {
    return this.serverValidationError ? 'error' : '';
  }
  dismissModal() {
    this.modalCtrl.dismiss();
  }
  async onUpdate() {
    try {
      const completed = await this.completeNewPassword(this.user, this.password);
      if (completed) {
        await this.modalCtrl.dismiss();
      }
    } catch (err) {
      this.showError(err);
    }
  }
  async showError(err: any) {
    if (!err) {
      return;
    }
    this.serverValidationError = err;
  }

  async completeNewPassword(user: CognitoUser, password: string): Promise<CognitoUser | any> {
    this.authStore.setLoading(true);
    return Auth.completeNewPassword(
      user,
      password,
      user['challengeParam']['requiredAttributes'],
    )
      .then(async (res) => {
        const toast = await this.toastCtrl.create({
          message: 'Please re-login using your updated credentials.',
          duration: 4000,
          color: 'success'
        });
        toast.present();
        this.router.navigate([ROUTE_LOGIN]);
        return res;
      })
      .catch(async (err) => {
        this.authStore.setError(err.message);
        this.serverValidationError = err.message;
      })
      .finally(() => {
        this.authStore.setLoading(false);
      });
  }
}
