

export enum UploadPhotoEventStatus {
  INIT = 'INIT',
  UPLOAD_STARTED = 'UPLOAD_STARTED',
  UPLOAD_PROGRESS = 'UPLOAD_PROGRESS',
  UPLOAD_COMPLETD = 'UPLOAD_COMPLETD',
  UPLOAD_UNHANDLED = 'UPLOAD_UNHANDLED',
  ENHANCE_SKIPPED = 'ENHANCE_SKIPPED',
  ENHANCE_PROGRESS = 'ENHANCE_PROGRESS',
  ENHANCE_SUCCESS = 'ENHANCE_SUCCESS',
  ENHANCE_FAILED = 'ENHANCE_FAILED',
  COMPLETE = 'COMPLETE'
}

export interface PhotoBillDataModel {
  id: string;
  file?: File;
  dataUrl?: string;
  url?: string;
  s3Url?: string;
  s3Key?: string; // s3-url-to-key
  status?: UploadPhotoEventStatus;
  loading?: boolean;
  progress?: number;
}

export enum stepNumber {

  STEP_ONE ="step-one",
  STEP_TWO ="step-two",
  STEP_THREE ="step-three"
  
  }
