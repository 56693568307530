import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-missing-information-modal',
  styleUrls: ['./missing-information-modal.component.scss'],
  template: `
  <div class="main-wrapper">
    <div class="header-wrapper">
      <div class="icon-wrapper">
        <!-- <ion-icon class="start-icon" name="chevron-back-outline" slot="start" color="primary" (click)="dismiss(false);">
        </ion-icon> -->
      </div>
      <ion-text><h4>Enter missing information</h4></ion-text>
      <div class="icon-wrapper">
        <ion-icon class="end-icon" name="close-outline" slot="end" (click)="dismiss(false);"></ion-icon>
      </div>
    </div>
    <div class="body-wrapper">
      <p>Your profile is incomplete. Please enter the missing information below to continue.</p>
      <div class="form-wrapper">
        <div class="form-item-wrapper">
          <ion-label>First name</ion-label>
          <ion-input [(ngModel)]="firstName" placeholder="Enter your first name"></ion-input>
        </div>
        <div class="form-item-wrapper">
          <ion-label>Last name</ion-label>
          <ion-input [(ngModel)]="lastName" placeholder="Enter your last name"></ion-input>
        </div>
      </div>
      <ion-button class="save-button" expand="block" (click)="dismiss(true);" [disabled]="!firstName || !lastName">Save</ion-button>
    </div>
  </div>
  `,
})
export class MissingInformationModal {
  @Input() firstName: string;
  @Input() lastName: string;

  constructor(private modalController: ModalController) {}

  dismiss(confirmed?: boolean) {
    if (confirmed) {
      this.modalController.dismiss({ firstName: this.firstName, lastName: this.lastName }, 'confirm');
    } else {
      this.modalController.dismiss(null, 'cancel');
    }
  }
}