import { CSS_COLOR_PRIMARY } from '@1bill-app/constants';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import { last, sum } from 'lodash';
import { NGXLogger } from 'ngx-logger';
import { CSS_COLOR_LIGHTERPRIMARY } from '@1bill-app/constants';
import { AuthQuery } from '@1bill-app/services/auth/state/auth.query';
import { NumberFormatPipe } from '@1bill-app/pipes/number-format.pipe';
import { CurrencyDrSignPipe } from '@1bill-app/pipes/currency-dr-sign.pipe';
import { YodleeQuery } from '@1bill-app/services/yodlee/yodlee.query';

const xAxisId = 'defaultXAxis';
const yAxisId = 'defaultYAxis';

interface SelectedPoint {
  index?: number;
  plotLineId?: string;
}

let selectedPoint: SelectedPoint = {};

HighchartsMore(Highcharts);
@Component({
  selector: 'app-wealth-chart',
  templateUrl: './wealth-chart.component.html',
  styleUrls: ['./wealth-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WealthChartComponent implements AfterViewInit, OnChanges {
  @ViewChild('netWealthChart') netWealthChart: any;
  @ViewChild('wealthChartWrapper') wealthChartWrapper: any;

  @Input() hasAny: boolean;
  @Input() set chartData(input) {
    this.loading = true;
    if (input?.length === 1) {
      input = [{ amount: 0, date: `${new Date().getFullYear() - 1}/12` }, ...input];
      this.onlyOneDataPoint = true;
    }
    this._chartData = input;
    // Initialise and refresh chart after data change
    this.initWealthChart();
    this.chart?.reflow();
  }

  loading = true;

  get chartData() {
    return this._chartData ?? [];
  }

  private _chartData: { amount: number; date: string }[];

  get chart(): Highcharts.Chart {
    return this.netWealthChart?.chart as Highcharts.Chart;
  }

  onlyOneDataPoint = false;

  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {};

  selectedPointMonth: string = 'Total';
  selectedPointAmount: string;

  get chartWrapperDiv() {
    return this.wealthChartWrapper?.nativeElement as HTMLDivElement;
  }

  /**
   * CSS padding-left and padding-right properties of the chart wrapper.
   */
  readonly CHART_SIDE_PADDING_PX = 14;

  /**
   * This is necessary as the Yodlee API does not seem to send data regarding loans on the current month. All other
   * assets and liabilities are returned by the Yodlee API. This will lead to inconsistent data, so a workaround is
   * to just use the difference between the total liability amount and total asset amount.
   */
  lastMonthAmount: number;

  /**
   * Width of the div in px that wraps around the chart. Necessary so that the chart itself doesn't overflow into nothing on sizes where it doesn't fit.
   * 378px appears to be the default width on desktop views. It will most likely be necessary to set to a smaller value on mobile screens.
   */
  get wrapperWidth() {
    return this.chartWrapperDiv?.clientWidth - 2 * this.CHART_SIDE_PADDING_PX || 378;
  }

  chartCallback: Highcharts.ChartCallbackFunction = function (chart): void {
    setTimeout(() => {
      try {
        if (chart) chart?.reflow();
      } catch (err) {
        console.warn('chartCallback error:', err);
      }
    }, 500);
  };

  constructor(
    private cdRef: ChangeDetectorRef,
    private logger: NGXLogger,
    private numberFormatPipe: NumberFormatPipe,
    private currencyDrSignPipe: CurrencyDrSignPipe,
    private yodleeQuery: YodleeQuery,
  ) {}

  ngAfterViewInit() {
    this.yodleeQuery.wealthCategories$.subscribe((categories) => {
      if (this.hasAny) {
        // Replace the last item of chartData with the amount of the liabilities (negative) plus assets.
        this.lastMonthAmount =
          sum(categories.liability?.map((liability) => liability.totalAmount)) +
          sum(categories.asset?.map((asset) => asset.totalAmount));
        const lastDate = last(this.chartData).date;
        const chartData = this.chartData.slice(0, this.chartData.length - 1);
        const replaceLastItem = { amount: this.lastMonthAmount, date: lastDate };
        this.chartData = [...chartData, replaceLastItem];
        // this.initWealthChart();
      }
    });
    this.initWealthChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const { currentValue, previousValue } = changes.value;
      if (currentValue !== previousValue) {
        this.initWealthChart();
        this.cdRef.detectChanges();
      }
    }
  }

  refreshChart() {
    this.chart?.reflow();
  }

  async initWealthChart() {
    const chartDataAmount = this.chartData?.map((data) => data?.amount);
    const chartDataLabels = this.chartData?.map((data) => data?.date);
    const onlyOneDataPoint = this.onlyOneDataPoint;
    const numberFormatPipe = this.numberFormatPipe;
    const currencyDrSignPipe = this.currencyDrSignPipe;
    // ERROR: Cannot assign to read only property '0' of object '[object Array]' if not cloned.
    const dataSortedByDates = [...this.chartData].sort((dataItem1, dataItem2) =>
      new Date(dataItem1.date).valueOf() - new Date(dataItem2.date).valueOf(),
    );
    const earliestChartItem = dataSortedByDates[0];
    const latestChartItem = dataSortedByDates[dataSortedByDates.length - 1];

    this.chartOptions = {
      chart: {
        renderTo: 'chart',
        type: 'line',
        styledMode: false,
        className: 'wealth-chart',
        height: 200,
        width: this.wrapperWidth,
        alignTicks: true,
        reflow: true,
      },

      title: {
        text: '',
      },

      legend: {
        enabled: false,
      },

      tooltip: {
        enabled: true,
        backgroundColor: '#FCFFC5',
        borderColor: 'black',
        borderRadius: 10,
        borderWidth: 3,
        formatter: function (tooltip) {
          return `${dayjs(chartDataLabels[this.x]).format(
            'MMMM YYYY',
          )}: $${currencyDrSignPipe.transform(
            numberFormatPipe.transform(chartDataAmount[this.x], Infinity, 2),
            'value',
          )}`;
        },
      },

      xAxis: {
        id: xAxisId,
        categories: [], // if null or undefined, the white lines pointing down for categories will be displayed
        labels: {
          formatter: function (label) {
            if (onlyOneDataPoint) {
              return label.isFirst ? '' : dayjs().format('YYYY');
            }
            return label.isFirst
              ? dayjs(earliestChartItem.date).format('YYYY')
              : label.isLast
              ? dayjs(latestChartItem.date).format('YYYY')
              : '';
          },
          style: {
            color: 'white',
          },
        },
      },

      yAxis: {
        id: yAxisId,
        title: {
          text: '',
          style: {
            color: '#FFFFFF',
          },
        },
        // Per dummy design, do not show y-axis labels if no data
        labels: {
          enabled: true,
        },
        gridLineDashStyle: 'Dash',
      },

      scrollbar: {
        enabled: true,
      },

      plotOptions: {
        column: {
          borderRadius: 2,
          color: 'white',
        },
      },

      credits: {
        enabled: false,
      },

      series: [
        {
          // This array must be cloned as items of data are mutated during the point click event.
          // The items are modified into `{ y: number; selected: boolean }` objects instead of `number`.
          // This causes an error in the tooltip formatter function after having selected a point.
          data: [...chartDataAmount],
          pointPadding: 0,
          type: 'line',
          // To make the plot points invisible, the series colour must have 0 opacity. Setting properties
          // via CSS by giving the series a `className` does not work.
          color: CSS_COLOR_LIGHTERPRIMARY,
          zoneAxis: 'x',
          allowPointSelect: true,
          point: {
            events: {
              click: (event) => {
                this.selectedPointAmount = String(event.point.y);
                this.selectedPointMonth = dayjs(this.chartData[event.point.x].date).format(
                  'MMMM',
                );

                const xAxis = this.chart.get(xAxisId) as Highcharts.Axis;

                // Remove existing selected plot line
                xAxis.removePlotLine(selectedPoint.plotLineId);

                // Only add if not last plot point
                const isLastPlotPointSelected = event.point.x === this.chartData.length - 1;
                if (!isLastPlotPointSelected) {
                  const plotLineId = `wealthChartSelect_${event.point.x}`;

                  selectedPoint.index = event.point.x;
                  selectedPoint.plotLineId = plotLineId;

                  xAxis.addPlotLine({
                    value: event.point.x,
                    dashStyle: 'Dash',
                    id: plotLineId,
                    zIndex: 4,
                    className: 'selected-plot-point-line',
                  });
                }

                // const yAxis = this.chart.get(yAxisId) as Highcharts.Axis;

                const zones: Highcharts.SeriesZonesOptionsObject[] = isLastPlotPointSelected
                  ? [{ value: Infinity, color: CSS_COLOR_LIGHTERPRIMARY }]
                  : [
                      { value: selectedPoint.index, color: CSS_COLOR_LIGHTERPRIMARY },
                      { value: Infinity, color: CSS_COLOR_PRIMARY },
                    ];

                // Updating zones allows for certain styling to be applied only to points in the specified range (all points after each `value`)
                this.chart.series[0].update({
                  type: 'line',
                  zones,
                });

                this.cdRef.detectChanges();
              },
            },
          },
        },
      ],
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 200,
            },
          },
        ],
      },
    };

    const lastElement = last(this.chartData);
    if (lastElement) {
      this.selectedPointAmount = String(lastElement.amount);
      this.selectedPointMonth = dayjs(lastElement.date).format('MMMM');
    }

    this.loading = false;
    this.chart?.redraw();
    this.cdRef.detectChanges();
  }

  setLoading(loading = true) {
    this.loading = loading;
  }
}
