import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-switch-cc-iframe-modal',
  styleUrls: ['./switch-cc-iframe-modal.component.scss'],
  template: `
    <ion-header class="ob-modal-header">
      <app-header-logo [title]="title" [showRightActionBtns]="false">
        <ion-buttons slot="end" class="title-btn" (click)="closeModal()">
          <ion-icon name="close" color="primary"></ion-icon>
        </ion-buttons>
      </app-header-logo>
    </ion-header>

    <ion-content>
      <iframe [src]="iframeUrl" height="100%" width="100%" style="border: 0;"></iframe>
    </ion-content>
  `,
})
export class SwitchCCIframeModalComponent implements OnInit {
  iframeUrl: SafeUrl;
  // @Input() crmAccountId;
  // @Input() categoryKey: 'E' | 'G' | 'I';
  // @Input() accountBillTypeId: number;
  // @Input() billPaymentId: number;
  // @Input() billData: ViewBillType;
  @Input() switchURL: any;
  @Input() title = 'Get a better deal';

  // billUsage: {
  //   billAmt: number;
  //   billDays: number;
  //   peakUsage: string;
  //   hasOffPeak: string;
  //   hasControlledLoad1: string;
  //   hasControlledLoad2: string;
  //   hasShoulder: string;
  // };
  constructor(
    private modalCtrl: ModalController,
    private logger: NGXLogger,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    // const redirectUrl = environment.switchRedirectUrl + `/${this.billPaymentId}`;

    // this.billUsage = {
    //   billAmt: this.billData.amount,
    //   billDays: getDayCount(this.billData.billStartDate, this.billData.billEndDate),
    //   peakUsage: this.billData.peakUsage,
    //   hasOffPeak: this.billData.offPeakUsage,
    //   hasControlledLoad1: this.billData.controlledLoad1,
    //   hasControlledLoad2: this.billData.controlledLoad2,
    //   hasShoulder: this.billData.shoulder1,
    // };
    // const params = {
    //   crmAcId: this.crmAccountId,
    //   billTypeId: this.accountBillTypeId,
    //   redirectUrl,
    //   source: '1bill',
    //   ...this.billUsage,
    // };

    // const querystring = encodeQueryData(params);
    // const url = environment.switchCCIframeUrl + `?${querystring}`;
    // this.logger.log('iframe url', url);

    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.switchURL);
    this.logger.log('iframe url', this.iframeUrl);
    // local env test url
    // this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl('http://127.0.0.1:8887/'+`?crmAcId=${this.crmAccountId}&billTypeId=${this.accountBillTypeId}&redirectUrl=${redirectUrl}&source=1bill`);
  }

  async closeModal() {
    await this.modalCtrl.dismiss(true);
  }
  async finished() {
    await this.modalCtrl.dismiss(true);
  }
}


