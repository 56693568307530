import {
  ROUTE_CONNECT_EMAIL,
  ROUTE_EMAIL_SCANNER,
  ROUTE_MOBILE_NUMBER,
  ROUTE_POINTS_INFO,
  ROUTE_SETTINGS_NOTIFICATION,
  ROUTE_SETTINGS_PAYMENT_INFO,
  ROUTE_UPLOAD_FIRST_BILL,
} from '@1bill-app/constants';
import { HomeService } from '@1bill-app/services/home/home.service';
import { Reward, RewardActionCodeMapping } from '@1bill-app/services/reward/reward.type';
import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-profile-todo-item',
  templateUrl: './profile-todo-item.component.html',
  styleUrls: ['./profile-todo-item.component.scss'],
})
export class ProfileTodoItemComponent implements OnInit {
  @Input() accordionState: 'expand' | 'collapse';
  @Input() rewardData: Reward;
  constructor(
    private navCtrl: NavController,
    private homeService: HomeService,
    private popoverController: PopoverController,
  ) {}

  ngOnInit() {}

  getPointsText(data: Reward) {
    let txt = data.rewardPoints + '';
    const rewardType =
      typeof data?.rewardType === 'string' ? data.rewardType.toLowerCase() : '';
    if (rewardType.includes('qantas frequent flyer')) {
      txt += ' 1bill pts';
    }
    if (!data.completed) {
      txt = txt;
    }
    return txt;
  }

  renderPointItemClass(data) {
    const classNames = [];
    if (this.accordionState === 'expand') {
      classNames.push('expand');
    } else {
      classNames.push('collapse');
    }
    if (data.completed) {
      classNames.push('complete');
    } else {
      classNames.push('incomplete');
    }
    return classNames.join(' ');
  }

  routePointsInfo() {
    this.navCtrl.navigateForward([ROUTE_POINTS_INFO]);
  }
  onItemClick(data: Reward) {
    if (data.completed) return;
    switch (data.actionCode) {
      case RewardActionCodeMapping.ADD_FIRST_BILL:
        this.navCtrl.navigateForward([ROUTE_UPLOAD_FIRST_BILL]);
        break;
      case RewardActionCodeMapping.ADD_PAYMENT_DETAILS:
        this.navCtrl.navigateForward([ROUTE_SETTINGS_PAYMENT_INFO]);
        break;
      case RewardActionCodeMapping.SET_NOTIFICATION:
        this.navCtrl.navigateForward([ROUTE_SETTINGS_NOTIFICATION]);
        break;
      case RewardActionCodeMapping.VERIFY_MOBILE_NUMBER:
        this.navCtrl.navigateForward([ROUTE_MOBILE_NUMBER]);
        break;
      case RewardActionCodeMapping.FIRST_CONNECT_EMAIL_WITH_SUPPLIER:
        this.navCtrl.navigateForward([ROUTE_CONNECT_EMAIL]);
        break;
      case RewardActionCodeMapping.EMAIL_SCANNER:
        this.navCtrl.navigateForward([ROUTE_EMAIL_SCANNER]);
        break;
      case RewardActionCodeMapping.LINK_BANK_ACCOUNT:
        this.linkBankAccounts();
        break;
    }
  }

  linkBankAccounts() {
    this.homeService.presentLinkBankAccountModal();
  }
}
