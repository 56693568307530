import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SelectableBill, BillCategoryKey, ViewBillType } from '@1bill-app/services/bill/types';
import { CategoryService } from '@1bill-app/services/category/category.service';

@Component({
  templateUrl: './selectable-bill.component.html',
  styleUrls: ['./selectable-bill.component.scss'],
  selector: 'app-selectable-bill',
})
export class SelectableBillComponent {
  constructor(private categoryService: CategoryService) {}

  @Input() bill: SelectableBill;
  @Input('showMore') showMoreSection = true;
  @Output() onSelectBill: EventEmitter<number> = new EventEmitter(null);

  toggleShowMore() {
    this.bill.showMore = !this.bill.showMore;
  }

  /**
   * Fired when the user selects the 'Select bill/Deselect bill' button in the list of bills
   * @param bill The bill being selected or deselected
   */
  toggleSelectBill() {
    this.bill.selected = !this.bill.selected;
    this.onSelectBill.emit(this.bill.billPaymentId);
  }

  getTypeIconSource(categoryKey: BillCategoryKey) {
    return this.categoryService.getCategoryDetails(categoryKey);
  }

  getCardIconUrl(bill: ViewBillType) {
    return this.categoryService.getCardIconUrl(bill);
  }
}
