import { PaymentCard } from '@1bill-app/services/payment/payment-card.service';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'paymentCardFilter',
    pure: false
})
export class paymentCardFilter implements PipeTransform {
    transform(items: PaymentCard[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.status === filter.status);
    }
}