import dayjs from 'dayjs'; // Replacement of moment since moment is no longer maintained
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

export function isDateValid(date: string) {
  return dayjs(date).isValid();
}

export function isDateWithinAMonth(dateTime: string) {
  const retval = dateTime ? dayjs(dateTime).diff(dayjs(), 'day') <= 30 : false;
  return retval;
}

export function isDateWithinAWeek(dateTime: string) {
  const retval = dateTime ? dayjs(dateTime).diff(dayjs(), 'day') <= 7 : false;
  return retval;
}

export function isDateWithinCurrentMonth(dateTime: string) {
  const currentDayjs = dayjs();
  const checkingDayjs = dayjs(dateTime);
  return currentDayjs.year() === checkingDayjs.year() && currentDayjs.month() === checkingDayjs.month();
}

export function isChartDateValid(date: string) {
  return dayjs(date, 'DD/MM/YYYY').isValid();
}

export function formatDateTrimmed(date: string) {
  return dayjs(date).format('D MMM');
}

export function formatDateChart(date: string, includeYear: boolean = false) {
  let format = 'MMM';
  if (includeYear) format = format + ' YY';
  return dayjs(date, 'DD/MM/YYYY').format(format);
}

export function formatDateStandard(date: string) {
  return dayjs(date).format('DD/MM/YYYY');
}

export function formatDateLong2(date: string) {
  return dayjs(date).format('LL');
}

export function dateNowToTime(date: string) {
  return dayjs().to(dayjs(date));
}

export function getDayCount(startDate: string, endDate: string) {
  return dayjs(endDate).diff(dayjs(startDate), 'day');
}

export function isDateInPast(date: string) {
  const now = dayjs();
  return dayjs(date).isBefore(now, 'day');
}

export function isDateExpired(date: string, expiry: number, expiryUnit: dayjs.OpUnitType) {
  return dayjs() > dayjs(date).add(expiry, expiryUnit);
}

export function isDateTimeWithin24Hrs(dateTime: string) {
  return dateTime ? dayjs().diff(dateTime, 'hour') <= 24 : false;
}

/**
 * Calculates how many months ago a date in the format YYYY-MM-DD is.
 * @param date a date in the format YYYY-MM-DD.
 * @param fromDate the date to count as the 'current date'; finding the offset from `date` If blank, equals today's date.
 * @returns {number} the number representing how many months ago the given date is
 */
export function getNativeMonthsOffset(date: string, fromDate?: string): number {
  // Set to beginning of month
  const dateObject = new Date(date);
  dateObject.setDate(1); // Set to first day of month

  const currentDate = fromDate ? new Date(fromDate) : new Date();
  currentDate.setDate(1);

  // 2629800000 = milliseconds in a month (assuming 30.4375 days per month)
  // Due to setDate(1) and rounding, this will always be the precise number of months elapsed from
  // the given date
  return Number(((currentDate.valueOf() - dateObject.valueOf()) / 2629800000).toFixed(0));
}

/**
 * Calculates how many weeks ago a date in the format YYYY-MM-DD is.
 * Note that this function considers Sunday the start of the week.
 * @param date a date in the format YYYY-MM-DD.
 * @returns {number} the number representing how many weeks ago the given date is
 */
export function getNativeWeeksOffset(date: string, fromDate?: string): number {
  const dateObject = new Date(date);
  // Set to first day of week (Sunday)
  dateObject.setDate(dateObject.getDate() - dateObject.getDay());

  const currentDate = fromDate ? new Date(fromDate) : new Date();
  currentDate.setDate(currentDate.getDate() - currentDate.getDay());

  // 604800000 = milliseconds in a week
  return Number(((currentDate.valueOf() - dateObject.valueOf()) / 604800000).toFixed(0));
}

export function getMomentMonthsOffset(momentString: string) {
  if (momentString.includes('month')) {
    if (momentString === 'a month ago') return 1;
    // Return x as number in 'x months ago'
    return Number(momentString.match(/\d+/)[0]);
  } else {
    if (momentString.includes('year')) {
      // x year[s] ago
      return 12;
    } else {
      // seconds, minutes, hours, days
      return 0;
    }
  }
}

export function getFullMonthName(monthAbbreviationOrDate: string, isDate = false) {
  if (isDate) {
    monthAbbreviationOrDate = dayjs(monthAbbreviationOrDate).format('MMM');
  }
  switch (monthAbbreviationOrDate.slice(0, 3).toLowerCase()) {
    case 'jan':
      return 'January';
    case 'feb':
      return 'February';
    case 'mar':
      return 'March';
    case 'apr':
      return 'April';
    case 'may':
      return 'May';
    case 'jun':
      return 'June';
    case 'jul':
      return 'July';
    case 'aug':
      return 'August';
    case 'sep':
      return 'September';
    case 'oct':
      return 'October';
    case 'nov':
      return 'November';
    case 'dec':
      return 'December';
    default:
      return monthAbbreviationOrDate;
  }
}

export function getLastOfInterval(interval: 'W' | 'M' | 'Y') {
  // Set to last day of current period to get all data up to that point
  switch (interval) {
    case 'M': {
      return dayjs().set('date', dayjs().daysInMonth()).format('YYYY-MM-DD');
    }
    break;
    case 'W': {
      return dayjs().set('day', 6).format('YYYY-MM-DD');
    }
    break;
    case 'Y': {
      return dayjs().set('month', 11).format('YYYY-MM-DD');
    }
    break;
    default: {
      console.warn('Unexpected interval:', interval);
      return dayjs().format('YYYY-MM-DD');
    }
  }
}
