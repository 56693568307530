import { PhotoBillDataModel, UploadPhotoEventStatus } from '@1bill-app/interfaces/photo-bill';
import {
  HttpClient,
  HttpEvent, HttpEventType, HttpHeaders, HttpParams, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { ImageEnhancerService } from '../image-enhancer/image-enhancer.service';
import { InterceptorSkipHeader } from '../interceptors/enum';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  photoBills: PhotoBillDataModel[] = [];
  constructor(
    private http: HttpClient,
    private api: ApiService,
    private imgEnhancer: ImageEnhancerService,
  ) {}

  // file from event.target.files[0]
  uploadFile<R>(url: string, file: File): Observable<HttpEvent<R>> {
    const params = new HttpParams();
    const headers = new HttpHeaders()
      .set('Content-Type', file.type)
      .set(InterceptorSkipHeader.Authorization, '')
      .set(InterceptorSkipHeader.CustomHeader, '');

    const options = {
      params,
      reportProgress: true,
      headers,
    };

    const req = new HttpRequest('PUT', url, file, options);
    return this.http.request(req);
  }
  // ! here
  getUploadUrl(fileName: string, mime: string, type: string) {
    return this.api.getUploadUrl(fileName, mime, type);
  }
  // ! here
  uploadPhotoBill(
    file: File,
    enhanceImage = true,
  ): Observable<{ url: string; s3Key: string }> {
    const generateId = () => Math.random().toString(36).substring(2, 15);
    const id = generateId();
    let ext = 'jpg';
    try {
      ext = file.type.split('image/')[1];
    } catch (err) {}
    const fileName = file.name ? file.name : id + '.' + ext;
    this.photoBills.push({
      id,
      file,
      dataUrl: URL.createObjectURL(file),
      status: UploadPhotoEventStatus.INIT,
      loading: true,
    });
    const setData = (data: any) => {
      const fileIndex = this.photoBills.findIndex((b) => b.id === id);
      const original = this.photoBills[fileIndex];
      return (this.photoBills[fileIndex] = { ...original, ...data });
    };
    const getData = () => this.photoBills.find((b) => b.id === id);
    return this.getUploadUrl(fileName, file.type, 'photo-bill').pipe(
      tap((data) => setData({ s3Url: data.s3Url, url: data.url, s3Key: data.s3Key })), // s3-url-to-key
      concatMap(() => this.uploadFile<{ data: any }>(getData().url, file)), // upload bill
      concatMap((data) => {
        switch (data.type) {
          case HttpEventType.Sent:
            return of({ status: UploadPhotoEventStatus.UPLOAD_STARTED });
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * data.loaded) / data.total);
            return of({ status: UploadPhotoEventStatus.UPLOAD_PROGRESS, progress });
          case HttpEventType.Response:
            if (enhanceImage) {
              setData({ status: UploadPhotoEventStatus.ENHANCE_PROGRESS });
              return this.imgEnhancer.enhanceS3KeyImage(
                { s3Key: getData().s3Key },
                { displayEnhancedImage: true },
              ); // done - maintain s3 key only here
            }
            return of({
              status: UploadPhotoEventStatus.ENHANCE_SKIPPED,
              url: getData().s3Url,
              s3Key: getData().s3Key, // s3-url-to-key
            });
          default:
            return of({ status: UploadPhotoEventStatus.UPLOAD_UNHANDLED, value: data.type });
        }
      }),
      map((data: any) => {
        setData({ status: data.status });
        if (
          data.status === UploadPhotoEventStatus.ENHANCE_SUCCESS ||
          data.status === UploadPhotoEventStatus.ENHANCE_SKIPPED
        ) {
          setData({ url: data.url });
        }
        if (data.dataUrl) {
          setData({ dataUrl: data.dataUrl });
        }
        return { url: data.url, s3Key: data.s3Key }; // s3-url-to-key
      }),
      finalize(() => {
        setData({
          status: UploadPhotoEventStatus.COMPLETE,
          loading: false,
        });
      }),
    );
  }
  getUploadUrlByProduct(fileName: string, mime: string, type: string, product) {
    return this.api.getUploadUrlByProduct(fileName, mime, type, product);
  }
  uploadIdDocument(file: File, enhanceImage = false) {
    const generateId = () => Math.random().toString(36).substring(2, 15);
    const id = generateId();
    let ext = 'jpg';
    try {
      ext = file.type.split('image/')[1];
    } catch (err) {}
    const fileName = file.name ? file.name : id + '.' + ext;
    let pictureDetail: any = {
      id,
      file,
      dataUrl: URL.createObjectURL(file),
      status: UploadPhotoEventStatus.INIT,
      loading: true,
    };
    return this.getUploadUrlByProduct(fileName, file.type, 'photo-bill', 'cards').pipe(
      tap((data) => {
        pictureDetail = {
          ...pictureDetail,
          s3Url: data.s3Url, // data.s3Key
          url: data.url,
          s3Key: data.s3Key,
        };
      }),
      concatMap(() => this.uploadFile<{ data: any }>(pictureDetail.url, file)), // upload bill - all good
      concatMap((data) => {
        switch (data.type) {
          case HttpEventType.Sent:
            return of({ status: UploadPhotoEventStatus.UPLOAD_STARTED });
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * data.loaded) / data.total);
            return of({ status: UploadPhotoEventStatus.UPLOAD_PROGRESS, progress });
          case HttpEventType.Response:
            if (enhanceImage) {
              pictureDetail.status = UploadPhotoEventStatus.ENHANCE_PROGRESS;
              return this.imgEnhancer.enhanceS3KeyImage(
                { s3Key: pictureDetail.s3Key, card:true },
                { displayEnhancedImage: false }, // displaying image not required
              );
              // return this.imgEnhancer.enhanceUrlImage(pictureDetail.s3Url, { base64: true }); // s3-key - pictureDetail.s3Key
            }
            return of({
              status: UploadPhotoEventStatus.ENHANCE_SKIPPED,
              url: pictureDetail.s3Url, // s3-key - pictureDetail.s3Key
              s3Key: pictureDetail.s3Key,
            });
          default:
            return of({ status: UploadPhotoEventStatus.UPLOAD_UNHANDLED, value: data.type });
        }
      }),
      map((data: any) => {
        pictureDetail.status = data.status;
        if (
          data.status === UploadPhotoEventStatus.ENHANCE_SUCCESS ||
          data.status === UploadPhotoEventStatus.ENHANCE_SKIPPED
        ) {
          pictureDetail.url = data.url;
        }
        if (data.dataUrl) {
          pictureDetail.dataUrl = data.dataUrl;
        }
        return { url: data.url, s3Key: data.s3Key };
      }),
      finalize(() => {
        pictureDetail.status = UploadPhotoEventStatus.COMPLETE;
        pictureDetail.loading = false;
      }),
    );
  }
  /* uploadPhotoBillByProduct(file: File, enhanceImage = true, product) {
    const generateId = () => Math.random().toString(36).substring(2, 15);
    const id = generateId();
    let ext = 'jpg';
    try {
      ext = file.type.split('image/')[1];
    } catch (err) {}
    const fileName = file.name ? file.name : id + '.' + ext;
    this.photoBills.push({
      id,
      file,
      dataUrl: URL.createObjectURL(file),
      status: UploadPhotoEventStatus.INIT,
      loading: true,
    });
    const setData = (data: any) => {
      const fileIndex = this.photoBills.findIndex((b) => b.id === id);
      const original = this.photoBills[fileIndex];
      return (this.photoBills[fileIndex] = { ...original, ...data });
    };
    const getData = () => this.photoBills.find((b) => b.id === id);
    return this.getUploadUrlByProduct(fileName, file.type, 'photo-bill', product).pipe(
      tap((data) => setData({ s3Url: data.s3Url, url: data.url })),
      concatMap(() => this.uploadFile<{ data: any }>(getData().url, file)), // upload bill
      concatMap((data) => {
        switch (data.type) {
          case HttpEventType.Sent:
            return of({ status: UploadPhotoEventStatus.UPLOAD_STARTED });
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * data.loaded) / data.total);
            return of({ status: UploadPhotoEventStatus.UPLOAD_PROGRESS, progress });
          case HttpEventType.Response:
            if (enhanceImage) {
              setData({ status: UploadPhotoEventStatus.ENHANCE_PROGRESS });
              return this.imgEnhancer.enhanceS3KeyImage(
                { s3Key: getData().s3Key },
                { base64: true },
              );
            }
            return of({
              status: UploadPhotoEventStatus.ENHANCE_SKIPPED,
              url: getData().s3Url,
              s3Key: getData().s3Key, // s3-url-to-key
            });
          default:
            return of({ status: UploadPhotoEventStatus.UPLOAD_UNHANDLED, value: data.type });
        }
      }),
      map((data: any) => {
        setData({ status: data.status });
        if (
          data.status === UploadPhotoEventStatus.ENHANCE_SUCCESS ||
          data.status === UploadPhotoEventStatus.ENHANCE_SKIPPED
        ) {
          setData({ url: data.url });
        }
        if (data.dataUrl) {
          setData({ dataUrl: data.dataUrl });
        }
        return { url: data.url, s3Key: data.s3Key };
      }),
      finalize(() => {
        setData({
          status: UploadPhotoEventStatus.COMPLETE,
          loading: false,
        });
      }),
    );
  } */
  clearPhotoBillsUpload() {
    this.photoBills = [];
  }
}
