import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { BillService } from '@1bill-app/services/bill/bill.service';
import { RewardQuery } from '@1bill-app/services/reward/reward.query';
import { RewardService } from '@1bill-app/services/reward/reward.service';
import { SettingsQuery } from '@1bill-app/services/settings/settings.query';

import { SettingsService } from '@1bill-app/services/settings/settings.service';

import { Component, OnDestroy, OnInit } from '@angular/core';

import { SubSink } from 'subsink';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss'],
})
export class ProfileHeaderComponent implements OnInit, OnDestroy {
  subs = new SubSink();
  userDetails: any;
  firstName: string;
  lastName: string;
  initials = '';
  
  totalPointsBalance$ = this.rewardQuery.select('totalPointsBalance');
  totalBillSaving;

  constructor(
    public query: SettingsQuery,
    private _settings: SettingsService,
    private rewardService: RewardService,
    public rewardQuery: RewardQuery,
    public billQuery: BillQuery,
    public billService: BillService,

  ) {}

   ngOnInit() {
  
    this.subs.sink = this._settings.fetch().subscribe((settingsData) => {
      if(settingsData){
        this.userDetails = settingsData;
        this.lastName = this.userDetails.lastName;
        this.firstName = this.userDetails.firstName;
        this.initials = this.firstName[0] + this.lastName[0];
      }
    });

    this.subs.sink = this.billService.fetchBill().subscribe();

    this.billQuery.totalBillSaving$.subscribe(res=>{
      this.totalBillSaving = res;
    });
   
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
