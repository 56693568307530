import { PopoverJobInfoUpdateComponent } from '@1bill-app/components/popover/popover-job-info-update/popover-job-info-update.component';
import { ROUTE_UPLOAD_FIRST_BILL_SELECT_PAGE } from '@1bill-app/constants';
import {
  AppsflyerService,
  UploadedBillMimeTypes,
  UserRegistrationMethods,
} from '@1bill-app/services/analytics/appsflyer.service';
import { BillQuery } from '@1bill-app/services/bill/bill.query';
import { StorageItemKeys, StorageService } from '@1bill-app/services/storage.service';
import { UploadQuery } from '@1bill-app/services/upload/upload.query';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Facebook } from '@awesome-cordova-plugins/facebook/ngx';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
})
export class UploadFileModalComponent implements OnInit, OnDestroy {
  isUploading$ = this.uploadQuery.select('isUploading');
  uploaded$ = this.uploadQuery.select('hasUploaded');
  hasUploadErr$ = this.uploadQuery.select('hasUploadErr');
  uploadedSubscription$: Subscription;
  hasUploadErrSubscription$: Subscription;
  @Input() source: 'IMG' | 'PDF';

  constructor(
    private modalCtrl: ModalController,
    private uploadQuery: UploadQuery,
    private router: Router,
    private storageSvc: StorageService,
    private alertController: AlertController,
    private popoverController: PopoverController,
    private billQuery: BillQuery,
    private fb: Facebook,
    private appsflyerSvc: AppsflyerService,
  ) {}

  ngOnInit() {
    this.uploadedSubscription$ = this.uploaded$.subscribe((isUploaded) => {
      if (isUploaded) this.presentUploadFinished();
    });
    this.hasUploadErrSubscription$ = this.hasUploadErr$.subscribe((hasError) => {
      if (hasError) this.presentUploadFailed();
    });
  }

  ngOnDestroy() {
    this.uploadedSubscription$.unsubscribe();
    this.hasUploadErrSubscription$.unsubscribe();
  }

  async presentUploadFinished() {
    if (this.source == 'IMG') {
      // Because IMG are already uploaded beforehand
      // and modal gets dismissed very quickly after
      // creating
      setTimeout(
        function () {
          this.onGoHomeClick({ uploadedBillType: UploadedBillMimeTypes.IMG });
        }.bind(this),
        1000,
      );
    } else {
      this.onGoHomeClick({ uploadedBillType: UploadedBillMimeTypes.PDF });
    }

    // const alert = await this.alertController.create({
    //   mode: "ios",
    //   cssClass: 'bill-alert alert-icon',
    //   header: 'Bill has been successfully uploaded',
    //   message: '<img src="assets/icon/alert/success.svg" alt="success-icon" />',
    //   buttons: [
    //     {
    //       text: 'Okay',
    //       handler: () => {
    //         this.onGoHomeClick();
    //       }
    //     }
    //   ]
    // });

    // await alert.present();
  }

  async presentUploadFailed() {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'bill-alert alert-icon',
      message:
        '<img src="assets/icon/alert/warning.svg" alt="warning-icon" /><h2 class="alert-title">Sorry, our bad</h2><p class="alert-text">Due to technical issues, we could not process your last bill. Please try uploading it again shortly.</p>',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.onGoHomeClick({ success: false });
          },
        },
      ],
    });

    await alert.present();
  }

  onBackBtnClick() {
    this.modalCtrl.dismiss(null, 'go-home');
  }
  async onGoHomeClick({
    success = true,
    uploadedBillType,
  }: {
    success?: boolean;
    uploadedBillType?: UploadedBillMimeTypes;
  }) {
    if (success) {
      // appsflyer
      this.appsflyerSvc.billUpload(uploadedBillType);
      // check if has existing bill type
      if (this.billQuery.getValue().accountBillTypes?.length == 0) {
        this.fb.logEvent('Start Trial');
        // first bill uploaded
      }
      const { directDebit, alreadyPaid, cusSelected } = await this.presentJobInfoPopover();
      await this.storageSvc.setItem({
        key: StorageItemKeys.FIRST_BILL_UPLOADED,
        value: 'true',
      });
      await this.modalCtrl.dismiss(
        { directDebit, alreadyPaid, cusSelected },
        'go-home',
        'file-uploading-modal',
      );
    } else {
      await this.modalCtrl.dismiss(
        { directDebit: null, alreadyPaid: null, cusSelected: null },
        'go-home',
        'file-uploading-modal',
      );
    }
  }
  async goToFirstBillSelect() {
    await this.modalCtrl.dismiss({
      // 'dismissed': true
    });
    this.router.navigate([ROUTE_UPLOAD_FIRST_BILL_SELECT_PAGE]);
  }

  async presentJobInfoPopover() {
    const popover = await this.popoverController.create({
      component: PopoverJobInfoUpdateComponent,
      cssClass: 'onebill-medium-popover',
      mode: 'ios',
      backdropDismiss: false,
    });
    await popover.present();
    const { data } = await popover.onDidDismiss();
    if (data && data.type == 'OK') {
      const { directDebit, alreadyPaid } = data.info;
      return { directDebit, alreadyPaid, cusSelected: true };
      // this.updateJobMetaInfo({ directDebit, alreadyPaid, jobId });
    } else {
      return { directDebit: null, alreadyPaid: null, cusSelected: false };
    }
  }
}
